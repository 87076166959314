import m from 'mithril'
import {classes} from '@bitstillery/common/lib/utils.ts'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {api} from '@bitstillery/common/app.ts'
import {Icon} from '@bitstillery/common/components/ui/icon/icon.tsx'
import {watch} from '@bitstillery/common/lib/store.ts'
import {Spinner} from '@bitstillery/common/components/ui/spinner/spinner.tsx'
import {Amount} from '@bitstillery/common/components/ui/amount/amount.tsx'

import {GetSalesOrderFiguresResponse, SalesOrderStatus} from '@/factserver_api/fact2server_api'

interface SalesOrderFiguresWidgetAttrs {
    model: {
        user_artkey: number | null
    }
}

export class SalesOrderFiguresWidget extends MithrilTsxComponent<SalesOrderFiguresWidgetAttrs> {
    watchers: any[] = []
    user_artkey: number | null
    loading_figures: boolean = true
    sales_order_figures: GetSalesOrderFiguresResponse

    async oninit(vnode: m.Vnode<SalesOrderFiguresWidgetAttrs>) {
        this.watchers.push(watch(vnode.attrs.model, 'user_artkey', (user_artkey: number | null) => {
            this.user_artkey = user_artkey
            this.fetch_so_figures(user_artkey)
        }))
    }

    ondestroy(): void {
        this.watchers.forEach((unwatch: any) => unwatch())
    }

    async fetch_so_figures(user_artkey: number | null) {
        this.loading_figures = true
        const artkeys: number[] = []
        if (user_artkey) {
            artkeys.push(user_artkey)
        }
        const filter_str = `${encodeURI(JSON.stringify({sales_manager_artkey: artkeys}))}`
        const {result} = await api.get<GetSalesOrderFiguresResponse>(
            `discover/sales-orders/key-figures?filters=${filter_str}`,
        )
        this.sales_order_figures = result
        this.loading_figures = false
    }

    to_so_list(filter: object = {}): void {
        if (this.user_artkey) {
            filter['sales_manager_artkey'] = this.user_artkey.toString()
        }
        m.route.set(`/sales/orders/list/manage?${new URLSearchParams(filter as any).toString()}`)
    }

    view(): m.Children {

        return (
            <div className={classes('c-widget-sales-order-figures dashboard-widget', {
                loading: !this.sales_order_figures,
            })}
            >
                <div className="widget-title">
                    Sales order figures
                </div>
                {this.loading_figures && <Spinner />}
                {!this.loading_figures && (
                    <div className="c-sales-figures-widget">
                        <div className="statistics-group horizontal">
                            <div
                                className="stat danger"
                                onclick={() => {this.to_so_list({process_state: encodeURI('Overdue orders')}) }}
                            >
                                <div className="amount">
                                    <Icon name="warning" type="unset"/>
                                    {this.sales_order_figures.old_orders}
                                </div>
                                <div>
                                    <div className="text">Overdue</div>
                                    <Amount amount={this.sales_order_figures.old_total} currency={'EUR'} />
                                </div>
                            </div>
                            <div
                                className="stat warning"
                                onclick={() => {this.to_so_list({sales_order_state: SalesOrderStatus.Saved}) }}
                            >
                                <div className="amount">
                                    <Icon name="refresh" type="unset"/>
                                    {this.sales_order_figures.pending_portal_orders}
                                </div>
                                <div>
                                    <div className="text">Pending</div>
                                    <Amount amount={this.sales_order_figures.pending_total} currency={'EUR'} />
                                </div>
                            </div>
                            <div
                                className="stat default"
                                onclick={() => {this.to_so_list({sales_order_state: SalesOrderStatus.Saved}) }}
                            >
                                <div className="amount">
                                    <Icon name="save" type="unset"/>
                                    {this.sales_order_figures.saved_sales_orders}
                                </div>
                                <div>
                                    <div className="text">Saved & Finalized</div>
                                    <Amount amount={this.sales_order_figures.saved_total} currency={'EUR'} />
                                </div>
                            </div>
                            <div
                                className="stat info"
                                onclick={() => {this.to_so_list({sales_order_state: SalesOrderStatus.Confirmed}) }}
                            >
                                <div className="amount">
                                    <Icon name="sales" type="unset"/>
                                    {this.sales_order_figures.confirmed_sales_orders}
                                </div>
                                <div>
                                    <div className="text">Confirmed</div>
                                    <Amount amount={this.sales_order_figures.confirmed_total} currency={'EUR'} />
                                </div>
                            </div>
                            <div
                                className="stat success"
                                onclick={() => {this.to_so_list({process_state: encodeURI('In progress orders')}) }}
                            >
                                <div className="amount">
                                    <Icon name="truck" type="unset"/>
                                    {this.sales_order_figures.in_progress_sales_orders}
                                </div>
                                <div>
                                    <div className="text">In Progress</div>
                                    <Amount amount={this.sales_order_figures.in_progress_total} currency={'EUR'} />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        )
    }
}
