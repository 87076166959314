import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'
import {to_specs} from '@bitstillery/common/models/item'
import {
    Button,
    ButtonGroup,
    FieldMoney,
    FieldSelect,
    FieldText,
    Icon,
} from '@bitstillery/common/components'
import {countries} from '@bitstillery/common/lib/countries'
import {
    invalid_fields,
    invalid_fields_format,
    reset_validation,
} from '@bitstillery/common/lib/validation'
import {CollectionProxy} from '@bitstillery/common/lib/collection'
import {api} from '@bitstillery/common/app'
import {classes} from '@bitstillery/common/lib/utils'
import {merge_deep} from '@bitstillery/common/lib/utils'

import {context, EntitySpli, EntitySplsl, methods} from '@/market/pricelists/view/lib/context'
import {$m, $s} from '@/app'
import {GiftBoxTypeDropDown} from '@/components/case_inputs'
import {IncotermsDropDown} from '@/components/incoterms'
import {IncotermsDropDownData} from '@/factserver_api/incoterms_api'
import {EntityType} from '@/market/pricelists/view/lib/context'

interface PricelistItemAttrs {
    collection: CollectionProxy
    /** Either create a spli from a sourceline or update an existing spli */
    entity_type: EntityType.SPLI | EntityType.SPLSL
}

/**
 * This form component handles both resolved (SPLI) and
 * unresolved (SPLSL) items. The context component determines
 * which entity type is being handled.
 */
export class PricelistItem extends MithrilTsxComponent<PricelistItemAttrs> {

    view(vnode: m.Vnode<PricelistItemAttrs, {}>): m.Children {
        const entity = context.data.entities[vnode.attrs.entity_type] as EntitySpli | EntitySplsl
        const entity_type = vnode.attrs.entity_type
        let invalid = invalid_fields(context.$v[entity_type])

        const product_status = {
            icon: context.data.linked.product ? 'link' : 'linkOff',
            text: (() => {
                const linked = [] as any
                if (context.data.loading.pricelist_item) return 'Loading...'
                if (context.data.linked.product) linked.push('Product name')
                if (context.data.linked.specs) linked.push('Specs')
                if (context.data.linked.gtin) linked.push('GTIN')

                if (linked.length) return `Linked: ${linked.join(', ')}`
                return 'Unlinked; no product/specs linked yet'
            })(),
            type: (() => {
                if (context.data.loading.pricelist_item) return 'default'
                if (context.data.linked.product && context.data.linked.specs && context.data.linked.gtin) return 'success'
                if (context.data.linked.product && context.data.linked.specs) return 'success'
                if (context.data.linked.product) return 'info'
                return 'default'
            })(),
        }

        return <div className="c-upsert-spli">
            <div className={classes('spli-status', product_status.type)}>
                <Icon
                    name={product_status.icon}
                    type={product_status.type}
                />
                <span>{product_status.text}</span>
            </div>

            <div className="context-well">
                <div className="field-group">
                    <FieldText
                        disabled={context.data.linked.gtin}
                        help={(() => {
                            if (context.data.linked.product || context.data.linked.gtin) {
                                return 'Good, we know this product already!'
                            }

                            return 'Do you REALLY need to create a new product?'
                        })()}
                        label="Product name"
                        oninput={async(filter_text: string) => {
                            if (filter_text.length <= 3) return []
                            const {result} = await api.get(`discover/products?search_terms=${filter_text}`) as any
                            const search_options = result.map((i) => ({value: i.name, label: i.name, ...i}))
                            context.data.search_options.splice(0, context.data.search_options.length, ...search_options)
                        }}
                        placeholder="Product name"
                        search={{
                            linked: !!entity.product_artkey,
                            onreset: () => {
                                // Clear the reference to an existing product; we're about to create a new one.
                                merge_deep(entity, {
                                    bottle_artkey: null,
                                    product_artkey: null,
                                    product_category_artkey: null,
                                })
                                merge_deep(context.data, {
                                    bottles: [],
                                    linked: {product: false, gtin: false, specs: false},
                                })
                            },
                            onsuggestion: async(suggestion) => {
                                context.data.bottles.length = 0
                                reset_validation(context.$v[entity_type])

                                await methods.match_from_data({
                                    country_of_origin: suggestion.default_country_code,
                                    product_artkey: suggestion.artkey,
                                    product_category: suggestion.product_category,
                                    product_category_artkey: suggestion.product_category.artkey,
                                    product_name: suggestion.name,
                                }, suggestion.bottles, entity)
                            },
                            options: context.data.search_options,
                        }}
                        model={[entity, 'product_name']}
                        validation={context.$v[entity_type].product_name}
                    />

                    <div className="field gtin-field">
                        <FieldText
                            disabled={context.data.linked.gtin}
                            help="Product & specs from GTIN"
                            label="GTIN (Bottle)"
                            model={[entity, 'bottle_gtin_code']}
                            validation={context.$v[entity_type].bottle_gtin_code}
                            formatter={(value: string) => value.replace(/\D/g, '').slice(0, 13)}
                        >
                            <Button
                                disabled={!context.data.linked.gtin && !entity.bottle_gtin_code || context.$v[entity_type].bottle_gtin_code._invalid}
                                icon={context.data.linked.gtin ? 'linkOff' : 'link'}
                                onclick={async() => {
                                    if (context.data.linked.gtin) {
                                        entity.bottle_gtin_code = null
                                        context.data.linked.gtin = false
                                    } else if (entity.bottle_gtin_code) {
                                        await methods.match_from_gtin(entity)
                                    }
                                }}
                                tip={() => context.data.linked.gtin ? 'Clear bottle GTIN' : 'Match product & specs from bottle GTIN'}
                                type={context.data.linked.gtin ? 'danger' : 'info'}
                            />
                        </FieldText>
                    </div>
                </div>

                <div className="field-group">
                    <FieldSelect
                        disabled={context.data.linked.product}
                        help={context.data.linked.product ? 'Category from product' : 'Select a product category'}
                        label="Category"
                        model={[entity, 'product_category_artkey']}
                        options={context.data.product_categories.map((category: any) => ({
                            label: category.name,
                            value: category.artkey,
                        }))}
                        placeholder={'Product category...'}
                        validation={context.$v[entity_type].product_category_artkey}
                    />
                    <FieldSelect
                        disabled={context.data.linked.gtin}
                        help={context.data.linked.specs ? 'Good! Use existing specs where possible' : 'Do you REALLY need new specs?'}
                        label="Specs"
                        model={[entity, 'bottle_artkey']}
                        onchange={(bottle_artkey: number) => {
                            // Make sure specs validation isn't triggered yet; no need for validation warnings to begin with.
                            reset_validation(context.$v[entity_type])
                            const bottle = context.data.bottles.find((i) => i.artkey === bottle_artkey)
                            if (bottle) {
                                merge_deep(entity, {
                                    alcohol_percentage: bottle.alcohol_percentage,
                                    refill_status: bottle.refill_status,
                                    volume: bottle.volume,
                                })
                                context.data.linked.specs = true
                            } else {
                                context.data.linked.specs = false
                                merge_deep(entity, {alcohol_percentage: null, refill_status: null, volume: null})
                            }
                        }}
                        options={context.data.bottles.map((bottle: any) => ({
                            label: to_specs(bottle, $s.identity.user.decimal_locale),
                            value: bottle.artkey,
                        }))}
                        placeholder="New Specs..."
                    />
                </div>

                {!entity.bottle_artkey && <div className="field-group">
                    <FieldText
                        disabled={context.data.linked.specs || context.data.linked.gtin}
                        label="Size cl"
                        min={0}
                        model={[entity, 'volume']}
                        step="0.1"
                        type="number"
                        validation={context.$v[entity_type].volume}
                    />

                    <FieldText
                        disabled={context.data.linked.specs || context.data.linked.gtin}
                        label="Alcohol %"
                        max={100}
                        min={0}
                        model={[entity, 'alcohol_percentage']}
                        step="0.1"
                        type="number"
                        validation={context.$v[entity_type].alcohol_percentage}
                    />

                    <FieldSelect
                        disabled={context.data.linked.specs || context.data.linked.gtin}
                        label="Refill status"
                        model={[entity, 'refill_status']}
                        options={$m.data.bottle_refill_statusses.map((i: string) => ({
                            value: i,
                            label: i,
                        }))}
                        placeholder="select..."
                        validation={context.$v[entity_type].refill_status}
                    />
                </div>}
            </div>

            <div className="fieldset">
                <div className="field-group">
                    <div className="field-merge">
                        <Button
                            className="mt-3"
                            icon={entity.per_case ? 'case' : 'bottle'}
                            onclick={async() => {
                                entity.per_case = !entity.per_case

                                if (entity.per_case) {
                                    if (entity.number_of_bottles) {
                                        entity.number_of_cases = Math.ceil(entity.number_of_bottles / entity.number_of_bottles_per_case)
                                    }
                                    entity.number_of_bottles = undefined
                                    if (entity.price_per_bottle) {
                                        entity.price_per_case = +(Number(entity.price_per_bottle) * entity.number_of_bottles_per_case).toFixed(2)
                                    }
                                    entity.price_per_bottle = null
                                } else {
                                    if (entity.number_of_cases) {
                                        entity.number_of_bottles = entity.number_of_cases * entity.number_of_bottles_per_case
                                    }
                                    entity.number_of_cases = undefined
                                    if (entity.price_per_case) {
                                        entity.price_per_bottle = +(Number(entity.price_per_case) / entity.number_of_bottles_per_case).toFixed(2)
                                    }
                                    entity.price_per_case = null
                                }
                            }}
                            tip={() => {
                                return `Use ${entity.per_case ? 'cases' : 'bottles'} for the unit price`
                            }}
                            type="info"
                        />
                        <FieldText
                            className="mw-120"
                            label={`Quantity (${entity.per_case ? 'case' : 'bottle'})`}
                            min={0}
                            model={entity.per_case ? [entity, 'number_of_cases'] : [entity, 'number_of_bottles']}
                            placeholder="..."
                            type="number"
                        />
                    </div>

                    <FieldText
                        label="Bottles per case"
                        min={1}
                        model={[entity, 'number_of_bottles_per_case']}
                        onafterupdate={(value: number) => {
                            if (value) {
                                // Adjust the case price when the number of bottles per case changes.
                                if (entity.per_case && entity.price_per_case) {
                                    entity.price_per_case = +(
                                        +entity.price_per_case / entity.previous_number_of_bottles_per_case * value
                                    ).toFixed(2)
                                }
                                entity.previous_number_of_bottles_per_case = value
                            }
                        }}
                        type="number"
                        validation={context.$v[entity_type].number_of_bottles_per_case}
                    />

                    <FieldMoney
                        change_currency={true}
                        currency={[entity, 'currency']}
                        label={`Price (${entity.currency})`}
                        min={0}
                        model={entity.per_case ? [entity, 'price_per_case'] : [entity, 'price_per_bottle']}
                        validation={entity.per_case ? context.$v[entity_type].price_per_case : context.$v[entity_type].price_per_bottle}
                    />
                </div>

                <div className="field-group">
                    <FieldSelect
                        label="Country of Origin"
                        model={[entity, 'country_of_origin']}
                        options={Object.entries(countries).map(([value, label]) => ({label, value}))}
                        placeholder="Select..."
                        validation={context.$v[entity_type].country_of_origin}
                    />

                    <FieldSelect
                        label="Customs status"
                        model={[entity, 'customs_status']}
                        options={[
                            {label: 'T1', value: 'T1'},
                            {label: 'T2', value: 'T2'},
                        ]}
                        placeholder={'Select...'}
                        validation={context.$v[entity_type].customs_status}
                    />

                </div>

                <div className="field-group">
                    <GiftBoxTypeDropDown
                        model={[entity, 'gift_box_type']}
                    />
                    <FieldText
                        label="GTIN (Case)"
                        model={[entity, 'case_gtin_code']}
                    />
                </div>

                <div className="field-group">
                    <IncotermsDropDown
                        get_all_for_drop_down_response$={IncotermsDropDownData.incoterms()}
                        label="Incoterm"
                        model={[entity, 'incoterm']}
                    />
                    <FieldText
                        label="Location"
                        model={[entity, 'incoterm_location']}
                    />
                </div>

                <div className="field-group">
                    <FieldText
                        label="Cases per pallet"
                        model={[entity, 'cases_per_pallet']}
                    />
                    <FieldText
                        label="Cases per pallet layer"
                        model={[entity, 'cases_per_pallet_layer']}
                    />
                </div>

                <div className="field-group">
                    <FieldText
                        label="Availability status"
                        model={[entity, 'availability_status']}
                    />
                    <FieldText
                        label="Aux info"
                        model={[entity, 'aux_info']}
                        oninput={(value: string | null) => entity.aux_info = value}
                    />
                </div>

                <ButtonGroup>
                    <Button
                        disabled={invalid.length}
                        icon="save"
                        onclick={() => methods.upsert_entity(vnode.attrs.entity_type)}
                        text={(() => {
                            if (vnode.attrs.entity_type === EntityType.SPLSL) {
                                return 'Resolve Sourceline'
                            } else {
                                if (entity.artkey) {
                                    return 'Update Pricelist Item'
                                }
                                return 'Add Pricelist Item'
                            }
                        })()}
                        tip={() => invalid_fields_format(invalid_fields(context.$v[entity_type]), 'tip')}
                        type="success"
                    />
                </ButtonGroup>
            </div>
        </div>
    }
}
