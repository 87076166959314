import {ContextProvider, ContextProviderDataGeneric} from '@bitstillery/common/lib/context'

const data = {
    entity_artkey: null,
    entity_type: '' as 'statistics',
    entities: {
        statistics: {
            meta: false,
            title: 'Statistics',
        },
    },
    root_artkey: null!,
    root_path: '/sales/orders/list',
} satisfies ContextProviderDataGeneric

type ListContextData = typeof data

export const context = new ContextProvider<ListContextData>({
    data,
    name: 'sales.orders.list',
    route: {
        match: '/sales/orders/list{/:tab_id}{/:entity_type}',
        root: '/sales/orders/list/manage',
    },
    transforms: {
        bootstrap: async() => {},
        fetch_entity: async() => {},
    },
})
