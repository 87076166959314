import m from 'mithril'
import {classes} from '@bitstillery/common/lib/utils'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {proxy} from '@bitstillery/common/lib/proxy'
import {Icon} from '@bitstillery/common/components'
import {current_account_slug} from '@bitstillery/common/account/account'
import {$s, $t, notifier, store} from '@bitstillery/common/app'

export class PanelLeft extends MithrilTsxComponent<any> {
    className: string
    logo_path: string
    data = proxy({
        betaClicker: 0,
    })

    slot_menu() {
        throw new Error('panel left menu slot not implemented')
    }

    slot_toggles() {
        throw new Error('panel left toggles slot not implemented')
    }

    view(_:m.Vnode<any>) {
        return (
            <div className={classes('c-panel-left', 'panel', this.className, {
                collapsed: $s.panels.left.collapsed || ['mobile', 'tablet'].includes($s.env.layout),
            })}>
                <div className="logo">
                    <a href="#!/">
                        {(() => {
                            let logo_path
                            if (process.env.MSI_PACKAGE === 'discover') {
                                logo_path = `/theme/img/discover/${current_account_slug()}-logo`
                            } else {
                                logo_path = '/theme/img/logo'
                            }
                            return [
                                <img src={`${logo_path}-simple.png`} class="collapsed"/>,
                                <img src={`${logo_path}.png`} class="collapse"/>,
                            ]
                        })()}
                    </a>

                    <Icon
                        className="version"
                        name="info"
                        size="s"
                        onclick={() => {
                            this.data.betaClicker += 1
                            if (this.data.betaClicker % 7 === 0) {
                                $s.settings.beta = !$s.settings.beta
                                notifier.notify($t(`settings.beta.${$s.settings.beta ? 'active' : 'inactive'}`), 'info')
                                store.save()
                            }
                        }}
                        tip={() => {
                            return `Made with ♥️ by <a href="https://bitstillery.com" target="_blank">
                            Bitstillery</a><br/>
                            Version ${process.env.MSI_VERSION}-${process.env.BUN_ENV?.substring(0, 3)} (${$s.settings.beta ? 'beta' : 'stable'})`
                        }
                        }
                        tip_attrs={{delay: [2000, 200], interactive: true, placement: 'right'}}
                    />

                </div>

                {this.slot_menu()}
                <div className="toggles">
                    {this.slot_toggles()}
                </div>
            </div>
        )
    }
}
