import {Observable} from 'rxjs'
import {map} from 'rxjs/operators'

import {Api, construct_query_parameters, create_download_for_blob, FactserverEmptyResponse} from './api'
import {
    CreateProductPhotoRequest, CreateProductPhotoResponse,
    GetProductPhotoResponse, OkResponse,
    RotateDirection,
    RotateProductPhotoResponse, UpdateProductPhotoRequest, UpdateProductPhotoResponse,
} from './fact2server_api'

export class ProductPhotoApi {
    api = new Api()

    get_all_photos_for_stock_item(item_artkey: number): Observable<GetProductPhotoResponse[]> {
        const filter = {}
        const url_params = construct_query_parameters(
            0, [], 'rank', true, filter, 0,
        )
        return this.api.get(`discover/items/${item_artkey}/product-photos?${url_params.toString()}`)
    }

    get_all_photos_for_lot(lot: string): Observable<GetProductPhotoResponse[]> {
        const filter = {}
        const url_params = construct_query_parameters(
            0, [], 'rank', true, filter, 0,
        )
        return this.api.get(`discover/loendersloot-stock/${lot}/product-photos?${url_params.toString()}`)
    }

    get_all_photos_for_tbo_offer_item(offer_item_artkey?: number): Observable<GetProductPhotoResponse[]> {
        const filter = {}
        const url_params = construct_query_parameters(
            0, [], 'rank', true, filter, 0,
        )
        return this.api.get(`discover/offer-items/${offer_item_artkey}/product-photos?${url_params.toString()}`)
    }

    delete(product_photo_artkey: number): Observable<FactserverEmptyResponse> {
        return this.api.delete(`discover/product-photos/${product_photo_artkey}`)
    }

    rotate(
        product_photo_artkey: number,
        rotate_direction: RotateDirection,
    ): Observable<RotateProductPhotoResponse> {
        return this.api.post_fact2server_request(`discover/product-photos/${product_photo_artkey}/rotate`, {
            rotate_direction: rotate_direction,
        })
    }

    approve(product_photo_artkey: number): Observable<RotateProductPhotoResponse> {
        return this.api.post_fact2server_request(`discover/product-photos/${product_photo_artkey}/approve`, {
        })
    }

    sort(artkeys: number[]): Observable<OkResponse> {
        return this.api.post_fact2server_request('discover/product-photos/sort', {
            artkeys: artkeys,
        })
    }

    update(artkey: number, request: UpdateProductPhotoRequest): Observable<UpdateProductPhotoResponse> {
        return this.api.put(`discover/product-photos/${artkey}`, request)
    }

    get_file(artkey: number, file_name: string): Observable<boolean> {
        return this.api.get(`discover/product-photos/${artkey}/download`)
            .pipe(
                map((response: Blob) =>
                    create_download_for_blob(response, file_name),
                ))
    }

    download_all_for_lot(loendersloot_lot: string, include_internal: boolean, file_name: string): void {
        this.api
            .get(`discover/loendersloot-stock/${loendersloot_lot}/product-photos/download?include_internal=${include_internal}`)
            .subscribe((response: Blob) => {
                create_download_for_blob(response, file_name)
            })
    }

    download_all_for_offer_item(offer_item_artkey: number, include_internal: boolean, file_name: string): void {
        this.api
            .get(`discover/offer-items/${offer_item_artkey}/product-photos/download?include_internal=${include_internal}`)
            .subscribe((response: Blob) => {
                create_download_for_blob(response, file_name)
            })
    }

    download_all_for_item(item_artkey: number, include_internal: boolean, file_name: string): void {
        this.api
            .get(`discover/items/${item_artkey}/product-photos/download?include_internal=${include_internal}`)
            .subscribe((response: Blob) => {
                create_download_for_blob(response, file_name)
            })
    }

    upload_photo_for_item(item_artkey: number, create_photo_request: CreateProductPhotoRequest): Observable<CreateProductPhotoResponse> {
        return this.api.post_fact2server_request(`discover/items/${item_artkey}/product-photos`, create_photo_request)
    }

    upload_photo_for_offer_item(offer_item_artkey: number, create_photo_request: CreateProductPhotoRequest): Observable<CreateProductPhotoResponse> {
        return this.api.post_fact2server_request(`discover/offer-items/${offer_item_artkey}/product-photos`, create_photo_request)
    }
}
