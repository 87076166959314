import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {format_iso_to_date} from '@bitstillery/common/lib/format'
import {
    Button,
    CollectionHeader,
    CollectionItems,
    CollectionView,
    Link,
    PanelFilters,
    RowActionDelete,
} from '@bitstillery/common/components'
import {CollectionTransforms, CollectionProxy} from '@bitstillery/common/lib/collection'
import {generate_filters} from '@bitstillery/common/lib/filters'
import {api, notifier} from '@bitstillery/common/app'

import {ProcessVouchers} from './processes'

const filters = generate_filters<any>({
    search_terms: {
        icon: 'search',
        placement: {order: 0},
        serialize: 'string',
        type: 'TEXT',
    },
})

const transforms:CollectionTransforms = {
    filters_to_query: (filters) => {
        const query = {
            ascending: collection.state.sort.order === 'asc' ? true : false,
            filters: {},
            page_size: collection.state.page_size,
            search_terms: [filters.search_terms.selection ? filters.search_terms.selection : ''],
            sort_by: collection.state.sort.by,
        }
        return query
    },
}

const collection = new CollectionProxy()

const columns = [
    {
        name: 'Name',
        render: (row) => {
            return <div className="td-group">
                <span className={'header'}>
                    {row.name ? <Link
                        href={`/offer/vouchers/${row.artkey}/relation-selection`}
                    >
                        {row.name}
                    </Link> : 'Unknown relation'}
                </span>
            </div>
        },
    }, {
        name: 'Code',
        render: (row) => row.code,
    }, {
        name: 'Value',
        render: (row) => {
            if (row.voucher_value_type === 'FIXED') {
                return `${row.value} EUR`
            } else if (row.voucher_value_type === 'PERCENTAGE') {
                return `${row.value}%`
            } else {
                return row.value
            }

        },
    }, {
        name: 'Start date',
        render: (row) => format_iso_to_date(row.start_date),
    }, {
        name: 'End date',
        render: (row) => format_iso_to_date(row.end_date),
    }, {
        name: 'Relations',
        render: (row) => {
            const nr_of_relations = row.relation_vouchers.length
            return <span>
                {nr_of_relations} { nr_of_relations === 1 ? 'relation' : 'relations' }
            </span>
        },
    },
]

export class VoucherList extends MithrilTsxComponent<unknown> {
    oninit() {
        collection.init({
            endpoint: {
                method: 'post',
                path: 'voucher.get_voucher_promotion_list',
            },
            sort: {
                by: 'name',
                order: 'asc',
                options: [
                    ['name', 'Name'],
                    ['start_date', 'Start date'],
                    ['end_date', 'End date'],
                    ['code', 'Code'],
                ],
            },
        }, filters, transforms)
    }

    view(): m.Children {
        return <div className="c-offer-vouchers view-container">
            <PanelFilters collection={collection} />
            <CollectionView mode="table">
                <ProcessVouchers
                    active='list'
                    context={{
                        offer_artkey: null,
                        email_batch_artkey: null,
                    }}
                />
                <CollectionHeader collection={collection} columns={columns} />
                <CollectionItems
                    collection={collection}
                    columns={columns}
                    on_row_click={(row) => {
                        m.route.set(`/offer/vouchers/${row.artkey}/relation-selection`)
                    }}
                    row_actions={(row) => {
                        return [
                            <Button
                                icon="edit"
                                link={`/offer/vouchers/${row.artkey}/edit`}
                                type="info"
                                variant="toggle"
                            />,
                            <RowActionDelete
                                needs_confirmation={true}
                                row={row}
                                row_delete={async() => {
                                    await api.post('voucher.delete_voucher_promotion', {artkey: row.artkey})
                                    collection.soft_delete(row.artkey)
                                    notifier.notify(`Voucher ${row.name} removed`, 'info')
                                }}
                            />,
                        ]
                    }}
                />
            </CollectionView>
        </div>
    }
}
