import m from 'mithril'
import {
    Button,
    FieldNumber,
    FieldSelect,
    FieldText,
} from '@bitstillery/common/components'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {
    invalid_fields,
    invalid_fields_format,
} from '@bitstillery/common/lib/validation'
import {watch} from '@bitstillery/common/lib/proxy'
import {focus_field} from '@bitstillery/common/lib/utils'

import {SalesOrderAdditionalType, SalesOrderAdditionalValueType} from '@/factserver_api/fact2server_api'
import {context, EntitySOA, EntityType, methods} from '@/sales/orders/view/lib/context'
import {ValueType} from '@/sales/models'

const ADDITIONAL = {
    [SalesOrderAdditionalType.Revenue]: {
        label: 'Costs - Revenue',
        help: 'Value Added Costs - documentation, sticker costs, ...',
        types: ['FIXED', 'PERCENTAGE'],
    },
    [SalesOrderAdditionalType.Transport]: {
        label: 'Costs - Transport',
        help: 'Transport costs',
        types: ['FIXED'],
    },
    [SalesOrderAdditionalType.WasteFund]: {
        label: 'Costs - Waste fund',
        help: 'Waste fund costs',
        types: ['FIXED'],
    },
    [SalesOrderAdditionalType.Pallet]: {
        label: 'Costs - Pallets',
        help: 'Pallet costs',
        types: ['FIXED'],
    },
    [SalesOrderAdditionalType.Other]: {
        label: 'Costs - Other',
        help: 'Other costs that don\'t fit in an existing category',
        types: ['FIXED', 'PERCENTAGE'],
    },
    [SalesOrderAdditionalType.Discount]: {
        label: 'Discount',
        help: 'A percentage (excl. excise) or fixed amount that is subtracted from the Sales order',
        types: ['FIXED', 'PERCENTAGE'],
    },
}

export class ManageAdditional extends MithrilTsxComponent<unknown> {

    watchers: any[] = []

    async oninit() {
        const entity = context.data.entities.SOA as EntitySOA

        this.watchers.push(watch(entity, 'sales_order_additional_type', (additional_type:any) => {
            if (additional_type && ADDITIONAL[additional_type].types.length === 1 && ADDITIONAL[additional_type].types[0] === 'FIXED') {
                entity.value_type = SalesOrderAdditionalValueType.FIXED
            }
        }))
    }

    onremove() {
        this.watchers.forEach((unwatch) => unwatch())
    }

    view(_vnode: m.Vnode<unknown, this>) {
        const entity = context.data.entities.SOA as EntitySOA
        const $v = context.$v.SOA
        const invalid = invalid_fields($v)

        if (!entity.sales_order_additional_type) {
            entity.sales_order_additional_type = SalesOrderAdditionalType.Revenue
        }

        return <div className="c-manage-additional sales orders">
            <FieldText
                help="Describe the additional item for yourself and the customer"
                label="Description"
                model={[entity, 'description']}
                placeholder="Description"
                tabindex={31}
                validation={$v.description}
            />

            <FieldSelect
                help={(() => ADDITIONAL[entity.sales_order_additional_type].help)()}
                label="Additional type"
                model={[entity, 'sales_order_additional_type']}
                options={Object.entries(ADDITIONAL).map((i) => ({
                    label: ADDITIONAL[i[0]].label,
                    value: i[0],
                }))}
                tabindex={32}
            />

            {ADDITIONAL[entity.sales_order_additional_type].types.length > 1 &&
            <FieldSelect
                help={entity.value_type === 'FIXED' ? 'A fixed amount' : 'Percentage of the sales order amount (excl. excise)'}
                label="Value type"
                model={[entity, 'value_type']}
                options={Object.entries(ValueType).map((i: any) => ({label: i[1], value: i[0]}))}
                tabindex={33}
            />}

            {entity.value_type === 'FIXED' ? <div className="field-group">
                <FieldText
                    help="Amount of units of this additional item"
                    label="Units"
                    min={1}
                    model={[entity, 'quantity']}
                    placeholder="Quantity"
                    type="number"
                    validation={$v.quantity}
                    tabindex={34}
                />
                <FieldNumber
                    decimals={2}
                    help={`Price per unit (${context.data.sales_order.was_sold_in})`}
                    label="Unit price"
                    min={(() => {
                        if (entity.sales_order_additional_type !== SalesOrderAdditionalType.Discount) {
                            return 0
                        }
                    })()}
                    max={(() => {
                        if (entity.sales_order_additional_type === SalesOrderAdditionalType.Discount) {
                            return 0
                        }
                    })()}
                    model={[entity, 'value_per_quantity']}
                    placeholder="Unit price"
                    tabindex={35}
                    validation={$v.value_per_quantity}
                />
            </div> : <FieldNumber
                decimals={1}
                help={`Percentage of the sales order amount,excl. excise (${entity.sales_order_additional_type === SalesOrderAdditionalType.Discount ? 'negative' : 'positive'})`}
                label="Percentage"
                min={(() => {
                    if (entity.sales_order_additional_type === SalesOrderAdditionalType.Discount) {
                        return -100
                    }
                    return 0.1
                })()}
                max={(() => {
                    if (entity.sales_order_additional_type === SalesOrderAdditionalType.Discount) {
                        return -0.1
                    }
                    return 100
                })()}
                model={[entity, 'value_per_quantity']}
                placeholder="Percentage"
                type="number"
                tabindex={36}
                validation={$v.value_per_quantity}
            />}

            <Button
                className="btn-submit"
                disabled={invalid.length}
                icon="save"
                onclick={async() => {
                    await methods.upsert_entity(EntityType.SOA, {
                        sales_order_additional_type: entity.sales_order_additional_type,
                        description: entity.description,
                        quantity: entity.value_type === SalesOrderAdditionalValueType.FIXED ? Number(entity.quantity) : 1,
                        value_type: entity.value_type,
                        value_per_quantity: entity.value_per_quantity,
                    })
                    focus_field(31)
                }}
                tabindex={37}
                text={context.data.entity_artkey ? 'Update Additional' : 'Add Additional'}
                tip={() => invalid_fields_format(invalid_fields($v), 'tip')}
                type="success"
            />
        </div>
    }
}
