import {Case} from '@/models/stock'
import {Supplier} from '@/models/suppliers'

export interface StockItem {
    item_artkey: number
    lot: string
    number_of_cases_available: number
    number_of_cases_in_purchase: number
    number_of_cases_in_sales: number
    reference: string
    stock_age_in_days: number
    was_bought_for_plus_costs: string
}

export class OfferItem {
    artkey = window.prop('')
    offer_item_type = window.prop('')
    case_artkey = window.prop('')
    case = window.prop(new Case())
    delivery_period = window.prop('')
    expected_delivery_date = window.prop('')
    maximum_quantity = window.prop('')
    minimum_quantity = window.prop('')
    sales_price_per_case = window.prop('')
    is_hidden = window.prop(false)
    is_seen = window.prop(false)
    can_be_ordered = window.prop(false)
    supplier = window.prop(null)
    silenced_until_timestamp = window.prop(null)
    custom_offer_item = window.prop(null)
    needs_approval = window.prop('')
    originating_spli = window.prop(null)
    originating_poi = window.prop(null)
    bottle_gtin = window.prop('')
    case_gtin_code = window.prop('')
    case_gtin_codes = window.prop('')
    bottle_gtin_codes = window.prop('')
    items = window.prop([])

    constructor(json: any = {}) {
        for (const prop in json) {
            if (prop === 'case' && json[prop]) {
                this.case_artkey(json[prop].artkey)
                this.case(new Case(json[prop]))
            } else if (prop === 'price_per_case') {
                this.sales_price_per_case(json[prop])
            } else if (prop === 'supplier') {
                if (json[prop] !== null && json[prop] !== undefined) {
                    this.supplier(new Supplier(json[prop]))
                } else {
                    this.supplier(null)
                }
            } else if (prop in this) {
                (this as any)[prop](json[prop])
            } else {
                // eslint-disable-next-line no-console
                console.error(`Unknown property ${prop} of OfferItem`)
            }
        }
    }

    // eslint-disable-next-line @typescript-eslint/naming-convention
    toJS(): Record<string, any> {
        return {
            artkey: this.artkey(),
            offer_item_type: this.offer_item_type(),
            case_artkey: this.case_artkey(),
            delivery_period: this.delivery_period(),
            expected_delivery_date: this.expected_delivery_date(),
            maximum_quantity: this.maximum_quantity(),
            minimum_quantity: this.minimum_quantity(),
            sales_price_per_case: this.sales_price_per_case(),
            is_hidden: this.is_hidden(),
            is_seen: this.is_seen(),
        }
    }
}
