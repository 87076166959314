/** llm:tested */
import m from 'mithril'
import {Amount, Link} from '@bitstillery/common/components'
import {
    AmountUnit,
    Button,
    ButtonGroup,
    DataCard,
    Lot,
    Icon,
    Spinner,
} from '@bitstillery/common/components'
import {to_specs} from '@bitstillery/common/lib/specs'
import {MithrilTsxComponent} from 'mithril-tsx-component'

import utils from '@/_utils'
import {ExploreData, FilterType, SearchFilterJson} from '@/market/explore/models'
import {Panel} from '@/components/panel'
import table from '@/components/table'
import {icon} from '@/components/icon'
import {from_properties} from '@/components/discover_link'
import {Pager} from '@/components/pager/pager'
import {Popover} from '@/components/popover/popover'
import {$s} from '@/app'
import {accountIconBySlug} from '@/accounts'

interface ShowStockAttrs {
    stock_history: Array<any>
    stock_totals: number
    stock_history_margin: number
    count: () => number
    explore_data: ExploreData
    fetch_page: (page_index: number) => void
    filter_type: () => FilterType
    show_specs: boolean
    loading: boolean
    download_callback?: () => void
    unique_name: string
    header: () => string
    search_filters: Array<SearchFilterJson>
}

export class ShowStock extends MithrilTsxComponent<ShowStockAttrs> {
    private stock: any

    constructor(vnode: any) {
        super()
        this.stock = vnode.attrs.stock
    }

    view(vnode: m.Vnode<ShowStockAttrs>) {
        const toolbar = vnode.attrs.count() > 0 ?
            <Pager
                page_size={vnode.attrs.explore_data.filter.limit}
                count={vnode.attrs.count()}
                fetch_page={vnode.attrs.fetch_page}
                display_page_count={false}
            />
            : undefined

        const show_product = !(vnode.attrs.filter_type() in [FilterType.BOTH, FilterType.PRODUCT])
        const show_relation = !(vnode.attrs.filter_type() in [FilterType.BOTH, FilterType.RELATION])
        const show_specs = vnode.attrs.show_specs && !show_product

        return <div class="c-market-explore-stock" key={vnode.attrs.unique_name}>
            <Panel
                title={vnode.attrs.explore_data.panels.stock.title}
                description={vnode.attrs.explore_data.panels.stock.description}
                toolbar={toolbar}
                footer={vnode.attrs.footer}
                model={vnode.attrs.explore_data.panels.stock}
            >
                {vnode.attrs.loading() ?
                    <Spinner /> :
                    this.stock().length ? [
                        <DataCard
                            model={{
                                data: [
                                    {label: 'Stock for', value: vnode.attrs.header()},
                                    {
                                        label: 'Number of cases',
                                        value: <div>
                                            <Icon size="xs" name="warehouse" tip="In stock" />
                                            <span>{vnode.attrs.stock_totals['in_stock']} </span>
                                            <Icon size="xs" name="cart" tip="In purchase" />
                                            <span>{vnode.attrs.stock_totals['in_purchase']} </span>
                                            <Icon size="xs" name="sales" tip="In sales" />
                                            <span>{vnode.attrs.stock_totals['in_sales']} </span>
                                            <Icon size="xs" name="shieldHalfFull" tip="Available" />
                                            <span>{vnode.attrs.stock_totals['available']}</span>
                                        </div>,
                                    },
                                ],
                            }}
                            variant="dense"
                        />,
                        table.table(this.stock, [
                            {
                                field: 'purchase_order_reference',
                                name: 'Purchase Order',
                                function: (record: any) => {
                                    return <div class="td-group">
                                        <span class="header">
                                            {accountIconBySlug(record.account_slug, record.account_name)}
                                            <Link
                                                target="_blank"
                                                href={`/purchase-orders/manage/${record.purchase_order_reference}`}
                                            >
                                                {record.purchase_order_reference}
                                            </Link>
                                        </span>
                                        {show_specs &&
                                            <span class="details">
                                                {to_specs({
                                                    bottle_volume: record.bottle_volume,
                                                    bottle_alcohol_percentage: record.bottle_alcohol_percentage,
                                                    bottle_refill_status: record.bottle_refill_status,
                                                    case_customs_status: record.case_customs_status,
                                                    case_gift_box_type: record.case_gift_box_type,
                                                    case_number_of_bottles: record.case_number_of_bottles,
                                                    case_tax_label: record.case_tax_label,
                                                })}
                                            </span>
                                        }
                                    </div>
                                },
                                width: 13,
                            },
                            {
                                name: 'Intake',
                                function: (record: any) => {
                                    let lot_date = null
                                    let lot_tip = null
                                    let lot_type = undefined

                                    if (utils.formatDate(record.item_entry_date)) {
                                        lot_date = utils.formatDate(record.item_entry_date)
                                        lot_type = 'default'
                                    } else if (!record.sales_order_credit_item_artkey) {
                                        lot_date = utils.formatDate(record.expected_delivery_date)
                                        lot_type = 'warning'
                                    }

                                    if (!record.item_lot) {
                                        if (record.item_is_target_in) {
                                            lot_tip = `Part of <a href='#!/stock/mutations/manage/${record.item_mutation_reference}' target='_blank'>mutation ${record.item_mutation_reference}</a>`
                                        } else {
                                            lot_tip = 'This is a purchase order item'
                                        }
                                    }

                                    return <Lot
                                        lot_date={lot_date}
                                        lot_number={record.item_lot}
                                        tip={lot_tip}
                                        type={lot_type}
                                        warehouse_name={record.warehouse_name}
                                    />
                                },
                                width: 10,
                            },
                            show_product && {
                                name: 'Product',
                                // ellipsis: true,
                                function: (record: any) => record.product_name,
                            },
                            show_relation && {
                                width: 20,
                                name: 'Supplier',
                                // ellipsis: true,
                                field: 'supplier_name',
                            },
                            {
                                field: 'item_was_bought_for',
                                name: 'Purchase Price',
                                function: (record: any) => {
                                    return <AmountUnit
                                        case_amount={+record.item_was_bought_for}
                                        case_number_of_bottles={record.case_number_of_bottles}
                                        currency={record.purchase_order_was_bought_in}
                                        display_currency={$s.currencies.default}
                                        rate={record.purchase_order_bought_against_rate}
                                    />
                                },
                            },
                            {
                                field: 'item_was_bought_for_plus_costs',
                                name: 'Cost Price',
                                function: (record: any) => {
                                    return <AmountUnit
                                        case_amount={+record.item_euro_was_bought_for_plus_costs}
                                        case_number_of_bottles={record.case_number_of_bottles}
                                        currency={$s.currencies.default}
                                        display_currency={$s.currencies.default}
                                        excise={record.case_excise_nl}
                                    />
                                },
                            },
                            {
                                field: 'item_total_stock_value',
                                name: 'Stock value',
                                function: (record: any) => {
                                    return <Amount
                                        amount={+record.item_euro_total_stock_value}
                                        currency={$s.currencies.default}
                                    />
                                },
                            },
                            {
                                width: 1,
                                field: 'item_number_of_cases_in_stock',
                                name: <Popover title="" content="Number of cases in stock">
                                    <span class="glyphicon glyphicon-home" />
                                </Popover>,
                            },
                            {
                                width: 1,
                                field: 'item_number_of_cases_in_purchase',
                                name: <Popover title="" content="Number of cases in purchase">
                                    <span class="glyphicon glyphicon-shopping-cart" />
                                </Popover>,
                            },
                            {
                                width: 1,
                                field: 'item_number_of_cases_in_sales',
                                name: <Popover title="" content="Number of cases in sales">
                                    <span class="glyphicon glyphicon-screenshot" />
                                </Popover>,
                            },
                            {
                                width: 1,
                                field: 'item_number_of_cases_available',
                                name: <Popover title="" content="Number of cases available">
                                    {icon('fa-shield-alt')}
                                </Popover>,
                            },
                            {
                                classes: ['actions'],
                                function: (record: any) => {
                                    return <div class="actions-group no-click">
                                        <ButtonGroup>
                                            <Button
                                                icon="search"
                                                onclick={() => window.open(from_properties(record.product_name, record.bottle_volume, record.bottle_alcohol_percentage))}
                                                tip={'Search in market'}
                                                type="info"
                                            />
                                            <Button
                                                icon="stock"
                                                onclick={() => window.open(`#!/stock/manage/${record.item_reference}`)}
                                                tip={`Manage Stock item ${record.item_reference}`}
                                            />
                                        </ButtonGroup>
                                    </div>
                                },
                            },
                        ], {
                            search_table_style: false,
                            sticky_header: false,
                        }),
                    ] : 'No results'
                }
            </Panel>
        </div>
    }
}
