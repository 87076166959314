import m from 'mithril'
import {notifier} from '@bitstillery/common/app'

import {ProductPhotoApi} from '@/factserver_api/product_photos'
import {GetProductPhotoResponse, RotateDirection} from '@/factserver_api/fact2server_api'

export class ProductPhotoHelper {
    product_photo_api = new ProductPhotoApi()
    is_loading = false

    flip_is_internal(product_photo: GetProductPhotoResponse) {
        this.product_photo_api.update(product_photo.artkey, {
            is_internal: !product_photo.is_internal,
        }).subscribe({
            next: (result) => {
                this.is_loading = false
                product_photo.is_internal = result.is_internal
                notifier.notify('Product photo updated', 'success')
                m.redraw()
            },
            error: () => {
                this.is_loading = false
                m.redraw()
            },
        })
    }

    rotate(product_photo: GetProductPhotoResponse, rotate_direction: RotateDirection, on_product_photos_changed): void {
        this.is_loading = true
        this.product_photo_api.rotate(product_photo.artkey, rotate_direction).subscribe({
            next: (result) => {
                this.is_loading = false
                product_photo.original_location = result.original_location
                m.redraw()
                on_product_photos_changed()
            },
            error: () => {
                this.is_loading = false
                m.redraw()
            },
        })
    }

    approve(product_photo: GetProductPhotoResponse, on_product_photos_changed): void {
        this.is_loading = true
        this.product_photo_api.approve(product_photo.artkey).subscribe({
            next: () => {
                this.is_loading = false
                m.redraw()
                on_product_photos_changed()
            },
            error: () => {
                this.is_loading = false
                m.redraw()
            },
        })
    }

    delete(product_photo: GetProductPhotoResponse, on_product_photos_changed): void {
        this.is_loading = true
        this.product_photo_api.delete(product_photo.artkey).subscribe({
            next: () => {
                this.is_loading = false
                on_product_photos_changed()
            },
            error: () => {
                this.is_loading = false
                m.redraw()
            },
        })
    }

    download_photo(product_photo: GetProductPhotoResponse): void {
        this.is_loading = true
        this.product_photo_api.get_file(product_photo.artkey, product_photo.file_name).subscribe({
            next: () => {
                this.is_loading = false
                m.redraw()
            },
            error: () => {
                this.is_loading = false
                m.redraw()
            },
        })
    }

    sort(artkeys: number[]) {
        this.is_loading = true
        this.product_photo_api.sort(artkeys).subscribe({
            next: () => {
                this.is_loading = false
                m.redraw()
            },
            error: () => {
                this.is_loading = false
                m.redraw()
            },
        })
    }
}
