import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {proxy} from '@bitstillery/common/lib/proxy'
import {Button, ButtonGroup, FieldSelect, FieldText, FieldTextArea} from '@bitstillery/common/components'
import {invalid_fields, invalid_fields_format, required, reset_validation, validation} from '@bitstillery/common/lib/validation'
import {DataCard, FieldMoney} from '@bitstillery/common/components'
import {api, events, notifier} from '@bitstillery/common/app'
import {SelectOption} from '@bitstillery/common/types/field'
import {to_specs} from '@bitstillery/common/lib/specs'
import {copy_object, merge_deep} from '@bitstillery/common//lib/utils'
import {logger} from '@bitstillery/common/app'

import {UpsertPurchaseOrder} from '@/purchase_orders/upsert_purchase_order'
import utils from '@/_utils'
import {$m, $s} from '@/app'
import {CountriesSelect} from '@/components/html_components'
import {PurchaseOrder} from '@/models/purchase_orders'
import {GiftBoxTypeDropDown} from '@/components/case_inputs'
import {NumberInput} from '@/components/input_numbers'
import {GetRelationPurchaseOrderResponse} from '@/factserver_api/fact2server_api'
import {EntityType} from '@/market/pricelists/view/lib/context'

const AddMode = {
    CUSTOM: 'custom',
    MARKET: 'market',
    PURCHASE: 'purchase',
}

export interface AddToOrderTboItem {
    sales_order: {
        artkey: number
        buyer: {
            name: string
        }
    }
    artkey: number
    case_artkey: number
    number_of_cases: number
}

interface SpliAddToOrder {
    bid_per_case: string
    cases: number | null
    country_of_origin: string
    number_of_bottles_per_case: number | null
    original_price_per_case: string
    remark: string
    selected_po_artkey: number | null
    selected_cases_per_pallet_option: string | null
    selected_cases_per_pallet_layer_option: string | null
    suggested_price_per_case: string
    cases_per_pallet: number | null
    cases_per_pallet_layer: number | null
    gift_box_type: string
}

export class AddToOrder extends MithrilTsxComponent<any> {
    custom_offer_item: any
    empty_purchase_order: typeof PurchaseOrder
    purchase_order_item: any
    purchase_order: any
    selected_spli: any
    cases_per_pallet_options = [] as SelectOption[]
    cases_per_pallet_layer_options = [] as SelectOption[]

    data = (() => {
        const $f: SpliAddToOrder = {
            bid_per_case: '',
            cases: null,
            country_of_origin: '',
            number_of_bottles_per_case: null,
            original_price_per_case: '',
            remark: '',
            selected_po_artkey: null,
            selected_cases_per_pallet_option: null,
            selected_cases_per_pallet_layer_option: null,
            suggested_price_per_case: '',
            cases_per_pallet: null,
            cases_per_pallet_layer: null,
            gift_box_type: '',
        }

        return proxy({
            $f,
            case_per_pallet_disabled: true,
            case_per_pallet_layer_disabled: true,
            error_msg: '',
            exclude_purchase_order_artkeys: [] as number[],
            loading: true,
            mode: '',
            purchase_orders: [] as GetRelationPurchaseOrderResponse[],
            purchase_order_items: [],
            new_purchase_order: false,
            tax_label: '',
        })
    })()

    _data: typeof this.data

    $v = {
        cases: validation([this.data.$f, 'cases'], required()),
        country_of_origin: validation([this.data.$f, 'country_of_origin'], required()),
    }

    oninit(vnode:m.Vnode<any>) {
        if (!this._data) {
            this._data = copy_object(this.data.$f)
        }
        this.selected_spli = null

        if (vnode.attrs.selected_spli) {
            this.selected_spli = vnode.attrs.selected_spli
        }

        this.custom_offer_item = vnode.attrs.custom_offer_item
        this.purchase_order_item = vnode.attrs.purchase_order_item

        this.data.exclude_purchase_order_artkeys = vnode.attrs.exclude_purchase_order_artkeys || []

        if (this.selected_spli) {
            this.data.mode = AddMode.MARKET
            // The selected_spli and $f should be the same object, so we don't
            // need to manually update the model. However this Component is used
            // in multiple modes, which makes it hard to convert.
            this.selected_spli.update_model = () => {
                reset_validation(this.$v)
                // Restore defaults

                merge_deep(this.data.$f, copy_object(this._data), {
                    country_of_origin: this.selected_spli.bottle.product.default_country_code,
                    gift_box_type: this.selected_spli.case_gift_box_type ? this.selected_spli.case_gift_box_type : this.selected_spli.gift_box_type,
                    number_of_bottles_per_case: this.selected_spli.number_of_bottles_per_case,
                })

                if (this.selected_spli.price_per_case) {
                    this.data.$f.number_of_bottles_per_case = this.selected_spli.number_of_bottles_per_case
                    // No need to look at price per bottle; price per case is always filled if possible.
                    this.data.$f.original_price_per_case = this.selected_spli.price_per_case
                }
                if (!vnode.attrs.tbo_item) {
                    this.data.$f.number_of_bottles_per_case = this.selected_spli.number_of_bottles_per_case
                    this.data.$f.remark = this.selected_spli.aux_info
                }

                let query_supplier_artkey = vnode.attrs.supplier_artkey
                if (this.selected_spli.supplier.artkey) {
                    query_supplier_artkey = this.selected_spli.supplier.artkey
                }
                this._update_cpp_data()
                this.query_orders(vnode, query_supplier_artkey)
            }

        } else if (this.custom_offer_item) {
            this.data.mode = AddMode.CUSTOM
            this.data.$f.number_of_bottles_per_case = this.custom_offer_item.number_of_bottles

            if (this.custom_offer_item.purchase_price_per_case) {
                this.data.$f.original_price_per_case = this.custom_offer_item.purchase_price_per_case
            }
        } else if (this.purchase_order_item) {
            this.data.mode = AddMode.PURCHASE
            this.data.$f.number_of_bottles_per_case = this.purchase_order_item.case().number_of_bottles()
            this.data.$f.original_price_per_case = this.purchase_order_item.was_bought_for()

            this.data.$f.suggested_price_per_case = this.purchase_order_item.suggested_price_per_case()
            this.data.$f.country_of_origin = this.purchase_order_item.country_of_origin()
        }

        if (vnode.attrs.tbo_item) {
            if (this.data.mode === AddMode.MARKET) {
                this.data.tax_label = vnode.attrs.tbo_item.case_tax_label
                this.data.$f.number_of_bottles_per_case = vnode.attrs.tbo_item.case_number_of_bottles
            }

            this.data.$f.cases = vnode.attrs.tbo_item.number_of_cases
            this.data.$f.remark = this.poi_remark(vnode.attrs.tbo_item)
        }

        if (!this.data.$f.cases) {
            this.data.$f.cases = vnode.attrs.cases
        }

        this.empty_purchase_order = new PurchaseOrder()
        this.empty_purchase_order.was_handled_by_artkey($s.identity.artkey)
        this.empty_purchase_order.supplier_artkey(vnode.attrs.supplier_artkey)

        this.purchase_order = window.prop(this.empty_purchase_order)

        if (this.selected_spli) {
            this.selected_spli.update_model()
        } else {
            this.query_orders(vnode, vnode.attrs.supplier_artkey)
        }
    }

    _update_cpp_data() {
        const cpl_options = [] as any
        const cpp_options = [] as any
        const spli = this.selected_spli

        const _pallet_option = (index: number, value: string, src: string) => {
            // Option values are prefixed with an index to ensure uniqueness.
            return {value: `${index}:${value}`, label: `${value} cs from ${src}`}
        }

        if (spli.cases_per_pallet) {
            cpp_options.push(_pallet_option(cpp_options.length, spli.cases_per_pallet, 'price list'))
        }
        if (spli.case_cases_per_pallet) {
            cpp_options.push(_pallet_option(cpp_options.length, spli.case_cases_per_pallet, 'system'))
        }
        if (spli.trusted_supplier_cases_per_pallet) {
            cpp_options.push(_pallet_option(cpp_options.length, spli.trusted_supplier_cases_per_pallet, 'trusted supplier'))
        }
        if (spli.cases_per_pallet_layer) {
            cpl_options.push(_pallet_option(cpl_options, spli.cases_per_pallet_layer, 'price list'))
        }
        if (spli.case_cases_per_pallet_layer) {
            cpl_options.push(_pallet_option(cpl_options, spli.case_cases_per_pallet_layer, 'system'))
        }
        if (spli.trusted_supplier_cases_per_pallet_layer) {
            cpl_options.push(_pallet_option(cpl_options, spli.trusted_supplier_cases_per_pallet_layer, 'trusted supplier'))
        }

        if (cpp_options.length > 0) {
            this.data.$f.cases_per_pallet = +(cpp_options[0].value.toString().split(':')[1])
            this.data.$f.selected_cases_per_pallet_option = cpp_options[0].value.toString()
            this.data.case_per_pallet_disabled = true
        } else {
            this.data.$f.selected_cases_per_pallet_layer_option = null
            this.data.case_per_pallet_disabled = false
        }

        if (cpl_options.length > 0) {
            this.data.$f.cases_per_pallet_layer = +(cpl_options[0].value.toString().split(':')[1])
            this.data.$f.selected_cases_per_pallet_layer_option = cpl_options[0].value.toString()
            this.data.case_per_pallet_layer_disabled = true
        } else {
            this.data.$f.selected_cases_per_pallet_layer_option = null
            this.data.case_per_pallet_layer_disabled = false
        }

        this.cases_per_pallet_options.splice(0, this.cases_per_pallet_options.length, ...cpp_options)
        this.cases_per_pallet_layer_options.splice(0, this.cases_per_pallet_layer_options.length, ...cpl_options)
    }

    onupdate(vnode) {
        this.sync_cases(vnode)
    }

    async query_orders(vnode, supplier_artkey, selected_po_artkey: number | null = null) {
        // If no supplier is selected, we don't need no orders.
        logger.debug(`[AddToOrder] query_orders for supplier ${supplier_artkey}`)
        if (!supplier_artkey) return

        this.data.loading = true
        this.data.new_purchase_order = false

        const filters = {
            purchase_order_statuses: ['Saved'],
        }

        const {response, result} = await api.get<GetRelationPurchaseOrderResponse[]>(
            `discover/relations/${supplier_artkey}/purchase-orders/collection-view?filters=${JSON.stringify(filters)}&sort_by=was_last_updated_on&sort_ascending=True`)
        this.data.loading = false
        if (!response.ok) {
            return
        }

        this.data.purchase_orders = result.filter((i:GetRelationPurchaseOrderResponse) => !this.data.exclude_purchase_order_artkeys.includes(i.artkey))
        if (this.data.purchase_orders.length > 0) {
            // select either the purchase order with artkey == selected_po_artkey, or the first from the api.
            let selected_po = this.data.purchase_orders.find((i:any) => i.artkey === selected_po_artkey)

            const previous_selected_po_artkey = $s.supplier_price_list.add_to_order.last_selected.artkey
            if (previous_selected_po_artkey && !selected_po) {
                selected_po = this.data.purchase_orders.find((i:any) => i.artkey === previous_selected_po_artkey) || selected_po
            }
            await this.select_purchase_order(
                vnode,
                selected_po ? selected_po.artkey : this.data.purchase_orders[0].artkey,
            )
        }
    }

    async select_purchase_order(vnode, po_artkey) {
        this.data.$f.selected_po_artkey = po_artkey

        if (po_artkey) {
            const po = this.data.purchase_orders.find((i:any) => i.artkey === po_artkey)
            this.purchase_order(po)
            $s.supplier_price_list.add_to_order.last_selected.artkey = po_artkey
        } else {
            this.purchase_order(window.prop(this.empty_purchase_order))
        }

        this.data.purchase_order_items = []
        if (!this.purchase_order()) {
            this.purchase_order(this.empty_purchase_order)
        } else if (vnode.attrs.tbo_item) {
            const {result} = await api.post('purchase.core.get_purchase_order_items', {
                case_artkey: vnode.attrs.tbo_item.case_artkey,
                purchase_order_artkey: +po_artkey,
            })
            this.data.purchase_order_items = result
            if (this.data.purchase_order_items.length) {
                // The selected PO already has a POI with the same case, so
                // we are going to update that POI instead of creating a
                // new POI. Determine new remark, number of cases and
                // bid_per_case values for that existing POI.
                const poi = this.data.purchase_order_items[0]
                if (poi.remark) {
                    this.data.$f.remark = poi.remark + '\n' + this.poi_remark(vnode.attrs.tbo_item)
                }
                this.data.$f.cases = poi.number_of_cases + vnode.attrs.tbo_item.number_of_cases
                this.data.$f.bid_per_case = poi.was_bought_for
                this.data.$f.suggested_price_per_case = poi.suggested_price_per_case
                this.data.$f.country_of_origin = poi.country_of_origin
            } else {
                // The selected PO has no POI with the same case. Restore
                // the remark, number of cases and bid_per_case values to
                // their original values. This is necessary if an earlier
                // selected PO did have such a POI, after which these
                // values had been updated.
                this.data.$f.remark = this.poi_remark(vnode.attrs.tbo_item)

                this.data.$f.cases = vnode.attrs.tbo_item.number_of_cases
                this.data.$f.bid_per_case = ''
            }
        }
    }

    /**
     * Used to keep the amount of cases in sync with an external component; e.g.
     * splitting POI from split_purchase_order_item
     */
    sync_cases(vnode) {
        if (vnode.attrs.cases && this.data.$f.cases !== vnode.attrs.cases) {
            this.data.$f.cases = vnode.attrs.cases
        }
    }

    // Generate a remark for the purchase order item based on a tbo item.
    poi_remark(tbo_item) {
        return `TBO item for S${tbo_item.sales_order.artkey} ${tbo_item.sales_order.buyer.name}`
    }

    async add_to_order(vnode:m.Vnode<any>, close_side_panel: boolean) {
        const params = {
            country_of_origin: this.data.$f.country_of_origin,
            purchase_order_artkey: this.data.$f.selected_po_artkey,
            remark: this.data.$f.remark,
        }
        const bid_per_case = this.data.$f.bid_per_case ? +this.data.$f.bid_per_case : +this.data.$f.original_price_per_case
        let result, success

        if (this.data.mode === AddMode.MARKET) {
            Object.assign(params, {
                bid_per_case,
                cases: +this.data.$f.cases,
                for_tbo_item_artkey: vnode.attrs.tbo_item?.artkey,
                number_of_bottles_per_case: +this.data.$f.number_of_bottles_per_case,
                suggested_price_per_case: this.data.$f.suggested_price_per_case,
                supplier_price_list_item_artkey: this.selected_spli.artkey,
                cases_per_pallet: this.data.$f.cases_per_pallet || null,
                cases_per_pallet_layer: this.data.$f.cases_per_pallet_layer || null,
            })
            if (this.data.$f.gift_box_type) {
                params.gift_box_type = this.data.$f.gift_box_type
            }
            if (this.data.tax_label) {
                params.tax_label = this.data.tax_label
            }

            ({result, success} = await api.post('purchase.core.add_spli_to_purchase_order', params))

            if (success) {
                const po_url = `<a href="#!/purchase-orders/manage/${this.data.$f.selected_po_artkey}">${this.data.$f.selected_po_artkey}</a>`
                notifier.notify(`Added "${this.selected_spli.bottle.product.name} (${this.selected_spli.bottle.to_specs})" to purchase order ${po_url}.`, 'success')
            }
        } else if (this.data.mode === AddMode.CUSTOM) {
            Object.assign(params, {
                bid_per_case,
                cases: +this.data.$f.cases,
                custom_offer_item_artkey: this.custom_offer_item.artkey,
                for_tbo_item_artkey: vnode.attrs.tbo_item?.artkey,
                suggested_price_per_case: this.data.$f.suggested_price_per_case,
            });
            ({result, success} = await api.post('purchase.core.add_custom_offer_item_to_purchase_order', params))
            if (success) {
                notifier.notify(`Added "${this.custom_offer_item.name} (${this.custom_offer_item.volume} /
                ${this.custom_offer_item.alcohol_percentage}  / ${this.custom_offer_item.refill_status})" to purchase order ${this.purchase_order().reference}.`, 'success')
            }
        } else if (this.data.mode === AddMode.PURCHASE && vnode.attrs.tbo_item) {
            Object.assign(params, {
                bid_per_case,
                cases_for_poi: +this.data.$f.cases,
                cases_for_tbo_item: vnode.attrs.tbo_item.number_of_cases,
                lower_number_of_cases_in_poi: vnode.attrs.lower_number_of_cases_in_poi,
                original_tbo_item_artkey: vnode.attrs.tbo_item.artkey,
            });

            ({result, success} = await api.post('purchase.core.split_tbo_to_new_poi', params))
        } else if (this.data.mode === AddMode.PURCHASE) {
            Object.assign(params, {
                cases_for_poi: +this.data.$f.cases,
                original_poi_artkey: this.purchase_order_item.artkey(),
                suggested_price_per_case: this.data.$f.suggested_price_per_case,
                was_bought_for: this.data.$f.bid_per_case ? +this.data.$f.bid_per_case : +this.data.$f.original_price_per_case,
            });
            ({success} = await api.post('purchase.core.split_poi_to_new_poi', params))
        }

        if (success) {
            this.data.error_msg = ''
            await vnode.attrs.on_added_item(this.purchase_order().artkey)

            if (this.data.mode !== AddMode.PURCHASE) {
                events.emit('spli:added_to_purchase_order', this.purchase_order().artkey, this.selected_spli.artkey)
            }
            if (close_side_panel && vnode.attrs.on_close_panel) {
                vnode.attrs.on_close_panel()
            }
        } else {
            if (result.message) {
                this.data.error_msg = result.message
            } else {
                this.data.error_msg = 'Failed adding item to order. Please try again later or contact us if the problem persists.'
            }
        }
    }

    is_editing(vnode) {
        return (vnode.attrs.tbo_item && this.data.purchase_order_items.length)
    }

    needed_cases(vnode) {
        if (this.is_editing(vnode)) {
            return this.data.purchase_order_items[0].number_of_cases + vnode.attrs.tbo_item.number_of_cases
        } else if (vnode.attrs.tbo_item) {
            return vnode.attrs.tbo_item.number_of_cases
        }
        return 1
    }

    view(vnode) {
        const invalid = invalid_fields(this.$v)

        return <div className="c-add-to-order">
            {this.data.error_msg && <div className="alert alert-danger alert-dismissible">
                <button
                    className="close"
                    data-dismiss="alert"
                    onclick={() => this.data.error_msg = ''}
                    type="button"
                >
                    <span>×</span>
                </button>
                <span>{this.data.error_msg}</span>
            </div>}

            {this.data.mode === AddMode.MARKET && [
                <DataCard
                    model={{data: [
                        {label: 'Supplier', value: this.selected_spli.supplier?.name},
                        {label: 'Product', value: this.selected_spli.bottle.product.name},
                        {label: 'Specs', value: to_specs({
                            case_number_of_bottles: this.selected_spli.number_of_bottles_per_case,
                            bottle_volume: this.selected_spli.bottle.volume,
                            bottle_alcohol_percentage: this.selected_spli.bottle.alcohol_percentage,
                            bottle_refill_status: this.selected_spli.bottle.refill_status,
                            case_gift_box_type: this.selected_spli.case_gift_box_type,
                            case_customs_status: this.selected_spli.customs_status,
                        })},
                        {label: 'Aux info', value: this.selected_spli.aux_info},
                    ]}}
                />,
                <div className="fl">
                    <DataCard
                        model={{data: [
                            {
                                label: 'Quantity',
                                value: this.selected_spli.number_of_cases ? `${this.selected_spli.number_of_cases} cs` : this.selected_spli.number_of_bottles ? `${this.selected_spli.number_of_bottles} btl` : '-',
                            },
                            {
                                label: 'Price',
                                value: this.selected_spli.price_per_case ? `${this.selected_spli.price_per_case} (cs)` : this.selected_spli.price_per_bottle ? `${this.selected_spli.price_per_bottle} (btl)` : '-',
                            },
                        ]}}
                    />
                    <DataCard
                        model={{data: [
                            {label: 'Incoterm', value: this.selected_spli.incoterm},
                            {label: 'Availability', value: this.selected_spli.availability_status},
                        ]}}
                    />
                </div>,
            ]}

            {this.data.mode === AddMode.CUSTOM && <DataCard
                model={{data: [
                    {label: 'Supplier', value: this.custom_offer_item.supplier.name},
                    {label: 'Product', value: this.custom_offer_item.name},
                    {label: 'Specs', value: `${this.custom_offer_item.volume} / ${this.custom_offer_item.alcohol_percentage}  / ${this.custom_offer_item.refill_status}`},
                    {label: 'Bottles per case', value: this.custom_offer_item.number_of_bottles},
                    {label: 'Giftbox', value: this.custom_offer_item.gift_box_type},
                    {label: 'Customs status', value: this.custom_offer_item.customs_status},
                    {label: 'Tax label', value: this.custom_offer_item.tax_label},
                    {label: 'Best before date', value: utils.formatDate(this.custom_offer_item.best_before_date)},
                    {label: 'Features', value: $m.data.item_tag.get_tag_names(this.custom_offer_item.item_tags || []).join(' / ')},
                    {label: 'Incoterm', value: this.custom_offer_item.incoterm},
                    {label: 'Quantity (cases)', value: this.custom_offer_item.quantity},
                ]}}
            />}

            {(this.data.mode === AddMode.PURCHASE && vnode.attrs.tbo_item) && <DataCard
                model={{data: [
                    {label: 'Supplier', value: this.purchase_order_item.purchase_order().supplier().name()},
                    {label: 'Product', value: this.purchase_order_item.case().bottle().product().name()},
                    {label: 'Specs', value: `${this.purchase_order_item.case().bottle().volume()} / ${this.purchase_order_item.case().bottle().alcohol_percentage()}  / ${this.purchase_order_item.case().bottle().refill_status()}`},
                    {label: 'Bottles per case', value: this.purchase_order_item.case().number_of_bottles()},
                    {label: 'Customs status', value: this.purchase_order_item.case().number_of_bottles()},
                    {label: 'Gift box type', value: this.purchase_order_item.case().gift_box_type()},
                    {label: 'Tax label', value: this.purchase_order_item.case().tax_label()},
                    {label: 'Best before date', value: utils.formatDate(this.purchase_order_item.case().best_before_date())},
                    {label: 'Features', value: $m.data.item_tag.get_tag_names(this.purchase_order_item.case().item_tags() || []).join(' / ')},
                    {label: 'Incoterm', value: `${this.purchase_order_item.purchase_order().incoterm()} - ${this.purchase_order_item.purchase_order().incoterm_location()}`},
                    {label: 'Quantity (cases)', value: vnode.attrs.tbo_item.number_of_cases},
                ]}}
            />}

            <FieldSelect
                label="Purchase Order"
                help="Select a purchase order to add the item to"
                model={[this.data.$f, 'selected_po_artkey']}
                onchange={(value) => {
                    this.select_purchase_order(vnode, value)
                }}
                options={this.data.purchase_orders.map((i) => ({
                    value:i.artkey,
                    label: `${i.reference} - ${utils.formatDate(i.created_on)} - ${i.is_communicated_on ? 'Closed' : 'Open'} (${i.was_bought_in})`,
                }))}
                placeholder="New Purchase Order"
            />
            {(() => {
                if (this.is_editing(vnode)) {
                    return <div className="alert alert-warning">
                        A purchase order item already exists for this product, editing the record instead.'
                    </div>
                }
            })()}

            {!this.data.$f.selected_po_artkey ? <UpsertPurchaseOrder
                is_used_as_inline_form={true}
                onsaved={(artkey) => {
                    this.query_orders(vnode, vnode.attrs.supplier_artkey, artkey)
                }}
                relation_artkey={vnode.attrs.supplier_artkey}
                supplier_watcher={vnode.attrs.context ? [vnode.attrs.context.data.entities[EntityType.PURCHASE_ORDER].supplier, 'artkey'] : undefined}
            /> : (() => {
                return [
                    <div className="field-group">
                        <FieldText
                            disabled={(() => {
                                return !(this.selected_spli && !vnode.attrs.tbo_item)
                            })()}
                            label="Bottles per case"
                            min={1}
                            model={[this.data.$f, 'number_of_bottles_per_case']}
                            onafterupdate={(number_of_bottles_per_case) => {
                                this.data.$f.number_of_bottles_per_case = number_of_bottles_per_case

                                if (this.selected_spli.price_per_case && this.selected_spli.number_of_bottles_per_case) {
                                    // This should avoid issues with rounding.
                                    const amount = String((this.selected_spli.price_per_case / +this.selected_spli.number_of_bottles_per_case) * number_of_bottles_per_case)
                                    this.data.$f.original_price_per_case = amount
                                    // Empty == bid the original price.
                                    this.data.$f.bid_per_case = ''
                                } else if (this.selected_spli.price_per_bottle) {
                                    this.data.$f.original_price_per_case = String(this.selected_spli.price_per_bottle * number_of_bottles_per_case)
                                    // Empty == bid the original price.
                                    this.data.$f.bid_per_case = ''
                                }
                            }}
                            type="number"
                        />
                        <CountriesSelect
                            help="Where the product was produced"
                            label='Country of origin'
                            model={[this.data.$f, 'country_of_origin']}
                        />
                    </div>,

                    <GiftBoxTypeDropDown model={[this.data.$f, 'gift_box_type']} />,

                    <div className="field-group">
                        <FieldSelect
                            help="Override cases per pallet"
                            label="CPP suggestions"
                            model={[this.data.$f, 'selected_cases_per_pallet_option']}
                            onchange={(value) => {
                                if (value) {
                                    this.data.$f.cases_per_pallet = value.split(':')[1]
                                    this.data.case_per_pallet_disabled = true
                                } else {
                                    this.data.case_per_pallet_disabled = false
                                }
                            }}
                            options={this.cases_per_pallet_options}
                            placeholder="Enter manually"
                        />
                        <NumberInput
                            label='Override or enter cases per pallet'
                            disabled={this.data.case_per_pallet_disabled}
                            value={this.data.$f.cases_per_pallet}
                            oninput={(value: number) => {
                                this.data.$f.cases_per_pallet = value || null
                            }}
                        />
                    </div>,

                    <div className="field-group">
                        <FieldSelect
                            help="Override or enter cases per pallet layer"
                            label="CPL suggestions"
                            model={[this.data.$f, 'selected_cases_per_pallet_layer_option']}
                            onchange={(value) => {
                                if (value) {
                                    this.data.$f.cases_per_pallet_layer = value.split(':')[1]
                                    this.data.case_per_pallet_layer_disabled = true
                                } else {
                                    this.data.case_per_pallet_layer_disabled = false
                                }
                            }}
                            options={this.cases_per_pallet_layer_options}
                            placeholder="Enter manually"
                        />
                        <NumberInput
                            disabled={this.data.case_per_pallet_layer_disabled}
                            help=""
                            label='Cases per pallet layer'
                            value={this.data.$f.cases_per_pallet_layer}
                            oninput={(value: number) => {
                                this.data.$f.cases_per_pallet_layer = value || null
                            }}
                        />
                    </div>,

                    <div className="field-group">
                        <FieldMoney
                            currency={this.selected_spli ? [this.selected_spli, 'currency'] : [this.purchase_order(), 'was_bought_in']}
                            disabled={true}
                            label='Original price per case'
                            model={[this.data.$f, 'original_price_per_case']}
                        />
                        <FieldMoney
                            currency={[this.purchase_order(), 'was_bought_in']}
                            label='Bid per case'
                            model={[this.data.$f, 'bid_per_case']}
                            placeholder={this.data.$f.original_price_per_case ? Number(this.data.$f.original_price_per_case).toFixed(2) : ''}
                            required={!this.data.$f.original_price_per_case}
                        />
                    </div>,
                    <div className="field-group">
                        {!vnode.attrs.tbo_item && <FieldMoney
                            currency={[this.purchase_order(), 'was_bought_in']}
                            label='Suggested sales price'
                            model={[this.data.$f, 'suggested_price_per_case']}
                            placeholder=""
                        />}
                        <FieldText
                            disabled={this.data.mode === AddMode.PURCHASE}
                            label="Cases to purchase"
                            min={0}
                            model={[this.data.$f, 'cases']}
                            step={1}
                            type="number"
                            validation={this.$v.cases}
                        />
                    </div>,

                    <FieldTextArea
                        label="Remark"
                        model={[this.data.$f, 'remark']}
                    />,

                    (() => {
                        if (!vnode.attrs.tbo_item) return
                        if (this.data.purchase_order_items.length) {
                            return <div className="help">
                                {`To complete the sales TBO item, enter at least ${this.data.purchase_order_items[0].number_of_cases} + ${vnode.attrs.tbo_item.number_of_cases} (tbo) = ${this.needed_cases(vnode)} cases.`}
                            </div>
                        } else {
                            return <div className="help">
                                {`To complete the sales TBO item, enter at least ${this.needed_cases(vnode)} cases.`}
                            </div>
                        }
                    })(),
                    <ButtonGroup>
                        {vnode.attrs.mode === 'panel' && <Button
                            icon="stop"
                            onclick={vnode.attrs.oncancel}
                            text="Cancel"
                        />}

                        <Button
                            disabled={invalid.length}
                            icon="plus"
                            onclick={() => this.add_to_order(vnode, true)}
                            text={(() => {
                                if (vnode.attrs.tbo_item && this.data.$f.cases < this.needed_cases(vnode)) {
                                    if (this.is_editing(vnode)) return 'Split sales TBO item and update purchase order'
                                    return 'Split sales TBO item and add to purchase order'
                                }

                                if (this.is_editing(vnode)) {
                                    return 'Update Purchase Order'
                                } return 'Add to Purchase Order'
                            })()}
                            tip={() => invalid_fields_format(invalid_fields(this.$v), 'tip')}
                            type="success"
                        />
                    </ButtonGroup>,
                ]
            })()}
        </div>
    }
}
