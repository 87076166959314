import m from 'mithril'
import {Amount} from '@bitstillery/common/components'
import {Spinner} from '@bitstillery/common/components'
import {format_iso_to_date} from '@bitstillery/common/lib/format'

import panel from '@/components/_panel'
import table from '@/components/table'

export class SalesOrderItems {
    view(vnode: m.Vnode<any>) {
        return panel(vnode.attrs.title,
            vnode.attrs.loading() ?
                m(Spinner) :
                vnode.attrs.data().length > 0 ?
                    table.table(vnode.attrs.data, [
                        {
                            width: 3,
                            field: 'sales_order_reference',
                            name: '#',
                            function: (sales_order_item: any) =>
                                m(m.route.Link, {href: `/sales/orders/${sales_order_item.sales_order_artkey}/view/manage`},
                                    sales_order_item.sales_order_reference,
                                ),
                        },
                        {
                            width: 5,
                            field: 'sales_order_created_on',
                            name: 'Date',
                            function: (sales_order_item: any) =>
                                format_iso_to_date(sales_order_item.sales_order_created_on),
                        },
                        {
                            width: 9,
                            field: 'supplier_name',
                            name: 'Relation',
                            ellipsis: true,
                        },
                        {
                            width: 8,
                            field: 'product_name',
                            name: 'Product',
                        },
                        {
                            width: 6,
                            field: 'case_number_of_bottles',
                            name: 'Btl / cs',
                            classes: 'number',
                        },
                        {
                            width: 4,
                            field: 'bottle_volume',
                            name: 'Size',
                            classes: 'number',
                            function: (sales_order_item: any) => (+sales_order_item.bottle_volume).toFixed(1) + ' cl',
                        },
                        {
                            width: 4,
                            field: 'alcohol_percentage',
                            name: 'Alc %',
                            classes: 'number',
                            function: (sales_order_item: any) => (+sales_order_item.bottle_alcohol_percentage).toFixed(1) + '%',
                        },
                        {
                            width: 3,
                            field: 'bottle_refill_status',
                            name: 'Ref',
                        },
                        {
                            width: 6,
                            field: 'case_gift_box_type',
                            name: 'GB',
                        },
                        {
                            width: 3,
                            field: 'case_customs_status',
                            name: 'Cus.',
                        },
                        {
                            width: 3,
                            field: 'number_of_cases',
                            name: 'Cases',
                            classes: 'number',
                        },
                        {
                            width: 6,
                            field: 'price_per_case',
                            name: 'Price / cs',
                            classes: 'price',
                            function: (sales_order_item: any) =>
                                m(Amount, {
                                    amount: sales_order_item.price_per_case_excl_excise,
                                    currency: sales_order_item.sales_order_was_sold_in,
                                    rate: sales_order_item.sales_order_sold_against_rate,
                                }),
                        },
                        {
                            width: 7,
                            field: 'no_field',
                            name: 'Total price',
                            classes: 'price',
                            function: (sales_order_item: any) =>
                                m(Amount, {
                                    amount: sales_order_item.price_per_case_excl_excise * sales_order_item.number_of_cases,
                                    currency: sales_order_item.sales_order_was_sold_in,
                                    rate: sales_order_item.sales_order_sold_against_rate,
                                }),
                        },
                        {
                            width: 7,
                            field: 'sales_order_incoterm',
                            name: 'Incoterm',
                            ellipsis: true,
                        },
                        {
                            width: 7,
                            field: 'destination_name',
                            name: 'Destination',
                            ellipsis: true,
                        },
                        {
                            width: 5,
                            field: 'sales_order_status',
                            name: 'Status',
                        },
                        {
                            width: 8,
                            field: 'was_handled_by_name',
                            name: 'Handled by',
                        },
                    ], {
                        search_table_style: false,
                        sticky_header: false,
                    }) :
                    'No transactions',
            '',
            {
                download_content: m('a.fa.fa-file-excel'),
                download_callback: vnode.attrs.download_callback,
            },
        )
    }
}
