import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {format_iso_to_date, format_money} from '@bitstillery/common/lib/format'
import {countries} from '@bitstillery/common/lib/countries'
import {DateTime} from 'luxon'
import {to_specs, to_specs_with_features} from '@bitstillery/common/models/item'
import {CurrencyAndAmountCellDef, PDF} from '@bitstillery/common/pdf/pdf'
import {Icon, Spinner, Tippy} from '@bitstillery/common/components'
import {api, notifier} from '@bitstillery/common/app'

import {
    PDFCommentTemplate,
    PDFComponent,
    PDFDecimalPointSelector,
    PDFHelper,
    PDFUpdateAndDownload,
} from '../components/pdf_helper'

import {$s} from '@/app'
import {DefaultButton} from '@/components/buttons'
import {CheckBox, RadioSelection} from '@/components/html_components'
import {PDFEmailer} from '@/components/pdf_emailer'
import {DocumentType} from '@/factserver_api/email_api'
import {
    GetPurchaseOrderItemResponse,
    GetPurchaseOrderResponse,
    OkResponse,
    PricePreference,
} from '@/factserver_api/fact2server_api'

export interface RequestForProposalRendererAttrs {
    purchase_order: GetPurchaseOrderResponse
    purchase_order_items: GetPurchaseOrderItemResponse[]
    cases_or_bottles: PricePreference
    decimal_locale: string
}

/**
 * Maps the RequestForProposalRendererAttrs to base64 encoded string containing the RFP.
 *
 * @param attrs Needed data for the pdf (purchase order, cases_or_bottles etc.)
 * @returns Base64 encoded string for the created PDF.
 */
function render_to_base64(attrs: RequestForProposalRendererAttrs) {
    const pdf = new PDF(attrs.purchase_order.account)
    const doc = pdf.document

    doc.setProperties({
        title: `Request For Proposal ${attrs.purchase_order.reference}`,
        author: attrs.purchase_order.account.name,
        keywords: 'Request For Proposal, Purchase',
        creator: attrs.purchase_order.account.name,
        subject: `Request For Proposal ${attrs.purchase_order.reference}`,
    })

    pdf.title('Request For Proposal')
    pdf.relation_address([
        attrs.purchase_order.supplier.name,
        attrs.purchase_order.supplier.street_address,
        attrs.purchase_order.supplier.zip_code
            ? `${attrs.purchase_order.supplier.zip_code} ${attrs.purchase_order.supplier.city}`
            : attrs.purchase_order.supplier.city,
        countries[attrs.purchase_order.supplier.country_code],
    ])

    pdf.incoterm(`${attrs.purchase_order.incoterm} - ${attrs.purchase_order.incoterm_location}`)

    const destination_address = [
        attrs.purchase_order.target_warehouse.name,
        attrs.purchase_order.target_warehouse.street_address,
        `${attrs.purchase_order.target_warehouse.zip_code} ${attrs.purchase_order.target_warehouse.city}`,
        countries[attrs.purchase_order.target_warehouse.country_code],
    ]
    destination_address.push(`Location ID: ${attrs.purchase_order.target_warehouse.warehouse_id}`)
    if (attrs.purchase_order.target_warehouse.excise_id) {
        destination_address.push(`Excise ID: ${attrs.purchase_order.target_warehouse.excise_id}`)
    }
    destination_address.push('')
    pdf.destination_address(destination_address)
    pdf.details(
        'RFP details:',
        ['RFP reference:', 'Relation reference:', 'RFP date:', 'ETA:'],
        [
            attrs.purchase_order.reference,
            `${attrs.purchase_order.supplier.relation_nr}`,
            format_iso_to_date(DateTime.now().toISODate()),
            format_iso_to_date(attrs.purchase_order.expected_delivery_date),
        ],
    )

    let unit_short = ''
    let header_unit_price = ''
    let header_bid_price = ''
    if (attrs.cases_or_bottles === PricePreference.Case) {
        unit_short = 'cs.'
        header_unit_price = 'Unit Price Cs.'
        header_bid_price = 'Bid Cs.'
    } else {
        unit_short = 'btls.'
        header_unit_price = 'Unit Price Btls.'
        header_bid_price = 'Bid Btls.'
    }

    const items = attrs.purchase_order_items
        /* Show as new items first, if all new sort on product name. */
        .sort((poi_a, poi_b) => {
            const sort_on_name = poi_a.case.bottle.product.name.localeCompare(poi_b.case.bottle.product.name)
            return poi_a.show_as_new_on_rfp === poi_b.show_as_new_on_rfp
                ? sort_on_name
                : poi_a.show_as_new_on_rfp
                    ? -1
                    : 1
        })
        .map((poi) => {
            let quantity = poi.number_of_cases
            if (attrs.cases_or_bottles === PricePreference.Bottle) {
                quantity *= poi.case.number_of_bottles
            }
            /* Get the original spli and see if the price differs from our price (bid price or poi.was_bought_for). */
            /* Correct for hide_bid_price and bottles or cases price. */
            let unit_bid_price = Number(poi.was_bought_for)
            let unit_spli_price = poi.refers_to
                ? +poi.refers_to.price_per_case
                : +poi.was_bought_for
            if (!poi.show_bid_price_on_rfp) {
                unit_spli_price = +poi.was_bought_for
            }
            if (attrs.cases_or_bottles === PricePreference.Bottle) {
                unit_bid_price /= poi.case.number_of_bottles
                unit_spli_price /= poi.case.number_of_bottles
            }
            const description = poi.show_as_new_on_rfp
                ? pdf.text_cell(poi.case.bottle.product.name, {
                    fontStyle: 'bold',
                })
                : pdf.text_cell(`${poi.case.bottle.product.name}`)

            return [
                pdf.text_cell(`${quantity} ${unit_short}`),
                description,
                pdf.text_cell(to_specs_with_features(poi.case, poi.case.bottle, attrs.decimal_locale)),
                pdf.text_cell(poi.case.customs_status),
                unit_bid_price !== unit_spli_price && poi.show_bid_price_on_rfp
                    ? pdf.currency_and_amount_cell(attrs.purchase_order.was_bought_in, unit_spli_price, {
                        textColor: 'gray',
                    })
                    : pdf.currency_and_amount_cell(attrs.purchase_order.was_bought_in, unit_spli_price),
                /* Bid price, our poi price. Only show if differ from spli price.*/
                unit_bid_price !== unit_spli_price && poi.show_bid_price_on_rfp
                    ? pdf.currency_and_amount_cell(attrs.purchase_order.was_bought_in, unit_bid_price, {
                        fontStyle: 'bold',
                    })
                    : pdf.text_cell('         '),
                /* Offered price from spli. */
                pdf.currency_and_amount_cell(
                    attrs.purchase_order.was_bought_in,
                    Number(poi.was_bought_for) * poi.number_of_cases,
                ),
            ]
        })

    const main_table = pdf.auto_table(
        attrs.purchase_order.account,
        attrs.decimal_locale,
        ['Quantity', 'Description', '', '', header_unit_price, header_bid_price, 'Total'],
        items,
    )

    /* Total quantity */
    const total_quantity_in_cases = attrs.purchase_order_items.reduce(
        (previous, current) => current.number_of_cases + previous,
        0,
    )
    const total_quantity_in_bottles = attrs.purchase_order_items.reduce(
        (previous, current) => current.number_of_cases * current.case.number_of_bottles + previous,
        0,
    )
    const total_quantity =
        attrs.cases_or_bottles === PricePreference.Case
            ? `${total_quantity_in_cases} ${unit_short}`
            : `${total_quantity_in_bottles} ${unit_short}`
    const quantity_table = pdf.total_quantity(pdf.text_cell(`${total_quantity}`), main_table)

    /* Render the totals. Total of the items last column. */
    const total_last_column = items.reduce(
        (previous, current) => previous + +(current[6] as CurrencyAndAmountCellDef).custom_content.amount,
        0,
    )
    const totals: any[][] = [
        [
            pdf.text_cell('Subtotal'),
            pdf.currency_and_amount_cell(attrs.purchase_order.was_bought_in, total_last_column),
        ],
        [pdf.text_cell('VAT'), pdf.currency_and_amount_cell(attrs.purchase_order.was_bought_in, 0)],
        [pdf.text_cell('Total'), pdf.currency_and_amount_cell(attrs.purchase_order.was_bought_in, total_last_column)],
    ]
    pdf.total_amount(main_table, attrs.decimal_locale, totals)

    /* Render the comments, if any. */
    if (attrs.purchase_order.rfp_comment) {
        pdf.additional_string_table(quantity_table, ['Comments:'], attrs.purchase_order.rfp_comment.split('\n'))
    }

    pdf.purchase_footer(attrs.purchase_order.account)

    return doc
}

export default class RequestForProposal extends MithrilTsxComponent<unknown> {
    purchase_order_artkey: number
    purchase_order: GetPurchaseOrderResponse | null = null
    purchase_order_items: GetPurchaseOrderItemResponse[] = []
    pdf_helper = new PDFHelper<RequestForProposalRendererAttrs>(render_to_base64)
    show_in_cases_or_bottles: PricePreference | null = null
    show_decimal_point_as = 'en'
    group_similar_items = true

    constructor() {
        super()

        if (!m.route.param('artkey')) {
            m.route.set('/purchase-orders/manage')
            return
        }
        this.purchase_order_artkey = +m.route.param('artkey')
    }

    async oncreate() {
        await this.fetch_purchase_order()
    }

    async fetch_purchase_order(): Promise<void> {
        const api_calls = [
            api.get<GetPurchaseOrderResponse>(`discover/purchase-orders/${this.purchase_order_artkey}`),
            api.get<GetPurchaseOrderItemResponse[]>(`discover/purchase-orders/${this.purchase_order_artkey}/items?group_similar_items=${this.group_similar_items}`),
        ]
        const [po_result, poi_result] = await Promise.all(api_calls)
        if (!po_result.response.ok || !poi_result.response.ok) {
            notifier.notify('Cannot poi', 'warning')
            m.route.set('/purchase-orders/manage')
            return
        }
        this.purchase_order = po_result.result as GetPurchaseOrderResponse
        this.purchase_order_items = poi_result.result as GetPurchaseOrderItemResponse[]

        this.show_in_cases_or_bottles = this.purchase_order.supplier.price_preference

        this.rerender_pdf()
        m.redraw()
    }

    async save_request_proposal() {
        if (!this.purchase_order) {
            return
        }
        const poi_request_for_proposal = this.purchase_order_items.map((poi) => {
            return {
                artkey: poi.artkey,
                show_bid_price_on_rfp: poi.show_bid_price_on_rfp,
                show_as_new_on_rfp: poi.show_as_new_on_rfp,
            }
        })
        const {response} = await api.post<OkResponse>(`discover/purchase-orders/${this.purchase_order.artkey}/update-request-for-proposal`, {
            rfp_comment: this.purchase_order.rfp_comment,
            item_rfp: poi_request_for_proposal,
        }, true)
        if (!response.ok) {
            notifier.notify('Something went wrong saving the rfp details', 'warning')
            return
        }
        notifier.notify('RFP settings were saved', 'info')
    }

    /** Rerender the pdf for this purchase order. Any pending changes concerning this rfp will be saved. */
    rerender_pdf() {
        if (!this.purchase_order) {
            return
        }
        this.pdf_helper.render_base64_encoded({
            purchase_order: this.purchase_order,
            purchase_order_items: this.purchase_order_items,
            cases_or_bottles: this.show_in_cases_or_bottles || PricePreference.Case,
            decimal_locale: this.show_decimal_point_as,
        })
    }

    pdf_file_name(): string {
        return `Request for proposal - ${this.purchase_order?.reference || ''} - ${
            this.purchase_order?.supplier.name || ''
        }.pdf`
    }

    view(): m.Children {
        if (!this.purchase_order) return <Spinner className='table-spinner' />
        return (
            <div className="c-request-for-proposal view pdf-view">
                <div className="btn-toolbar">
                    <DefaultButton
                        title=" Back to list"
                        icon_class="glyphicon glyphicon-arrow-left"
                        additional_class="btn-default"
                        onclick={() => m.route.set(`/purchase-orders/manage/${this.purchase_order_artkey}`)}
                    />
                </div>

                <div className="pdf-wrapper">
                    <form className="flex-form">
                        <div className="fieldset">
                            <div className="field">
                                <label>Show bid / Show as new</label>
                                <div className="biddings">
                                    {this.purchase_order_items.map((poi) => {
                                        const bid_price_differs =
                                        poi.refers_to &&
                                        +poi.was_bought_for !== +poi.refers_to.price_per_case
                                        return (
                                            <div className="bid-line" key={`poi-${poi.artkey}`}>
                                                <div className="field-group">
                                                    <div className="field">
                                                        <Tippy content={'<div>Hide or show bid price</div>'}>
                                                            <CheckBox
                                                                checked={poi.show_bid_price_on_rfp}
                                                                disabled={!bid_price_differs}
                                                                onchange={async() => {
                                                                    poi.show_bid_price_on_rfp = !poi.show_bid_price_on_rfp
                                                                    await this.save_request_proposal()
                                                                    this.rerender_pdf()
                                                                }}
                                                            />
                                                        </Tippy>
                                                    </div>
                                                    <div className="field">
                                                        <Tippy content={'<div>Show item as new</div>'}>
                                                            <CheckBox
                                                                checked={poi.show_as_new_on_rfp}
                                                                onchange={async() => {
                                                                    poi.show_as_new_on_rfp = !poi.show_as_new_on_rfp
                                                                    await this.save_request_proposal()
                                                                    this.rerender_pdf()
                                                                }}
                                                            />
                                                        </Tippy>
                                                    </div>
                                                </div>
                                                <div className="product-description">
                                                    {` ${poi.case.bottle.product.name} ${to_specs(
                                                        poi.case.bottle,
                                                        $s.identity.user.decimal_locale,
                                                    )}. Original: ${format_money(
                                                        Number(poi.refers_to?.price_per_case || 0),
                                                        this.purchase_order?.was_bought_in,
                                                    )} p/cs. Bid: ${format_money(
                                                        Number(poi.was_bought_for),
                                                        this.purchase_order?.was_bought_in,
                                                    )} `}
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>

                                <div className="btn-group">
                                    <DefaultButton
                                        onclick={async() => {
                                            this.purchase_order_items.forEach(
                                                (poi) => (poi.show_as_new_on_rfp = true),
                                            )
                                            await this.save_request_proposal()
                                            this.rerender_pdf()
                                        }}
                                    >
                                        Mark all new
                                    </DefaultButton>
                                    <DefaultButton
                                        onclick={async() => {
                                            this.purchase_order_items.forEach(
                                                (poi) => (poi.show_as_new_on_rfp = false),
                                            )
                                            await this.save_request_proposal()
                                            this.rerender_pdf()
                                        }}
                                    >
                                        Clear new
                                    </DefaultButton>
                                </div>
                                <div className="btn-group">
                                    <DefaultButton
                                        onclick={async() => {
                                            this.purchase_order_items
                                                .filter((poi) => poi.refers_to && +poi.was_bought_for !== +poi.refers_to.price_per_case)
                                                .forEach((poi) => (poi.show_bid_price_on_rfp = true),
                                                )
                                            await this.save_request_proposal()
                                            this.rerender_pdf()
                                        }}
                                    >
                                        Mark show all bid prices
                                    </DefaultButton>
                                    <DefaultButton
                                        onclick={async() => {
                                            this.purchase_order_items.forEach(
                                                (poi) => (poi.show_bid_price_on_rfp = false),
                                            )
                                            await this.save_request_proposal()
                                            this.rerender_pdf()
                                        }}
                                    >
                                        Clear bid prices
                                    </DefaultButton>
                                </div>
                            </div>
                        </div>

                        <div className="fieldset">

                            <div className="field">
                                <label>Show quantity in</label>
                                <RadioSelection
                                    value={this.show_in_cases_or_bottles}
                                    onclick={(e) => {
                                        this.show_in_cases_or_bottles = e
                                        this.rerender_pdf()
                                    }}
                                    choices={[
                                        {
                                            description: 'Cases',
                                            value: PricePreference.Case,
                                        },
                                        {
                                            description: 'Bottles',
                                            value: PricePreference.Bottle,
                                        },
                                    ]}
                                />
                            </div>

                            <PDFDecimalPointSelector
                                onchange={(new_value: string) => {
                                    this.show_decimal_point_as = new_value
                                    this.rerender_pdf()
                                }}
                                value={this.show_decimal_point_as}
                            />

                            <CheckBox
                                label="Group similar items"
                                checked={this.group_similar_items}
                                onchange={async() => {
                                    this.group_similar_items = !this.group_similar_items
                                    await this.fetch_purchase_order()
                                }}
                                icon={<Icon size={'s'} name={'info'} tip={'Similar means same specs, case and price.'}/>}
                            />

                            <PDFCommentTemplate
                                value={this.purchase_order.rfp_comment}
                                onchange={async(value: string) => {
                                    if (this.purchase_order) {
                                        this.purchase_order.rfp_comment = value
                                        await this.save_request_proposal()
                                        this.rerender_pdf()
                                    }
                                }}
                            />

                            <PDFEmailer
                                purchase_order={this.purchase_order}
                                supplier={this.purchase_order.supplier}
                                document_type={DocumentType.RFP}
                                pdf_file_name={this.pdf_file_name()}
                                pdf_helper={this.pdf_helper}
                            />

                            <PDFUpdateAndDownload
                                rerender_pdf={async() => {
                                    this.rerender_pdf()
                                    await this.save_request_proposal()
                                }}
                                pdf_file_name={this.pdf_file_name()}
                                pdf_helper={this.pdf_helper}
                            />
                        </div>
                    </form>
                    <PDFComponent pdf_helper={this.pdf_helper}/>
                </div>
            </div>
        )
    }
}
