/** llm:tested */
// eslint-disable-next-line no-unused-vars
import m from 'mithril'
import {compact} from 'prelude-ls'
import {deref} from '@bitstillery/common/lib/utils'
import {format_iso_to_date} from '@bitstillery/common/lib/format'

import {toString} from '@/_utils'
import {Popover} from '@/components/popover/popover'
import {$m} from '@/app'

interface ItemTag {
    category(): { name(): string } | null
    name(): string
    abbreviation(): string | null
}

interface LabelData {
    category: string
    name: string
    abbreviation: string
}

interface Item {
    item_tags: any
    tax_label: any
    best_before_date: any
    cases_per_pallet: any
    remark: any
}

// Generates a list of label data for a PurchaseOrderItem or a (stock) Item.
const get_label_list = (item: Item): LabelData[] => {
    const item_labels: LabelData[] = compact(
        (deref(item.item_tags) || []).map((tag) => $m.data.item_tag.get_item_tag(tag)),
    ).map((tag: ItemTag) => ({
        category: tag.category() ? tag.category()!.name() : '',
        name: tag.name(),
        abbreviation: tag.abbreviation() || tag.name(),
    }))

    const tax_label = deref(item.tax_label)
    if (tax_label) {
        item_labels.push({
            category: 'Tax Label',
            name: tax_label,
            abbreviation: tax_label,
        })
    }

    const best_before_date = deref(item.best_before_date)
    if (best_before_date) {
        item_labels.push({
            category: 'Best Before Date',
            name: format_iso_to_date(best_before_date),
            abbreviation: 'BBD',
        })
    }

    const cases_per_pallet = deref(item.cases_per_pallet)
    if (cases_per_pallet) {
        item_labels.push({
            category: 'Cases Per Pallet',
            name: toString(cases_per_pallet),
            abbreviation: 'CPP',
        })
    }

    const remark = deref(item.remark)
    if (remark) {
        item_labels.push({
            category: 'Remark',
            name: remark,
            abbreviation: 'RE',
        })
    }

    return item_labels
}

// A mapping of categories and colors.
// Some are double, as they are used in full as well as in abbreviated form.
const label_color = (category: string): string => {
    switch (category) {
    case 'Tax Label':
        return '#e4d1d1'
    case 'Packaging':
        return '#b9b0b0'
    case 'Pack Size':
        return '#d9ecd0'
    case 'Damages':
        return '#b2c2bf'
    case 'General':
        return '#77a8a8'
    case 'Best Before Date':
    case 'BBD':
        return '#fff2df'
    case 'Cases Per Pallet':
    case 'CPP':
        return '#d6d4e0'
    default:
        return '#87bdd8'
    }
}

// Renders a list of labels.
export const labels = (list: LabelData[]) => {
    return list
        .sort((a, b) => {
            const category_compare = a.category.localeCompare(b.category)
            return category_compare !== 0 ? category_compare : a.name.localeCompare(b.name)
        })
        .map(tag =>
            <span class="label tag-label" style={{'background-color': label_color(tag.category)}}>
                {`${tag.category}: ${tag.name}`}
            </span>,
        )
}

// Renders a list of "icons"
export const label_icons = (list: LabelData[]) => {
    return list
        .sort((a, b) => {
            const category_compare = a.category.localeCompare(b.category)
            return category_compare !== 0 ? category_compare : a.name.localeCompare(b.name)
        })
        .map(tag =>
            <Popover title={tag.category || 'Item tag'} content={tag.name}>
                <span class="label tag-label-icon" style={{'background-color': label_color(tag.category)}}>
                    {tag.abbreviation}
                </span>
            </Popover>,
        )
}

// Renders a list of "icons" for a PurchaseOrderItem or a (stock) Item.
export const label_icons_for_item = (item: Item) => {
    return label_icons(get_label_list(item))
}
