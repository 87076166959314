import {PanelContext} from '@bitstillery/common/components'
import m from 'mithril'
import {notifier} from '@bitstillery/common/app'
import {MithrilTsxComponent} from 'mithril-tsx-component'

import {AddToOffer} from '@/market/pricelists/components/add_to_offer'
import {AddToOrder} from '@/purchase_orders/components/add_to_order'
import {ContextSpli} from '@/market/pricelists/view/context/spli'
import {ContextDataCard} from '@/market/pricelists/view/context/data_card'
import {context} from '@/market/pricelists/view/lib/context'
import {ContextSplsl} from '@/market/pricelists/view/context/splsl'
import {EntityType} from '@/market/pricelists/view/lib/context'

/**
 * PanelContext is not directly defined in manage.tsx,
 * because we use a tabbed view, and resolve.tsx needs
 * the same Panel. Ideally, we use a single collection
 * for spli & splsl, just like we did in the sales module
 * for sellable items.
 */
export class PanelPricelist extends MithrilTsxComponent<any> {

    view(vnode:m.Vnode<any>) {
        return <PanelContext
            collection={vnode.attrs.collection}
            context={context}
        >
            <div className="content">
                {context.data.entity_type === 'data_card' && <ContextDataCard/>}
                {context.data.entity_type === EntityType.SPLI && <ContextSpli />}
                {context.data.entity_type === EntityType.SPLSL && <ContextSplsl />}

                {context.data.entity_type === EntityType.PURCHASE_ORDER && <AddToOrder
                    context={context}
                    mode="panel"
                    selected_spli={context.data.entities[EntityType.PURCHASE_ORDER]}
                    supplier_artkey={context.data.entities[EntityType.PURCHASE_ORDER].supplier.artkey}
                    oncancel={() => {
                        context.select_none(vnode.attrs.collection)
                    }}
                    on_added_item={async() => {
                        vnode.attrs.collection.update_context()
                        context.select_next(vnode.attrs.collection)
                    }}
                />}

                {context.data.entity_type === EntityType.CUSTOM_OFFER && <AddToOffer
                    className="form-panel"
                    done={() => {
                        context.select_none(vnode.attrs.collection)
                        notifier.notify('Successfully added item to custom offer', 'success')
                    }}
                    mode="panel"
                    model={context.data.entities[EntityType.CUSTOM_OFFER]}
                    oncancel={() => {
                        context.select_none(vnode.attrs.collection)
                    }}
                />}
            </div>
        </PanelContext>
    }
}
