import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {FieldSelect, Icon} from '@bitstillery/common/components'
import {classes} from '@bitstillery/common/lib/utils'
import {grid_columns} from '@bitstillery/common/lib/collection'

import {context, methods} from '@/market/pricelists/list/lib/context'

interface ColumnsPickerAttrs {
    model: any
    type: 'table-header' | 'list'
}

export class ColumnsPicker extends MithrilTsxComponent<ColumnsPickerAttrs> {

    view(vnode:m.Vnode<ColumnsPickerAttrs>) {
        const grid_template_cols = grid_columns(context.data.columns_preview, false)
        return <div className={classes(
            'c-columns-picker',
            `type-${vnode.attrs.type ? vnode.attrs.type : 'table-header'}`, {
                'c-collection-items': vnode.attrs.type === 'table-header',
            },
        )}>
            <div className="item">
                <div className="cells" style={`grid-template-columns: ${grid_template_cols}`}>
                    <div className="cell"></div>
                    {context.data.header.map((column, column_index) => {
                        const column_invalid = methods.column_is_invalid(column_index)
                        return <div className={classes('cell', {
                            disabled: !(column_index in context.data.column_mapping),
                            hidden: (() => {
                                // In the list view, the cell is never hidden; it uses the disabled class instead.
                                if (vnode.attrs.type === 'list') return false
                                return !(column_index in context.data.column_mapping)
                            })(),
                            // The column selector has no value.
                            invalid: !!column_invalid,
                        })}>
                            <div className="column-header">
                                <Icon
                                    name={(column_index in context.data.column_mapping) ? 'toggle-switch-outline' : 'toggle-switch-off-outline'}
                                    onclick={() => {
                                        if (!(column_index in context.data.column_mapping)) {
                                            context.data.column_mapping[column_index] = ''
                                        } else {
                                            delete context.data.column_mapping[column_index]
                                        }
                                    }}
                                    type="unset"
                                />
                                <div className="column-name">
                                    {column}
                                </div>
                                {column_invalid && vnode.attrs.type === 'list' && <Icon name="warning" size="s" tip={() => {
                                    const column_invalid = methods.column_is_invalid(column_index)
                                    if (column_invalid) {
                                        return column_invalid.message
                                    }
                                    return ''
                                }}/>}
                            </div>
                            {column_index in context.data.column_mapping ? <FieldSelect
                                model={[context.data.column_mapping, column_index]}
                                options={context.data.columns.map((col) => ({label: col, value: col}))}
                                placeholder="Select Column..."
                            /> : <div className="field-placeholder">Column disabled...</div>}
                        </div>})}
                </div>
            </div>
        </div>
    }
}
