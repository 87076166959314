import {copy_object, object_to_query_string} from '@bitstillery/common/lib/utils'
import {debounce} from '@bitstillery/common/lib/utils'
import {$m, $s, config} from '@bitstillery/common/app'

const reload_window_debounced = debounce(100, () => {
    window.location.reload()
})

export default class Api {

    bodyMethods = ['POST', 'PUT']
    hooks = {
        get: [],
        delete: [],
        post: [],
        put: [],
    }

    basic_auth_header() {
        return `Basic ${btoa(`${config.api_user}:${config.api_password}`)}`
    }

    async call(endpoint, method, data, v2) {
        let api_endpoint
        const token = $s.identity.token

        if (v2) {
            api_endpoint = `${config.api_host_new}/${endpoint}`
        } else {
            api_endpoint = `${config.api_host}/${endpoint}`
        }

        let body: any = null
        if (this.bodyMethods.includes(method)) {
            body = JSON.stringify(data)
        }
        const headers = {
            Accept: 'application/json, text/*',
            'Accept-Encoding': 'gzip, deflate, br',
            'Content-Type': 'application/json; charset=UTF-8',
            'X-Requested-With': 'XMLHttpRequest',
            'X-transaction-id': `portal-${this.transaction_id()}`,
            Authorization: this.basic_auth_header(),
            'X-Auth-Token': token || '',
        }
        if ($s.session.account && $s.session.account.slug) {
            headers['X-account-slug'] = $s.session.account.slug
        }
        const request_data = {
            body,
            headers: headers,
            method,
        }

        const request = new Request(api_endpoint, request_data)
        return await fetch(request)
    }

    async delete<T>(endpoint: string): Promise<{
        response: Response
        result: T
        success: Boolean
        status_code: number
    }> {
        const response = await this.call(endpoint, 'DELETE', null, true)
        if (response.status === 503) {
            reload_window_debounced()
            return {
                response,
                result: {} as T,
                status_code: response.status,
                success: false,
            }
            
        } else if (response.status >= 500) {
            throw new Error(`${response.status}: ${response.statusText} (${response.url})`)
        }
        let success = true
        if (response.status === 401) {
            $m.identity.logout_debounced()
        }

        if (response.status > 299 || response.status < 200) {
            success = false
        }

        return {
            response,
            result: await response.json() as T,
            status_code: response.status,
            success,
        }
    }

    async get<T>(endpoint: string, data: object = null): Promise<{
        response: Response
        result: T
        success: Boolean
        status_code: number
        total: null | number
    }> {
        if (data) {
            if ('artkeys' in data) {
                data.artkeys = data.artkeys.join(',')
            }
            const flat_data = object_to_query_string(copy_object(data))
            let query_string = new URLSearchParams(flat_data).toString()
            endpoint = `${endpoint}?${query_string}`
        }
        const response = await this.call(endpoint, 'GET', null, true)
        if (response.status === 503) {
            reload_window_debounced()
            return {
                response,
                result: {} as T,
                status_code: response.status,
                success: false,
                total: null,
            }
        } else if (response.status >= 500) {
            throw new Error(`${response.status}: ${response.statusText} (${response.url})`)
        }
        let success = true
        if (response.status === 401) {
            $m.identity.logout_debounced()
        }

        if (response.status > 299 || response.status < 200) {
            success = false
        }

        const content_type: any = response.headers.get('content-type')
        let result, result_data, total = null

        if (response.status !== 204 && (!content_type || content_type === 'application/json')) {
            result = await response.json()
            total = 'total' in result ? result.total : 'total_count' in result ? result.total_count : null
            // Deal with several response types from the API.
            if ('result' in result) {
                result_data = result.result
            } else if ('items' in result) {
                result_data = result.items
            } else {
                result_data = result
            }

        } else {
            result_data = await response.blob()
        }

        // Hook into the response.
        if (!this.hooks.get.every((hook: any) => hook({endpoint, data}, response))) {
            return {
                response,
                result: result_data as T,
                status_code: response.status,
                success,
                total,
            }
        }

        return {
            response,
            result: result_data as T,
            status_code: response.status,
            success,
            total,
        }
    }

    load_hooks(hooks: any) {
        for (const [method, hook] of Object.entries(hooks)) {
            this.hooks[method] = hook
        }
    }

    async post<T>(endpoint, data = {}, v2 = false): Promise<{
        response: Response
        result: T
        success: boolean
        status_code: number
        total: null | number
    }> {

        if (process.env.MSI_PACKAGE === 'discover' && !v2) {
            // Deal with legacy requests to the factserver (Discover)
            const v1_data_format = {
                data: {
                    _account_slug: $s.session.account.slug,
                },
            }

            Object.assign(v1_data_format.data, data)
            data = v1_data_format
        }
        const response = await this.call(endpoint, 'POST', data, v2)
        const content_type = response.headers.get('Content-Type')
        let result = content_type && content_type.startsWith('application/json') ? await response.json() as T : {}

        // Hook into the response.
        if (!this.hooks.post.every((hook: any) => hook({endpoint, data, v2}, response))) {
            return {
                result: result as T,
                status_code: response.status,
                response,
            }
        }
        if (response.status === 503) {
            reload_window_debounced()
            return {
                response,
                result: {} as T,
                status_code: response.status,
                success: false,
                total: null,
            }
        } else if (response.status === 401) {
            $m.identity.logout_debounced()
            return {
                response,
                result: {} as T,
                status_code: response.status,
                success: false,
                total: 0,
            }
        } else if (response.status >= 500) {
            throw new Error(`${response.status}: ${response.statusText} (${response.url})`)
        }

        let success = true
        let total = null

        // (!) Make sure the response format for v1 & v2 is common
        let result_data: any = null
        if (result === null) {
            result = {}
        }
        total = 'total' in result ? result.total : 'total_count' in result ? result.total_count : null

        if (v2) {
            delete result.total
            success = response.status < 300
        } else {
            if ('success' in result) {
                success = result.success
                delete result.success
            } else if (typeof result.result === 'object' && 'success' in result.result) {
                // Deal with inconsistent placement of 'success' in response
                success = result.result.success
                delete result.result.success
            }

            if (
                typeof result !== 'boolean' &&
                ((result !== null && !Array.isArray(result)) && Object.keys(result).length === 0)
            ) {
                result = null
            }
        }

        if (result) {
            if ('result' in result) {
                result_data = result.result
            } else if ('items' in result) {
                result_data = result.items
            } else {
                result_data = result
            }
        }

        return {
            response,
            result: result_data as T,
            status_code: response.status,
            success,
            total,
        }
    }

    async put<T>(endpoint, data = {}): Promise<{
        response: Response
        result: T
        success: boolean
        status_code: number
        total: null | number
    }> {
        const response = await this.call(endpoint, 'PUT', data, true)
        const result = await response.json()

        let success = true

        if (response.status === 503) {
            reload_window_debounced()
            return {
                response,
                result: {} as T,
                status_code: response.status,
                success: false,
                total: null,
            }
        } else if (response.status >= 500) {
            throw new Error(`${response.status}: ${response.statusText} (${response.url})`)
        }

        if (response.status > 299 || response.status < 200) {
            success = false
        }
        if (response.status === 401) {
            $m.identity.logout_debounced()
        }

        if ('success' in result) {
            success = result.success
            delete result.success
        }

        let total = result.total
        delete result.total

        return {
            response,
            result: result as T,
            status_code: response.status,
            success,
            total,
        }
    }

    async download(endpoint: string, file_name: string, data = {}) {
        const res = await this.call(endpoint, 'POST', data, true)
        if (res.status === 401) {
            $m.identity.logout_debounced()
            return
        }
        if (res.status === 204) return {result: null}

        const blob = await res.blob()
        const a = document.createElement('a')
        document.body.appendChild(a) // neccessary for Firefox(?)
        const url = window.URL.createObjectURL(blob)
        a.href = url
        a.download = file_name
        a.click()
    }

    transaction_id() {
        return Math.random().toString(36).substr(2, 9)
    }
}
