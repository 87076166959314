/** llm:tested */
import m from 'mithril'
import {Amount, Icon, Link} from '@bitstillery/common/components'
import {AccountSlug, current_account_slug} from '@bitstillery/common/account/account'
import {Spinner} from '@bitstillery/common/components'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {displayable_float} from '@bitstillery/common/lib/format'

import {RevenueType} from './models'

import api from '@/api'
import {button_with_icon} from '@/components/_buttons'
import {radio, date_week, date, date_month} from '@/components/inputs'
import table from '@/components/table'
import {download_binary_file_from_base64_str, format_date_html5, formatDate} from '@/_utils'
import {convert_from_currency_to_euro} from '@/factserver_api/currencies'
import {$m, $s} from '@/app'

export class SalesOrdersRevenue extends MithrilTsxComponent<unknown> {
    sales_orders: any
    start_date: any
    end_date: any
    from_date: any
    to_date: any
    week_date: any
    month_date: any
    _week_input_value: any
    _month_input_value: any
    date_selection_value: any
    loading: any

    constructor() {
        super()
        this.sales_orders = window.prop([])
        this.start_date = window.prop('')
        this.end_date = window.prop('')
        this.from_date = window.prop('')
        this.to_date = window.prop('')
        this.week_date = window.prop(new Date())
        this.month_date = window.prop(new Date())
        this._week_input_value = window.prop('')
        this._month_input_value = window.prop('')
        this.date_selection_value = window.prop('month')
        this.loading = window.prop(false)

        // Default to the current month!
        this.month(new Date())
        this.week(new Date())
        this.date_selection(this.date_selection_value())
        this.query()
    }

    oncreate() {
        m.redraw()
    }

    set_month_dates = () => {
        const date = this.month_date()
        this.start_date(new Date(date.getFullYear(), date.getMonth(), 1))
        this.end_date(new Date(date.getFullYear(), date.getMonth() + 1, 0))
    }

    set_week_dates = () => {
        const date = this.week_date()
        this.start_date(new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1))
        this.end_date(new Date(date.getFullYear(), date.getMonth(), date.getDate() + 6))
    }

    week = (value?: Date) => {
        if (value) {
            this._week_input_value(value.getWeek())
            this.week_date(value)
        } else {
            return this._week_input_value()
        }
    }

    month = (value?: Date) => {
        if (value) {
            this._month_input_value(value.getMonthInputFormat())
            this.month_date(value)
        } else {
            return this._month_input_value()
        }
    }

    from = (value?: string) => {
        if (value) {
            this.from_date(value)
        } else {
            return this.from_date()
        }
    }

    to = (value?: string) => {
        if (value) {
            this.to_date(value)
        } else {
            return this.to_date()
        }
    }

    date_selection = (value?: string) => {
        if (value !== undefined) {
            this.date_selection_value(value)
        } else {
            return this.date_selection_value()
        }
    }

    set_start_end_date = () => {
        switch (this.date_selection_value()) {
        case 'range':
            this.start_date(this.from_date())
            this.end_date(this.to_date())
            break
        case 'week':
            this.set_week_dates()
            break
        case 'month':
            this.set_month_dates()
            break
        }
    }

    query = (event?: Event) => {
        if (event) {
            event.preventDefault()
        }
        this.loading(true)

        this.set_start_end_date()

        const data = {
            start_date: format_date_html5(this.start_date()),
            end_date: format_date_html5(this.end_date()),
        }
        api.call('sales.reporting.revenue', data, this.set_sales_orders)
    }

    set_sales_orders = (resp: any) => {
        this.loading(false)
        if (resp.success) {
            this.sales_orders(resp.result)
        }
    }

    handle_export_result = (resp: any) => {
        if (resp.success) {
            download_binary_file_from_base64_str(
                resp.result,
                'Revenue from ' + formatDate(this.start_date()) + ' to ' + formatDate(this.end_date()) + '.xlsx',
            )
        } else {
            $m.common.generic_error_handler()
        }
    }

    export_revenue_to_excel = () => {
        this.set_start_end_date()

        api.call('sales.reporting.export_revenue_to_excel', {
            start_date: format_date_html5(this.start_date()),
            end_date: format_date_html5(this.end_date()),
        }, this.handle_export_result)
    }

    reseller_supplier_names = () => {
        if ([AccountSlug.A2BC, AccountSlug.ETR].includes(current_account_slug())) {
            return [$m.data.msi_supplier_name, $m.data.msp_supplier_name]
        } else if (current_account_slug() === AccountSlug.MSI) {
            return [$m.data.a2bc_supplier_name, $m.data.msp_supplier_name]
        } else if (current_account_slug() === AccountSlug.MSP) {
            return [$m.data.a2bc_supplier_name, $m.data.msi_supplier_name]
        } else {
            return []
        }
    }

    sales_orders_for_type = (type: RevenueType) => {
        if (type === RevenueType.DIRECT_SALES) {
            return this.sales_orders().filter((so) => !so.supplier.is_supplier_for_account_artkey)
        } else if (type === RevenueType.TO_RESELLERS) {
            return this.sales_orders().filter((so) => so.supplier.is_supplier_for_account_artkey)
        } else {
            return this.sales_orders()
        }
    }

    supplier_name = (type: RevenueType) => {
        if (type === RevenueType.TO_RESELLERS) {
            return `To ${this.reseller_supplier_names().join(' / ')}`
        } else if ($m.accounts.current_account()) {
            return $m.accounts.current_account().name()
        }
    }

    turnover = (type: RevenueType) => {
        return this.sales_orders_for_type(type).map((sales_order) =>
            convert_from_currency_to_euro(
                +sales_order.turnover,
                sales_order.was_sold_in,
                sales_order.sold_against_rate,
            ),
        ).reduce((partial, s) => partial + s, 0)
    }

    additionals_other_total = (type: RevenueType) => {
        return this.sales_orders_for_type(type).map(sales_order =>
            convert_from_currency_to_euro(
                +sales_order.additionals_other_total,
                sales_order.was_sold_in,
                sales_order.sold_against_rate,
            ),
        ).reduce((partial, s) => partial + s, 0)
    }

    additionals_turnover_total = (type: RevenueType) => {
        return this.sales_orders_for_type(type).map(sales_order =>
            convert_from_currency_to_euro(
                +sales_order.additionals_turnover_total,
                sales_order.was_sold_in,
                sales_order.sold_against_rate,
            ),
        ).reduce((partial, s) => partial + s, 0)
    }

    credit_total = (type: RevenueType) => {
        return this.sales_orders_for_type(type).map(sales_order =>
            convert_from_currency_to_euro(
                +sales_order.credit_total,
                sales_order.was_sold_in,
                sales_order.sold_against_rate,
            ),
        ).reduce((partial, s) => partial + s, 0)
    }

    euro_turnover = (type: RevenueType) => {
        return this.sales_orders_for_type(type).map(sales_order => +sales_order.euro_turnover).reduce((partial, s) => partial + s, 0)
    }

    gross_margin_euro = (type: RevenueType) => {
        return this.sales_orders_for_type(type).map(sales_order => +sales_order.margin).reduce((partial, s) => partial + s, 0)
    }

    gross_margin_percentage = (type: RevenueType) => {
        return ((this.gross_margin_euro(type)) / (this.euro_turnover(type)) * 100) || 0
    }

    net_margin_euro = (type: RevenueType) => {
        return this.sales_orders_for_type(type).map(sales_order => +sales_order.net_margin).reduce((partial, s) => partial + s, 0)
    }

    net_margin_percentage = (type: RevenueType) => {
        return ((this.net_margin_euro(type)) / (this.euro_turnover(type)) * 100) || 0
    }

    view() {
        return (
            <div class="c-sales-revenue view">
                <div class="c-filter-group">
                    {button_with_icon('Export to excel', 'fa-file-excel', {
                        class: 'btn-default',
                        onclick: this.export_revenue_to_excel,
                    })}
                </div>

                <div class="c-filter-group">
                    {radio(this.date_selection, [
                        {value: 'range', description: 'Range'},
                        {value: 'week', description: 'Week'},
                        {value: 'month', description: 'Month'},
                    ])}
                </div>

                <div class="c-filter-group" onsubmit={this.query}>
                    {this.date_selection() === 'range' && (
                        <span>
                            <div class="filter-field">
                                <label>From</label>
                                {date(this.from, {required: true})}
                            </div>
                            <div class="filter-field">
                                <label>To</label>
                                {date(this.to, {required: true})}
                            </div>
                        </span>
                    )}

                    {this.date_selection() === 'week' && (
                        <div class="filter-field">
                            <label>Select week</label>
                            {date_week(this.week, {required: true})}
                        </div>
                    )}

                    {this.date_selection() === 'month' && (
                        <div class="filter-field">
                            <label>Select month</label>
                            {date_month(this.month, {required: true})}
                        </div>
                    )}

                    <button
                        class="btn btn-default"
                        onclick={this.query}
                        type="submit"
                        disabled={this.loading()}
                    >
                        {this.loading() ? <Spinner /> : 'Search'}
                    </button>
                </div>

                <div class="c-filter-group">
                    {[RevenueType.DIRECT_SALES, RevenueType.TO_RESELLERS].map(type => (
                        <dl class="dl-horizontal">
                            <dt>Supplier</dt>
                            <dd class="number">{this.supplier_name(type)}</dd>
                            <dt>Period</dt>
                            <dd class="number">{formatDate(this.start_date())} - {formatDate(this.end_date())}</dd>
                            <dt>Turnover</dt>
                            <dd class="number">€{this.turnover(type).formatMoney()}</dd>
                            <dt>Additionals turnover</dt>
                            <dd class="number">€{displayable_float(this.additionals_turnover_total(type), 2)}</dd>
                            <dt>Additionals other</dt>
                            <dd class="number">€{this.additionals_other_total(type).formatMoney()}</dd>
                            <dt>Total credited</dt>
                            <dd class="number">€{this.credit_total(type).formatMoney()}</dd>
                            <dt>Nr. of sales orders</dt>
                            <dd class="number">{this.sales_orders_for_type(type).length}</dd>
                            <dt>Gross Margin (€)</dt>
                            <dd class="number">€{this.gross_margin_euro(type).formatMoney()}</dd>
                            <dt>Gross Margin (%)</dt>
                            <dd class="number">{this.gross_margin_percentage(type).toFixed(2)}%</dd>
                            <dt>Net Margin (€)</dt>
                            <dd class="number">€{this.net_margin_euro(type).formatMoney()}</dd>
                            <dt>Net Margin (%)</dt>
                            <dd class="number">{this.net_margin_percentage(type).toFixed(2)}%</dd>
                        </dl>
                    ))}
                </div>

                {table.table(this.sales_orders, [
                    {
                        width: 6,
                        field: 'reference',
                        name: '#',
                        function: (sales_order) => (
                            <Link href={`/sales/orders/${sales_order.artkey}/view/manage`}>
                                {sales_order.reference}
                            </Link>
                        ),
                    },
                    {
                        width: 6,
                        field: 'invoice_number',
                        name: 'Invoice nr',
                    },
                    {
                        width: 8,
                        name: 'Invoice date',
                        function: (sales_order) => formatDate(sales_order.is_invoiced_on),
                    },
                    {
                        width: 15,
                        field: 'supplier.name',
                        name: 'Relation',
                    },
                    {
                        width: 10,
                        name: 'Sales manager',
                        function: (sales_order) => (
                            <span>
                                {sales_order.supplier.sales_manager.profile.name + ' '}
                                {sales_order.supplier.sales_manager.profile.name !== sales_order.was_handled_by.profile.name && (
                                    <Icon
                                        name="profile"
                                        tip={sales_order.was_handled_by.profile.name}
                                    />
                                )}
                            </span>
                        ),
                    },
                    {
                        width: 10,
                        name: 'Product total excl. excise',
                        classes: 'price',
                        function: (sales_order) => (
                            <Amount
                                amount={sales_order.product_total_excl_excise}
                                currency={sales_order.was_sold_in}
                                rate={sales_order.sold_against_rate}
                                display_currency={$s.currencies.default}
                            />
                        ),
                    },
                    {
                        width: 10,
                        name: 'Additional turnover',
                        classes: 'price',
                        function: (sales_order) => (
                            <Amount
                                amount={sales_order.additionals_turnover_total}
                                currency={sales_order.was_sold_in}
                                rate={sales_order.sold_against_rate}
                                display_currency={$s.currencies.default}
                            />
                        ),
                    },
                    {
                        width: 10,
                        name: 'Turnover',
                        classes: 'price',
                        function: (sales_order) => (
                            <Amount
                                amount={sales_order.turnover}
                                currency={sales_order.was_sold_in}
                                rate={sales_order.sold_against_rate}
                                display_currency={$s.currencies.default}
                            />
                        ),
                    },
                    {
                        width: 10,
                        name: 'Additional other',
                        classes: 'price',
                        function: (sales_order) => (
                            <Amount
                                amount={sales_order.additionals_other_total}
                                currency={sales_order.was_sold_in}
                                rate={sales_order.sold_against_rate}
                                display_currency={$s.currencies.default}
                            />
                        ),
                    },
                    {
                        width: 10,
                        field: '',
                        name: 'Gross Margin (€)',
                        classes: 'price',
                        function: (sales_order) => (
                            <Amount
                                amount={sales_order.margin}
                                currency={$s.currencies.default}
                            />
                        ),
                    },
                    {
                        width: 8,
                        name: 'Gross Margin (%)',
                        classes: 'number',
                        function: (sales_order) => (
                            sales_order.euro_turnover
                                ? (sales_order.margin / Math.abs(sales_order.euro_turnover) * 100).toFixed(2) + '%'
                                : '-'
                        ),
                    },
                    {
                        width: 9,
                        field: '',
                        name: 'Net Margin (€)',
                        classes: 'price',
                        function: (sales_order) => (
                            <Amount
                                amount={sales_order.net_margin}
                                currency={$s.currencies.default}
                            />
                        ),
                    },
                    {
                        width: 8,
                        name: 'Net Margin (%)',
                        classes: 'number',
                        function: (sales_order) => (
                            sales_order.euro_turnover
                                ? (sales_order.net_margin / Math.abs(sales_order.euro_turnover) * 100).toFixed(2) + '%'
                                : '-'
                        ),
                    },
                ])}
                {!this.sales_orders().length && <p>No results.</p>}
            </div>
        )
    }
}
