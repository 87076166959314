import {MithrilTsxComponent} from 'mithril-tsx-component'
import {Button} from '@bitstillery/common/components'
import {classes} from '@bitstillery/common/lib/utils'
import {$s, $t} from '@bitstillery/common/app'
import m from 'mithril'

export interface DataCardAttrs {
    className?: string
    model: {
        data: DataCardEntry[]
    }
    type?: string
    variant?: string
}

interface DataCardEntry {
    label: string | JSX.Element
    value?: any
    hide_value?: boolean
    type?: string
}

export interface DataCardModel {
    data: DataCardEntry[]
}

export class DataCard extends MithrilTsxComponent<DataCardAttrs> {
    view(vn:m.Vnode<DataCardAttrs>) {
        return <div className={classes('c-data-card',
            `type-${vn.attrs.type ? vn.attrs.type : 'default'}`,
            vn.attrs.className, `variant-${vn.attrs.variant ? vn.attrs.variant : 'default'}`,
        )}>
            {vn.attrs.model.data.map((entry:DataCardEntry) => {
                if (!entry) return null
                return <div className={classes('entry', `type-${entry.type ? entry.type : 'default'}`)}>
                    <div className="entry-label">{entry.label}</div>
                    {!entry.hide_value && <div className="entry-value">{entry.value ? entry.value : '-'}</div>}
                </div>
            })}
        </div>
    }
}

export class ButtonDataCard extends MithrilTsxComponent<any> {

    view(vnode:m.Vnode<any>) {
        return <Button
            active={(() => {
                if (vnode.attrs.context) {
                    return vnode.attrs.context.link_entity_active('data_card')
                }
                return !$s.panels.context.collapsed
            })()}
            className="btn-data-card"
            icon="card_account_details_outline"
            link={vnode.attrs.context ? vnode.attrs.context.link_entity(vnode.attrs.collection, 'data_card', null) : undefined}
            link_options={vnode.attrs.link_options}
            onclick={() => {
                if (vnode.attrs.context) {
                    return
                }
                $s.panels.context.collapsed = !$s.panels.context.collapsed
            }}
            replace_history={!!vnode.attrs.context}
            tip={$t('data_card.tip')}
            text={$t('data_card.text')}
            type="info"
            variant="context"
        />
    }
}
