import m from 'mithril'
import {format_iso_to_date_time} from '@bitstillery/common/lib/format'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {
    CollectionHeader,
    CollectionItems,
    CollectionView,
    RowActionProcessed,
    Link,
    PanelFilters,
} from '@bitstillery/common/components'
import {CollectionTransforms, CollectionProxy} from '@bitstillery/common/lib/collection'
import {generate_filters} from '@bitstillery/common/lib/filters'
import {api, notifier} from '@bitstillery/common/app'
import {
    FiltersDescription,
    FilterSelectSingleState,
    FilterTextState,
} from '@bitstillery/common/types'

import {EmailBounceEventType, GetAllUsersResponse} from '@/factserver_api/fact2server_api'

interface BounceFilters extends FiltersDescription {
    event_type: Partial<FilterSelectSingleState>
    manager_artkey: Partial<FilterSelectSingleState>
    search_terms: Partial<FilterTextState>
}

const filters = generate_filters<BounceFilters>({
    event_type: {
        options: [
            [EmailBounceEventType.Bounce, 'Bounce'],
            [EmailBounceEventType.DeliveryDelay, 'Delivery delay'],
            [EmailBounceEventType.Complaint, 'Complaint'],
        ],
        placement: {order: 2},
        serialize: 'string',
        type: 'SELECT_SINGLE',
    },
    manager_artkey: {
        icon: 'profile',
        placement: {order: 1},
        serialize: 'number',
        type: 'SELECT_SINGLE',
    },
    search_terms: {
        placement: {order: 0},
        serialize: 'string',
        type: 'TEXT',
    },
})

const transforms:CollectionTransforms = {
    filters_to_query: (filters) => {
        const query = {
            filters: {
                event_type: filters.event_type.selection,
                manager_artkey: filters.manager_artkey.selection,
            },
            page_size: collection.state.page_size,
            search_terms: filters.search_terms.selection ? filters.search_terms.selection : undefined,
            sort_by: collection.state.sort.by,
            sort_ascending: collection.state.sort.order === 'asc' ? 'ASC' : 'DESC',
        }
        return query
    },
    filter_metadata: {
        transform: async(filters) => {
            if (!filters.manager_artkey.options.length) {
                const query_param = '?only_active=true&only_traders=true'
                const {status_code, result} = await api.get<GetAllUsersResponse[]>(`discover/users${query_param}`)
                if (status_code > 299) {
                    return
                }
                const traders = result.map((i) => [i.artkey, i.name])
                filters.manager_artkey.options.splice(0, filters.manager_artkey.options.length, ...traders)
            }
        },
    },
}

const collection = new CollectionProxy()

const columns = [
    {
        name: 'Relation',
        render: (row) => {
            return <div className="td-group">
                <span className={'header'}>
                    {row.relation_name ? <Link
                        target="_blank"
                        href={`/crm/relations/${row.supplier_artkey}/edit`}
                    >
                        {row.relation_name}
                    </Link> : 'Unknown relation'}
                </span>
                <span className="details">
                    {row.contact_person_artkey !== null ? <Link
                        target={'_blank'}
                        href={`/crm/relations/${row.supplier_artkey}/contact-persons/${row.contact_person_artkey}/edit`}
                    >
                        {row.contact_person_name}
                    </Link> : row.email_address}
                </span>
            </div>
        },
    },
    {
        name: 'Event type',
        render: (row) => {
            return <div className="td-group">
                <span className={'header'}>
                    {row.event_type}
                </span>
                <span className="details">
                    {row.event_summary}
                </span>
            </div>
        },
        width: '2fr',
    },
    {
        name: 'Number of events',
        render: (row) => {
            return <span>
                {format_iso_to_date_time(row.last_event_on)} ({row.number_of_events} times)
            </span>
        },
    },
]

export class EmailBounces extends MithrilTsxComponent<unknown> {

    oninit() {
        collection.init({
            endpoint: {
                method: 'get',
                path: 'discover/email/bounce/collection-view',
            },
            page_size: 50,
            sort: {
                by: 'last_event_on',
                order: 'asc',
                options: [
                    ['last_event_on', 'Last event on'],
                    ['number_of_events', 'Number of events'],
                    ['relation_name', 'Relation name'],
                ],
            },
        }, filters, transforms)
    }

    view(): m.Children {
        return <div className="c-email-bounces view-container">
            <PanelFilters collection={collection}/>

            <CollectionView mode="table">
                <CollectionHeader collection={collection} columns={columns} />

                <CollectionItems
                    collection={collection}
                    columns={columns}
                    row_actions={(row) => {
                        return [
                            <RowActionProcessed
                                row={row}
                                row_process={async(row) => {
                                    await api.post(`discover/email/bounce/${row.artkey}/set-processed`, {}, true)
                                    collection.soft_delete(row.artkey)
                                    notifier.notify(`Email bounce ${row.artkey} set to processed`, 'info')
                                }}
                            />,
                        ]
                    }}
                />
            </CollectionView>
        </div>
    }
}
