import m from 'mithril'
import {
    FieldMoney,
    FieldCheckbox,
    FieldSelect,
    FieldText,
    FieldTextArea,
    Button,
    PanelContext,
    ButtonGroup,
    ButtonDataCard,
} from '@bitstillery/common/components'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {api, logger, notifier} from '@bitstillery/common/app'
import {conditional, invalid_fields, invalid_fields_format, required, validation} from '@bitstillery/common/lib/validation'
import {proxy} from '@bitstillery/common/lib/proxy'
import {merge_deep} from '@bitstillery/common/lib/utils'
import {mount_dialog} from '@bitstillery/common/lib/dialog'

import {CountriesSelect} from '@/components/html_components'
import {AttachmentList, AttachmentHelper} from '@/components/attachment_list'
import {AttachmentType} from '@/factserver_api/attachment_api'
import {$m, $s} from '@/app'

export class WarehouseUpsert extends MithrilTsxComponent<any> {
    destination: any
    user: any
    create: any
    country_to_region$: any
    regions: any
    supplier_artkey: string
    destination_artkey: string
    attachment_helper: AttachmentHelper | null

    data = (() => {
        const $f = {
            artkey: null,
            can_handle_our_stock: false,
            city: '',
            country_code: '',
            currency: 'EUR',
            destination_type: 'warehouse',
            emailaddress: '',
            excise_id: '',
            is_third_party_warehouse: false,
            memo: '',
            name: '',
            region_code: '',
            street_address: '',
            suppliers: [],
            vat_id: '',
            warehouse_base_costs: 0,
            warehouse_costs_per_case: 0,
            warehouse_id: '',
            zip_code: '',
        }
        return proxy({
            $f,
            current: 0,
            country_to_region$: null,
            create: false,
            destination: null,
            destination_artkey: Number(m.route.param('artkey')),
            dialog: {
                loading: false,
                title: 'Deactivate Location',
                type: 'danger',
            },
            supplier_artkey: Number(m.route.param('supplier_artkey')),
            user: [],
            regions: [] as any[],
        })
    })()

    $v = {
        city: validation([this.data.$f, 'city'], required()),
        country_code: validation([this.data.$f, 'country_code'], required()),
        destination_type: validation([this.data.$f, 'destination_type'], conditional(() => this.data.$f.is_third_party_warehouse, required())),
        name: validation([this.data.$f, 'name'], required()),
        zip_code: validation([this.data.$f, 'zip_code'], conditional(() => this.data.$f.destination_type === 'warehouse', required())),
    }

    async oncreate() {
        this.data.$f.is_third_party_warehouse = !this.data.supplier_artkey
        if (this.data.destination_artkey) {
            await this.query_destination(this.data.destination_artkey)
            this.attachment_helper = new AttachmentHelper(this.data.destination_artkey, AttachmentType.WAREHOUSE)
        } else {
            this.data.create = true
            this.attachment_helper = null
        }
    }

    confirm_dialog_deactivate() {
        const mounted = mount_dialog({
            body: () => [
                <span>Are you <i>Abolutely</i> sure that you want to deactivate warehouse {this.data.$f.name}?</span>,
            ],
            confirm: {
                action: async() => {
                    let endpoint
                    if (this.data.supplier_artkey) {
                        logger.info(`[warehouse_edit] deactivate warehouse ${this.data.$f.artkey} from relation ${this.data.supplier_artkey} endpoint`)
                        endpoint = `discover/relations/${this.data.supplier_artkey}/locations/${this.data.$f.artkey}`
                    } else {
                        logger.info(`[warehouse_edit] deactivate warehouse ${this.data.$f.artkey} from third-parties endpoint`)
                        endpoint = `discover/locations/third-parties/${this.data.$f.artkey}`
                    }

                    await api.delete(endpoint)
                    notifier.notify(`Successfully deactivated warehouse ${this.data.$f.name}.`, 'success')

                    if (this.data.supplier_artkey) {
                        m.route.set(`/crm/relations/${this.data.supplier_artkey}/edit?tabId=destinations`)
                    } else {
                        m.route.set('/data/warehouses')
                    }
                    mounted.close()
                },
                icon: 'trash',
                text: 'Deactivate',
            },
            model: this.data.dialog,
        })
    }

    redirect_to_edit_page(artkey: string) {
        const base_url = this.data.supplier_artkey
            ? `/crm/relations/${this.data.supplier_artkey}/edit/warehouses/`
            : '/data/warehouses/'
        m.route.set(`${base_url}${artkey}/edit`, {key: 'edit'})
    }

    redirect_to_previous_page() {
        const url = this.data.supplier_artkey
            ? `/crm/relations/${this.data.supplier_artkey}/edit?tabId=destinations`
            : '/data/warehouses'
        m.route.set(url)
    }

    async query_destination(destination_artkey: number) {
        let result: any
        if (this.data.supplier_artkey) {
            logger.info(`[warehouse_edit] load destination ${destination_artkey} from relation ${this.data.supplier_artkey} endpoint`);
            ({result} = await api.get(`discover/relations/${this.data.supplier_artkey}/locations/${destination_artkey}`) as any)
        } else {
            logger.info(`[warehouse_edit] load destination ${destination_artkey} from third-parties endpoint`);
            ({result} = await api.get(`discover/locations/third-parties/${destination_artkey}`) as any)
        }

        merge_deep(this.data.$f, {
            artkey: result.artkey,
            can_handle_our_stock: result.can_handle_our_stock,
            country_code: result.country_code,
            city: result.city,
            currency: result.currency ? result.currency : 'EUR',
            destination_type: result.destination_type,
            emailaddress: result.emailaddress,
            excise_id: result.excise_id,
            is_third_party_warehouse: result.is_third_party_warehouse,
            memo: result.memo,
            name: result.name,
            region_code: result.region_code,
            street_address: result.street_address,
            vat_id: result.vat_id,
            warehouse_base_costs: result.warehouse_base_costs,
            warehouse_costs_per_case: result.warehouse_costs_per_case,
            warehouse_id: result.warehouse_id,
            zip_code: result.zip_code,
        })
        this.update_regions(this.data.$f.country_code)
    }

    async update_regions(country_code: string) {
        const {result} = await api.post('suppliers.get_sub_divisions_for_country', {country_code}, false) as any
        this.data.regions.splice(0, this.data.regions.length, ...result.map((region: any) => ({
            label: `${region.name} (${region.code})`,
            value: region.code,
        })))

        if (!this.data.regions.find((region: any) => region.value === this.data.$f.region_code)) {
            this.data.$f.region_code = ''
        }
    }

    async save() {
        let endpoint = ''
        let method = 'post'
        if (this.data.supplier_artkey) {
            endpoint = `discover/relations/${this.data.supplier_artkey}/locations`
            if (this.data.$f.artkey) {
                endpoint += `/${this.data.$f.artkey}`
                method = 'put'
            }
        } else {
            endpoint = 'discover/locations/third-parties'
            if (this.data.$f.artkey) {
                endpoint += `/${this.data.$f.artkey}`
                method = 'put'
            }
        }
        const {result, success} = await api[method](endpoint, {
            artkey: this.data.$f.artkey,
            can_handle_our_stock: this.data.$f.can_handle_our_stock,
            city: this.data.$f.city,
            country_code: this.data.$f.country_code,
            currency: this.data.$f.currency,
            destination_type: this.data.$f.is_third_party_warehouse ? this.data.$f.destination_type : undefined,
            emailaddress: this.data.$f.emailaddress,
            excise_id: this.data.$f.excise_id,
            is_third_party_warehouse: this.data.$f.is_third_party_warehouse,
            memo: this.data.$f.memo,
            name: this.data.$f.name,
            region_code: this.data.$f.region_code,
            vat_id: this.data.$f.vat_id,
            street_address: this.data.$f.street_address,
            supplier_artkey: this.data.supplier_artkey ? this.data.supplier_artkey : undefined,
            warehouse_base_costs: this.data.$f.warehouse_base_costs,
            warehouse_costs_per_case: this.data.$f.warehouse_costs_per_case,
            warehouse_id: this.data.$f.warehouse_id,
            zip_code: this.data.$f.zip_code,
        }, true) as any
        if (success) {
            $m.common.observable.broadcast('warehouses_updated', '')
            if (this.data.create) {
                notifier.notify(`Successfully created new ${this.data.$f.destination_type} ${this.data.$f.name}.`, 'success')
                this.redirect_to_edit_page(result.artkey)
            } else {
                notifier.notify(`Successfully updated ${this.data.$f.name}.`, 'success')
            }
        } else {
            notifier.notify(result.detail, 'danger')
        }
    }

    view() {
        return <div class="c-destination-edit view-container">
            <div className="view">
                <div class="btn-toolbar">
                    <ButtonGroup>
                        <Button
                            icon="arrow_left_circle_outline"
                            onclick={() => this.redirect_to_previous_page()}
                            text="Back to list"
                            variant="context"
                        />
                        {!!this.attachment_helper && <ButtonDataCard />}
                    </ButtonGroup>
                    {!!this.data.destination_artkey && <ButtonGroup>
                        <Button
                            disabled={(() => {
                                if (this.data.supplier_artkey) {
                                    return this.data.$f.is_third_party_warehouse
                                }
                                return false
                            })()}
                            icon="trash"
                            onclick={() => this.confirm_dialog_deactivate()}
                            text="Deactivate"
                            tip={() => {
                                if (this.data.supplier_artkey && this.data.$f.is_third_party_warehouse) {
                                    return 'This warehouse is shared with other relations and can only be deleted from the general warehouse list.'
                                }

                                return 'Deactivate this relation warehouse'
                            }}
                            type="danger"
                            variant="context"
                        />
                    </ButtonGroup>}
                </div>

                <div className="flex-form">
                    <div className="fieldset-group">
                        <div class="fieldset">
                            <div class="fieldset-label">General Information</div>
                            <FieldText
                                help="Name of the destination"
                                label="Name"
                                model={[this.data.$f, 'name']}
                                placeholder="Name"
                                validation={this.$v.name}
                            />

                            <FieldCheckbox
                                disabled={this.data.$f.artkey}
                                help={(() => {
                                    if (this.data.$f.artkey) {
                                        return 'Existing warehouses cannot change its third-party status anymore.'
                                    }
                                    return 'A third-party warehouse is not unique for a relation, but shared with other relations'
                                })()}
                                label="Is third-party warehouse"
                                model={[this.data.$f, 'is_third_party_warehouse']}
                            />
                            {this.data.$f.is_third_party_warehouse && <FieldSelect
                                disabled={this.data.$f.artkey}
                                help={(() => {
                                    if (this.data.$f.artkey) {
                                        return 'Existing warehouses cannot change its destination type anymore.'
                                    }
                                    return 'Type of destination'
                                })()}
                                label="Destination type"
                                model={[this.data.$f, 'destination_type']}
                                options={[
                                    {value: 'warehouse', label: 'Warehouse'},
                                    {value: 'airport', label: 'Airport'},
                                    {value: 'seaport', label: 'Seaport'},
                                ]}
                                placeholder="Select a destination type..."
                                validation={this.$v.destination_type}
                            />}

                            <FieldText
                                help="Unique identifier for the location"
                                label="Location ID"
                                model={[this.data.$f, 'warehouse_id']}
                                placeholder="Warehouse ID"
                            />

                            <FieldCheckbox
                                help="Enable this if thia warehouse can handle our stock."
                                label="Warehouse with our stock"
                                model={[this.data.$f, 'can_handle_our_stock']}
                            />

                            <FieldTextArea
                                help="Memo for the warehouse"
                                label="Memo"
                                model={[this.data.$f, 'memo']}
                                placeholder="Memo"
                            />
                        </div>

                        <div class="fieldset">
                            <div class="fieldset-label">Address &Contact</div>

                            {this.data.$f.destination_type === 'warehouse' &&
                            <FieldText
                                help="Email address of the warehouse"
                                label="Email address"
                                model={[this.data.$f, 'emailaddress']}
                                validation={this.$v.emailaddress}
                                placeholder="Email address"
                            />}

                            <div class="field-group">
                                <CountriesSelect
                                    help="Country of the warehouse"
                                    label="Country"
                                    model={[this.data.$f, 'country_code']}
                                    onchange={(value: string) => this.update_regions(value)}
                                    validation={this.$v.country_code}
                                />
                                <FieldText
                                    help="City of the warehouse"
                                    label="City"
                                    model={[this.data.$f, 'city']}
                                    placeholder="City"
                                    validation={this.$v.city}
                                />
                            </div>

                            <FieldSelect
                                disabled={this.data.regions.length === 0}
                                help={this.data.regions.length === 0
                                    ? 'No regions available for the selected country'
                                    : 'Select a region...'}
                                label="Region"
                                model={[this.data.$f, 'region_code']}
                                options={this.data.regions}
                                placeholder="Select a region..."
                            />

                            <div class="field-group">
                                <FieldText
                                    help="Address of the warehouse"
                                    label="Address"
                                    model={[this.data.$f, 'street_address']}
                                    placeholder="Address"
                                />
                                <FieldText
                                    help="Zip code of the warehouse"
                                    label="Zip code"
                                    model={[this.data.$f, 'zip_code']}
                                    placeholder="Zip code"
                                />
                            </div>
                        </div>
                    </div>

                    {this.data.$f.destination_type === 'warehouse' && <div className="fieldset-group">
                        <div class="fieldset">
                            <div class="fieldset-label">Financial</div>
                            <FieldText
                                help="Excise ID of the warehouse"
                                label="Excise ID"
                                model={[this.data.$f, 'excise_id']}
                                placeholder="Excise ID"
                            />
                            <FieldText
                                help="VAT ID of the warehouse"
                                label="VAT ID"
                                model={[this.data.$f, 'vat_id']}
                            />
                            {!this.supplier_artkey && [
                                <FieldSelect
                                    label="Currency"
                                    model={[this.data.$f, 'currency']}
                                    options={$s.currencies.all.map((i) => ({label: i, value: i}))}
                                />,
                                <div class="field-group">
                                    <FieldMoney
                                        currency={[this.data.$f, 'currency']}
                                        label="Warehouse base costs"
                                        model={[this.data.$f, 'warehouse_base_costs']}
                                    />
                                    <FieldMoney
                                        currency={[this.data.$f, 'currency']}
                                        label="Warehouse costs per case"
                                        model={[this.data.$f, 'warehouse_costs_per_case']}
                                    />
                                </div>,
                            ]}
                        </div>
                    </div>}
                </div>
                <Button
                    className="btn-submit"
                    disabled={invalid_fields(this.$v).length > 0}
                    icon="save"
                    onclick={() => this.save()}
                    tip={() => invalid_fields_format(invalid_fields(this.$v), 'tip')}
                    type="success"
                    text={this.data.create ? 'Create Destination' : 'Update Destination'}
                />
            </div>
            <PanelContext>
                <div className="content p-1">
                    {this.attachment_helper && [
                        <AttachmentList attachment_helper={this.attachment_helper} />,
                    ]}
                </div>
            </PanelContext>
        </div>
    }
}
