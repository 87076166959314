import m from 'mithril'
import {format_percentage} from '@bitstillery/common/lib/format'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {$s} from '@bitstillery/common/app'
import {classes} from '@bitstillery/common/lib/utils'

interface MarginPercentageAttrs {
    className?: string
    label?: string
    value: string | null // Margin percentage as a fraction.
}

export class CellMargin extends MithrilTsxComponent<MarginPercentageAttrs> {
    // Do NOT use hardcoded margin values; this is sensitive data.
    low_margin_range = 0
    start_target_margin_range = 0

    view(vnode: m.Vnode<MarginPercentageAttrs>): m.Children {
        const margin_percentage = vnode.attrs.value !== null ? +vnode.attrs.value * 100 : Infinity
        let color_class = 'rating-warning'
        if (margin_percentage < $s.margin.low) {
            color_class = 'rating-danger'
        } else if (margin_percentage > $s.margin.target) {
            color_class = 'rating-success'
        }

        return <span className={classes('c-margin', vnode.attrs.className)}>
            {vnode.attrs.label && <label>{vnode.attrs.label}</label>}
            <span className={classes('amount', color_class)}>
                {format_percentage(margin_percentage / 100, $s.identity.user.decimal_locale)}
            </span>
        </span>
    }
}
