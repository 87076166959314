import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'
import {Tippy} from '@bitstillery/common/components'

import {context} from '@/sales/orders/view/lib/context'

export class LoenderslootOuttake extends MithrilTsxComponent<any> {
    view(): m.Children {
        return <div>
            <dl className={'dl-horizontal'}>
                <dt>Number of pallets</dt>
                <dd>{context.data.loendersloot_outtake.pallets}</dd>
                <dt>Weight</dt>
                <dd>{context.data.loendersloot_outtake.weight}</dd>
                <dt>Contact</dt>
                <dd>{context.data.loendersloot_outtake.contact_email_address}</dd>
            </dl>
            <table className={'table search-table'}>
                <thead className={'thead-default'}>
                    <tr>
                        <th>Lot</th>
                        <th>Matched</th>
                        <th>Loendersloot article</th>
                        <th>Article</th>
                        <th>Description</th>
                        <th>Quantity</th>
                    </tr>
                </thead>
                <tbody className={'table-row'}>
                    {context.data.loendersloot_outtake.loendersloot_outtake_items.sort((a,b) => {
                        if (a.is_matched_with_artkey && b.is_matched_with_artkey) {
                            return a.description.localeCompare(b.description)
                        }
                        if (!a.is_matched_with_artkey && !b.is_matched_with_artkey) {
                            return a.description.localeCompare(b.description)
                        }
                        if (a.is_matched_with_artkey && !b.is_matched_with_artkey) {
                            return 1
                        }
                        return -1
                    }).map((item) => (
                        <tr>
                            <td>{item.lot}</td>
                            <td>

                                {!item.is_matched_with_artkey &&

                                    <Tippy
                                        content={'This loendersloot outtake item is not matched with any sales order item. Please verify with the invoice / packing list.'}
                                        allowHTML={true}
                                    >
                                        {' '}
                                        <span className={'fas fa-exclamation bad-color'}/>
                                    </Tippy>

                                }
                                {item.is_matched_with_artkey && <span className={'fas fa-check'} />}
                            </td>
                            <td>{item.article_lo}</td>
                            <td>{item.article}</td>
                            <td>{item.description}</td>
                            <td>
                                {item.quantity} {item.unit}
                            </td>
                        </tr>
                    ))
                    }
                </tbody>
            </table>
        </div>
    }
}
