import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {Button, ButtonGroup, DataCard, FieldDate, FieldSelect, FieldText} from '@bitstillery/common/components'
import {classes, merge_deep} from '@bitstillery/common/lib/utils'
import {api, logger} from '@bitstillery/common/app'
import {Amount, FieldMoney} from '@bitstillery/common/components'
import {to_specs} from '@bitstillery/common/lib/specs'
import {
    and,
    greater_than_or_equal_to,
    invalid_fields,
    invalid_fields_format,
    required,
    reset_validation,
    validation,
} from '@bitstillery/common/lib/validation'
import {next_tick, proxy, watch} from '@bitstillery/common/lib/proxy'

import {$m, $s} from '@/app'
import {GetBottleCasesResponse} from '@/factserver_api/fact2server_api'
import {item_tags_editor} from '@/stock/components/item_tags_editor'
import {OfferItem} from '@/models/pricelists'

// Used in places where the entity model can't be used (yet)
export const model = proxy({
    artkey: null,
    aux_info: '',
    alcohol_percentage: null,
    availability_status: '',
    best_before_date: null,
    bottle_artkey: null,
    bottle_gtin_code: null,
    cases_per_pallet: null,
    cases_per_pallet_layer: null,
    country_of_origin: null,
    currency: null,
    customs_status: null,
    gift_box_type: null,
    offers: [],
    incoterm: null,
    number_of_bottles: null,
    number_of_bottles_per_case: null,
    number_of_bottles_per_pallet: null,
    number_of_bottles_per_pallet_layer: null,
    number_of_cases: null,
    price_per_bottle: null,
    price_per_case: null,
    product_artkey: null,
    product_name: '',
    volume: null,
    supplier_name: '',
    tax_label: null,
})

export class AddToOffer extends MithrilTsxComponent<any> {
    spli: Function
    $v: any = {}
    offer_item: any
    watchers: any[] = []
    tag_model:any

    async oninit(vnode:m.Vnode<any>) {
        const {result} = await api.post('offer.get_offer_records', {})
        vnode.attrs.model.offers.splice(0, vnode.attrs.model.offers.length, ...result)

        this.$v = {
            delivery_period: validation([vnode.attrs.model, 'delivery_period'], and([required(), greater_than_or_equal_to(1)])),
            maximum_quantity: validation([vnode.attrs.model, 'maximum_quantity'], and([required(), greater_than_or_equal_to(1)])),
            offer_artkey: validation([vnode.attrs.model, 'offer_artkey'], required()),
            number_of_bottles_per_case: validation([vnode.attrs.model, 'number_of_bottles_per_case'], and([required(), greater_than_or_equal_to(1)])),
            sales_price_per_case: validation([vnode.attrs.model, 'sales_price_per_case'], and([required(), greater_than_or_equal_to(1)])),
        }

        this.offer_item = window.prop(new OfferItem({case: {
            best_before_date: vnode.attrs.model.best_before_date,
            bottle_artkey:  vnode.attrs.model.bottle_artkey,
            customs_status: vnode.attrs.model.customs_status,
            gift_box_type: vnode.attrs.model.gift_box_type,
            cases_per_pallet: vnode.attrs.model.cases_per_pallet,
            cases_per_pallet_layer: vnode.attrs.model.cases_per_pallet_layer,
            item_tags: [],
            number_of_bottles: vnode.attrs.model.number_of_bottles_per_case,
            tax_label: vnode.attrs.model.tax_label,
        }}))

        this.watchers.push(watch(vnode.attrs.model, 'bottle_artkey', async(bottle_artkey) => {
            reset_validation(this.$v)
            if (!bottle_artkey) {
                return
            }
            await next_tick()
            this.lookup_case(vnode.attrs.model)
        }))
    }

    async lookup_case(model) {
        logger.info(`[add_to_offer] lookup case for bottle: ${model.bottle_artkey}`)
        const filters = JSON.stringify({
            best_before_date: model.best_before_date,
            bottle_artkey: model.bottle_artkey,
            customs_status: model.customs_status,
            gift_box_type: null,
            number_of_bottles: model.number_of_bottles_per_case,
            search_exact_case: true,
            tax_label: null,
        })
        const endpoint = `discover/bottles/${model.bottle_artkey}/cases?filters=${encodeURI(filters)}`
        const {result:cases} = await api.get<GetBottleCasesResponse[]>(endpoint)
        if (!cases.length) {
            logger.warn('[add_to_offer] no cases found for bottle', model.bottle_artkey)
            return
        }

        const case_artkey = {old: model.case_artkey, new: cases[0].artkey}
        logger.info(`[add_to_offer] using case ${case_artkey.new} for form context`)

        if (case_artkey.old !== case_artkey.new) {
            merge_deep(model, {
                case_artkey: case_artkey.new,
                best_before_date: cases[0].best_before_date,
                gift_box_type: cases[0].gift_box_type,
                tax_label: cases[0].tax_label,
            })

            if (case_artkey.new) {
                // Check if there is already an offeritem for this case.
                const {result: offer_item} = await api.post('pricelist.get_offer_item', {case_artkey: case_artkey.new}) as any
                if (!offer_item) {
                    logger.info(`[add_to_offer] no offer item yet for case: ${case_artkey.new}`)
                    return
                }

                merge_deep(model, {
                    delivery_period: offer_item.delivery_period,
                    maximum_quantity: offer_item.maximum_quantity,
                    minimum_quantity: offer_item.minimum_quantity,
                })

                // Only prefill the sales price if the offer item is currently offered, which
                // is the case if the list quantity > 0.
                if (offer_item.list_quantity > 0) {
                    logger.info('[add_to_offer] fill sales price for offered offer item')
                    model.sales_price_per_case = offer_item.price_per_case
                }
            } else {
                model.case_artkey = null
            }
        }
    }

    price_or_dash(model, currency) {
        if (!model.price_per_case) return '-'
        return <div className="fl">
            <Amount
                amount={model.price_per_case}
                currency={currency}
                display_currency={$s.currencies.default}
            />
            &nbsp;cs&nbsp;/&nbsp;
            <Amount
                amount={model.price_per_bottle}
                currency={currency}
                display_currency={$s.currencies.default}
            />
            &nbsp;btl
        </div>
    }

    view(vnode:m.Vnode<any>) {
        return <div className={classes('c-add-to-offer', vnode.attrs.className, `type-${vnode.attrs.mode ? 'panel' : 'dialog'}`)}>
            {vnode.attrs.mode !== 'panel' && <div className="datacard-container">
                <DataCard model={{
                    data: [
                        {label: 'Supplier', value: vnode.attrs.model.supplier_name},
                        {label: 'Product', value: vnode.attrs.model.product_name},
                        {label: 'Specs', value: to_specs({
                            bottle_alcohol_percentage: vnode.attrs.model.alcohol_percentage,
                            bottle_refill_status: vnode.attrs.model.refill_status,
                            bottle_volume: vnode.attrs.model.volume,
                            case_number_of_bottles: vnode.attrs.model.number_of_bottles_per_case,
                            case_gift_box_type: vnode.attrs.model.gift_box_type || '',
                            case_customs_status: vnode.attrs.model.customs_status,
                            case_tax_label: vnode.attrs.model.tax_label,
                        })},
                        {label: 'Quantity', value: vnode.attrs.model.number_of_cases && `${vnode.attrs.model.number_of_cases} cs, ${vnode.attrs.model.number_of_bottles} btl`},
                    ],
                }}/>
                <DataCard model={{
                    data: [
                        {
                            label: 'Price',
                            value: this.price_or_dash(vnode.attrs.model, vnode.attrs.model.currency),
                        },
                        {label: 'Aux info', value: vnode.attrs.model.aux_info},
                        {label: 'Incoterm', value: vnode.attrs.model.incoterm},
                        {label: 'Availability', value: vnode.attrs.model.availability_status},
                    ],
                }}/>
            </div>}

            <div className="field-group">
                <FieldSelect
                    label="Offer"
                    help="Select an offer to add the item to as a TBO item"
                    model={[vnode.attrs.model, 'offer_artkey']}
                    options={vnode.attrs.model.offers.map(((i:any) => ({
                        label: i.title,
                        value: i.artkey,
                    })))}
                    placeholder="Select an Offer..."
                    validation={this.$v.offer_artkey}
                />
            </div>
            <div className="field-group">
                <FieldMoney
                    currency={[$s.currencies, 'default']}
                    label="Sales Price"
                    help="The price per case to sell this offer item for"
                    model={[vnode.attrs.model, 'sales_price_per_case']}
                    required={true}
                    validation={this.$v.sales_price_per_case}
                />
            </div>
            <div className="field-group">
                <FieldText
                    label="Bottles per case"
                    help="The number of bottles in one case"
                    min={1}
                    model={[vnode.attrs.model, 'number_of_bottles_per_case']}
                    onafterupdate={() => this.lookup_case(vnode.attrs.model)}
                    type="number"
                    validation={this.$v.number_of_bottles_per_case}
                />
                <FieldText
                    label="Maximum Quantity"
                    help="Restrict the total amount that can be ordered"
                    min={1}
                    model={[vnode.attrs.model, 'maximum_quantity']}
                    type="number"
                    validation={this.$v.maximum_quantity}
                />
            </div>
            <div className="field-group">
                <FieldText
                    help="Amount of weeks it takes to deliver"
                    label="Delivery Period"
                    min={1}
                    model={[vnode.attrs.model, 'delivery_period']}
                    type="number"
                    validation={this.$v.delivery_period}
                />

                <FieldText
                    help="Minimum quantity to order"
                    label="Minimum Quantity"
                    min={1}
                    model={[vnode.attrs.model, 'minimum_quantity']}
                    type="number"
                />
            </div>

            <div className="field-group">
                <FieldSelect
                    help="Tax label; e.g. UKDS"
                    label="Tax Label"
                    model={[vnode.attrs.model, 'tax_label']}
                    options={(() => {
                        const category = $m.data.item_tag_category.tax_label_category()
                        return $m.data.item_tag.get_all_from_category(category).map((i) => ({
                            label: i.name(),
                            value: i.name(),
                        }))
                    })()}
                    placeholder="Select Tax label..."
                />

                <FieldDate
                    help="The best before date of the case"
                    label="Best Before Date"
                    date_picker_options={{
                        onSelect: ({date}) => {
                            vnode.attrs.model.best_before_date = date.toISOString().split('T')[0]
                        },
                    }}
                />
            </div>
            {this.offer_item && item_tags_editor(this.offer_item().case, {only_tags: true}) }

            <ButtonGroup>
                <Button
                    icon="stop"
                    onclick={vnode.attrs.oncancel}
                    text="Cancel"
                />
                <Button
                    disabled={invalid_fields(this.$v).length > 0}
                    icon="plus"
                    onclick={async() => {
                        await api.post('offer.create_custom_offer_item_from_market', {
                            case: {
                                best_before_date: vnode.attrs.model.best_before_date,
                                bottle_artkey:  vnode.attrs.model.bottle_artkey,
                                customs_status: vnode.attrs.model.customs_status,
                                gift_box_type: vnode.attrs.model.gift_box_type,
                                cases_per_pallet: vnode.attrs.model.cases_per_pallet,
                                cases_per_pallet_layer: vnode.attrs.model.cases_per_pallet_layer,
                                item_tags: this.offer_item().case().item_tags(),
                                number_of_bottles: vnode.attrs.model.number_of_bottles_per_case,
                                tax_label: vnode.attrs.model.tax_label,
                            },
                            delivery_period: vnode.attrs.model.delivery_period,
                            minimum_quantity: vnode.attrs.model.minimum_quantity,
                            offer_artkey: vnode.attrs.model.offer_artkey,
                            price_per_case: vnode.attrs.model.sales_price_per_case,
                            quantity: vnode.attrs.model.maximum_quantity,
                            spli_artkey: vnode.attrs.model.artkey,
                        })

                        if (vnode.attrs.done) {
                            vnode.attrs.done()
                        }
                    }}
                    text="Add to Custom Offer"
                    tip={() => invalid_fields_format(invalid_fields(this.$v), 'tip')}
                    type="success"
                />
            </ButtonGroup>
        </div>
    }
}
