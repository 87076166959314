/** llm:tested */
import $ from 'jquery'
import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'

import {randomUuid} from '@/_utils'

interface ConfirmationProps {
    unique_name: string
    title: string
    message: string
    additional_message?: string
    no_cancel?: boolean
    onconfirm?: () => void
    key?: string
}

class Confirmation extends MithrilTsxComponent<ConfirmationProps> {
    unique_name: string
    title: string
    message: string
    additional_message?: string
    no_cancel?: boolean
    onconfirm?: () => void

    constructor(vnode: m.CVnode<ConfirmationProps>) {
        super()
        this.unique_name = vnode.attrs.unique_name
        this.title = vnode.attrs.title
        this.message = vnode.attrs.message
        this.additional_message = vnode.attrs.additional_message
        this.no_cancel = vnode.attrs.no_cancel
        this.onconfirm = vnode.attrs.onconfirm
    }

    hide() {
        $('#' + this.unique_name).modal('hide')
    }

    close() {
        this.hide()
    }

    confirm() {
        this.hide()
        if (this.onconfirm) {
            this.onconfirm()
        }
    }

    view() {
        const modal_attrs = {
            id: this.unique_name,
            tabindex: '-1',
            role: 'dialog',
            'aria-labelledby': 'confirmationLabel',
            oncreate: (vnode: m.VnodeDOM) => $(vnode.dom).modal(),
        }

        return (
            <div class="c-dialog-confirmation modal fade" {...modal_attrs}>
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button
                                class="close"
                                type="button"
                                data-dismiss="modal"
                                aria-label="Close"
                            >×</button>
                            <h4 class="modal-title" id="confirmationLabel">{this.title}</h4>
                        </div>
                        <div class="modal-body">{this.message}</div>
                        {this.additional_message && <div class="modal-body">{this.additional_message}</div>}
                        <div class="modal-footer">
                            {!this.no_cancel &&
                                <button
                                    class="btn btn-default"
                                    type="button"
                                    data-dismiss="modal"
                                    onclick={() => this.close()}
                                >Cancel</button>
                            }
                            <button
                                class="btn btn-primary"
                                type="button"
                                data-dismiss="modal"
                                onclick={() => this.confirm()}
                            >OK</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export function show_confirmation(properties: ConfirmationProps): void {
    /**
     * Show a confirmation
     *
     * Example of properties:
     * properties = {
     *     title: 'I am a title',
     *     message: 'I am the question you want to ask',
     *     onconfirm: () => console.log('The function to execute on confirm'),
     *     unique_name: 'unique_name_to_prevent_clashes'
     * }
     */
    if (!properties.unique_name) {
        // eslint-disable-next-line no-console
        console.error('Provide a unique name for your confirmation, otherwise names might collide!')
    }

    properties.key = randomUuid()

    const element = document.getElementById('dialog-confirmation') as any
    m.render(element, m(Confirmation, properties))
}

// Backwards compatible.
export const show = show_confirmation

export default {
    show_confirmation,
    show,
}
