import m from 'mithril'
import {format_iso_to_date, format_iso_to_date_time} from '@bitstillery/common/lib/format'
import {DateTime} from 'luxon'
import {Button, ButtonGroup, DataCard, Link, PanelContext, Tabs, Tab, TabPanel} from '@bitstillery/common/components'
import {proxy} from '@bitstillery/common/lib/proxy'
import {notifier} from '@bitstillery/common/app'
import {api} from '@bitstillery/common/app'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {Amount} from '@bitstillery/common/components'

import {accountIcon} from '@/accounts'
import {Relation, RelationApi} from '@/factserver_api/relation_api'
import {GetSalesOrderForRelationResponse, SalesApi, SalesOrderStatus} from '@/factserver_api/sales_api'
import {Percent} from '@/components/html_components'
import {
    CollectionTable,
    CollectionTableColumn,
    PagedCollectionFetcher,
} from '@/components/collection/collection_table'
import {
    RelationPurchaseOrders,
} from '@/offer/relation_dashboard/components/relation_purchase_orders'

interface PanelRelationDetailsAttrs {
    fetch_relation: Function
    relation: Relation
}

enum SelectableTabs {
    RELATION_DETAILS = 'Relation-details',
    SALES_ORDERS = 'Sales-orders',
    PURCHASE_ORDERS = 'Purchase-orders',
}

export class PanelRelationDetails extends MithrilTsxComponent<PanelRelationDetailsAttrs> {
    relation_api = new RelationApi()
    selected_tab = SelectableTabs.RELATION_DETAILS
    sales_api = new SalesApi()
    relation_artkey: number | undefined = undefined

    sales_orders_fetcher: PagedCollectionFetcher<GetSalesOrderForRelationResponse>
    sales_order_container: HTMLElement | null = null

    data = (() => {
        return proxy({
            tabs: {
                active: m.route.param('tabId') ? m.route.param('tabId') : 'overview',
            },
        })
    })()

    oninit() {
        this.sales_orders_fetcher = new PagedCollectionFetcher<GetSalesOrderForRelationResponse>(
            'sales.core.get_sales_orders',
            'created_on',
            undefined,
            5,
            false,
        )
        this.sales_orders_fetcher.filters['status'] = [SalesOrderStatus.SAVED]
        this.sales_orders_fetcher.filters['relation_artkey'] = 1
    }

    on_select_tab(vnode: m.Vnode<PanelRelationDetailsAttrs>, requested_tab: SelectableTabs): void {
        this.selected_tab = requested_tab
    }

    oncreate(): void {
        this.sales_order_container = document.querySelector('.c-panel-relation-details')
    }

    onupdate(vnode: m.Vnode<PanelRelationDetailsAttrs>): void {
        const relation = vnode.attrs.relation
        if (this.relation_artkey !== relation?.artkey) {
            this.relation_artkey = relation?.artkey
            if (this.relation_artkey) {
                this.sales_orders_fetcher.filters['relation_artkey'] = this.relation_artkey
                this.sales_orders_fetcher.reset_and_query()
            }
        }
    }

    view(vnode: m.Vnode<PanelRelationDetailsAttrs>): m.Children {
        const relation = vnode.attrs.relation as Relation

        const last_refreshed_on = relation.relation_price_list_generated_on
        let price_list_refresh_age = 30
        if (last_refreshed_on) {
            const last_refreshed_on_date_time = DateTime.fromISO(last_refreshed_on)
            price_list_refresh_age = DateTime.now().diff(last_refreshed_on_date_time, 'days').days
        }

        const search_params = new URLSearchParams({search_terms: relation.name}).toString()

        return <PanelContext
            className="c-panel-relation-details"
            title={relation.name}
        >
            <div className="content">
                <Tabs data={this.data}>
                    <Tab
                        icon="list"
                        id="overview"
                        text="Overview"
                    >
                        <TabPanel>
                            <div className={'panel panel-info'}>
                                <div className={'panel-heading'}>
                                    <ButtonGroup>
                                        <Button
                                            icon="search"
                                            link={`/market/discover?${search_params}`}
                                            variant="context"
                                            target="_blank"
                                            tip={() => `${relation.name} in the market`}
                                            type="info"
                                            text="Search"
                                        />
                                        <Button
                                            icon="pricelist"
                                            link={`/market/pricelists?${search_params}`}
                                            variant="context"
                                            target="_blank"
                                            tip={() => `Pricelists of ${relation.name}`}
                                            type="info"
                                            text="Pricelists"
                                        />

                                        <Button
                                            icon="purchase"
                                            link={`/purchase-orders/manage?${search_params}`}
                                            variant="context"
                                            target="_blank"
                                            tip={() => `Purchase orders of ${relation.name}`}
                                            type="info"
                                            text="Purchase"
                                        />

                                        <Button
                                            icon="sales"
                                            link={`/sales/orders?${search_params}`}
                                            variant="context"
                                            target="_blank"
                                            tip={() => `Sales orders of ${relation.name}`}
                                            type="info"
                                            text="Sales"
                                        />

                                        <Button
                                            icon="profile"
                                            link={`/crm/relations/${relation.artkey}/edit`}
                                            variant="context"
                                            target="_blank"
                                            tip={() => `Edit relation ${relation.name}`}
                                            type="info"
                                            text="Edit Relation"
                                        />

                                        <Button
                                            icon="refresh"
                                            onclick={async() => {
                                                notifier.notify('Relation price list will be refreshed, should take about 10 seconds... Please wait.')
                                                await api.post(`discover/relations/${relation.artkey}/price-list/generate`, {}, true)
                                                vnode.attrs.fetch_relation()
                                            }}
                                            variant="context"
                                            target="_blank"
                                            tip={() => `Refreshes pricelist for ${relation.name}`}
                                            text="Refresh Pricelist"
                                            type={price_list_refresh_age <= 5 ? 'danger' : 'default'}
                                        />
                                    </ButtonGroup>
                                </div>

                            </div>
                            <DataCard model={{
                                data: [
                                    {label: 'Memo', value: relation.memo, type: 'textarea'},
                                    {
                                        label: 'Account',
                                        value: <span>
                                            {accountIcon({
                                                slug: relation.sales_account.slug,
                                                name: relation.sales_account.name,
                                            })}
                                            {` ${relation.sales_account.name}`}
                                        </span>,
                                    },
                                    {label: 'Name', value: relation.name},
                                    {
                                        label: 'Pricelist refreshed',
                                        value: <div className={'price-list-refresh-information'}>
                                            {!relation.relation_price_list_generated_on && <span>No price list available</span>}
                                            {relation.relation_price_list_generated_on && format_iso_to_date_time(relation.relation_price_list_generated_on)}
                                        </div>,
                                    },
                                    {label: 'Customs status visibility', value: relation.portal_customs_visibility},
                                    {label: 'Currency', value: relation.currency},
                                    {label: 'Incoterm', value: relation.incoterm},
                                    {label: 'Transport costs / cs', value: relation.transport_costs_per_case || '-'},
                                    {label: 'Minimum order amount', value: relation.minimum_order_amount || '-'},
                                    {label: 'Price Preference', value: relation.price_preference},
                                    {label: 'Price markup', value: <Percent value={(relation.price_markup_percentage || 0) / 100} />},
                                    {label: 'Portal level', value: relation.portal_level || '-'},
                                ],
                            }}/>
                        </TabPanel>
                    </Tab>

                    <Tab
                        icon="list"
                        id="sales_orders"
                        text="Sales Orders"
                    >
                        <TabPanel className="view">
                            <CollectionTable<GetSalesOrderForRelationResponse, unknown>
                                collection_fetcher={this.sales_orders_fetcher}
                                is_header_sticky={false}
                                is_header_visible={false}
                                scrollable_dom_element={() => this.sales_order_container}
                            >
                                <CollectionTableColumn<GetSalesOrderForRelationResponse>
                                    header_title={() => ''}
                                    data_field={(sales_order: GetSalesOrderForRelationResponse) => (
                                        <Link href={`/sales/orders/${sales_order.reference}/view/manage`}>
                                            {sales_order.reference}
                                        </Link>
                                    )}
                                />
                                <CollectionTableColumn<GetSalesOrderForRelationResponse>
                                    header_title={() => ''}
                                    data_field={(sales_order: GetSalesOrderForRelationResponse) =>
                                        format_iso_to_date(sales_order.created_on)
                                    }
                                />
                                <CollectionTableColumn<GetSalesOrderForRelationResponse>
                                    header_title={() => 'Incoterm'}
                                    data_field={(sales_order: GetSalesOrderForRelationResponse) =>
                                        sales_order.incoterm_location
                                    }
                                />
                                <CollectionTableColumn<GetSalesOrderForRelationResponse>
                                    header_title={() => 'Number of cases'}
                                    data_field={(sales_order: GetSalesOrderForRelationResponse) =>
                                        `${sales_order.number_of_cases}cs`
                                    }
                                />
                                <CollectionTableColumn<GetSalesOrderForRelationResponse>
                                    header_title={() => 'Total value'}
                                    data_field={(sales_order: GetSalesOrderForRelationResponse) => (
                                        <Amount amount={sales_order.euro_turnover} currency={'EUR'} />
                                    )}
                                />
                                <CollectionTableColumn<GetSalesOrderForRelationResponse>
                                    header_title={() => 'Status'}
                                    data_field={(sales_order: GetSalesOrderForRelationResponse) =>
                                        sales_order.combined_status
                                    }
                                />
                            </CollectionTable>
                        </TabPanel>
                    </Tab>
                    <Tab
                        icon="list"
                        id="purchase_orders"
                        text="Purchase Orders"
                    >
                        <TabPanel>
                            <RelationPurchaseOrders
                                relation_artkey={this.relation_artkey}
                            />
                        </TabPanel>
                    </Tab>
                </Tabs>
            </div>
        </PanelContext>
    }
}
