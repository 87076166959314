// eslint-disable-next-line no-unused-vars
import m from 'mithril'
import {CollectionProxy, CollectionTransforms} from '@bitstillery/common/lib/collection'
import {Amount, AmountUnit, Ranking} from '@bitstillery/common/components'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {$t, api, events, notifier} from '@bitstillery/common/app'
import {
    Button,
    ButtonDataCard,
    ButtonGroup,
    CellAvailability,
    CellDimension,
    CellProduct,
    CollectionHeader,
    CollectionItems,
    CollectionView,
    Icon,
    PanelFilters,
    RowActionDelete,
} from '@bitstillery/common/components'
import {merge_deep, url_query_string} from '@bitstillery/common/lib/utils'

import {context, EntityType, methods} from '@/market/pricelists/view/lib/context'
import {$s} from '@/app'
import {ProductAudit} from '@/market/pricelists/components/product_audit'
import {
    GetPurchaseOrderForSPLIResult,
    GetSpliThroughputResponse,
    GetSupplierPriceListItemCollectionResponse,
    RelationCompanyType,
} from '@/factserver_api/fact2server_api'
import {BottleThroughput} from '@/components/market_info/bottle_throughput'
import {BottleMarket} from '@/components/market_info/bottle_market'
import {BottleStock} from '@/components/market_info/bottle_stock'
import {BottlePurchaseOrders} from '@/components/market_info/bottle_purchase_orders'
import {BottleSalesOrders} from '@/components/market_info/bottle_sales_orders'
import {OfferHistory} from '@/components/market_info/offer_history'
import {create_download_for_blob} from '@/factserver_api/api'
import {SupplierPriceListItem} from '@/factserver_api/marketanalysis_api'
import {collection as collection_splsl} from '@/market/pricelists/view/lib/collection_splsl'

const RANKING_ENABLED_STATUS = ['always_active', 'active']

export const collection = new CollectionProxy()

export const columns = [
    {
        className: 'cell-group',
        name: 'Ranking',
        render: (row) => RANKING_ENABLED_STATUS.includes(context.data.supplier_pricelist?.status) ? <Ranking
            model={{
                rank: row.rank,
                tip: () => `Ranking: ${row.rank}/${row.total}`,
                total:row.total,
            }} /> : undefined,
        width: '0.5fr',
    },
    {
        className: 'cell-group',
        name: 'Product',
        render: (row: GetSupplierPriceListItemCollectionResponse) => {
            return [
                <CellProduct
                    product_name={row.product_name}
                    number_of_bottles={row.number_of_bottles_per_case}
                    customs_status={row.customs_status}
                    gift_box_type={row.gift_box_type}
                    alcohol_percentage={row.alcohol_percentage}
                    volume={row.volume}
                    refill_status={row.refill_status}
                    product_category_name={row.product_category}
                    bottle_gtin_code={row.bottle_gtin_code}
                    country_of_origin={row.country_of_origin || row.default_country_code}
                />,
                <CellDimension
                    cases_per_pallet={row.cases_per_pallet}
                    cases_per_pallet_layer={row.cases_per_pallet_layer}
                />,
            ]
        },
        width: '2fr',
    }, {
        name: 'Supplier price',
        render: (row) => {
            let price_per_case = row.price_per_case
            if (!price_per_case) {
                price_per_case = row.price_per_bottle * row.number_of_bottles_per_case
            }
            return <div className="td-group">
                <AmountUnit
                    case_amount={price_per_case}
                    case_number_of_bottles={row.number_of_bottles_per_case}
                    currency={row.currency}
                    display_currency={$s.currencies.default}
                />
            </div>
        },
    }, {
        name: 'Availability',
        render: (row) => <div className="td-group">
            {(() => {
                if (row.number_of_cases) return `${row.number_of_cases} cases`
                else if (row.number_of_bottles) return `${row.number_of_bottles} bottles`
                return '-'
            })()}
            {row.availability_status && <span className="details">{row.availability_status}</span>}
            {row.aux_info && <span className="details">{row.aux_info}</span>}
        </div>,
    },
    {
        name: 'Similar stock',
        render: (row) => row.number_of_cases_in_stock ? <div className="td-group">
            <CellAvailability key={row.artkey} row={row}/>
        </div> : '-',
    }, {
        name: 'Oldest stock age',
        render: (row) => row.max_stock_age ? row.max_stock_age : '-',
    }, {
        name: 'Average purchase price',
        render: (row:any) => {
            if (!row.avg_purchase_price) {
                return '-'
            }

            return <Amount
                amount={row.avg_purchase_price}
                currency={'EUR'}
            />
        },
    },
    {
        name: 'Throughput',
        render: (row) => {
            if (row.throughput) {
                return <div className="td-group">
                    <BottleThroughput
                        type={'Short'}
                        prefix={'Sold'}
                        throughput={{
                            last_month: row.throughput.sold_last_month,
                            last_quarter: row.throughput.sold_last_quarter,
                            last_year: row.throughput.sold_last_year,
                        }}
                    />
                    <BottleThroughput
                        type={'Short'}
                        prefix={'Bought'}
                        throughput={{
                            last_month: row.throughput.bought_last_month,
                            last_quarter: row.throughput.bought_last_quarter,
                            last_year: row.throughput.bought_last_year,
                        }}
                    />
                </div>

            }
        },
    },
]

export class CollectionSpli extends MithrilTsxComponent<any> {

    async oninit() {
        await collection.init({
            endpoint: {
                meta: true,
                method: 'get',
                path: `discover/supplier-price-lists/${context.data.root_artkey}/items/collection-view`,
            },
        }, undefined, {
            items_queried: async({result}) => {
                if (!result.length) {
                    return result
                }
                const spli_artkeys = result.map((i) => i.artkey)
                for (const item of result) {
                    item.purchase_orders = []
                }

                const api_calls = await Promise.all([
                    api.get<GetPurchaseOrderForSPLIResult[]>(`discover/supplier-price-lists/${m.route.param('artkey')}/items/purchase-orders?spli_artkeys=${spli_artkeys.join(',')}`),
                    api.get<GetSpliThroughputResponse[]>(`discover/supplier-price-lists/${m.route.param('artkey')}/items/throughput?spli_artkeys=${spli_artkeys.join(',')}`),
                ])

                const {result:spli_purchases} = api_calls[0]

                const items_by_artkey = result.reduce((acc, item) => {
                    acc[item.artkey] = item
                    return acc
                }, {})

                for (const spli_purchase of spli_purchases) {
                    items_by_artkey[spli_purchase.spli_artkey].purchase_orders.push(spli_purchase)
                }

                const {result:throughput_info} = api_calls[1]
                for (const throughput of throughput_info) {
                    items_by_artkey[throughput.artkey].throughput = throughput
                }

                return result
            },
        } as CollectionTransforms)

        collection.events.once('state_items_updated', () => {
            const params = m.route.param()
            if (params.search) {
                // Route already has an action; filter for a product from another page (e.g. compare prices
                return
            }

            // Route to the first entity in the collection (Trader workflow preference) on page enter.
            if (!context.data.entity_type && !context.data.entity_artkey) {
                m.route.set(`/market/pricelists/${context.data.root_artkey}/view/manage/${EntityType.SPLI}/${collection.state.items[0].artkey}${url_query_string()}`)
            }
        })
        // Required by legacy forms; e.g. add_to_order/add_to_offer
        events.on('spli:refetch', collection.reset_query, collection)
    }

    onremove() {
        events.off('spli:refetch', collection.reset_query, collection)
    }

    view(_vnode: m.Vnode<any>) {
        const supplier_pricelist = context.data.supplier_pricelist as any
        const supplier = supplier_pricelist.supplier
        return [
            <PanelFilters collection={collection} />,
            <CollectionView mode="table">
                <div className="btn-toolbar">
                    <ButtonGroup>
                        <ButtonDataCard
                            collection={collection}
                            context={context}
                        />
                        <Button
                            active={context.link_entity_active(EntityType.SPLI, null)}
                            link={context.link_entity(collection, EntityType.SPLI)}
                            link_options={{
                                deactivate: context.data.root_path,
                                replace: true,
                            }}
                            icon="plus"
                            text="Add to Pricelist"
                            tip={() => {
                                return 'Add a new Pricelist item to this Supplier\'s Pricelist'
                            }}
                            type="info"
                            variant="context"
                        />
                    </ButtonGroup>
                    <ButtonGroup classname="ml-2">
                        <Button
                            icon="excel"
                            text="Excel Export"
                            onclick={async() => {
                                context.data.loading.export = true
                                try {
                                    const {result: blob} = await api.get<Blob>(`discover/supplier-price-lists/${supplier_pricelist.artkey}/export`)
                                    create_download_for_blob(blob, `supplier-pricelist-${supplier_pricelist.artkey}.xlsx`)
                                } catch (err) {
                                    notifier.notify('Something went wrong downloading the export of this pricelist', 'danger')
                                } finally {
                                    context.data.loading.export = false
                                }
                            }}
                            disabled={context.data.loading.export}
                            type="info"
                            variant="context"
                        />
                    </ButtonGroup>
                </div>

                <CollectionHeader collection={collection} columns={columns} />

                <CollectionItems
                    collection={collection}
                    columns={columns}
                    row_actions={(row) => {
                        return [
                            <Button
                                active={context.link_entity_active(EntityType.SPLI, row.artkey)}
                                disabled={false}
                                icon="edit"
                                link={context.link_entity(collection, EntityType.SPLI, row.artkey)}
                                tip={(() => {
                                    return 'Edit this pricelist item'
                                })()}
                                type="info"
                                variant="toggle"
                            />,
                            <Button
                                active={context.link_entity_active(EntityType.PURCHASE_ORDER, row.artkey)}
                                disabled={!row.is_available}
                                icon="cart"
                                link={context.link_entity(collection, EntityType.PURCHASE_ORDER, row.artkey)}
                                tip={(() => {
                                    return 'Add to Purchase Order'
                                })()}
                                type="info"
                                variant="toggle"
                            />,
                            <Button
                                active={context.link_entity_active(EntityType.CUSTOM_OFFER, row.artkey)}
                                disabled={!row.is_available}
                                icon="copy"
                                link={context.link_entity(collection, EntityType.CUSTOM_OFFER, row.artkey)}
                                tip={(() => {
                                    return 'Add to Custom Offer'
                                })()}
                                type="info"
                                variant="toggle"
                            />,
                            <Button
                                disabled={
                                    row.number_of_matching_tbo_items > 0 ||
                                supplier.company_type === RelationCompanyType.Competitor ||
                                !row.is_available
                                }
                                onclick={async(e) => {
                                    e.stopPropagation()

                                    await api.post('pricelist.create_tbo_offer_item', {
                                        spli_artkey: row.artkey,
                                        case: {
                                            bottle_artkey: row.bottle_artkey,
                                            number_of_bottles: row.number_of_bottles_per_case,
                                            gift_box_type: row.gift_box_type,
                                            customs_status: row.customs_status,
                                            tax_label: '',
                                            best_before_date: '',
                                            item_tags: [],
                                        },
                                    })
                                    row.number_of_matching_tbo_items += 1
                                }}
                                icon="thumbUp"
                                tip={() => {
                                    let tip
                                    if (row.number_of_matching_tbo_items > 0) {
                                        tip = 'Already on <a href="/#!/pricelist/work" target="_blank">TBO approval list</a>'
                                    } else if (supplier.company_type === RelationCompanyType.Competitor) {
                                        tip = 'Cannot add to TBO approval list; this is a competitor'
                                    } else if (!row.is_available) {
                                        tip = 'Cannot add to TBO approval list; this item is deactivated'
                                    } else {
                                        tip = 'Add to <a href="/#!/pricelist/work" target="_blank">TBO approval list</a>'
                                    }
                                    return tip
                                }}
                                type="default"
                                variant="toggle"
                            />,
                            <Button
                                icon={row.is_available ? 'toggle-switch-off-outline' : 'toggle-switch-outline'}
                                onclick={async(e) => {
                                    e.stopPropagation()
                                    await api.put(`discover/supplier-price-list-items/${row.artkey}/mark-${row.is_available ? 'unavailable' : 'available'}`, {})
                                    row.is_available = !row.is_available
                                    notifier.notify(row.is_available ? 'Pricelist item activated' : 'Pricelist item deactivated', 'warning')
                                }}
                                tip={() => `${row.is_available ? 'Deactivate' : 'Activate'} this pricelist item`}
                                type="warning"
                                variant="toggle"
                            />,
                            <RowActionDelete
                                icon={row.line_content ? 'deactivate' : 'trash'}
                                row={row}
                                row_delete={async() => {
                                    await api.delete(`discover/supplier-price-list-items/${row.artkey}`)
                                    collection.soft_delete(row.artkey)
                                    if (row.line_content) {
                                        notifier.notify($t('notifications.spli_unresolved', {name: row.product_name}), 'warning')
                                    } else {
                                        notifier.notify($t('notifications.spli_deleted', {name: row.product_name}), 'warning')
                                    }
                                    collection_splsl.reset_query()
                                    events.emit('spl:refetch')
                                }}
                                tip={() => {
                                    if (row.line_content) return 'Unresolve this pricelist item'
                                    return 'Delete this pricelist item'
                                }}
                                variant="deactivate"
                            />,
                        ]
                    }}
                    row_detail={(row) => {
                        row.spl_header_list = supplier_pricelist.header_as_list

                        const elements = [
                            <BottleMarket
                                current_supplier_price_list_artkey={supplier_pricelist.artkey}
                                add_to_order={(spli: SupplierPriceListItem) => {
                                    const entity = context.data.entities[EntityType.PURCHASE_ORDER]
                                    merge_deep(entity, spli)
                                    entity.update_model()
                                }}
                                bottle_artkey={row.bottle_artkey}
                                case_customs_status={row.case_customs_status}
                                context={context}
                                context_reset={methods.to_entity_purchase_order}
                                entity_type={EntityType.PURCHASE_ORDER}
                                ignore_ref={true}
                            />,
                            <BottleStock
                                bottle_artkey={row.bottle_artkey}
                                case_customs_status={row.case_customs_status}
                                ignore_ref={true}
                            />,
                            <OfferHistory
                                bottle_artkey={row.bottle_artkey}
                                case_customs_status={row.case_customs_status}
                                ignore_ref={true}
                            />,
                            <div className="columns">
                                <div className="column is-6">
                                    <BottlePurchaseOrders
                                        bottle_artkey={row.bottle_artkey}
                                        case_customs_status={row.case_customs_status}
                                        ignore_ref={true}
                                    />
                                </div>
                                <div className="column is-6">
                                    <BottleSalesOrders
                                        bottle_artkey={row.bottle_artkey}
                                        case_customs_status={row.case_customs_status}
                                        ignore_ref={true}
                                    />
                                </div>
                            </div>,
                        ]
                        if (row.line_content !== null) {
                            elements.push(
                                <ProductAudit
                                    audit_endpoint={`discover/supplier-price-lists/${context.data.supplier_pricelist.artkey}/supplier-price-list-items/${row.artkey}/audit-logs`}
                                    header={context.data.supplier_pricelist.header_as_list}
                                    line_content={row.line_content}
                                    specs={{
                                        bottle_alcohol_percentage: row.alcohol_percentage,
                                        bottle_refill_status: row.refill_status,
                                        bottle_volume: row.volume,
                                        case_gift_box_type: row.gift_box_type,
                                        case_number_of_bottles: row.number_of_bottles_per_case,
                                        case_customs_status: row.customs_status,
                                        product_category_name: row.product_category,
                                        product_artkey: row.product_artkey,
                                        product_name: row.product_name,
                                    }}
                                    type="success"
                                />,
                            )
                        }
                        return elements
                    }}
                    row_status={(row) => {
                        return {
                            render: [
                                row.purchase_orders && row.purchase_orders.length ? <Icon
                                    name="cart"
                                    tip={() => {
                                        const items = row.purchase_orders.map((i) => {
                                            return `<a href="/#!/purchase-orders/manage/${i.purchase_order_artkey}" target="_blank">P${i.purchase_order_artkey}</a> - ${i.number_of_cases} cases`
                                        })
                                        return `Already in purchase orders:<br/>${items.join('<br/>')}`

                                    }}
                                    type="info"
                                /> : null,
                                !row.is_available ? <Icon
                                    name="toggle-switch-off-outline"
                                    tip="This item is deactivated"
                                    type="warning"
                                /> : null,
                                row.number_of_matching_tbo_items > 0 ? <Icon
                                    name="thumbUp"
                                    tip="On TBO approval list"
                                    type="default"
                                /> : null,
                            ],
                            type: 'default',
                        }
                    }}
                />
            </CollectionView>,
        ]
    }
}
