import m from 'mithril'
import {Button, ButtonGroup, Dialog} from '@bitstillery/common/components'
import {$t} from '@bitstillery/common/app'

interface ShowDialogAttrs {
    body: () => m.Children
    confirm?: {
        action: () => unknown
        icon: string
        text: string
    }
    model: {
        loading?: boolean
        type?: string
        title?: string
    }
}

/** Remove insert location and recreate the insert location. **/
function remove_element_from_dom() {
    const element = document.getElementById('dialog-confirmation')
    const parent = element?.parentElement
    const new_element = document.createElement('div')
    new_element.id = 'dialog-confirmation'
    element?.remove()
    parent?.append(new_element)
}

export function mount_dialog(attrs: ShowDialogAttrs) {
    const element = document.getElementById('dialog-confirmation') as any

    m.mount(element, {
        view: () => {
            return <Dialog {...attrs}
                body={attrs.body}
                footer={<ButtonGroup>
                    {attrs.confirm && <Button
                        disabled={attrs.model.loading}
                        onclick={() => {
                            remove_element_from_dom()
                        }}
                        text={$t('dialog.cancel')}
                    />}
                    {attrs.confirm && <Button
                        disabled={attrs.model.loading}
                        loading={attrs.model.loading}
                        icon={attrs.confirm.icon}
                        onclick={() => {
                            if (!attrs.confirm || !attrs.confirm.action) return
                            attrs.confirm.action()
                        }}
                        text={attrs.confirm.text}
                        type={attrs.model.type ? attrs.model.type : 'default'}
                    ></Button>}
                </ButtonGroup>
                }
                loading={attrs.model.loading}
                onclose={() => {
                    remove_element_from_dom()
                }}
                title={attrs.model.title}
                type={attrs.model.type ? attrs.model.type : 'default'}
            >
                {attrs.body()}
            </Dialog>
        },
    })

    return {
        close: () => {
            remove_element_from_dom()
        },
    }
}
