import m from 'mithril'
import {Icon} from '@bitstillery/common/components'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {classes} from '@bitstillery/common/lib/utils.ts'

interface CellBottleAndCaseAttrs {
    bottle_text: string
    case_text: string
    className?: string
}

export class CellBottleAndCase extends MithrilTsxComponent<CellBottleAndCaseAttrs> {

    view(vnode: m.Vnode<CellBottleAndCaseAttrs>) {
        return (
            <div className={classes(vnode.attrs.className, 'c-quantity')}>
                {!!vnode.attrs.bottle_text && (
                    <div className="bottle-quantity">
                        <Icon
                            name="bottle"
                            size="s"
                            type="unset"
                        />
                        {vnode.attrs.bottle_text}
                    </div>
                )}
                {!!vnode.attrs.case_text && (
                    <div className="case-quantity">
                        <Icon
                            name="case"
                            size="s"
                            type="unset"
                        />
                        {vnode.attrs.case_text}
                    </div>
                )}
            </div>
        )
    }
}
