import {Icon} from '@bitstillery/common/components'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'

interface CellDimensionAttrs {
    weight?: number
    length?: number
    height?: number
    width?: number

    cases_per_pallet?: number
    cases_per_pallet_layer?: number
}

export class CellDimension extends MithrilTsxComponent<CellDimensionAttrs> {
    view(vnode: m.Vnode<CellDimensionAttrs>) {
        const children = [] as m.ChildArray
        if (vnode.attrs.weight) {
            children.push(
                <div className='dimension-info'>
                    <Icon name='weight' size='xs'/>
                    <div className='stat'>
                        {vnode.attrs.weight} kg
                    </div>
                </div>,
            )
        }
        if (vnode.attrs.length || vnode.attrs.height || vnode.attrs.width) {
            children.push(<div className='dimension-info'>
                <Icon name='case' size='xs'/>
                <div className='stat'>
                    {`${vnode.attrs.length} x ${vnode.attrs.width} x ${vnode.attrs.height}`}
                </div>
            </div>,
            )
        }
        if (vnode.attrs.cases_per_pallet || vnode.attrs.cases_per_pallet_layer) {
            children.push(
                <div className='dimension-info'>
                    <Icon name='cpp' size='xs'/>
                    <div className='stat'>
                        <span>{`${vnode.attrs.cases_per_pallet} cpp`}</span>
                        {vnode.attrs.cases_per_pallet_layer && <span>{`${vnode.attrs.cases_per_pallet_layer} cpl`}</span>}
                    </div>
                </div>)
        }
        return <div className="c-cell-dimension td-group">
            {children}
        </div>
    }
}
