import {$s, $t} from '@bitstillery/common/app'
import {DateTime} from 'luxon'

export const CURRENCY_SYMBOLS = {
    EUR: '€',
    GBP: '£',
    JPY: '¥',
    USD: '$',
}

export function days_left(date: string): number {
    const today = new Date()
    const time_diff = new Date(date).getTime() - today.getTime()
    return Math.ceil(time_diff / (1000 * 3600 * 24))
}

/**
 * Formats a float to a certain number of fixed fraction digits, formatting according to users locale.
 *
 * @param float_to_display
 * @param number_of_fraction_digits The number of fraction digits.
 */
export function displayable_float(
    float_to_display: string | number | null | undefined,
    number_of_fraction_digits: number = 2,
    locale = 'en',
): string {
    const formatter = new Intl.NumberFormat(locale, {
        style: 'decimal',
        minimumFractionDigits: number_of_fraction_digits,
        maximumFractionDigits: number_of_fraction_digits,
    })
    if (!float_to_display) {
        return formatter.format(0)
    }
    return formatter.format(+float_to_display)
}

/**
 * Displays the integer, taking into account if it is 0, null or undefined.
 *
 * @param to_display
 */
export function displayable_integer(
    to_display: number | null | undefined,
): string {
    return to_display?.toString() ?? '0'
}

/**
 * Format bytes as a Mb/Gb string.
 *
 * @param bytes The bytes to format.
 * @param decimals The number of decimals to display, default 2.
 */
export function format_bytes(bytes: string | number | null | undefined, decimals = 2): string {
    if (!bytes || !+bytes) return '0 Bytes'
    const bytes_as_num = +bytes

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']

    const i = Math.floor(Math.log(bytes_as_num) / Math.log(k))

    return `${parseFloat((bytes_as_num / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export function format_month_day(date: string | Date) {
    if (!date) return ''

    const date_obj = date instanceof Date ? date : new Date(date)
    return date_obj.toLocaleDateString('en-US', {
        month: 'long',
        day: 'numeric',
    })
}

export function format_month_day_year(date: string | Date) {
    if (!date) return ''

    const date_obj = date instanceof Date ? date : new Date(date)
    return date_obj.toLocaleDateString('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
    })
}

export function format_days_since_date(date) {
    const today = new Date()
    const entry = new Date(date)
    const diff = Math.abs(entry.getTime() - today.getTime())
    const days = Math.floor(diff / (1000 * 3600 * 24))
    if (days === 0) {
        return 'Today'
    } else if (days === 1) {
        return '1 day ago'
    }
    return `${days} days ago`
}

export function format_delivery_period(value) {
    // Even if less then 1 always display as 1 week
    if (!value || value <= 1) return $t('offer.delivery_week', {count: 1})

    return $t('offer.delivery_week', {count: value})
}

export function format_leading_zero(input) {
    const input_str = `${input}`

    if (input_str.length < 2) {
        return `0${input_str}`
    }

    return input_str
}

/**
 * Formats a number as money according to the users locale.
 *
 * @param money The amount to format.
 * @param currency In which currency to format ("EUR", "GBP", etc).
 * @param locale The locale to use, default = nl
 *
 * @returns A as currency formatted string, or "" if the argument money is undefined or null.
 */
export function format_money_with_symbol(
    money: number | string | undefined,
    currency: string | undefined | null,
    locale = 'nl',
) {
    if (currency && (money || money === 0)) {
        const formatter = new Intl.NumberFormat(locale, {
            style: 'currency',
            currency: currency,
        })
        if (money || money === 0) {
            return formatter.format(+money)
        }
    }
    return ''
}

/**
 * Format the ISO date_time as a nl-date string (dd-mm-yyyy).
 *
 * @param date_time The date to format.
 * @param locale The locale override to use. If not set nl-NL is used.
 */
export const format_iso_to_date = function(date_time: string | null | undefined, locale: string = 'nl-NL'): string {
    if (!date_time) {
        return ''
    }
    return Intl.DateTimeFormat(locale).format(new Date(date_time))
}

/**
 * Format the ISO date_time as a nl-date string (dd-mm-yyyy hh:mm:ss).
 *
 * @param date_time The date to format.
 * @param locale The locale override to use. If not set nl-NL is used.
 */
export const format_iso_to_date_time = function(date_time: string | null | undefined, locale = 'nl-NL'): string {
    if (!date_time) {
        return ''
    }
    return Intl.DateTimeFormat(locale, {
        dateStyle: 'short',
        timeStyle: 'medium',
    }).format(new Date(date_time))
}

/**
 * Format the ISO date_time as a fixed string (yyyy-MM-dd).
 *
 * This function retains leading zeroes, as opposed to format_iso_to_date. This keeps it consistent
 * with already existing date strings that were formatted via format-date from utils.ls.
 *
 * @param date_time The date to format.
 *
 * @return {string} the formatted date string.
 */
export const format_iso_to_fixed_date_format = function(date_time: string): string {
    if (!date_time) {
        return ''
    }
    const as_dt = new Date(date_time)
    const month = String(as_dt.getMonth() + 1).padStart(2, '0')
    const day_of_month = String(as_dt.getDate()).padStart(2, '0')
    return `${as_dt.getFullYear()}-${month}-${day_of_month}`
}

/**
 * Return the date_time in a relative format (yesterday, today, 8 days ago, etc).
 *
 * @see https://moment.github.io/luxon/docs/class/src/datetime.js~DateTime.html#instance-method-toRelativeCalendar
 * @param date_time The iso datetime format to make relative.
 */
export const format_iso_to_relative = function(date_time: string): string {
    if (date_time === '') {
        return ''
    }
    const as_dt = DateTime.fromISO(date_time)
    return as_dt.toRelativeCalendar({locale: 'en'}) || format_iso_to_date_time(date_time)
}

export function format_money(money: number | undefined, currency: string | undefined | null, locale = 'nl') {
    if (currency && (money || money === 0)) {
        const formatter = new Intl.NumberFormat(locale, {
            style: 'currency',
            currency: currency,
            currencyDisplay: 'code',
        })
        return formatter.format(money)
    }
    return ''
}

export function format_money_responsive(value, short: boolean | null = null, currency = '') {
    let minimum_fraction_digits = 2
    let maximum_fraction_digits = 2

    let formatted_value: number = value
    if (short && value > 100) {
        formatted_value = value / 1000
        minimum_fraction_digits = 1
        maximum_fraction_digits = 1
    }

    const formatter = new Intl.NumberFormat($s.language, {
        style: 'decimal',
        minimumFractionDigits: minimum_fraction_digits,
        maximumFractionDigits: maximum_fraction_digits,
    })

    let notation = formatter.format(formatted_value)
    if (currency) {
        notation = `${CURRENCY_SYMBOLS[currency]} ${notation}`
    }

    if (short && value > 100) {
        notation = `${notation}K`
    }

    return notation
}

export function format_percentage(value: number, locale = 'en'): string {
    const formatter = new Intl.NumberFormat(locale, {
        style: 'percent',
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
    })
    return formatter.format(value)
}

export function format_textual_date(date) {
    const month_names = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ]

    if (!(date instanceof Date)) {
        date = new Date(date)
    }

    let day = date.getDate()
    if (day === 1) {
        day = '1st'
    } else if (day === 2) {
        day = '2nd'
    } else if (day === 3) {
        day = '3rd'
    } else {
        day = day + 'th'
    }

    const month = month_names[date.getMonth()]
    return `${day} of ${month} ${date.getFullYear()}`

}

export function format_time_to_go(timestamp) {
    const today = new Date()
    const expiry = new Date(timestamp)
    const diff = Math.floor((expiry.getTime() - today.getTime()) / 1000)
    const seconds = diff % 60
    const diff_minutes = Math.floor (diff / 60)
    const minutes = diff_minutes % 60
    const hours = Math.floor (diff_minutes / 60)

    if (diff > 0) {
        return `${format_leading_zero(hours)}:${format_leading_zero(minutes)}:${format_leading_zero(seconds)}`
    }

    return '00:00:00'
}

/**
 * Capitalize the first letter of all words in a string.
 */
export function titleize(str: string | null): string {
    if (!str) {
        return ''
    }
    return str.replace(/\b[a-z]/g, (letter) => letter.toUpperCase())
}
