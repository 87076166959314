import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {
    Button,
    Country,
    FieldCheckbox,
    FieldSelect,
    FieldText,
    PanelContext,
    FieldListInput,
} from '@bitstillery/common/components'
import {invalid_fields, invalid_fields_format, required, validation} from '@bitstillery/common/lib/validation'
import {CollectionProxy} from '@bitstillery/common/lib/collection'
import {api, notifier} from '@bitstillery/common/app'

import {context, EntityType,methods} from '@/data/product_categories/list/lib/context'
import {
    ProductBottleType,
} from '@/factserver_api/fact2server_api'

interface PanelProductCategoryAttrs {
    collection: CollectionProxy
}

export class PanelProductCategory extends MithrilTsxComponent<PanelProductCategoryAttrs> {

    $v: any = {
        name: validation([context.data.entities[EntityType.PRDCAT], 'name'], required()),
    }

    view(vnode: m.Vnode<PanelProductCategoryAttrs>) {
        const entity = context.data.entities[EntityType.PRDCAT]
        return <PanelContext
            collection={vnode.attrs.collection}
            context={context}
            title="Product Category"
        >
            <div className="content form-container">
                <div className="field-group">
                    <FieldText
                        label="Category name"
                        model={[entity, ['name']]}
                        placeholder="Product Category name"
                        validation={this.$v.name}
                    />
                </div>
                <div className="field-group">
                    <FieldText
                        label="CBS Code"
                        model={[entity, ['cbs_code']]}
                    />
                    <FieldText
                        label="CBS Code >= 200"
                        model={[entity, ['cbs_code_gte_200']]}
                    />
                    <FieldText
                        label="CBS Code >= 1000"
                        model={[entity, ['cbs_code_gte_1000']]}
                    />
                </div>
                <div className="field-group">
                    <FieldSelect
                        label={<span>VAT Rate NL{' '}<Country country_code={'nl'}/></span>}
                        model={[entity, ['nl_vat_rate_artkey']]}
                        options={context.data.nl_vat_rates.map((i) => ({label: i.percentage, value: i.artkey}))}
                    />
                    <FieldSelect
                        label={<span>VAT Rate DE{' '}<Country country_code={'de'}/></span>}
                        model={[entity, ['de_vat_rate_artkey']]}
                        options={context.data.de_vat_rates.map((i) => ({label: i.percentage, value: i.artkey}))}
                    />
                    <FieldSelect
                        label={<span>VAT Rate BE{' '}<Country country_code={'be'}/></span>}
                        model={[entity, ['be_vat_rate_artkey']]}
                        options={context.data.be_vat_rates.map((i) => ({label: i.percentage, value: i.artkey}))}
                    />
                </div>
                <div className="field-group">
                    <FieldCheckbox
                        label={'Is spirit'}
                        model={[entity, 'is_spirit']}
                    />
                </div>
                <div className="field-group">
                    <FieldSelect
                        label={'Parent'}
                        model={[entity, 'parent_artkey']}
                        placeholder={'No parent'}
                        options={context.data.main_categories.map((it) => ({
                            label: it.name,
                            value: it.artkey,
                        }))}
                    />
                </div>
                <div className="field-group">
                    <FieldSelect
                        label="Default bottle"
                        model={[entity, 'default_product_bottle_type']}
                        options={Object.entries(ProductBottleType).map((i) => ({label: i[0], value: i[1]}))}
                    />
                </div>
                <FieldListInput
                    label='Alternative names'
                    model={[context.data.alternatives, 'new_item']}
                    items={context.data.alternatives.items}
                    placeholder={'Alternative name (atleast 3 characters)'}
                    validation={{
                        new_item: validation([context.data.alternatives, 'new_item'], {
                            validate: (value: string) => {
                                if (
                                    value.length >= 3 &&
                                    !context.data.alternatives.items.some((name) => name.toLowerCase() === value.toLowerCase())
                                ) {
                                    return false
                                }
                                return this
                            },
                            label: '*',
                            message: '',
                        }),
                    }}
                    onadd={() => {
                        const entity = context.data.entities[EntityType.PRDCAT]

                        if (context.data.alternatives.new_item) {
                            context.data.alternatives.disabled = true
                            api.put(
                                `discover/product-categories/${entity.artkey}/alternative`,
                                {alternative_name: context.data.alternatives.new_item},
                            ).then((resp) => {
                                if (resp.status_code > 299) {
                                    notifier.notify(`Alternative name "${context.data.alternatives.new_item}" cannot be added`, 'warning')
                                    context.data.alternatives.disabled = false
                                    return
                                }
                                notifier.notify(`Alternative name "${context.data.alternatives.new_item}" was added`, 'success')
                                context.data.alternatives.items.push(context.data.alternatives.new_item)
                                context.data.alternatives.new_item = ''
                                context.data.alternatives.disabled = false
                            })
                        }
                    }}
                    ondelete={(name: string) => {
                        const entity = context.data.entities[EntityType.PRDCAT]
                        context.data.alternatives.disabled = true
                        api.delete(
                            `discover/product-categories/${entity.artkey}/alternative/${name}`,
                        ).then((resp) => {
                            if (resp.status_code > 299) {
                                notifier.notify(`Alternative name "${name}" cannot be removed`, 'warning')
                                context.data.alternatives.disabled = false
                                return
                            }
                            context.data.alternatives.items = context.data.alternatives.items.filter((n) => n !== name)
                            notifier.notify(`Alternative name "${name}" was removed`, 'success')
                            context.data.alternatives.disabled = false
                        })
                    }}
                />
                <Button
                    className="btn-submit"
                    disabled={invalid_fields(this.$v).length > 0}
                    icon="save"
                    onclick={() => {
                        methods.upsert_entity(EntityType.PRDCAT, entity)}
                    }
                    text={entity.artkey ? 'Update Category' : 'Create Category'}
                    tip={() => invalid_fields_format(invalid_fields(this.$v), 'tip')}
                    type="success"
                />
            </div>

        </PanelContext>
    }
}
