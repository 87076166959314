import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'
import Sortable from 'sortablejs'
import {Button, ButtonGroup, Dialog} from '@bitstillery/common/components'
import {proxy} from '@bitstillery/common/lib/proxy'
import {api, notifier} from '@bitstillery/common/app'

import {Link} from '../components/discover'

import {
    CollectionTable,
    CollectionTableColumn,
    CollectionTableRowComponentProps,
    PagedCollectionFetcherWithTotal,
} from '@/components/collection/collection_table'
import {ProductPhotoApi} from '@/factserver_api/product_photos'
import {GetReviewableProductPhotoResponse, ReviewableProductPhoto, RotateDirection, RotateProductPhotoResponse} from '@/factserver_api/fact2server_api'
import {CheckBox} from '@/components/html_components'

class ItemImageView extends MithrilTsxComponent<
    CollectionTableRowComponentProps<ReviewablePhoto, void>
> {
    sortable: Sortable | null = null
    item_photo_data: ReviewableProductPhoto[] = []
    product_photo_api = new ProductPhotoApi()
    is_loading = false

    data = proxy({
        photo: {
            detail: null as ReviewablePhoto| null,
        },
    })

    oncreate(vnode: m.Vnode<CollectionTableRowComponentProps<ReviewablePhoto, void>>): void {
        this.item_photo_data = vnode.attrs.row.product_photos
        // Initialise the sortable list
        const photo_list_element = document.getElementById(`photo-list-${vnode.attrs.row.artkey}`)
        if (!photo_list_element) {
            throw new Error(`Programmer error, cannot locate element photo-list-${vnode.attrs.row.artkey}`)
        }
        this.sortable = Sortable.create(photo_list_element, {
            animation: 150,
            onEnd: () => this.rerank(),
        })
    }

    async delete_photo(product_photo: ReviewableProductPhoto, row: GetReviewableProductPhotoResponse) {
        const {status_code} = await api.delete(`discover/product-photos/${product_photo.artkey}`)
        if (status_code > 299) {
            return
        }

        row.product_photos = row.product_photos.filter((photo) => String(photo.artkey) !== String(product_photo.artkey))
        m.redraw()
    }

    rerank() {
        if (!this.sortable) {
            return
        }
        const artkeys = this.sortable.toArray().map((artkey) => +artkey)

        let rank = 1
        artkeys.forEach((artkey) => {
            const photo = this.item_photo_data.filter((photo_data) => {
                return photo_data['artkey'] === artkey
            })[0]
            photo.rank = rank
            rank++
        })
        m.redraw()
    }

    async rotate_photo(product_photo: ReviewableProductPhoto, rotate_direction: RotateDirection) {
        this.is_loading = true
        const {result, status_code} = await api.post<RotateProductPhotoResponse>(`discover/product-photos/${product_photo.artkey}/rotate`, {
            rotate_direction: rotate_direction,
        }, true)
        this.is_loading = false
        if (status_code > 299) {
            return
        }
        product_photo.original_location = result.original_location
        m.redraw()
    }

    view(vnode: m.Vnode<CollectionTableRowComponentProps<ReviewablePhoto, void>>): m.Children {
        return (
            <td className="c-item-image-view">
                {!vnode.attrs.row.is_showing_details && <span/>}

                {this.data.photo.detail && <Dialog
                    onclose={() => {
                        this.data.photo.detail = null
                    }}
                    title={(() => {
                        const photo = this.data.photo.detail
                        let title = `Rank: ${photo.rank} ${photo.is_internal ? '(internal)' : ''} - ${photo.file_name}`
                        return title
                    })()}
                >
                    <img src={this.data.photo.detail.original_location}/>
                </Dialog>}

                {!vnode.attrs.row.is_showing_details && (
                    <div className="photos" id={`photo-list-${vnode.attrs.row.artkey}`}>
                        {vnode.attrs.row.product_photos.map((photo) => (
                            <div
                                className="product-photo"
                                id={`movable-photo-${photo.artkey}`}
                                key={photo.artkey}
                                data-id={photo.artkey}
                            >
                                <div className="image">
                                    <img
                                        src={photo.original_location}
                                        alt={`Cases for ${vnode.attrs.row.artkey}`}
                                    />
                                </div>

                                <div className="controls">
                                    <ButtonGroup>
                                        <CheckBox
                                            disabled={this.is_loading}
                                            checked={photo.is_internal}
                                            label="Internal"
                                            id={`flip_is_internal-${photo.artkey}`}
                                            onchange={() => (photo.is_internal = !photo.is_internal)}
                                        />
                                    </ButtonGroup>
                                    <ButtonGroup>
                                        <Button
                                            disabled={this.is_loading}
                                            icon="search"
                                            onclick={() => {
                                                this.data.photo.detail = photo
                                            }}
                                            size="s"
                                        />
                                        <Button
                                            disabled={this.is_loading}
                                            onclick={async() => await this.rotate_photo(photo, RotateDirection.Left)}
                                            icon="rotateLeft"
                                            size="s"
                                        />
                                        <Button
                                            disabled={this.is_loading}
                                            icon="rotateRight"
                                            onclick={async() => await this.rotate_photo(photo, RotateDirection.Right)}
                                            size="s"
                                        />

                                        <Button
                                            disabled={this.is_loading}
                                            onclick={() => this.delete_photo(photo, vnode.attrs.row)}
                                            icon="trash"
                                            size="s"
                                            type="danger"
                                        />
                                    </ButtonGroup>
                                </div>

                            </div>
                        ))}
                    </div>
                )}
            </td>
        )
    }
}

interface ReviewablePhoto extends GetReviewableProductPhotoResponse {
    is_approved: boolean
    is_showing_details: boolean
}

export default class ImageReview extends MithrilTsxComponent<unknown> {
    items_reviewed = 0
    product_photo_fetcher = new PagedCollectionFetcherWithTotal<GetReviewableProductPhotoResponse>(
        'discover/items/reviewable-product-photos/collection-view',
        'rank',
        undefined,
        10,
        false,
    )

    async update_item_photos(item: ReviewablePhoto) {
        const {status_code} = await api.post(`discover/items/${item.artkey}/mark-as-reviewed`, item.product_photos, true)
        if (status_code > 299) {
            return
        }
        notifier.notify(`Photos for ${item.purchase_order_item.case.bottle.product.name} reviewed`, 'success')
        item.is_approved = true
        item.is_showing_details = true
        this.items_reviewed += 1
    }

    view(): m.Children {
        return (
            <div className="c-stock-image-review view">
                <div className="btn-toolbar">
                    <Button
                        icon={this.product_photo_fetcher.sort_ascending ? 'sortDescending' : 'sortAscending'}
                        text={`${this.product_photo_fetcher.sort_ascending ? 'Latest' : 'Oldest'} first`}
                        disabled={this.product_photo_fetcher.is_fetching}
                        onclick={() => {
                            this.product_photo_fetcher.sort_ascending = !this.product_photo_fetcher.sort_ascending
                            this.product_photo_fetcher.reset_and_query()
                        }}
                    />
                </div>

                <CollectionTable<ReviewablePhoto, void>
                    collection_fetcher={this.product_photo_fetcher}
                    on_row_click={(row: ReviewablePhoto) =>
                        (row.is_showing_details = !row.is_showing_details)
                    }
                    on_row_click_component={ItemImageView}
                >
                    <CollectionTableColumn
                        header_title={() => <span>
                            <div>Showing {this.product_photo_fetcher.count_fetched_rows() - this.items_reviewed} of {this.product_photo_fetcher.total} items
                                    to review</div>
                        </span>}
                        data_field={(row: ReviewablePhoto) => <div className="item-header">
                            <ButtonGroup>
                                <Button
                                    icon="thumbUp"
                                    size="s"
                                    type="info"
                                    tooltip={'Approve'}
                                    disabled={row.is_approved}
                                    onclick={async() => await this.update_item_photos(row)}
                                />
                                <Button
                                    link={`/stock/manage/${row.reference}`}
                                    icon="search"
                                    size="s"
                                />
                            </ButtonGroup>
                            <span>
                                <Link href={`/purchase-orders/manage/${row.purchase_order_item.purchase_order.artkey}`}>
                                    {row.purchase_order_item.purchase_order.reference}
                                </Link>
                                {' - '}
                                {row.purchase_order_item.case.bottle.product.name}
                                {' - '}
                                <Link href={`/stock/manage?search=${row.lot}`}>
                                    {row.lot} ({row.reference})
                                </Link>{' '}

                            </span>
                        </div>}
                    />
                </CollectionTable>
            </div>
        )
    }
}
