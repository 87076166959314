/** llm:tested */
import {maybeMap, joinMaybes, randomUuid} from '@/_utils'
import {TaxLabelConstants} from '@/models/data'
import {Product} from '@/models/products'
import {Case} from '@/models/stock'
import {Supplier} from '@/models/suppliers'
import {MithrilProp} from '@/types/utils'

export class FilterType {
    static RELATION = 'Relation'
    static PRODUCT = 'Product'
    static BOTH = 'Both'
    static VARIOUS = 'Various'
}

export interface ExploreFilter {
    limit: number
    date_selection: string
    account_selection: string
}
export interface ExplorePanel {
    title: string
    description?: string
    collapsible: boolean
    collapsed: boolean
}

export interface ExplorePanels {
    purchase_history: ExplorePanel
    sales_history: ExplorePanel
    stock: ExplorePanel
    bottle_market: ExplorePanel
    offer_history: ExplorePanel
    price_list_history: ExplorePanel
}

export interface ExploreData {
    filter: ExploreFilter
    panels: ExplorePanels
}

export class BottleFilters {
    volume: MithrilProp<string>
    alcohol_percentage: MithrilProp<string>
    refill_status: MithrilProp<string>

    constructor(json: any = {}) {
        this.volume = window.prop(json.volume || '')
        this.alcohol_percentage = window.prop(json.alcohol_percentage || '')
        this.refill_status = window.prop(json.refill_status || '')
    }

    static from_bottle(bottle: any): BottleFilters {
        return new BottleFilters({
            volume: bottle.volume(),
            alcohol_percentage: bottle.alcohol_percentage(),
            refill_status: bottle.refill_status(),
        })
    }

    reset() {
        this.volume('')
        this.alcohol_percentage('')
        this.refill_status('')
    }

    to_json(): any {
        return {
            volume: this.volume(),
            alcohol_percentage: this.alcohol_percentage(),
            refill_status: this.refill_status(),
        }
    }

    is_empty(): boolean {
        return this.volume() === '' &&
            this.alcohol_percentage() === '' &&
            this.refill_status() === ''
    }

    show(): string {
        return joinMaybes(' ', [
            maybeMap((v) => v + 'cl', this.volume()),
            maybeMap((v) => v + '%', this.alcohol_percentage()),
            this.refill_status(),
        ])
    }

    is_full(): boolean {
        return Boolean(this.volume() && this.alcohol_percentage() && this.refill_status())
    }

    is_half(): boolean {
        return !(this.is_empty() || this.is_full())
    }

    clone(): BottleFilters {
        return new BottleFilters(this.to_json())
    }
}

export class SearchFilter {
    artkey: string
    product: () => Product
    product_name: () => string
    case: () => Case
    supplier: () => Supplier
    selected_relations: () => any[]
    bottle: () => BottleFilters
    volume_options: () => any[]
    alcohol_percentage_options: () => any[]
    refill_status_options: () => any[]

    constructor() {
        this.artkey = randomUuid()
        this.product = window.prop(new Product())
        this.product_name = window.prop('')
        this.case = window.prop(new Case())
        this.supplier = window.prop(new Supplier())
        this.selected_relations = window.prop([])
        this.bottle = window.prop(new BottleFilters())
        this.volume_options = window.prop([])
        this.alcohol_percentage_options = window.prop([])
        this.refill_status_options = window.prop([])
    }

    to_json(): SearchFilterJson {
        const selected_relation = this.selected_relations()[0]
        this.supplier().name(selected_relation?.name || '')
        this.supplier().artkey(selected_relation?.artkey)
        this.supplier().country_code(selected_relation?.country_code || '')

        const supplier_artkey = selected_relation?.artkey
        const tax_label = (() => {
            switch (this.case().tax_label()) {
            case '':
                return undefined
            case TaxLabelConstants.NO_TAX_LABEL:
                return ''
            default:
                return this.case().tax_label()
            }
        })()

        return {
            product_artkey: this.product().artkey(),
            product_name: this.product().name(),
            supplier_artkey: supplier_artkey,
            supplier_name: this.supplier().name(),
            number_of_bottles: this.case().number_of_bottles(),
            gift_box_type: this.case().gift_box_type(),
            bottle_volume: this.bottle().volume(),
            bottle_alcohol_percentage: this.bottle().alcohol_percentage(),
            bottle_refill_status: this.bottle().refill_status(),
            tax_label: tax_label,
        }
    }
}

export interface SearchFilterJson {
    product_artkey: string | undefined
    product_name: string | undefined
    supplier_artkey: number
    supplier_name: string
    number_of_bottles: string
    gift_box_type: string
    bottle_volume: string
    bottle_alcohol_percentage: string
    bottle_refill_status: string
    tax_label: string | undefined
}
