import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {DataCard} from '@bitstillery/common/components'
import {proxy} from '@bitstillery/common/lib/proxy'

import {ExploreData, FilterType, SearchFilter, SearchFilterJson} from './models'

import {BottleMarket} from '@/components/market_info/bottle_market'
import {Panel} from '@/components/panel'

interface ShowBottleMarketAttrs {
    search_filters: Array<SearchFilter>
    filter_type: FilterType
    unique_name: string
    explore_data: ExploreData
}

export class ShowBottleMarket extends MithrilTsxComponent<ShowBottleMarketAttrs> {
    search_filters: Array<SearchFilterJson>
    data = proxy({
        number_of_items: 0,
    })

    constructor(vnode: m.Vnode<ShowBottleMarketAttrs>) {
        super()
        this.search_filters = vnode.attrs.search_filters.map((filter) => {
            return filter.to_json()
        })
    }

    view(vnode: m.Vnode<ShowBottleMarketAttrs>) {
        let user_has_searched = false
        for (let filter of this.search_filters) {
            if (filter.product_name || filter.supplier_artkey) {
                user_has_searched = true
                break
            }
        }
        const toolbar = this.data.number_of_items > 0 ?
            <span className='c-pager'>
                <span className='result-count'>{`${this.data.number_of_items} results`}
                </span>
            </span>
            : undefined

        return (
            <div class="c-market-explore-spli">
                <Panel
                    title={vnode.attrs.explore_data.panels.bottle_market.title}
                    description={vnode.attrs.explore_data.panels.stock.description}
                    toolbar={toolbar}
                    model={vnode.attrs.explore_data.panels.bottle_market}
                >
                    {user_has_searched && this.search_filters.map((filter) => {
                        return <ShowSingleMarket
                            filter_type={vnode.attrs.filter_type}
                            search_filter={filter}
                            explore_data={vnode.attrs.explore_data}
                            data={this.data}
                        />
                    })}
                    {!user_has_searched && 'No results'}
                </Panel>
            </div>
        )
    }
}

interface ShowSingleMarketAttrs {
    filter_type: FilterType
    search_filter: SearchFilterJson
    explore_data: ExploreData
    data: {
        number_of_items: number
    }
}

export class ShowSingleMarket extends MithrilTsxComponent<ShowSingleMarketAttrs> {
    discover_link(product_name?: string, volume?: number | string, alcohol_percentage?: number | string, supplier_artkey?: string): string {
        const params = new URLSearchParams()
        if (product_name) {
            params.append('search_terms', product_name)
        }
        if (volume) {
            params.append('volume', (+volume).toFixed(1))
        }
        if (alcohol_percentage) {
            params.append('alcohol_percentage', (+alcohol_percentage).toFixed(1))
        }
        if (supplier_artkey) {
            params.append('relation_artkeys', supplier_artkey)
        }
        return params.toString() ? `#!/market/discover?${params.toString()}` : ''
    }

    _discover_link_for_filter_type(
        filter_type: FilterType,
        product_name?: string,
        volume?: number | string,
        alcohol_percentage?: number | string,
        supplier_artkey?: string,
    ): JSX.Element | null {
        const querystring = this.discover_link(product_name, volume, alcohol_percentage, supplier_artkey)
        if (!querystring) {
            return null
        }

        return <div>
            <a href={querystring} target="_blank">
                <span class="glyphicon glyphicon-search" />
                {(filter_type === FilterType.RELATION || filter_type === FilterType.BOTH) && (
                    <span> For selected supplier</span>
                )}
                {filter_type === FilterType.PRODUCT && (
                    <span> For all suppliers</span>
                )}
            </a>
        </div>
    }

    view(vnode: m.Vnode<ShowSingleMarketAttrs>) {
        const product_name = vnode.attrs.search_filter.product_name
        const product_artkey = vnode.attrs.search_filter.product_artkey
        const volume = vnode.attrs.search_filter.bottle_volume
        const alcohol_percentage = vnode.attrs.search_filter.bottle_alcohol_percentage
        const refill_status = vnode.attrs.search_filter.bottle_refill_status
        const supplier_artkey = vnode.attrs.search_filter.supplier_artkey
        const supplier_name = vnode.attrs.search_filter.supplier_name

        const go_to_discover_link: JSX.Element | null = this._discover_link_for_filter_type(
            vnode.attrs.filter_type, product_name, volume, alcohol_percentage, supplier_artkey,
        )

        const bottle_market_data = {
            specs: {
                product_name: product_name,
                product_artkey: product_artkey,
                volume: volume,
                alcohol_percentage: alcohol_percentage,
                refill_status: refill_status,
            },
            supplier_name: supplier_name,
            with_pagination: true,
            page_size: 50,
            fetch_info: (number_of_items) => vnode.attrs.data.number_of_items = number_of_items,
        }

        return (
            <div>
                {go_to_discover_link &&
                    <DataCard
                        model={{data: [{label: 'Go to Discover', value: go_to_discover_link}]}}
                        variant="dense"
                    />
                }
                {product_name && <BottleMarket {...bottle_market_data} />}
                {!product_name && 'Please enter a product name.'}
            </div>
        )
    }
}
