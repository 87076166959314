import {MithrilTsxComponent} from 'mithril-tsx-component'
import {
    active_filters,
    clear_filter,
    reset_filters,
    url_to_filters,
} from '@bitstillery/common/lib/filters'
import {classes, debounce, entity_artkey, get_route, unique_id} from '@bitstillery/common/lib/utils'
import {watch} from '@bitstillery/common/lib/store'
import {CollectionInlineField, FieldCheckbox, Icon, Spinner} from '@bitstillery/common/components'
import {filters_to_url} from '@bitstillery/common/lib/filters'
import {grid_columns} from '@bitstillery/common/lib/collection'
import {Filters} from '@bitstillery/common/types'
import m from 'mithril'
import {$t, events, logger, view} from '@bitstillery/common/app'
import {next_tick} from '@bitstillery/common/lib/proxy'

interface CollectionItemsAttrs {
    className?: string
    /** Set the context when you define the columns elsewhere, and want to access the CollectionItems context elsewhere */
    context: any
    collection: any
    /** A description of how to render each row's columns and the collection header */
    columns?: any[]

    disable_scroll?: boolean
    /** Whether the row has a checkbox for selection */
    has_selection?: boolean
    /** A custom row renderer can be defined here */
    item?: Function
    /** CollectionItems can be used without a collection; use the items attribute instead then */
    items?: any[]
    /** Add custom behavior when clicking a row */
    on_row_click?: Function
    /** The row action buttons shown on hovering a row */
    row_actions?: Function
    /** Extra context space beneath the row, opened after clicking a row */
    row_detail?: Function
    /** Row status icons at the end of a row */
    row_status?: Function
    /** Whether to hide the row status column */
    row_status_no_cell_status?: boolean
    /** Row separator between different groups of items */
    row_separator?: (item: any, index: number, items: any[]) => { type: string; text: string } | null
    /** Specify a different scroll container */
    scroll_container?: string
}

export class CollectionItems extends MithrilTsxComponent<CollectionItemsAttrs> {

    container: HTMLElement
    debounced_cancel = false
    has_row_status = false
    id = `collection-items-${unique_id()}`
    scroll_observer?: IntersectionObserver
    watchers = [] as any

    oninit(vnode:m.Vnode<CollectionItemsAttrs>) {
        if (!vnode.attrs.collection) {
            throw new Error('CollectionItems component misses a collection')
        }

        if (vnode.attrs.collection.state.ready) {
            this.oncollectionready(vnode, vnode.attrs.collection)
        } else {
            let unwatch = watch(vnode.attrs.collection.state, 'ready', () => {
                this.oncollectionready(vnode, vnode.attrs.collection)
                unwatch()
            }) as Function
        }

        // Row status is a small column at the right that contains a few indicators.
        if (vnode.attrs.row_status && vnode.attrs.columns) {
            this.has_row_status = true
            // The columns are initialized at the top level and reused;
            // a cell-status column only needs to be added once.
            if (!vnode.attrs.row_status_no_cell_status) {
                if (!vnode.attrs.columns.find((i) => i.className === 'cell-status')) {
                    vnode.attrs.columns.push({
                        className: 'cell-status',
                        name: '',
                        width: 'var(--coll-status-width)',
                    })
                }
            }
        }
    }

    async oncreate(vnode:m.Vnode<CollectionItemsAttrs>) {
        if (!vnode.attrs.disable_scroll) {
            this.setup_scroll_observer(vnode)
        }
        events.on('collection:scroll_to_edit_row', () => {
            const selected_element = document.querySelector('.item.selected')
            if (selected_element) {
                selected_element.scrollIntoView({behavior: 'instant', block: 'center'})
            }
        })
    }

    setup_scroll_observer(vnode: m.Vnode<CollectionItemsAttrs>) {
        // No collection, no infinite scrolling...
        const collection = vnode.attrs.collection

        if (vnode.attrs.scroll_container) {
            this.container = document.querySelector(vnode.attrs.scroll_container) as HTMLElement
            if (!this.container) {
                throw new Error(`CollectionItems component misses a DOM scrolling container (selector: ${vnode.attrs.scroll_container})`)
            }
        } else {
            this.container = document.querySelector(`#${this.id}`)?.closest('.view') as HTMLElement
        }

        if (this.scroll_observer) {
            this.scroll_observer.disconnect()
        }

        this.scroll_observer = new IntersectionObserver(async(entries) => {
            if (
                (collection && collection.state.loading) ||
                !vnode.attrs.collection.state.ready ||
                entries[0].intersectionRatio <= 0
            ) {
                return
            }
            collection.state.query.offset += collection.state.page_size

            const scroll_height = this.container.scrollHeight
            if (collection.state.has_items) {
                await collection.query()
                this.container.scrollTo({
                    behavior: 'smooth',
                    top: scroll_height,
                })
            }

        }, {
            root: this.container,
            rootMargin: '8px',
        })

        const observable_element = document.querySelector(`#${this.id} + .loading-observable`) as HTMLElement
        if (observable_element) {
            this.scroll_observer.observe(observable_element)
        }
    }

    async oncollectionready(vnode, collection) {
        if (!collection.state.endpoint.path) {
            return
        }
        const filters = collection.filters
        view.filters = filters

        collection.state.loading = true
        collection.state.query.offset = 0
        collection.state.selection.ids.splice(0, collection.state.selection.ids.length)

        this.watchers.push(watch(collection.state.selection, 'all', this.watch_select_all.bind(this, vnode)))
        this.watchers.push(watch(collection.state.selection, 'mode', this.watch_select_mode.bind(this, vnode)))
        this.watchers.push(watch(collection.state.sort, 'by', this.watch_sort.bind(this, vnode)))
        this.watchers.push(watch(collection.state.sort, 'order', this.watch_sort.bind(this, vnode)))

        if (filters) {
            logger.debug(`[collection-items] setup watchers (${collection.state.endpoint.path})`)
            if (collection.state.url_sync) {
                await next_tick()
                url_to_filters(filters, collection)
            }

            // Watch filter data.
            for (const filter of Object.values(filters)) {
                if (['boolean', 'number', 'string'].includes(typeof filter.selection)) {
                    this.watchers.push(watch(filter, 'selection', this.watch_filters.bind(this, vnode)))
                } else {
                    this.watchers.push(watch(filter.selection, this.watch_filters.bind(this, vnode)))
                }
            }
        }

        collection.query()
    }

    onremove(vnode:m.Vnode<CollectionItemsAttrs>) {
        view.filters = null
        // Cancel any query that may be triggered while cleaning up.
        vnode.attrs.collection.state.query.offset = 0
        vnode.attrs.collection.state.items.splice(0, vnode.attrs.collection.state.items.length)

        this.debounced_cancel = true
        this.watchers.map((unwatch) => unwatch())

        if (!vnode.attrs.disable_scroll) {
            if (this.scroll_observer) {
                this.scroll_observer.disconnect()
            }
        }

        if (vnode.attrs.collection.filters) {
            reset_filters(vnode.attrs.collection.filters)
        }

        // (!) Make sure to disable the collection again, so
        // the collection items view can wait for the next
        // collection.init to be called.
        vnode.attrs.collection.state.ready = false
        events.off('collection:scroll_to_edit_row')
    }

    view(vnode:m.Vnode<CollectionItemsAttrs>) {
        const loading = vnode.attrs.collection ? vnode.attrs.collection.state.loading : false
        const items = vnode.attrs.collection.state.items
        let has_selection = vnode.attrs.has_selection

        if (vnode.attrs.collection) {
            const selection = vnode.attrs.collection.state.selection
            has_selection = ['select', 'deselect'].includes(selection.mode)
        }

        return [
            <div
                className={classes('c-collection-items', vnode.attrs.className, {
                    'has-row-click': !!vnode.attrs.on_row_click,
                    'has-row-status': this.has_row_status && !vnode.attrs.row_status_no_cell_status,
                })}
                id={this.id}
            >
                {(['outdated', 'unavailable'].includes(vnode.attrs.collection.state.content_state)) &&
                <div className="collection-feedback">
                    <div className="hero">
                        <Spinner />
                        <span>{$t('collection.no_results_retry')}</span>
                    </div>
                    <div className="suggest mt-1">
                        {$t('collection.no_results_retry_loading')}
                    </div>
                </div>}

                {(!items.length && !loading) && (() => {
                    let filters_active = false
                    if (vnode.attrs.collection) {
                        filters_active = active_filters(vnode.attrs.collection.filters) > 0
                    }
                    return <div className="collection-feedback">
                        <div className="hero">
                            <Icon name="notfound" />
                            <span>{$t('collection.no_results')}</span>
                        </div>
                        {!!filters_active && <div className="suggest mt-1">
                            <Icon name="filterRemove" onclick={() => {
                                reset_filters(vnode.attrs.collection.filters)
                            }}/>
                            <span>{$t('collection.no_results_clear_filters')}</span>
                        </div>}
                    </div>
                })()}

                {(() => {
                    return items.map((item:any, index: number) => {
                        // See https://git.osso.nl/springtimegroup/discover/-/issues/1615
                        // Artkeys need to be splitted in reference: SOI & artkey 123
                        // Until that's fixed, we need to split the artkey.
                        const item_artkey = entity_artkey(item.artkey)

                        let row_status:any
                        let row_separator:any
                        let row_detail:any

                        if (vnode.attrs.row_separator) {
                            row_separator = vnode.attrs.row_separator(item, index, items)
                        }

                        if (vnode.attrs.row_detail) {
                            row_detail = vnode.attrs.row_detail(item)
                        }
                        if (vnode.attrs.row_status) {
                            row_status = vnode.attrs.row_status(item)
                        }

                        if (vnode.attrs.item) {
                            // Use a custom renderer...
                            return vnode.attrs.item(item)
                        }

                        if (!vnode.attrs.columns) {
                            throw new Error('item or column renderer is required')
                        }

                        const grid_template_cols = grid_columns(vnode.attrs.columns, has_selection)

                        const item_elements = [
                            <div
                                key={`${vnode.attrs.collection._id}-${item_artkey}`}
                                className={classes('item',
                                    this.has_row_status ? `item-type-${row_status.type}` : 'item-type-default', {
                                        detail: vnode.attrs.collection && (vnode.attrs.collection.state.detail === item_artkey),
                                        'has-row-detail': !!row_detail,
                                        selected: (
                                            vnode.attrs.collection.state.selection.ids?.includes(item_artkey) &&
                                            !vnode.attrs.collection.state.selection.all
                                        ),
                                    })}
                                onclick={(e) => {
                                    // Row-details elements must block the row_detail toggle,
                                    // or actions like copy-paste are impossible.
                                    if (e.target) {
                                        const closest = e.target.closest('.no-click')
                                        if (closest) {
                                            return
                                        }
                                    }

                                    if (vnode.attrs.collection && 'row_detail' in vnode.attrs) {
                                        if (vnode.attrs.collection.state.detail !== item_artkey) {
                                            vnode.attrs.collection.state.detail = item_artkey
                                        } else {
                                            vnode.attrs.collection.state.detail = null
                                        }
                                    }
                                    if (vnode.attrs.on_row_click) {
                                        vnode.attrs.on_row_click(item)
                                    }
                                }}
                            >
                                <div
                                    className='cells'
                                    style={`grid-template-columns: ${grid_template_cols}`}
                                >
                                    <div className="cell-selection">
                                        {has_selection && <FieldCheckbox
                                            disabled={vnode.attrs.collection.state.loading}
                                            onAfterChange={(newValue) => {
                                                if (!newValue) {
                                                    vnode.attrs.collection.item_select(item)
                                                } else {
                                                    vnode.attrs.collection.item_deselect(item)
                                                }
                                            }}
                                            computed={() => {
                                                if (vnode.attrs.collection.state.selection.all) {
                                                    return !vnode.attrs.collection.state.selection.ids.includes(item_artkey)
                                                } else {
                                                    return (
                                                        vnode.attrs.collection.state.selection.ids.includes(item_artkey)
                                                    )
                                                }
                                            }}
                                        />}
                                    </div>

                                    {vnode.attrs.columns.map((column, index) => {
                                        const is_last_column = index === vnode.attrs.columns.length - 1
                                        let rendered_row
                                        if (
                                            vnode.attrs.collection.state.bulk_mode === 'edit' ||
                                            (
                                                vnode.attrs.collection.state.selection.ids?.includes(item_artkey) &&
                                                !vnode.attrs.collection.state.selection.all
                                            )
                                        ) {
                                            const bulk_data = vnode.attrs.collection.bulk
                                            if (column.id && column.id in bulk_data.fields) {
                                                return <CollectionInlineField
                                                    column_id={column.id}
                                                    collection={vnode.attrs.collection}
                                                    field={bulk_data.fields[column.id]}
                                                    item={item}
                                                />
                                            }
                                        }
                                        if (this.has_row_status && is_last_column) {
                                            rendered_row = row_status.render
                                        } else {
                                            rendered_row = column.render(item, vnode.attrs.context)
                                        }

                                        let cell_type = 'default'
                                        if (column.type && typeof column.type === 'function') {
                                            cell_type = column.type(item)
                                        }
                                        return <div className={classes('cell', column.className, `cell-type-${cell_type}`)}>
                                            {rendered_row}
                                        </div>
                                    })}

                                    {vnode.attrs.row_actions && <div className='cell cell-actions no-click'>
                                        {vnode.attrs.row_actions(item, vnode.attrs.context)}
                                    </div>}
                                </div>
                                {(row_detail && (vnode.attrs.collection.state.detail === item_artkey)) && <div className="row-details no-click">
                                    {row_detail}
                                </div>}
                            </div>,
                        ]
                        if (row_separator) {
                            item_elements.unshift(<div key={item_artkey} className={classes('row-separator', `type-${row_separator.type}`)}>
                                {row_separator.icon && <Icon name={row_separator.icon} type="unset"/>}
                                {row_separator.text}
                            </div>)
                        }
                        return item_elements
                    })
                })()}
            </div>,
            !vnode.attrs.disable_scroll && <div class="loading-observable"></div>,
        ]
    }

    watch_filters = debounce(100, async function(vnode) {
        // Last minute filter value check; a filter may get a value
        // which results in resetting a filter(e.g. range filters).
        // This can be done from here.
        for (const filter of Object.values(vnode.attrs.collection.filters as Filters)) {
            // A range filter that needs to be reset.
            if ('min' in filter && filter.selection.length) {
                if (filter.selection[0] === filter.min && (filter.selection[1] === filter.max || filter.selection[1] === Infinity)) {
                    clear_filter(filter)
                }
            }
        }
        if (!this.debounced_cancel) {
            vnode.attrs.collection.state.query.offset = 0
            const {path} = m.parsePathname(m.route.get()) as any

            // Reflect the active filter state in the url; include also
            // sort_by and sort_order when they are part of the params.
            const exclude_params = ['meta'] as any
            if (vnode.attrs.collection.state.url_sync) {
                const route = get_route(path, filters_to_url(vnode.attrs.collection.filters), exclude_params)
                m.route.set(route, {}, {replace: true})
            }

            this.container.scrollTo({
                behavior: 'auto',
                top: 1,
            })
            vnode.attrs.collection.query()
        }
    })

    watch_select_all(vnode) {
        const selection = vnode.attrs.collection.state.selection
        if (selection.current) {
            // There is a list of the current selected items from the backend
            if (selection.all) {
                selection.ids.splice(0, selection.ids.length)
            } else {
                selection.ids.splice(0, selection.ids.length, ...selection.current)
            }
        } else {
            selection.ids.splice(0, selection.ids.length)
        }
    }

    watch_select_mode(vnode) {
        const selection = vnode.attrs.collection.state.selection
        if (vnode.attrs.on_selection_mode_change) {
            vnode.attrs.on_selection_mode_change(selection.mode)
        }
    }

    watch_sort = debounce(100, async function(vnode) {
        if (!this.debounced_cancel) {
            const defaults = vnode.attrs.collection.state.sort.defaults
            const sort = vnode.attrs.collection.state.sort
            // Reset the query offset; so all results are replaced after
            // changing the sort direction.
            vnode.attrs.collection.state.query.offset = 0
            if (defaults.sort_by !== sort.by || defaults.order !== sort.order) {
                const exclude_params = ['meta'] as any
                const {path} = m.parsePathname(m.route.get()) as any
                const route = get_route(path, {...filters_to_url(vnode.attrs.collection.filters),
                    sort_by: sort.by,
                    sort_order: sort.order,
                }, exclude_params)
                m.route.set(route, {}, {replace: true})
                this.container.scrollTo({
                    behavior: 'auto',
                    top: 1,
                })
                vnode.attrs.collection.query()
            }
        }
    })
}
