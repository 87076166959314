/** llm:tested */
// eslint-disable-next-line no-unused-vars
import m from 'mithril'
import {deref} from '@bitstillery/common/utils'
import {AccountSlug} from '@bitstillery/common/account/account'

interface Account {
    slug: AccountSlug
    name: string
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const accountIcon = (account: Account) => {
    return accountIconBySlug(account.slug, account.name)
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const accountIconBySlug = (account_slug: AccountSlug, account_name?: string) => {
    account_slug = deref(account_slug)
    let icon_src: string | undefined

    if (account_slug === AccountSlug.A2BC) {
        icon_src = '/theme/img/discover/a2bc-favicon.ico'
    } else if (account_slug === AccountSlug.ETR) {
        icon_src = '/theme/img/discover/etr-favicon.ico'
    } else if (account_slug === AccountSlug.MSI) {
        icon_src = '/theme/img/discover/msi-favicon.ico'
    } else if (account_slug === AccountSlug.MSP) {
        icon_src = '/theme/img/discover/msp-favicon.ico'
    }

    return <img
        className="account-slug-icon"
        src={icon_src}
        title={deref(account_name)}
        alt={`${deref(account_name)}-favicon.ico`}
    />
}
