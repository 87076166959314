import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'
import {to_specs_from_values} from '@bitstillery/common/models/item'
import {Country, Tippy} from '@bitstillery/common/components'

import {$s} from '@/app'
import {GetLoenderslootInspectionItemResponse} from '@/factserver_api/purchase_api'

interface LoenderslootInspectionItemViewAttrs {
    loendersloot_inspection_item: GetLoenderslootInspectionItemResponse
}

export class LoenderslootInspectionItemView extends MithrilTsxComponent<LoenderslootInspectionItemViewAttrs> {
    view(vnode: m.Vnode<LoenderslootInspectionItemViewAttrs>) {
        const loendersloot_inspection_item = vnode.attrs.loendersloot_inspection_item
        return (
            <dl class="dl-horizontal">
                <dt>Description</dt>
                <dd>{loendersloot_inspection_item.description}</dd>
                <dt>Matched</dt>
                <dd>
                    {loendersloot_inspection_item.item_artkey && (
                        <Tippy content={'Matched purchase order item.'}>
                            <i className="fas fa-check-circle" />
                        </Tippy>
                    )}
                    {!loendersloot_inspection_item.item_artkey && loendersloot_inspection_item.bottle_artkey && (
                        <Tippy
                            content={'Matched product specs but we couldn\'t find the matching purchase order item.'}
                        >
                            <i className="fas fa-tasks" />
                        </Tippy>
                    )}
                    {!loendersloot_inspection_item.item_artkey && !loendersloot_inspection_item.bottle_artkey && (
                        <Tippy content={'We could not match the specs to a product and specs.'}>
                            <i className="glyphicon glyphicon-remove" />
                        </Tippy>
                    )}
                </dd>
                <dt>Lot</dt>
                <dd>{loendersloot_inspection_item.lot}</dd>
                <dt>Specs</dt>
                <dd>
                    {loendersloot_inspection_item.bottle_artkey && loendersloot_inspection_item.bottle_volume
                        ? to_specs_from_values(
                            loendersloot_inspection_item.bottle_volume.toString(),
                            loendersloot_inspection_item.bottle_refill_status,
                            loendersloot_inspection_item.bottle_alcohol_percentage,
                            $s.identity.user.decimal_locale,
                        )
                        : `${loendersloot_inspection_item.alcohol_percentage}%`}
                </dd>
                <dt>Btl / cs</dt>
                <dd>{loendersloot_inspection_item.bottles_per_case}</dd>
                <dt>Cases</dt>
                <dd>
                    {loendersloot_inspection_item.number_of_bottles / loendersloot_inspection_item.bottles_per_case}
                </dd>
                <dt>Bottles</dt>
                <dd>{loendersloot_inspection_item.number_of_bottles}</dd>
                <dt>Origin</dt>
                <dd>
                    <Country
                        country_code={loendersloot_inspection_item.country_of_origin}
                        tip='Country as found by Loendersloot'
                        type='flag_with_country'
                    />
                </dd>
                <dt>CPP / CPL</dt>
                <dd>{loendersloot_inspection_item.cases_per_pallet} cs / {loendersloot_inspection_item.cases_per_pallet_layer} cs</dd>
                <dt>Gift box</dt>
                <dd>{loendersloot_inspection_item.has_gift_box ? <i className="fas fa-check-circle" /> : <i className="glyphicon glyphicon-remove" />}</dd>
                <dt>BBD</dt>
                <dd>{loendersloot_inspection_item.best_before_date || '-'}</dd>
                <dt>Tags</dt>
                <dd><ul>{loendersloot_inspection_item.loendersloot_tags.map((loendersloot_tag) => <li>{loendersloot_tag}</li>)}</ul></dd>
            </dl>
        )
    }
}
