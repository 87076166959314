import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'

import {EntityType} from '@/market/pricelists/view/lib/context'
import {PricelistItem} from '@/market/pricelists/view/components/pricelist_item'
import {collection} from '@/market/pricelists/view/lib/collection_spli'

export class ContextSpli extends MithrilTsxComponent<any> {

    view(): m.Children {
        return <PricelistItem
            collection={collection}
            entity_type={EntityType.SPLI}
        />
    }
}
