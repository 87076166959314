/** llm:tested */
import m from 'mithril'
import {Button, ButtonGroup} from '@bitstillery/common/components'
import {MithrilTsxComponent} from 'mithril-tsx-component'

import inputs from '@/components/inputs'
import {ExploreData, SearchFilter} from '@/market/explore/models'
import {TaxLabelConstants} from '@/models/data'
import {EditableRelationList, RelationsSearch} from '@/components/relation'
import {AutocompleteInput} from '@/components/collection/autocomplete_input'
import {ProductManagementApi} from '@/factserver_api/product_management_api'

const page_sizes = [5, 15, 30, 50, 100]

interface SearchFieldsAttrs {
    search_filters: () => SearchFilter[]
    case: () => any
    unique_name: string
    from_date: any
    to_date: any
    month: any
    year: any
    explore_data: ExploreData
    reset: () => void
    update: () => void
}

export class SearchFields extends MithrilTsxComponent<SearchFieldsAttrs> {
    search_filters: () => SearchFilter[]
    product_name: any
    product_management_api: ProductManagementApi

    constructor(vnode: { attrs: SearchFieldsAttrs }) {
        super()
        this.search_filters = vnode.attrs.search_filters
        this.product_name = window.prop(this.search_filters()[0].product_name())
        this.product_management_api = new ProductManagementApi()
    }

    add_filter(index: number) {
        this.search_filters().splice(index + 1, 0, new SearchFilter())
    }

    remove_filter(index: number) {
        this.search_filters().splice(index, 1)
        if (this.search_filters().length === 0) {
            this.search_filters([new SearchFilter()])
        }
    }

    on_selected_product(selected_product: any, search_filter: SearchFilter) {
        if (!selected_product) {
            search_filter.product_name('')
            search_filter.product().artkey('')
            search_filter.bottle().volume('')
            search_filter.bottle().alcohol_percentage('')
            search_filter.bottle().refill_status('')
        } else {
            this.product_management_api.get_bottles_for_product(selected_product.artkey).subscribe({
                next: (bottles: any[]) => {
                    search_filter.product().name(selected_product.name)
                    search_filter.product().artkey(selected_product.artkey)
                    search_filter.volume_options([...new Set(bottles.map((bottle) => +bottle.volume))].sort((a, b) => a - b))
                    search_filter.alcohol_percentage_options([...new Set(bottles.map((bottle) => +bottle.alcohol_percentage))].sort((a, b) => a - b))
                    search_filter.refill_status_options([...new Set(bottles.map((bottle) => bottle.refill_status))].sort())
                    m.redraw()
                },
            })
        }
    }

    view(vnode:m.Vnode<SearchFieldsAttrs>) {
        return <div class="c-explore-search-fields" key={vnode.attrs.unique_name}>
            <div class="explore-search-filters">
                {vnode.attrs.search_filters().map((search_filter, index) => {
                    return <div class="c-filter-group product-filter-group" key={search_filter.artkey}>
                        <div class="filter-block fl-3">
                            <AutocompleteInput
                                label="Product"
                                placeholder="Select a product..."
                                on_get_suggestions$={(filter_text) => this.product_management_api.get_simple_products(filter_text)}
                                suggestion_as_label={(simple_product) => simple_product.name}
                                on_selected={(simple_product) => this.on_selected_product(simple_product, search_filter)}
                                default_text={vnode.attrs.search_filters()[index].product_name()}
                            />
                        </div>

                        <div class="filter-block fl-2">
                            {inputs.select(search_filter.bottle().volume, search_filter.volume_options().map((key) => [key, `${key.toFixed(1)} cl`]), {
                                label: 'Volume',
                                empty_option: true,
                                selected: search_filter.bottle().volume(),
                            })}

                            {inputs.select(search_filter.bottle().alcohol_percentage, search_filter.alcohol_percentage_options().map((key) => [key, `${key.toFixed(1)} %`]), {
                                empty_option: true,
                                label: 'Alcohol %',
                            })}

                            {inputs.select(search_filter.bottle().refill_status, search_filter.refill_status_options(), {
                                empty_option: true,
                                label: 'Refillable',
                            })}

                            {inputs.number(search_filter.case().number_of_bottles, {
                                label: 'Bottles / case',
                            })}

                            {inputs.gift_box_type(search_filter.case().gift_box_type, {
                                include_no_gift_box_type: true,
                                label: 'Giftbox',
                            })}

                            {inputs.tax_label(search_filter.case().tax_label, {
                                label: 'Tax Label',
                                no_option: TaxLabelConstants.NO_TAX_LABEL,
                            })}
                        </div>

                        <div class="filter-block fl-3">
                            <div class="relations">
                                <RelationsSearch
                                    label="Relation"
                                    selected_relations={vnode.attrs.search_filters()[index].selected_relations()}
                                    is_single_select={true}
                                    selected_relation_name={vnode.attrs.search_filters()[index].supplier().name()}
                                    search_bar_controller={() => {
                                        return // SBC goes unused
                                    }}
                                />
                                <EditableRelationList
                                    selected_relations={vnode.attrs.search_filters()[index].selected_relations()}
                                />
                            </div>
                        </div>

                        <div class="filter-block fl-1">
                            <div class="field">
                                <label></label>
                                <div class="actions-group">
                                    <ButtonGroup>
                                        <Button
                                            disabled={vnode.attrs.search_filters().length === 1}
                                            icon="minus"
                                            onclick={() => this.remove_filter(index)}
                                        />
                                        <Button
                                            icon="plus"
                                            onclick={() => this.add_filter(index)}
                                            type="info"
                                        />
                                    </ButtonGroup>
                                </div>
                            </div>
                        </div>
                    </div>
                })}
            </div>

            <form id="userform" class="c-filter-group">
                <div class="field">
                    <label>Date selection</label>
                    <ButtonGroup>
                        <Button
                            onclick={() => vnode.attrs.explore_data.filter.date_selection = 'all'}
                            text='All'
                            active={vnode.attrs.explore_data.filter.date_selection === 'all'}
                        />
                        <Button
                            onclick={() => vnode.attrs.explore_data.filter.date_selection = 'year'}
                            text='Year'
                            active={vnode.attrs.explore_data.filter.date_selection === 'year'}
                        />
                        <Button
                            onclick={() => vnode.attrs.explore_data.filter.date_selection = 'month'}
                            text='Month'
                            active={vnode.attrs.explore_data.filter.date_selection === 'month'}
                        />
                        <Button
                            onclick={() => vnode.attrs.explore_data.filter.date_selection = 'range'}
                            text='Range'
                            active={vnode.attrs.explore_data.filter.date_selection === 'range'}
                        />
                    </ButtonGroup>
                </div>

                {vnode.attrs.explore_data.filter.date_selection === 'range' ? [
                    <div class="field">
                        <label>From</label>
                        {inputs.date(vnode.attrs.from_date, {required: true})}
                    </div>,
                    <div class="field">
                        <label>To</label>
                        {inputs.date(vnode.attrs.to_date, {required: true})}
                    </div>,
                ] : vnode.attrs.explore_data.filter.date_selection === 'month' ? <div class="field">
                    <label>Select month</label>
                    {inputs.date_month(vnode.attrs.month, {required: true})}
                </div>
                    : vnode.attrs.explore_data.filter.date_selection === 'year' ? <div class="field">
                        <label>Select year</label>
                        {inputs.number(vnode.attrs.year, {required: true})}
                    </div>
                        : null}

                <div class="field">
                    <label>Account selection</label>
                    <ButtonGroup>
                        <Button
                            onclick={() => vnode.attrs.explore_data.filter.account_selection = ''}
                            text='All'
                            active={vnode.attrs.explore_data.filter.account_selection === ''}
                        />
                        <Button
                            onclick={() => vnode.attrs.explore_data.filter.account_selection = 'msi'}
                            text='MSI'
                            active={vnode.attrs.explore_data.filter.account_selection === 'msi'}
                        />
                        <Button
                            onclick={() => vnode.attrs.explore_data.filter.account_selection = 'msp'}
                            text='MSP'
                            active={vnode.attrs.explore_data.filter.account_selection === 'msp'}
                        />
                        <Button
                            onclick={() => vnode.attrs.explore_data.filter.account_selection = 'a2bc'}
                            text='A2BC'
                            active={vnode.attrs.explore_data.filter.account_selection === 'a2bc'}
                        />
                        <Button
                            onclick={() => vnode.attrs.explore_data.filter.account_selection = 'etr'}
                            text='ETR'
                            active={vnode.attrs.explore_data.filter.account_selection === 'etr'}
                        />
                    </ButtonGroup>
                </div>

                <div class="field">
                    <label>Limit</label>
                    <select
                        required={true}
                        onchange={(ev: any) => vnode.attrs.explore_data.filter.limit = +ev.target.value}
                    >
                        {page_sizes.map((value) =>
                            <option
                                value={value}
                                selected={value === vnode.attrs.explore_data.filter.limit}
                            >
                                {value}
                            </option>,
                        )}
                    </select>
                </div>
            </form>

            <div class="btn-toolbar">
                <ButtonGroup>
                    <Button
                        disabled={!vnode.attrs.search_filters().some((i) => (i.product().name() || i.supplier().artkey())) || vnode.attrs.search_filters().length > 1}
                        icon="filterRemove"
                        onclick={vnode.attrs.reset}
                        text="Reset Filters"
                    />
                    <Button
                        disabled={!vnode.attrs.search_filters().every((i) => (i.product().name() || i.selected_relations().length))}
                        icon="search"
                        onclick={() => {
                            vnode.attrs.explore_data.panels.purchase_history.collapsed = false
                            vnode.attrs.explore_data.panels.sales_history.collapsed = false
                            vnode.attrs.explore_data.panels.stock.collapsed = false
                            vnode.attrs.explore_data.panels.bottle_market.collapsed = false
                            vnode.attrs.explore_data.panels.offer_history.collapsed = false
                            vnode.attrs.explore_data.panels.price_list_history.collapsed = false
                            vnode.attrs.update()
                        }
                        }
                        text="Explore Products"
                        type="info"
                    />
                    <Button
                        disabled={!vnode.attrs.search_filters().every((i) => (i.product().name() || i.selected_relations().length))}
                        icon="search"
                        onclick={() => {
                            vnode.attrs.explore_data.panels.purchase_history.collapsed = true
                            vnode.attrs.explore_data.panels.sales_history.collapsed = true
                            vnode.attrs.explore_data.panels.stock.collapsed = true
                            vnode.attrs.explore_data.panels.bottle_market.collapsed = true
                            vnode.attrs.explore_data.panels.offer_history.collapsed = true
                            vnode.attrs.explore_data.panels.price_list_history.collapsed = true
                            vnode.attrs.update()
                        }
                        }
                        text="Explore Products and collapse"
                        type="info"
                    />
                </ButtonGroup>
            </div>
        </div>
    }
}
