import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {format_iso_to_date_time} from '@bitstillery/common/lib/format'
import {Spinner} from '@bitstillery/common/components'

import {CollectionTable, CollectionTableColumn, PagedCollectionFetcherWithGET} from './collection/collection_table'

import {CollectionLoenderslootMessageResponse} from '@/factserver_api/fact2server_api'
import {GetLoenderslootMessagesResponse} from '@/email/communication_api'
import {LoenderslootApi} from '@/factserver_api/loendersloot_api'

export interface EdiMessagesAttrs {
    sales_order_artkey: number | null
    purchase_order_artkey: number | null
    item_mutation_artkey: number | null
}

enum MessageType {
    OUTBOUND = 'outbound',
    RELEASE_OUTBOUND = 'release-outbound',
    PRE_ADVICE = 'preadvice',
    INBOUND = 'inbound',
    SENT_XML_SALES_ORDER = 'sent_xml_sales_order',
    SENT_XML_PURCHASE_ORDER = 'sent_xml_purchase_order',
    SENT_XML_ITEM_MUTATION = 'sent_xml_item_mutation',
    SENT_XML_PRE_ADVICE = 'sent_xml_pre_advice',
    SENT_XML_PRE_ADVICE_RELEASE = 'sent_pre_advice_release',
    SENT_XML_PRE_ADVICE_CANCEL = 'sent_pre_advice_cancel',
    SENT_XML_PRE_ADVICE_OUTBOUND = 'sent_pre_advice_outbound',
}

enum MessageStatus {
    PROCESSED = 'Processed',
    FAILED = 'Failed',
    RECEIVED = 'Received',
}

interface LoenderslootMessageRow extends CollectionLoenderslootMessageResponse {
    is_showing_details?: boolean
}

export class EdiMessages extends MithrilTsxComponent<EdiMessagesAttrs> {

    loendersloot_message_fetcher: PagedCollectionFetcherWithGET<CollectionLoenderslootMessageResponse>

    constructor(vnode: m.Vnode<EdiMessagesAttrs>) {
        super()
        this.loendersloot_message_fetcher = new PagedCollectionFetcherWithGET('discover/loendersloot-messages/collection-view', 'created_on')
        if (vnode.attrs.sales_order_artkey) {
            this.loendersloot_message_fetcher.filters = {
                sales_order_artkey: vnode.attrs.sales_order_artkey,
            }
        }
        if (vnode.attrs.purchase_order_artkey) {
            this.loendersloot_message_fetcher.filters = {
                purchase_order_artkey: vnode.attrs.purchase_order_artkey,
            }
        }
        if (vnode.attrs.item_mutation_artkey) {
            this.loendersloot_message_fetcher.filters = {
                item_mutation_artkey: vnode.attrs.item_mutation_artkey,
            }
        }
    }

    oncreate() {
        this.loendersloot_message_fetcher.fetch_next()
    }

    view(): m.Children {
        return <div classname="view-container">
            <CollectionTable<LoenderslootMessageRow, void>
                collection_fetcher={this.loendersloot_message_fetcher}
                on_row_click={(row: LoenderslootMessageRow) => (row.is_showing_details = !row.is_showing_details)}
                on_row_click_component={LoenderslootMessageDetails}
            >
                <CollectionTableColumn<LoenderslootMessageRow>
                    header_title={() => 'Created on'}
                    sort_name={'name'}
                    data_field={(row: LoenderslootMessageRow) => format_iso_to_date_time(row.created_on)}
                />
                <CollectionTableColumn<LoenderslootMessageRow>
                    header_title={() => 'Warehouse reference'}
                    sort_name={'shipment_id'}
                    data_field={(row: LoenderslootMessageRow) => row.shipment_id}
                />
                <CollectionTableColumn<LoenderslootMessageRow>
                    header_title={() => 'Message'}
                    data_field={(row: LoenderslootMessageRow) => {
                        if ([
                            MessageType.SENT_XML_SALES_ORDER.toString(),
                            MessageType.SENT_XML_PURCHASE_ORDER.toString(),
                            MessageType.SENT_XML_ITEM_MUTATION.toString(),
                        ].includes(row.message_type)) {
                            return [
                                <span className={'fas fa-arrow-right'}/>,
                                ' Sent to Loendersloot',
                            ]
                        } else if (row.message_type === MessageType.SENT_XML_PRE_ADVICE) {
                            return [
                                <span className={'fas fa-arrow-right'}/>,
                                ' Pre-advice to Loendersloot',
                            ]
                        } else if (row.message_type === MessageType.SENT_XML_PRE_ADVICE_CANCEL) {
                            return [
                                <span className={'fas fa-arrow-right'}/>,
                                ' Cancel pre-advice to Loendersloot',
                            ]
                        } else if (row.message_type === MessageType.SENT_XML_PRE_ADVICE_OUTBOUND) {
                            return [
                                <span className={'fas fa-arrow-right'}/>,
                                ' Outbound to Loendersloot',
                            ]
                        } else if (row.message_type === MessageType.SENT_XML_PRE_ADVICE_RELEASE) {
                            return [
                                <span className={'fas fa-arrow-right'}/>,
                                ' Release to Loendersloot',
                            ]
                        } else if (row.message_type === MessageType.OUTBOUND) {
                            return [
                                <span className={'fas fa-truck'}/>,
                                ' Outbound',
                            ]
                        } else if (row.message_type === MessageType.RELEASE_OUTBOUND) {
                            return [
                                <span className={'fas fa-house-user'}/>,
                                ' Release',
                            ]
                        } else if (row.message_type === MessageType.PRE_ADVICE) {
                            return [
                                <span className={'glyphicon glyphicon-sort'}/>,
                                ' Pre-advice',
                            ]
                        } else if (row.message_type === MessageType.INBOUND) {
                            return [
                                <span className={'glyphicon glyphicon-home'}/>,
                                ' Inbound',
                            ]
                        } else {
                            return row.message_type
                        }
                    }}/>
                <CollectionTableColumn<LoenderslootMessageRow>
                    header_title={() => 'Event'}
                    data_field={(row: LoenderslootMessageRow) => {
                        if (row.action_type && row.header_type) {
                            return `${row.header_type}: ${row.action_type}`
                        } else if (row.header_type) {
                            return `${row.header_type}`
                        } else {
                            `${row.header_type || ''}: ${row.action_type || ''}`
                        }
                    }
                    }/>
                <CollectionTableColumn<LoenderslootMessageRow>
                    header_title={() => 'Status'}
                    data_field={(row: LoenderslootMessageRow) => {
                        if (row.status === MessageStatus.PROCESSED) {
                            return [
                                <span className={'fas fa-check'} style={'color: green'}/>,
                                ' Processed',

                            ]
                        } else if (row.status === MessageStatus.FAILED) {
                            return [
                                <span className={'fas fa-cross'} style={'color: red'}/>,
                                ' Failed',

                            ]
                        } else if (row.status === MessageStatus.RECEIVED) {
                            return [
                                <span className={'fas fa-bars-progress'}/>,
                                ' In progress',

                            ]
                        } else {
                            row.status
                        }
                    }}/>
            </CollectionTable>
        </div>
    }

}

interface LoenderslootMessageDetailsAttrs {
    row: LoenderslootMessageRow
}

/** The row details component. Shows the Edit, Market and Hide information. */
export class LoenderslootMessageDetails extends MithrilTsxComponent<LoenderslootMessageDetailsAttrs> {
    selected_tab: 'market' | 'edit' | 'hide-information-item' = 'market'
    details: GetLoenderslootMessagesResponse | null = null
    is_loading = false
    loendersloot_api = new LoenderslootApi()

    fetch_details(loendersloot_message_artkey: number) {
        if (this.details) {
            return
        }
        this.is_loading = true
        this.loendersloot_api.get_loendersloot_message(loendersloot_message_artkey).subscribe({
            next: (response) => {
                this.details = response
                this.is_loading = false
                m.redraw()
            },
            error: () => {
                this.is_loading = false
                m.redraw()
            },
        })
    }

    view(vnode: m.Vnode<LoenderslootMessageDetailsAttrs>) {
        const loendersloot_message_row = vnode.attrs.row
        if (!loendersloot_message_row.is_showing_details) {
            return
        }
        this.fetch_details(vnode.attrs.row.artkey)
        return (
            <div className={'c-loendersloot-message-details'}>
                {this.is_loading && <Spinner/>}
                <label>Message contents</label>
                {!this.is_loading && <div>
                    <pre className={'raw-xml'}>{this.details?.raw_xml}</pre>
                </div>}
            </div>)
    }
}
