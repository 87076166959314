import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'

import {Collection} from '@/components/collection/collection'
import {EmailTable} from '@/email/components/email_table'

export interface PurchaseOrderEmailCorrespondenceAttrs {
    purchase_order: () => {
        artkey: () => string
    }
}

export class PurchaseOrderEmailCorrespondence extends MithrilTsxComponent<PurchaseOrderEmailCorrespondenceAttrs> {
    artkey: () => string
    emails: Collection

    constructor(vnode: m.Vnode<PurchaseOrderEmailCorrespondenceAttrs>) {
        super()
        this.artkey = window.prop(vnode.attrs.purchase_order().artkey())
        this.emails = new Collection({
            api_function_name: 'email.email.get',
            additional_params: () => ({purchase_order_artkey: this.artkey()}),
            query_limit: 25,
            default_sort_by: 'created_on',
            default_sort_order: 'desc',
        })
        this.emails.query()
    }

    oncreate() {
        this.emails.requery()
    }

    view() {
        return <EmailTable
            email_collection={this.emails}
        />
    }
}
