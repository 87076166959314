import m from 'mithril'
import {classes} from '@bitstillery/common/lib/utils'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {Icon} from '@bitstillery/common/components'
import {FieldText} from '@bitstillery/common/components'
import {FieldTextAttrs} from '@bitstillery/common/types/field'
import {modelref_adapter, modelref_assign} from '@bitstillery/common/lib/store'

import {FieldCurrency} from '../currency/currency'

interface FieldMoneyAttrs extends FieldTextAttrs {
    additional_class? :string
    change_currency?: boolean
    currency?: any
    icon?: string
    tabindex?: number
    precision?: number // default: 2
}

export class FieldMoney extends MithrilTsxComponent<FieldMoneyAttrs> {

    precision: number

    oninit(vnode: m.Vnode<FieldMoneyAttrs>) {
        this.precision = vnode.attrs.precision || 2
        const {model_value, model_ref} = modelref_adapter(vnode.attrs.model)
        // Make sure a set model value always starts with two decimals; e.g.
        // 144.00 instead of 144 & 144.20 instead of 144.2
        if (model_value) {
            modelref_assign(model_ref, String(Number(model_value).toFixed(this.precision)))
        }
    }

    format_value(value: string, from_navigation = false) {
        if (value === '') return ''
        // A comma becomes a decimal point
        value = value.replace(',', '.')
        // Non-numeric chars except decimal point and minus sign are removed
        value = value.replace(/[^\d.-]/g, '')
        // More than 1 points are not allowed
        const parts = value.split('.')
        if (parts.length > 2) {
            value = parts[0] + '.' + parts.slice(1).join('')
        }
        // Only a minus sign at the start is allowed
        if (value.indexOf('-') > 0) {
            value = value.replace(/-/g, '')
        } else if (value.split('-').length > 2) {
            value = '-' + value.replace(/-/g, '')
        }

        // More than 'this.precision' decimal places are not allowed (e.g. 54.546)
        if (value.includes('.') && value.split('.')[1].length > this.precision) {
            value = Number(value).toFixed(this.precision)
        }

        if (from_navigation) {
            // If there's exactly one decimal, add a trailing zero
            if (value.includes('.') && value.split('.')[1].length === 1) {
                value = value + '0'
            }
        }

        return value || null
    }

    view(vnode: m.Vnode<FieldMoneyAttrs>): m.Children {
        const validation = vnode.attrs.validation
        if (validation && vnode.attrs.label) {validation.description = vnode.attrs.label}
        const invalid = validation ? validation._invalid : false

        return <div className={classes('c-field-composed-money', 'field', 'no-click', vnode.attrs.className, {
            currency: vnode.attrs.currency,
            disabled: vnode.attrs.disabled,
            invalid: invalid && validation.dirty,
            valid: validation && !invalid && validation.dirty,
        })}>
            {vnode.attrs.label && <label>{vnode.attrs.label}
                {vnode.attrs.icon && <Icon name={vnode.attrs.icon}/>}
                {vnode.attrs.validation && <span className="validation">{validation.label}</span>}
            </label>}
            <div className="control">
                <FieldText
                    autofocus={vnode.attrs.autofocus}
                    composed={true}
                    formatter={(value: any) => {
                        return this.format_value(value)
                    }}
                    max={vnode.attrs.max}
                    min={vnode.attrs.min}
                    model={vnode.attrs.model}
                    onkeydown={(e) => {
                        const {model_value, model_ref} = modelref_adapter(vnode.attrs.model)
                        let step_size = 0.05
                        if (vnode.attrs.step && !isNaN(Number(vnode.attrs.step))) {
                            step_size = Number(vnode.attrs.step)
                        } else {
                            if (e.ctrlKey) {
                                if (e.shiftKey) {
                                    step_size = 10
                                } else {
                                    step_size = 0.1
                                }
                            } else if (e.shiftKey) {
                                step_size = 1
                            }
                        }

                        if (['ArrowDown', 'ArrowUp'].includes(e.key)) {
                            e.preventDefault()
                            let new_value
                            if (e.key === 'ArrowDown') {
                                new_value = String(parseFloat((Number(model_value) - step_size).toFixed(this.precision)))
                            } else if (e.key === 'ArrowUp') {
                                new_value = String(parseFloat((Number(model_value) + step_size).toFixed(this.precision)))
                            }

                            new_value = this.format_value(new_value, true)

                            if ('min' in vnode.attrs) {
                                if (Number(new_value) >= Number(vnode.attrs.min)) {
                                    modelref_assign(model_ref, new_value)
                                    if (vnode.attrs.oninput) {
                                        vnode.attrs.oninput(new_value)
                                    }
                                }
                            } else {
                                modelref_assign(model_ref, new_value)
                                if (vnode.attrs.oninput) {
                                    vnode.attrs.oninput(new_value)
                                }
                            }
                        }
                    }}
                    oninput={(value: any) => {
                        if (vnode.attrs.oninput) {
                            vnode.attrs.oninput(value)
                        }
                    }}
                    placeholder={vnode.attrs.placeholder}
                    tabindex={vnode.attrs.tabindex}
                />
                {(() => {
                    if (!vnode.attrs.currency) return null
                    const {model_value} = modelref_adapter(vnode.attrs.currency)
                    if (vnode.attrs.currency && !vnode.attrs.change_currency) {
                        return <span class="control-addon">{model_value}</span>
                    }

                    if (vnode.attrs.change_currency) {
                        return <FieldCurrency
                            className="change-currency"
                            disabled={vnode.attrs.disabled}
                            model={vnode.attrs.currency}
                        />
                    }
                })()}
            </div>
            {(() => {
                if (invalid && validation.dirty) return <div className="help validation">{typeof invalid.message === 'function' ? invalid.message() : invalid.message}</div>
                else if (vnode.attrs.help) return <div className="help">{vnode.attrs.help}</div>
            })()}
        </div>
    }
}
