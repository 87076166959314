import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {Button} from '@bitstillery/common/components'
import {format_iso_to_date, format_iso_to_relative} from '@bitstillery/common/lib/format'
import {
    CollectionHeader,
    CollectionItems,
    CollectionView,
    Icon,
    PanelFilters,
    RowActionDelete,
    RowActionDownload,
} from '@bitstillery/common/components'
import {CollectionProxy, CollectionTransforms} from '@bitstillery/common/lib/collection'
import {download_base64_file, url_query_string} from '@bitstillery/common/lib/utils'
import {api, notifier} from '@bitstillery/common/app'
import {watch} from '@bitstillery/common/lib/store'

import {context} from '@/market/pricelists/list/lib/context'
import {$s} from '@/app'
import {
    GetSupplierPriceListStatisticsResponse,
    SupplierPriceListCollectionResponse,
} from '@/factserver_api/fact2server_api'
import {EntityAlt} from '@/factserver_api/fact2server_api'

export const collection = new CollectionProxy()

const columns = [
    {
        name: 'Supplier',
        render: (row) => {
            return <div className="td-group">
                <span className="header">
                    {row.supplier_name}
                </span>
                <span className="details wrap">
                    {row.supplier_price_list_description}
                </span>
                <span className="details">
                    <div className="stats mt-05">
                        <div className="stat">
                            <Icon name="checked" type="unset" tip={() => {
                                return `Already ${row.statistics.total_items} items resolved to our products`
                            }}/>
                            {row.statistics.total_items}
                        </div>
                        <div className="stat">
                            <Icon name="question" type="unset" tip={() => {
                                return `${row.statistics.total_unresolved} items could not be resolved to known products`
                            }}/>
                            {row.statistics.total_unresolved}
                        </div>
                    </div>
                </span>
            </div>
        },
    }, {
        name: 'Created on',
        render: (row) => format_iso_to_relative(row.created_on),
    },
    {
        name: 'From / To',
        render: (row) => {
            const date_range = [format_iso_to_date(row.start_date)]
            if (row.end_date) {
                date_range.push(format_iso_to_date(row.end_date))
            } else {
                date_range.push('N/A')
            }
            return <span>
                {date_range.join(' - ')}
            </span>
        },
    }, {
        name: 'Pricelist manager',
        render: (row) => row.price_list_manager_name,
    },
]

export class CollectionSpl extends MithrilTsxComponent<any> {

    watchers: any[] = []

    oninit() {
        this.watchers.push(watch(context.data.stepper, 'selection', (new_step, old_step) => {
            // The CollectionItems was removed on step 2; the collection is still
            // there, but after removal, it must be reinitialized again.
            if (old_step === 2) {
                this.init_collection()
            }
        }))
        this.init_collection()
    }

    onremove() {
        this.watchers.forEach((unwatch) => unwatch())
    }

    init_collection() {
        collection.init({
            endpoint: {
                meta: true,
                method: 'get',
                path: 'discover/supplier-price-lists/collection-view',
            },
        }, undefined, {
            items_queried: async({result}) => {
                if (!result.length) {
                    return result
                }
                const spl_artkeys = result.map((i) => i.artkey)
                const query_param = spl_artkeys.join(',')
                const {result: statistics} = await api.get<GetSupplierPriceListStatisticsResponse[]>(`discover/supplier-price-lists/collection-view/statistics?spl_artkeys=${query_param}`)
                for (const spl of result) {
                    spl.statistics = statistics.find((stat) => stat.spl_artkey === spl.artkey)
                }
                return result
            },
        } as CollectionTransforms)
    }

    pricelist_status(row) {
        let status = {
            icon: 'checked',
            tip: 'This supplier price list is active',
            row_type: 'info',
            icon_type: 'success',
        }

        if (row.supplier_price_list_status === 'expired') {
            Object.assign(status, {icon: 'clock', row_type: 'warning', tip: 'Pricelist is past the end date'})
        } else if (row.supplier_price_list_status === 'not_yet_active') {
            Object.assign(status, {icon: 'clock', row_type: 'inactive', tip: 'Pricelist is not yet active'})
        } else if (row.supplier_price_list_status === 'active') {
            Object.assign(status, {icon: 'checkFilled', tip: 'Pricelist is active'})
        } else if (row.supplier_price_list_status === 'always_active') {
            Object.assign(status, {icon: 'infinity', tip: 'Pricelist is always active'})
        } else if (row.supplier_price_list_status === 'processing') {
            Object.assign(status, {icon: 'cog_clickwise', icon_type: 'inactive', row_type: 'inactive', tip: 'Pricelist is being processed'})
        } else if (row.supplier_price_list_status === 'failed_processing') {
            Object.assign(status, {icon: 'danger', icon_type: 'warning', row_type: 'warning', tip: 'Processing failed'})
        } else if (row.supplier_price_list_status === 'deleted') {
            Object.assign(status, {icon: 'trash', icon_type: 'warning', row_type: 'warning', tip: 'Pricelist was deleted'})
        }

        return status
    }

    view(_vnode: m.Vnode<any>) {
        return [
            <PanelFilters collection={collection} />,
            <CollectionView mode="table">
                <div className="btn-toolbar">
                    <Button
                        active={$s.env.uri === `/market/pricelists/list/manage/${EntityAlt.SPL}`}
                        icon="edit"
                        link={(() => {
                            if ($s.env.uri === `/market/pricelists/list/manage/${EntityAlt.SPL}`) {
                                return `/market/pricelists/list/${EntityAlt.SPL}${url_query_string()}`
                            }
                            return `/market/pricelists/list/manage/${EntityAlt.SPL}${url_query_string({step: '0'})}`
                        })()}
                        text="New Pricelist"
                        type="info"
                        variant="context"
                    />
                </div>
                <CollectionHeader collection={collection} columns={columns} />

                <CollectionItems
                    collection={collection}
                    columns={columns}
                    on_row_click={(row) => {
                        if (!row.failed_processing_message) {
                            m.route.set(`/market/pricelists/${row.artkey}/view/manage?meta=true`)
                        }
                    }}
                    row_actions={(row) => {
                        const inactive = row.supplier_price_list_status === 'deleted'
                        return [
                            <Button
                                active={context.data.entity_artkey === row.artkey}
                                icon="edit"
                                link={(() => {
                                    if (context.data.entity_type === EntityAlt.SPL && context.data.entity_artkey === row.artkey) {
                                        return `/market/pricelists/list/manage${url_query_string()}`
                                    }
                                    return `/market/pricelists/list/manage/${EntityAlt.SPL}/${row.artkey}${url_query_string({step: '0'})}`
                                })()}
                                tip={(() => {
                                    return 'Edit this pricelist'
                                })()}
                                type="info"
                                variant="toggle"
                            />,
                            row.file_name ? <RowActionDownload
                                row={row}
                                row_download={async() => {
                                    const {result, success} = await api.post('offerprocessing.get_supplier_price_list_file', {
                                        spl_artkey: row.artkey,
                                    }) as any
                                    if (!success) {
                                        notifier.notify(result.message, 'warning')
                                    } else {
                                        download_base64_file(result.file_base64_encoded, row.file_name)
                                    }
                                }}
                            /> : null,
                            <RowActionDelete
                                icon={inactive ? 'undo' : 'deactivate'}
                                needs_confirmation={false}
                                row={row}
                                row_delete={async() => {
                                    const {status_code} = inactive
                                        ? await api.post(`discover/supplier-price-lists/${row.artkey}/reactivate`, undefined, true)
                                        : await api.delete(`discover/supplier-price-lists/${row.artkey}`)

                                    if (status_code > 299) {
                                        notifier.notify('Could not reactivate pricelist', 'warning')
                                        return
                                    }
                                    notifier.notify(`Pricelist is ${inactive ? 're' : 'de'}activated`, 'info')
                                    row.supplier_price_list_status = inactive ? 'active' : 'deleted'
                                }}
                                tip={()=> {
                                    return row.supplier_price_list_status === 'deleted' ? 'Reactivate pricelist' : 'Deactivate pricelist'
                                }}
                            />,
                        ]
                    }}
                    row_detail={(row) => {
                        if (row.failed_processing_message) {
                            return <div className="pricelist-status">
                                {row.failed_processing_message}
                            </div>
                        }
                        return null
                    }}
                    row_status={(row: SupplierPriceListCollectionResponse) => {
                        const status = this.pricelist_status(row)
                        return {
                            render: [
                                <Icon
                                    name={status.icon}
                                    tip={() => {
                                        const status = this.pricelist_status(row)
                                        return status.tip
                                    }}
                                    type={status.icon_type}
                                />,
                                row.file_name ? <Icon
                                    name="excel"
                                    tip={() => {
                                        if (row.started_processing_on) {
                                            return `Processed: ${format_iso_to_relative(row.started_processing_on)}`
                                        }
                                        return `Ready for import: ${row.file_name}`
                                    }}
                                    type={row.started_processing_on ? 'success' : 'default'}
                                /> : null,
                            ],
                            type: status.row_type,
                        }
                    }}
                />
            </CollectionView>,
        ]
    }
}
