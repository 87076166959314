import m from 'mithril'
import {datetime_to_date, merge_deep, url_query_string} from '@bitstillery/common/lib/utils'
import {api, logger} from '@bitstillery/common/app'
import {
    conditional,
    required,
    validation,
} from '@bitstillery/common/lib/validation'
import {ContextProvider, ContextProviderDataGeneric} from '@bitstillery/common/lib/context'

import {collection} from '@/market/pricelists/list/lib/collection_spl'
import {$s} from '@/app'
import {EntityAlt} from '@/factserver_api/fact2server_api'

const data = {
    is_processing_pricelist: false,
    always_active: false,
    cache_key: '', // caches api calls that return the Excel rows from Swift
    candidates: {}, // resolve results in preview
    candidates_loaded: false, // blocks resolving when the candidates failed to load or are still loading
    column_mapping: {}, // the configuration of the column mapping
    column_render: [], // dynamically generated CollectionItems columns from config headers
    columns_preview: [],
    columns: [], // the type of column to choice from
    config_loaded: false, // SetupColumns depends on the config to be loaded
    entities: {
        [EntityAlt.SPL]: {
            default_currency: '',
            default_customs_status: '',
            default_incoterm: 'EXW',
            default_incoterm_location: '',
            description: '',
            end_date: '',
            meta: false,
            reference: '',
            started_processing_on: null, // this indicates that an import has already been started; from then on, no other import can be made
            start_date: new Date().toLocaleDateString('en-CA'),
            title: 'Pricelist',
        },
    },
    file_name: '',
    file: {
        content_type: '',
        data: '',
        name: '',
    },
    header: [],
    incoterm_location_options: [],
    relation_artkey: null,
    search_options: [],
    stepper: {
        _direction: () => $s.panels.context.collapsed ? 'vertical' : 'horizontal',
        loading: true,
        options: [
            {
                _disabled: false,
                icon: 'edit',
                link: () => `#!/market/pricelists/list/manage/${EntityAlt.SPL}/${context.data.entity_artkey}${url_query_string({step: '0'})}`,
                title: 'Fill Pricelist details',
            },
            {
                _disabled: () => {
                    if (context.data.entities[EntityAlt.SPL].started_processing_on) {
                        return true
                    }

                    const validation_fields = validation_steps[0]
                    const invalid_fields = validation_fields.map((i) => context.$v[EntityAlt.SPL][i]).filter((i) => i && i._invalid)
                    return invalid_fields.length
                },
                link: () => `#!/market/pricelists/list/manage/${EntityAlt.SPL}/${context.data.entity_artkey}${url_query_string({step: '1'})}`,
                icon: 'excel',
                title: 'Import from Excel',
            },
            {
                _disabled: () => {
                    if (context.data.entities[EntityAlt.SPL].started_processing_on) {
                        return true
                    }
                    const validation_fields = validation_steps[1]
                    const invalid_fields = validation_fields.map((i) => context.$v[EntityAlt.SPL][i]).filter((i) => i && i._invalid)
                    return invalid_fields.length
                },
                link: () => `#!/market/pricelists/list/manage/${EntityAlt.SPL}/${context.data.entity_artkey}${url_query_string({step: '2'})}`,
                icon: 'auto_fix',
                title: 'Adjust Column Setup',
            },
        ],
        selection: 0,
    },
    supplier_name: '',
    status: '',
    store_cpp_on_case: false,
    store_cpl_on_case: false,
} satisfies ContextProviderDataGeneric

// TODO: Fix stepper data typing.
export type ViewContextData = typeof data

export const context = new ContextProvider<ViewContextData>({
    data,
    onpageload: async() => {
        const params = m.route.param()
        if (params?.step) {
            context.data.stepper.selection = Number(params.step)
            logger.debug(`[${context.name}] restore step ${context.data.stepper.selection} from url`)
        }

        context.data.stepper.loading = false
    },
    name: 'market.pricelists.list',
    route: {
        match: '/market/pricelists/list/manage{/:entity_type}{/:entity_artkey}',
        root: '/market/pricelists/list/manage',
    },
    transforms: {
        bootstrap: async(context) => {
            context.data.stepper.loading = false
        },
        fetch_entity: async(context) => {
            const {result} = await api.get(`discover/supplier-price-lists/${context.data.entity_artkey}`) as any
            const entity = context.data.entities[EntityAlt.SPL]

            entity.always_active = !result.end_date
            merge_deep(context.data, {
                file: {
                    name: result.file_name,
                },
                file_name: result.file_name,
                started_processing_on: result.started_processing_on,
            })

            merge_deep(entity, result, {
                end_date: datetime_to_date(result.end_date),
                relation_artkey: result.supplier.artkey,
                supplier_name: result.supplier.name,
                start_date: datetime_to_date(result.start_date),
            })
            return {collection}
        },
        reset_entity: async() => {
            context.data.stepper.selection = 0
        },
    },
    validation: () => {
        return {
            [EntityAlt.SPL]:{
                default_currency: validation([context.data.entities[EntityAlt.SPL], 'default_currency'], required()),
                end_date: validation([context.data.entities[EntityAlt.SPL], 'end_date'], conditional(() => !context.data.entities[EntityAlt.SPL].always_active, required())),
                file: validation([context.data.file, 'name'], required()),
                default_incoterm: validation([context.data.entities[EntityAlt.SPL], 'default_incoterm'], required()),
                default_incoterm_location: validation([context.data.entities[EntityAlt.SPL], 'default_incoterm_location'], required()),
                start_date: validation([context.data.entities[EntityAlt.SPL], 'start_date'], required()),
                relation_artkey: validation([context.data.entities[EntityAlt.SPL], 'relation_artkey'], required()),
            },
        }
    },
})

export const mappings = {
    cpl: ['Cases per pallet layer', 'Bottles per pallet layer'],
    cpp: ['Cases per pallet', 'Bottles per pallet'],
    product: ['Magic product finder'],
}

export const methods = {
    /**
     * Determines whether a type of column is selected. This can't be
     * a column level validation, because one of the whole set of
     * columns must be selected.
     */
    column_is_mapped(column_type) {
        return mappings[column_type].some((column) => Object.values(context.data.column_mapping).includes(column))
    },
    column_is_invalid(column_index) {
        const column_selector = context.data.column_mapping[column_index]
        if (column_selector === '') {
            // Check for empty mapping
            return {message: 'Column not mapped'}
        }

        // Check for duplicates
        const selectors_mapped = Object.values(context.data.column_mapping).filter((i) => i === column_selector)
        if (selectors_mapped.length > 1) {
            // Aux info duplicates are unified in the backend.
            if (column_selector !== 'Aux info') {
                return {message: 'Duplicate column mapping'}
            } else {
                return false
            }
        }
        return false
    },
    columns_are_invalid() {
        const invalid_columns = [] as any
        for (const column_index of Object.keys(context.data.column_mapping)) {
            const column_invalid = methods.column_is_invalid(column_index)
            if (column_invalid) {
                invalid_columns.push(column_invalid)
            }
        }

        if (!methods.column_is_mapped('product')) return {
            message: 'Product column not mapped',
        }

        if (invalid_columns.length) {
            return invalid_columns
        }

        return false
    },
}

export const validation_steps = {
    0: ['default_currency', 'end_date', 'default_incoterm', 'default_incoterm_location', 'start_date', 'relation_artkey'],
    1: ['default_currency', 'end_date', 'default_incoterm', 'default_incoterm_location', 'start_date', 'relation_artkey', 'file'],
}
