import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {Button, Icon, Link, PanelContext} from '@bitstillery/common/components'
import {format_iso_to_date, format_money_responsive} from '@bitstillery/common/lib/format'
import {Amount} from '@bitstillery/common/components'
import {
    CellDestination,
    CellMargin,
    CollectionHeader,
    CollectionItems,
    CollectionView,
    PanelFilters,
} from '@bitstillery/common/components'
import {proxy} from '@bitstillery/common/lib/proxy'
import {CollectionProxy} from '@bitstillery/common/lib/collection'
import {api} from '@bitstillery/common/app'

import {context} from '@/sales/orders/list/context'
import {
    CombinedOrderStatus,
    GetSalesOrdersCollectionViewResponse as CollectionViewResponse,
    GetSalesOrdersCollectionViewResponse,
} from '@/factserver_api/fact2server_api'

export const collection = new CollectionProxy()

const columns = [
    {
        name: 'Sales Order',
        render: (row: CollectionViewResponse) => {
            return <div className="td-group">
                <div className="header">
                    {row.supplier_name}
                </div>
                {row.combined_status === CombinedOrderStatus.Invoiced ? [
                    <span className="details no-click">Invoiced: <Link href={`/sales/orders/${row.artkey}/view/invoice`} target="_blank">{format_iso_to_date(row.is_invoiced_on)}</Link></span>,
                    <span className="details no-click">Created: {format_iso_to_date(row.created_on)} ({row.reference})</span>,
                ] : <span className="details">
                    {format_iso_to_date(row.created_on)} ({row.reference})
                </span>}

                <span className="sub-details">
                    {row.sales_manager_name}
                </span>
            </div>
        },
        width: '2fr',
    },
    {
        name: 'Destination',
        render: (row: CollectionViewResponse) => {
            return <CellDestination row={row} />
        },
        width: '2fr',
    },
    {
        name: 'Cases',
        render: (row: CollectionViewResponse) => row.number_of_confirmed_cases,
    },
    {
        name: 'Margin',
        render: (row: CollectionViewResponse) => {
            return <div className="td-group">
                <div className="header">
                    {row.margin_percentage ? <CellMargin value={row.margin_percentage / 100}/> : null}
                </div>
                {row.margin && <span className="details">
                    <Amount
                        amount={row.margin}
                        currency={row.was_sold_in}
                    />
                </span>}
            </div>
        },
    },
    {
        name: 'Total value',
        render: (row: CollectionViewResponse) => {
            return <Amount
                amount={row.was_sold_for}
                currency={row.was_sold_in}
                rate={row.sold_against_rate}
            />
        },
    },
]

export class ListManage extends MithrilTsxComponent<unknown> {

    data = proxy({
        key_figures: {},
    })

    oninit() {
        collection.init({
            endpoint: {
                meta: true,
                method: 'get',
                path: 'discover/sales-orders/collection-view',
            },
        }, undefined, {
            items_queried: ({result}) => {
                this.fetch_key_figures()
                return result
            },
        })
    }

    async fetch_key_figures() {
        const filters_to_query = collection.transforms.filters_to_query(collection.filters)
        const {result} = await api.get(`discover/sales-orders/key-figures?search_terms=${filters_to_query.search_terms}&filters=${encodeURI(JSON.stringify(filters_to_query.filters))}`)
        Object.assign(this.data.key_figures, result)
    }

    sales_order_type(row: GetSalesOrdersCollectionViewResponse) {
        if ([
            CombinedOrderStatus.Finalized,
            CombinedOrderStatus.Saved,
            CombinedOrderStatus.Pending,
        ].includes(row.combined_status)) {
            return 'surface'
        }

        if (row.combined_status === CombinedOrderStatus.Confirmed) {
            return 'info'
        }

        if (row.combined_status === CombinedOrderStatus.Invoiced) {
            return 'success'
        }

        if (row.combined_status === CombinedOrderStatus.Cancelled) {
            return 'warning'
        }

        return 'default'
    }

    view(): m.Children {
        return <div className="c-list-manage view-container">
            <PanelFilters collection={collection}/>

            <CollectionView mode="table">
                <div className="btn-toolbar">
                    <Button
                        active={context.data.entity_type === 'statistics'}
                        icon='chartGraph'
                        link={context.data.entity_type === 'statistics' ? context.data.root_path : '/sales/orders/list/manage/statistics'}
                        replace_history={true}
                        text='Statistics'
                        type="info"
                        variant="context"
                    />
                </div>

                <CollectionHeader collection={collection} columns={columns} />
                <CollectionItems
                    collection={collection}
                    columns={columns}
                    on_row_click={(row: GetSalesOrdersCollectionViewResponse) => m.route.set(`/sales/orders/${row.artkey}/view/manage`, {meta: true})}
                    row_status={(row: GetSalesOrdersCollectionViewResponse) => {
                        const show_for_status = [CombinedOrderStatus.Saved, CombinedOrderStatus.Confirmed]
                        const all_in_stock = row.is_complete && show_for_status.includes(row.combined_status)
                        let context: string
                        if (show_for_status.includes(row.combined_status)) {
                            context = `${all_in_stock ? 'All' : 'Not all'} items are in stock or TBOs in an order`
                        } else {
                            context = 'Not applicable'
                        }

                        return {
                            render: [
                                <Icon
                                    context={`Sales order status: ${row.combined_status}`}
                                    name="sales"
                                    size="s"
                                    type={this.sales_order_type(row)}
                                />,
                                all_in_stock && <Icon
                                    context={context}
                                    name='stock'
                                    size='s'
                                    type='success'
                                />,
                                row.delivery_status && <Icon
                                    context={`Delivery status Green: ${row.delivery_status}`}
                                    size="s"
                                    name="logistics"
                                    type="success"
                                />,
                            ],
                            type: this.sales_order_type(row),
                        }

                    }}
                />
            </CollectionView>

            <PanelContext context={context}>
                <div className="content">
                    {(() => {
                        if (Object.keys(this.data.key_figures).length === 0) {
                            return null
                        }

                        return <div className="c-stats">
                            <div className="statistics-group vertical">
                                <div className="stat success">
                                    <div className="amount">
                                        <Icon name="invoice" type="unset" />
                                        {this.data.key_figures.invoiced_sales_orders}
                                    </div>
                                    <div className="text">Invoiced Orders</div>
                                </div>

                                <div className="stat info">
                                    <div className="amount">
                                        <Icon name="sales" type="unset" />
                                        {this.data.key_figures.confirmed_sales_orders + this.data.key_figures.in_progress_sales_orders}
                                    </div>
                                    <div className="text">Confirmed Orders</div>
                                </div>

                                <div className="stat default">
                                    <div className="amount">
                                        <Icon name="save" type="unset" />
                                        {this.data.key_figures.saved_sales_orders}
                                    </div>
                                    <div className="text">Saved & Finalized Orders</div>
                                </div>

                                <div className="stat warning">
                                    <div className="amount">
                                        <Icon name="refresh" type="unset" />
                                        {this.data.key_figures.pending_portal_orders}
                                    </div>
                                    <div className="text">Pending Orders (portal)</div>
                                </div>

                                <div className="stat danger">
                                    <div className="amount">
                                        <Icon name="warning" type="unset" />
                                        {this.data.key_figures.old_orders}
                                    </div>
                                    <div className="text">Orders Overdue (1 month)</div>
                                </div>
                            </div>
                            <div className="statistics-group vertical">
                                <div className="stat info">
                                    <div className="amount">
                                        <Icon name="bank_outline" type="unset" />
                                        {format_money_responsive(this.data.key_figures.total_value ?? 0, true, 'EUR')}
                                    </div>
                                    <div className="text">Order Value</div>
                                </div>
                                <div className="stat default">
                                    <div className="amount">
                                        <Icon name="schedule" type="unset" />
                                        {this.data.key_figures.average_order_age.toFixed(1)}
                                    </div>
                                    <div className="text">Average Order Age (days)</div>
                                </div>
                            </div>
                        </div>
                    })()}
                </div>
            </PanelContext>
        </div>
    }
}
