import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {
    Tab,
    TabPanel,
    Tabs,
} from '@bitstillery/common/components'
import {CollectionProxy} from '@bitstillery/common/lib/collection'
import {get_route} from '@bitstillery/common/lib/utils'
import {proxy} from '@bitstillery/common/lib/proxy'

import {ListManage} from '@/market/pricelists/list/manage'

export const collection = new CollectionProxy()

export const model = (() => {
    return {
        data: proxy({
            key_figures: {},
            tabs: {
                active: 'manage_pricelists',
            },
        }),
    }
})()

export class MarketPricelistsList extends MithrilTsxComponent<unknown> {

    view(): m.Children {
        return <Tabs
            className="c-market-pricelists-list market pricelists c-list"
            data={model.data}
            header={{
                icon: 'list',
                onclick: () => {
                    const route = get_route('/market/pricelists/list/manage', {meta: true})
                    m.route.set(route)
                },
                title: 'Manage Pricelists',
            }}
            type="nested-view"
        >
            <Tab
                icon="bulkActions"
                id="manage_pricelists"
                link={'/market/pricelists/list/manage'}
                route={'/market/pricelists/list/manage{/*path}'}
                text="List Pricelists"
            >
                <TabPanel>
                    <ListManage />
                </TabPanel>
            </Tab>
        </Tabs>
    }
}
