import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'
import {FieldSelect} from '@bitstillery/common/components'
import {FieldSelectAttrs} from '@bitstillery/common/types/field'

// Hardcoded list; reconsider when Portal & Discover can use a common endpoint.
const CURRENCY_OPTIONS = ['EUR', 'USD', 'GBP', 'JPY']

export class FieldCurrency extends MithrilTsxComponent<FieldSelectAttrs> {

    view(vnode: m.Vnode<FieldSelectAttrs>): m.Children {
        return <FieldSelect
            className="c-field-composed-currency"
            disabled={vnode.attrs.disabled}
            label={vnode.attrs.label}
            model={vnode.attrs.model}
            options={CURRENCY_OPTIONS.map((currency) => ({value: currency, label: currency}))}
            validation={vnode.attrs.validation}
        />
    }
}
