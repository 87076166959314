import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {format_iso_to_date} from '@bitstillery/common/lib/format'
import {countries} from '@bitstillery/common/lib/countries'
import {DateTime} from 'luxon'
import {to_specs_with_features} from '@bitstillery/common/models/item'
import {PDF} from '@bitstillery/common/pdf/pdf'
import {Button, Spinner} from '@bitstillery/common/components'
import {notifier} from '@bitstillery/common/app'

import {PDFCommentTemplate, PDFComponent, PDFHelper, PDFUpdateAndDownload} from '../components/pdf_helper'

import {$s} from '@/app'
import {GetItemMutationByReferenceResponse, StockApi} from '@/factserver_api/stock_api'
import {Account} from '@/factserver_api/relation_api'

export interface OuttakeInstructionRendererAttrs {
    mutation: GetItemMutationByReferenceResponse
    account: Account
}

/**
 * Maps the OuttakeInstructionRendererAttrs to base64 encoded string containing the OuttakeInstruction.
 *
 * @param attrs Needed data for the pdf (purchase order)
 * @returns Base64 encoded string for the created PDF.
 */
function render_to_base64(attrs: OuttakeInstructionRendererAttrs) {
    const pdf = new PDF(attrs.account)
    const doc = pdf.document

    doc.setProperties({
        title: `Outtake Instruction ${attrs.mutation.reference}`,
        author: attrs.account.name,
        keywords: 'Mutation, Stock',
        creator: attrs.account.name,
        subject: `Outtake Instruction ${attrs.mutation.reference}`,
    })

    pdf.title('Outtake Instruction')
    pdf.relation_address([attrs.account.name])

    if (attrs.mutation.target_warehouse) {
        const destination_address = [
            attrs.mutation.target_warehouse.name,
            attrs.mutation.target_warehouse.street_address,
            `${attrs.mutation.target_warehouse.zip_code} ${attrs.mutation.target_warehouse.city}`,
            countries[attrs.mutation.target_warehouse.country_code],
        ]
        destination_address.push(`Location ID: ${attrs.mutation.target_warehouse.warehouse_id}`)
        if (attrs.mutation.target_warehouse.excise_id) {
            destination_address.push(`Excise ID: ${attrs.mutation.target_warehouse.excise_id}`)
        }
        destination_address.push('')
        pdf.destination_address(destination_address)
    }
    pdf.details(
        'Details:',
        ['Reference:', 'Date:'],
        [attrs.mutation.reference, format_iso_to_date(DateTime.now().toISODate())],
    )

    const items = attrs.mutation.parts
        .sort((source_a, source_b) => {
            return source_a.sources[0].case.bottle.product.name > source_b.sources[0].case.bottle.product.name ? 1 : -1
        })
        .map((part) => {
            const number_of_cases_in_mutation = part.number_of_cases_in_mutation
            return part.sources.map((item) => {
                // Fallback to number of cases in stock.
                const nr_of_cases = number_of_cases_in_mutation || item.number_of_cases_in_stock
                const items = [
                    pdf.text_cell(item.lot || ''),
                    pdf.text_cell(`${nr_of_cases} cs.`),
                    pdf.text_cell(item.case.bottle.product.name),
                    pdf.text_cell(to_specs_with_features(item.case, item.case.bottle, $s.identity.user.decimal_locale)),
                    pdf.text_cell(item.case.customs_status),
                    pdf.text_cell(item.case.bottle.loendersloot_id),
                ]
                return items
            })
        })
        .flat()
    const headers = ['Lot No.', 'Quantity', 'Description', '', 'Customs', 'Prod. Id']
    const main_table = pdf.auto_table(attrs.account, 'en', headers, items)

    /* Total quantity */
    const total_quantity_in_cases = attrs.mutation.parts.reduce(
        (previous, current) => (current.number_of_cases_in_mutation || 0) + previous,
        0,
    )
    const total_quantity = `${total_quantity_in_cases} cs.`
    const quantity_table = pdf.total_quantity(pdf.text_cell(`${total_quantity}`), main_table, 1)

    /* Render the comments, if any. */
    if (attrs.mutation.outtake_instruction_comment) {
        pdf.additional_string_table(
            quantity_table,
            ['Comments:'],
            attrs.mutation.outtake_instruction_comment.split('\n'),
        )
    }

    return doc
}

export default class MutationOuttakeInstruction extends MithrilTsxComponent<unknown> {
    item_mutation_reference: string
    stock_api = new StockApi()
    item_mutation: GetItemMutationByReferenceResponse | null = null
    pdf_helper = new PDFHelper<OuttakeInstructionRendererAttrs>(render_to_base64)
    selected_comment_artkey: string | null = null

    constructor() {
        super()
        if (!m.route.param('reference')) {
            m.route.set('/stock/mutations/manage')
            throw Error('No mutation reference in route.')
        } else {
            this.item_mutation_reference = m.route.param('reference')
            this.fetch_item_mutation()
        }
    }

    fetch_item_mutation(): void {
        this.stock_api.get_item_mutation_by_reference(this.item_mutation_reference).subscribe({
            next: (response: GetItemMutationByReferenceResponse) => {
                this.item_mutation = response
                this.rerender_pdf(false)
                m.redraw()
            },
            error: () => {
                notifier.notify(`Cannot load mutation with reference ${this.item_mutation_reference}`, 'warning')
                m.route.set('/stock/mutations/manage')
            },
        })
    }

    /** Rerender the pdf for this item mutation. Any pending changes concerning the pdf will be saved. */
    rerender_pdf(save_settings = true): void {
        const account = this.pdf_helper.current_account()
        if (!this.item_mutation || !account) {
            return
        }
        if (save_settings) {
            this.save_pdf_item_mutation()
        }
        this.pdf_helper.render_base64_encoded({
            mutation: this.item_mutation,
            account: account,
        })
    }

    save_pdf_item_mutation(): void {
        if (!this.item_mutation) {
            return
        }
        this.stock_api
            .update_item_mutation_outtake_instruction_comment(
                this.item_mutation.artkey,
                this.item_mutation.outtake_instruction_comment || '',
            )
            .subscribe({
                next: () => notifier.notify('Settings for RFP saved', 'success'),
                error: () => notifier.notify('Could not save the settings for the RFP', 'warning'),
            })
    }

    view(): m.Children {
        if (!this.item_mutation) return <Spinner/>
        return (
            <div className="c-outtake-instruction view pdf-view">
                <div className="btn-toolbar">
                    <Button
                        active={false}
                        className="btn-back"
                        icon="back"
                        onclick={() => m.route.set(`/stock/mutations/manage/${this.item_mutation_reference}`)}
                        variant="toggle"
                    />
                </div>
                <h2>Outtake Instruction {this.item_mutation_reference}</h2>

                <div className="pdf-wrapper">
                    <form className="flex-form">
                        <div className="fieldset">

                            <PDFCommentTemplate
                                value={this.item_mutation.outtake_instruction_comment}
                                onchange={(value: string) => {
                                    if (this.item_mutation) {
                                        this.item_mutation.outtake_instruction_comment = value
                                        this.rerender_pdf(true)
                                    }
                                }}
                            />

                            <PDFUpdateAndDownload
                                rerender_pdf={() => this.rerender_pdf()}
                                pdf_file_name={`Outtake Instruction - ${
                                    this.item_mutation?.reference || ''
                                }.pdf`}
                                pdf_helper={this.pdf_helper}
                            />
                        </div>
                    </form>
                    <PDFComponent pdf_helper={this.pdf_helper} />
                </div>
            </div>
        )
    }
}
