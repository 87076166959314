import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {DateTime, Duration} from 'luxon'
import {notifier} from '@bitstillery/common/app'
import {FieldSelect, Spinner} from '@bitstillery/common/components'

import {Modal} from '@/components/modal/modal'
import {download_binary_file_from_base64_str} from '@/_utils'
import {InputDate} from '@/components/html_components'
import {DefaultButton, SuccessButton} from '@/components/buttons'
import {
    GetStatusLogsRequest,
    RangeRequest,
    StandardUserWeek,
    UpdateCreateStatusLogRequest,
    user_schedule_options,
    UserManagementApi,
} from '@/factserver_api/user_management_api'
import {LoggedInUserData, User} from '@/factserver_api/user_api'

enum date_scopes {
    DAY,
    WORK_WEEK,
    MONTH,
}

interface DateScopeFunctions {
    start_date: (date: DateTime) => DateTime
    display_duration: Duration
    scroll_btn_title_format: string
    actual_duration?: Duration
}

const scope_functions: Record<date_scopes, DateScopeFunctions> = {
    [date_scopes.DAY]: {
        start_date: (date: DateTime) => date.startOf('day'),
        display_duration: Duration.fromISO('P1D'),
        scroll_btn_title_format: 'ccc d',
    },
    [date_scopes.WORK_WEEK]: {
        start_date: (date: DateTime) => date.startOf('week'),
        display_duration: Duration.fromISO('P5D'),
        scroll_btn_title_format: 'week W',
        actual_duration: Duration.fromISO('P7D'),
    },
    [date_scopes.MONTH]: {
        start_date: (date: DateTime) => date.startOf('month'),
        display_duration: Duration.fromISO('P1M'),
        scroll_btn_title_format: 'LLL',
    },
}

export class StatusDetailed extends MithrilTsxComponent<unknown> {
    _check_date_in_range(date: DateTime, start_range: DateTime, end_range: DateTime): boolean {
        return date >= start_range && date <= end_range
    }

    _check_if_in_quarter(log_date: DateTime): boolean {
        return Math.ceil(log_date.month / 3) === Math.ceil(DateTime.now().month / 3)
    }

    _get_actual_duration(): Duration {
        // @ts-ignore - es-lint does not recognize that actual_duration cannot be returned if it is undefined
        return scope_functions[this.date_scope].actual_duration !== undefined
            ? scope_functions[this.date_scope].actual_duration
            : scope_functions[this.date_scope].display_duration
    }

    api = new UserManagementApi()
    logged_in_user: User | null = null
    schedule_view = null
    show_status_edit_modal = false
    show_extract_modal = false
    scheduled_vacations: { user_artkey: number; start_date: string; end_date: string }[] | null = null
    scheduled_trips: { user_artkey: number; start_date: string; end_date: string }[] | null = null
    user_schedules: { [user_artkey: number]: { name: string; date_settings: StandardUserWeek } } = {}
    status_logs: { [user_artkey: number]: { [date: string]: { status: string; artkey: number } } } | null = null
    groups = new vis.DataSet()
    current_items = new vis.DataSet()
    item_to_update: any
    is_loading_standard_schedule = false
    is_loading_vacations = false
    is_loading_trips = false
    is_loading_logs = false
    is_loading_holidays = false
    is_loading_extract = false
    is_timeline_drawing = false

    update_or_create_status_request: UpdateCreateStatusLogRequest = {
        user_artkey: 0,
        status: '',
        date: '',
    }

    dutch_holidays: { [date: string]: string } = {}
    display_dt: DateTime = DateTime.now().startOf('day')

    // Get date scope (number) for url, else use work week
    date_scope: date_scopes = date_scopes.WORK_WEEK

    download_extract_request: RangeRequest = {
        start_date: '',
        end_date: '',
    }

    constructor() {
        super()

        this.date_scope = date_scopes[parseInt(m.route.param('scope'))] !== undefined
            ? parseInt(m.route.param('scope'))
            : date_scopes.WORK_WEEK
        // sometimes the viz renderer cannot render months on reload
        if (this.date_scope === date_scopes.MONTH) {
            this.change_date_scope(date_scopes.WORK_WEEK)
        }

        const date_range = this.gen_date_range_on_date_scope()
        this.query_logs_in_range(date_range, true)
    }

    add_group_highlight(): void {
        if (this.schedule_view === null) {
            return
        }

        this.schedule_view.on('mouseOver', (properties) => {
            if (properties.group === null) {
                return
            }

            this.groups.forEach((group:any) => {
                if (group.id === properties.group) {
                    if (!group.className) {
                        this.groups.update({id: group.id, className: 'group-highlight'})
                    } else if (!group.className?.includes('group-highlight')) {
                        group.className = group.className.trim() + 'group-highlight'
                        this.groups.update({id: group.id, className: group.className})
                    }
                } else if (group.id !== properties.group) {
                    if (group.className?.includes('group-highlight')) {
                        this.groups.update({
                            id: group.id,
                            className: group.className?.replace('group-highlight', ' '),
                        })
                    }
                }
            })
        })
    }

    add_manual_status_log_update(): void {
        if (this.schedule_view === null) {
            return
        }

        this.schedule_view.on('click', (properties) => {
            if (properties.item === null) {
                return
            }

            if (
                properties.event.target.className === 'vis-item-overflow' ||
                properties.event.target.className === 'vis-item-content'
            ) {
                this.item_to_update = this.current_items.get(properties.item)
                if (
                    !this.logged_in_user?.is_superuser &&
                    LoggedInUserData.instance().user_artkey !== parseInt(properties.group)
                )
                    return

                const log_date = DateTime.fromJSDate(this.item_to_update.start)
                if (
                    !this._check_if_in_quarter(log_date) &&
                    DateTime.now() > log_date &&
                    !this.logged_in_user?.is_superuser
                ) {
                    notifier.notify('You cannot alter logs from a previous quarter', 'warning')
                    return
                }
                if (this.item_to_update.content === 'Vacation') return
                if (this.item_to_update.content === 'Abroad') return

                this.update_or_create_status_request.date = log_date.toISODate()
                this.update_or_create_status_request.user_artkey = parseInt(properties.group)
                this.update_or_create_status_request.artkey = this.item_to_update.artkey
                this.show_status_edit_modal = true
                m.redraw()
            }
        })
    }

    add_redirect_to_user_details_on_label(): void {
        if (this.schedule_view === null) {
            return
        }

        this.schedule_view.on('click', (properties) => {
            if (properties.group === null) {
                return
            }

            if (properties.event.target.className === 'vis-inner') {
                m.route.set('/data/user-manage/'.concat(properties.group).concat('/edit'))
            }
        })
    }

    advance_schedule_date(): void {
        this.display_dt = this.display_dt.plus(this._get_actual_duration())
        this.query_logs_in_range(this.gen_date_range_on_date_scope())
    }

    change_date_scope(date_scope: date_scopes): void {
        this.date_scope = date_scope
        m.route.set('/my-discover/colleagues?scope=' + date_scope.toString())
        this.query_logs_in_range(this.gen_date_range_on_date_scope())
    }

    check_date_during_user_trip(date: DateTime, user_artkey: number): boolean {
        let during_business_trip = false
        this.scheduled_trips?.forEach((trip) => {
            if (trip.user_artkey !== user_artkey) return

            const start = DateTime.fromISO(trip.start_date)
            const end = DateTime.fromISO(trip.end_date)

            if (this._check_date_in_range(date, start, end)) {
                during_business_trip = true
                return // Exit foreach if date in trip
            }
        })
        return during_business_trip
    }

    check_date_free_holiday(dt: DateTime): boolean {
        return this.dutch_holidays[dt.toISODate()] !== undefined
    }

    check_date_in_user_vacation(date: DateTime, user_artkey: number): boolean {
        let in_vacation = false
        this.scheduled_vacations?.forEach((vacation) => {
            if (vacation.user_artkey !== user_artkey) return

            const start = DateTime.fromISO(vacation.start_date)
            const end = DateTime.fromISO(vacation.end_date)

            if (this._check_date_in_range(date, start, end)) {
                in_vacation = true
                return // Exit foreach if date in vacation
            }
        })
        return in_vacation
    }

    close_edit_status_modal(): void {
        this.show_status_edit_modal = false
        this.item_to_update = undefined
        this.update_or_create_status_request.artkey = undefined
        this.update_or_create_status_request.user_artkey = 0
        this.update_or_create_status_request.date = ''
        this.update_or_create_status_request.status = ''
        m.redraw()
    }

    close_extract_modal(): void {
        this.show_extract_modal = false
        this.download_extract_request.start_date = ''
        this.download_extract_request.end_date = ''
    }

    download_user_schedule_extract(): void {
        this.is_loading_extract = true
        this.api.download_user_schedules_extract(this.download_extract_request).subscribe({
            next: (response) => {
                download_binary_file_from_base64_str(
                    response['file_base64_encoded'],
                    `User schedules ${this.download_extract_request.start_date} ${this.download_extract_request.end_date}.xlsx`,
                )
                this.download_extract_request = {start_date: '', end_date: ''}
                this.is_loading_extract = false
                this.show_extract_modal = false
                m.redraw()
            },
            error: () => {
                notifier.notify('Unable to extract the user schedules, please try again', 'danger')
                this.download_extract_request = {start_date: '', end_date: ''}
                this.is_loading_extract = false
            },
        })
    }

    gen_date_range_on_date_scope(): DateTime[] {
        const dates: DateTime[] = []
        const start_date = scope_functions[this.date_scope].start_date(this.display_dt)
        const end_date = start_date.plus(scope_functions[this.date_scope].display_duration)
        const number_of_days = end_date.diff(start_date, ['days']).days

        for (let index = 0; index < number_of_days; index++) {
            dates.push(start_date.plus({days: index}))
        }

        return dates
    }

    gen_schedule_group_list() {
        const group_list: any[] = []

        for (const user_artkey in this.user_schedules) {
            const user_schedule: { name: string; date_settings: StandardUserWeek } = this.user_schedules[user_artkey]

            group_list.push({
                id: user_artkey,
                content: user_schedule.name,
                style: 'vertical-align: middle',
            })
        }

        return group_list
    }

    gen_schedule_items_list(schedule_dates): any[] {
        const schedule_items_list:any[] = []

        const disp_year = this.display_dt.year
        const disp_start = scope_functions[this.date_scope].start_date(this.display_dt)
        const disp_end = disp_start.plus(scope_functions[this.date_scope].display_duration)
        Object.keys(this.dutch_holidays).forEach((date_str) => {
            const name = this.dutch_holidays[date_str]
            const date = DateTime.fromISO(date_str)
            if (!this._check_date_in_range(date, disp_start, disp_end)) return // Holiday not in date range
            schedule_items_list.push({
                id: name + disp_year.toString(),
                start: date_str,
                end: date.plus({days: 1}).toISODate(),
                content: name,
                type: 'background',
                className: `holiday ${name.toLowerCase().replace(' ', '-').replaceAll('.', '')}`,
            })
        })

        this.scheduled_vacations?.forEach((vacation, index) => {
            const start_date = DateTime.fromISO(vacation.start_date)
            const end_date = DateTime.fromISO(vacation.end_date)

            schedule_items_list.push({
                id: 'vacation' + index.toString(),
                group: vacation.user_artkey,
                start: start_date.toJSDate(),
                end: end_date.plus({days: 1}).toJSDate(),
                editable: false,
                selectable: false,
                content: 'Vacation',
                className: 'vacation',
            })
        })

        this.scheduled_trips?.forEach((trip, index) => {
            const start_date = DateTime.fromISO(trip.start_date)
            const end_date = DateTime.fromISO(trip.end_date)

            schedule_items_list.push({
                id: 'bt' + index.toString(),
                group: trip.user_artkey,
                start: start_date.toJSDate(),
                end: end_date.plus({days: 1}).toJSDate(),
                editable: false,
                selectable: false,
                content: 'Abroad',
                className: 'abroad',
            })
        })
        schedule_dates.forEach((date: DateTime, index: number) => {
            if (this.check_date_free_holiday(date)) return

            for (const user_artkey in this.user_schedules) {
                const user_schedule = this.user_schedules[user_artkey]

                if (this.check_date_in_user_vacation(date, parseInt(user_artkey))) continue
                if (this.check_date_during_user_trip(date, parseInt(user_artkey))) continue

                const weekday_str = date.toFormat('cccc').toLowerCase()
                // @ts-ignore
                let standard_schedule_setting = user_schedule.date_settings[`schedule_${weekday_str}`]

                // Replace the undefined value of the weekends with a null
                standard_schedule_setting = standard_schedule_setting === undefined ? null : standard_schedule_setting
                let log_artkey, schedule_option, standard_schedule_bool
                if (
                    this.status_logs &&
                    this.status_logs[user_artkey] &&
                    this.status_logs[user_artkey][date.toISODate()]
                ) {
                    const status_log = this.status_logs[user_artkey][date.toISODate()]
                    const schedule_option_str = status_log.status
                    schedule_option = user_schedule_options[schedule_option_str]
                    if (standard_schedule_setting !== null) {
                        // Check whether the status log is inline with the standard schedule
                        standard_schedule_bool = schedule_option_str === standard_schedule_setting
                        log_artkey = status_log.artkey
                    }
                } else if (standard_schedule_setting !== null) {
                    schedule_option = user_schedule_options[standard_schedule_setting]
                    standard_schedule_bool = true
                } else {
                    continue
                }

                schedule_items_list.push({
                    id: user_schedule.name + index.toString(),
                    group: user_artkey,
                    start: date.toJSDate(),
                    end: date.plus({days: 1}).toJSDate(),
                    editable: false,
                    selectable: false,
                    content: standard_schedule_bool ? schedule_option.title : String(schedule_option.title) + '*',
                    className: schedule_option.title.toLowerCase(),
                    artkey: log_artkey,
                })
            }
        })

        return schedule_items_list
    }

    gen_schedule_view(schedule_dates: DateTime[]): void {
        if (Object.getOwnPropertyNames(this.user_schedules).length === 0) return
        this.is_timeline_drawing = true
        const schedule_container = document.getElementById('schedule_container')
        const schedule_options = {
            zoomable: false,
            stack: false,
            groupOrder: 'content',
            horizontalScroll: false,
            start: schedule_dates[0].toJSDate(),
            min: schedule_dates[0].toJSDate(),
            max: schedule_dates[schedule_dates.length - 1].plus({days: 1}).toJSDate(),
            onInitialDrawComplete: () => {
                this.is_timeline_drawing = false
                m.redraw()
            },
        }

        this.groups = new vis.DataSet(this.gen_schedule_group_list())
        this.current_items = new vis.DataSet(this.gen_schedule_items_list(schedule_dates))

        if (schedule_container !== null) {
            this.schedule_view = new vis.Timeline(schedule_container, this.current_items, this.groups, schedule_options)
        }

        this.add_manual_status_log_update()
        this.add_redirect_to_user_details_on_label()
        this.add_group_highlight()
    }

    get_dutch_holidays(schedule_dates: DateTime[]): void {
        this.is_loading_holidays = true
        this.api.get_dutch_holidays(this.display_dt.toISODate()).subscribe({
            next: (response) => {
                this.dutch_holidays = response['holidays']
                this.is_loading_holidays = false
                this.update_or_create_schedule_view(schedule_dates)
            },
            error: () => {
                notifier.notify('Unable to load dutch holidays, the displayed schedule might be incorrect', 'danger')
                this.is_loading_holidays = false
                this.update_or_create_schedule_view(schedule_dates)
            },
        })
    }

    get_schedule_scroll_btn_title(dt: DateTime): string {
        const btn_title = dt.toFormat(scope_functions[this.date_scope].scroll_btn_title_format)
        return ` ${btn_title} `
    }

    oncreate(): void {
        LoggedInUserData.user().subscribe({
            next: (value) => {
                this.logged_in_user = value
            },
        })
    }

    query_business_trips_in_range(schedule_dates: DateTime[]): void {
        const request: RangeRequest = {
            start_date: schedule_dates[0].toISODate(),
            end_date: schedule_dates[schedule_dates.length - 1].toISODate(),
        }

        this.is_loading_trips = true
        this.api.get_business_trips_in_range(request).subscribe({
            next: (response) => {
                this.scheduled_trips = response.ranges
                this.is_loading_trips = false
                this.update_or_create_schedule_view(schedule_dates)
            },
            error: () => {
                notifier.notify('Unable to load scheduled trips, showing just the standard schedules', 'danger')
                this.update_or_create_schedule_view(schedule_dates)
                this.is_loading_trips = false
            },
        })
    }

    query_logs_in_range(date_range: DateTime[], include_standard_schedule = false): void {
        if (include_standard_schedule) {
            this.query_standard_schedule(date_range)
        }
        this.get_dutch_holidays(date_range)
        this.query_vacations_in_range(date_range)
        this.query_business_trips_in_range(date_range)
        this.query_status_logs(date_range)
    }

    query_standard_schedule(schedule_dates: DateTime[]): void {
        this.is_loading_standard_schedule = true
        this.api.get_standard_user_schedules().subscribe({
            next: (response) => {
                this.user_schedules = response.user_schedules
                this.is_loading_standard_schedule = false
                this.update_or_create_schedule_view(schedule_dates)
                m.redraw()
            },
            error: () => {
                notifier.notify('Unable to load the standard schedules, please refresh this page and try again', 'danger')
                this.is_loading_standard_schedule = false
                m.redraw()
            },
        })
    }

    query_status_logs(schedule_dates: DateTime[]): void {
        const logs_request: GetStatusLogsRequest = {
            start_date: schedule_dates[0].toISODate(),
            end_date: schedule_dates[schedule_dates.length - 1].plus({days: 1}).toISODate(),
        }

        this.is_loading_logs = true
        this.api.get_schedule_status_logs_range(logs_request).subscribe({
            next: (response) => {
                this.status_logs = response.status_logs
                this.is_loading_logs = false
                this.update_or_create_schedule_view(schedule_dates)
            },
            error: () => {
                notifier.notify('Unable to load status updates, showing just the standard schedules', 'danger')
                this.is_loading_logs = false
                this.update_or_create_schedule_view(schedule_dates)
            },
        })
    }

    query_vacations_in_range(schedule_dates: DateTime[]): void {
        const request: RangeRequest = {
            start_date: schedule_dates[0].toISODate(),
            end_date: schedule_dates[schedule_dates.length - 1].toISODate(),
        }

        this.is_loading_vacations = true
        this.api.get_vacations_in_range(request).subscribe({
            next: (response) => {
                this.scheduled_vacations = response.ranges
                this.is_loading_vacations = false
                this.update_or_create_schedule_view(schedule_dates)
            },
            error: () => {
                notifier.notify('Unable to load scheduled vacations, showing just the standard schedules', 'danger')
                this.update_or_create_schedule_view(schedule_dates)
                this.is_loading_vacations = false
            },
        })
    }

    recede_schedule_date(): void {
        this.display_dt = this.display_dt.minus(this._get_actual_duration())
        this.query_logs_in_range(this.gen_date_range_on_date_scope())
    }

    update_or_create_schedule_view(schedule_dates: DateTime[]): void {
        if (
            this.is_loading_standard_schedule ||
            this.is_loading_logs ||
            this.is_loading_trips ||
            this.is_loading_vacations ||
            this.is_loading_holidays
        ) {
            return
        }

        if (this.schedule_view === null) {
            this.gen_schedule_view(schedule_dates)
        } else {
            this.update_schedule_items(schedule_dates)
        }
    }

    update_or_create_status(): void {
        this.api.update_or_create_status_log(this.update_or_create_status_request).subscribe({
            next: () => {
                const part_str = this.update_or_create_status_request.artkey !== undefined ? ' updated ' : ' created '
                notifier.notify(
                    'successfully' +
                        part_str +
                        'status on ' +
                        DateTime.fromISO(this.update_or_create_status_request.date).toLocaleString({locale: 'nl'}),
                    'success',
                )
                const date_range = this.gen_date_range_on_date_scope()
                this.query_vacations_in_range(date_range)
                this.query_business_trips_in_range(date_range)
                this.query_status_logs(date_range)
                this.query_standard_schedule(date_range)
                this.close_edit_status_modal()
            },
            error: () => {
                this.close_edit_status_modal()
            },
        })
    }

    update_schedule_dates(new_schedule_dates: DateTime[]): void {
        this.schedule_view?.setOptions({
            min: new_schedule_dates[0].toJSDate(),
            max: new_schedule_dates[new_schedule_dates.length - 1].plus({days: 1}).toJSDate(),
        })
        this.schedule_view?.setWindow(
            new_schedule_dates[0].toJSDate(),
            new_schedule_dates[new_schedule_dates.length - 1].plus({days: 1}).toJSDate(),
        )
    }

    update_schedule_items(schedule_dates: DateTime[]): void {
        if (this.schedule_view === null || Object.getOwnPropertyNames(this.schedule_view).length === 0) {
            return
        }
        this.current_items = new vis.DataSet(this.gen_schedule_items_list(schedule_dates))
        this.schedule_view.setData({items: this.current_items})
        this.schedule_view.redraw()
        this.update_schedule_dates(schedule_dates)
    }

    view(): m.Children {
        return (
            <div className="c-user-schedules">
                <div className="btn-toolbar">
                    <DefaultButton
                        title={' Export to excel'}
                        icon_class={'fas fa-file-excel'}
                        onclick={() => (this.show_extract_modal = true)}
                    />
                </div>

                {!this.is_loading_standard_schedule && (
                    <div className="c-filter-group">
                        <DefaultButton
                            icon_class={'glyphicon glyphicon-arrow-left'}
                            title={this.get_schedule_scroll_btn_title(
                                this.display_dt.minus(this._get_actual_duration()),
                            )}
                            onclick={() => this.recede_schedule_date()}
                        />
                        <DefaultButton
                            title={' Today'}
                            icon_class={'glyphicon glyphicon-calendar'}
                            onclick={() => {
                                this.display_dt = DateTime.now().startOf('day')
                                this.query_vacations_in_range(this.gen_date_range_on_date_scope())
                            }}
                        />
                        <DefaultButton onclick={() => this.advance_schedule_date()}>
                            {this.get_schedule_scroll_btn_title(this.display_dt.plus(this._get_actual_duration()))}
                            <span class={'glyphicon glyphicon-arrow-right'} />
                        </DefaultButton>

                        <div className={' btn-group'}>
                            <DefaultButton
                                title={'Day'}
                                additional_class={this.date_scope === date_scopes.DAY ? 'active' : ''}
                                onclick={() => this.change_date_scope(date_scopes.DAY)}
                            />
                            <DefaultButton
                                title={'Week'}
                                additional_class={this.date_scope === date_scopes.WORK_WEEK ? 'active' : ''}
                                onclick={() => this.change_date_scope(date_scopes.WORK_WEEK)}
                            />
                            <DefaultButton
                                title={'Month'}
                                additional_class={this.date_scope === date_scopes.MONTH ? 'active' : ''}
                                onclick={() => this.change_date_scope(date_scopes.MONTH)}
                            />
                        </div>

                    </div>
                )}
                {this.is_timeline_drawing && <Spinner />}
                <div id={'schedule_container'} />
                {this.is_loading_standard_schedule && <Spinner />}
                <div className="legenda mt-2">
                    Schedule tickets that deviate from the standard schedule are followed by an *
                </div>
                {this.show_status_edit_modal && <Modal
                    title={'Edit status log'}
                    onclose={() => {
                        this.close_edit_status_modal()
                    }}
                >
                        You are {this.update_or_create_status_request.artkey !== undefined ? 'updating' : 'creating'} a{' '}
                        status log on{' '}
                    {DateTime.fromISO(this.update_or_create_status_request.date).toLocaleString({locale: 'nl'})}{' '}
                        for{' '}
                    {
                        // Check whether user is editing the status for themselves or someone else and reflect this in the message
                        LoggedInUserData.instance().user_artkey === this.update_or_create_status_request.user_artkey
                            ? 'yourself'
                            : this.groups.get(this.update_or_create_status_request.user_artkey.toString())?.content
                    }
                    <br />
                    <div className={'pull-right'}>
                        <SuccessButton
                            title={'Submit'}
                            disabled={
                                // If empty or current status is same as selected
                                this.update_or_create_status_request.status === '' ||
                                    this.update_or_create_status_request.status ===
                                        this.item_to_update.content.replace('*', '').toUpperCase()
                            }
                            onclick={() => this.update_or_create_status()}
                        />
                    </div>
                    <div className={'pull-right'}>
                        <FieldSelect
                            model={[this.update_or_create_status_request, 'status']}
                            options={(() => {
                                const options = [
                                    {value: 'OFF', label: 'Off'},
                                    {value: 'HOME', label: 'Home'},
                                    {value: 'OFFICE', label: 'Office'},
                                ]
                                if (new Date(this.update_or_create_status_request.date) <= new Date()) {
                                    options.push({value: 'SICK', label: 'Sick'})
                                }
                                return options
                            })()}
                            placeholder="Select status"
                        />

                    </div>
                </Modal>}
                {this.show_extract_modal && (
                    <Modal
                        title={'Export to excel'}
                        onclose={() => {
                            this.close_extract_modal()
                        }}
                        width="800px"
                    >
                        <div className="fieldset large">
                            <div className="field-group">
                                <InputDate
                                    label="Start"
                                    value={this.download_extract_request.start_date}
                                    max={this.download_extract_request.end_date}
                                    onchange={(val: DateTime) =>
                                        (this.download_extract_request.start_date = val ? val.toISODate() : '')
                                    }
                                />

                                <InputDate
                                    label="End"
                                    value={this.download_extract_request.end_date}
                                    min={this.download_extract_request.start_date}
                                    max={DateTime.now().toISODate()}
                                    onchange={(val: DateTime) =>
                                        (this.download_extract_request.end_date = val ? val.toISODate() : '')
                                    }
                                />
                            </div>

                            <div className="btn-group">
                                <DefaultButton
                                    title={'Q1'}
                                    onclick={() => {
                                        this.download_extract_request.start_date = `${this.display_dt.year}-01-01`
                                        this.download_extract_request.end_date = `${this.display_dt.year}-03-31`
                                    }}
                                />
                                <DefaultButton
                                    title={'Q2'}
                                    onclick={() => {
                                        this.download_extract_request.start_date = `${this.display_dt.year}-04-01`
                                        this.download_extract_request.end_date = `${this.display_dt.year}-06-30`
                                    }}
                                />
                                <DefaultButton
                                    title={'Q3'}
                                    onclick={() => {
                                        this.download_extract_request.start_date = `${this.display_dt.year}-07-01`
                                        this.download_extract_request.end_date = `${this.display_dt.year}-09-30`
                                    }}
                                />
                                <DefaultButton
                                    title={'Q4'}
                                    onclick={() => {
                                        this.download_extract_request.start_date = `${this.display_dt.year}-10-01`
                                        this.download_extract_request.end_date = `${this.display_dt.year}-12-31`
                                    }}
                                />
                            </div>
                        </div>

                        <SuccessButton
                            title={' Export Status Logs'}
                            icon_class={'fas fa-file-excel'}
                            disabled={
                                this.is_loading_extract ||
                                !this.download_extract_request.start_date ||
                                !this.download_extract_request.end_date
                            }
                            onclick={() => this.download_user_schedule_extract()}
                        />

                    </Modal>
                )}
            </div>
        )
    }
}
