import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'
import {api, events} from '@bitstillery/common/app'
import {Tab, TabPanel, Tabs} from '@bitstillery/common/components'
import {url_query_string} from '@bitstillery/common/lib/utils'

import {ViewResolve} from './view/resolve'

import {ViewManage} from '@/market/pricelists/view/manage'
import {SupplierPriceListResponse} from '@/factserver_api/fact2server_api'
import {context} from '@/market/pricelists/view/lib/context'

export class MarketPricelistView extends MithrilTsxComponent<any> {

    pricelist_artkey = Number(m.route.param('pricelist_artkey'))
    refetch: () => void

    async oninit() {
        this.refetch = async() => {
            ({result: context.data.supplier_pricelist} = await api.get<SupplierPriceListResponse>(`discover/supplier-price-lists/${context.data.supplier_pricelist.artkey}`))
        }
        events.on('spl:refetch', this.refetch)
    }

    onremove() {
        events.off('spl:refetch', this.refetch)
    }

    view(): m.Children {
        if (!context.data.supplier_pricelist) {
            return null
        }

        // Resolved & unresolved are separate tabs with their own CollectionItems for now.
        // It has yet to be decided whether we can use the same endpoint, filters and
        // collection logic for ViewManage & ViewResolve.
        return <Tabs
            className="c-market-pricelist-view"
            header={{
                icon: 'list',
                onclick: () => {
                    m.route.set('/market/pricelists/list/manage', {meta: true})
                },
                title: context.data.root_artkey ? `Manage Pricelists > ${context.data.supplier_pricelist.artkey}` : 'Manage Pricelists',
            }}
            type="nested-view"
        >
            <Tab
                icon="bulkActions"
                id="manage"
                link={`/market/pricelists/${context.data.root_artkey}/view/manage${url_query_string()}`}
                route={`/market/pricelists/${context.data.root_artkey}/view/manage{/*path}`}
                text={`Resolved${context.data.supplier_pricelist.supplier_price_list_items_count ? ` (${context.data.supplier_pricelist.supplier_price_list_items_count})` : ''}`}
            >
                <TabPanel>
                    <ViewManage/>
                </TabPanel>
            </Tab>

            <Tab
                disabled={!context.data.supplier_pricelist.unresolved_source_lines_count}
                icon="question"
                id="unresolved"
                link={`/market/pricelists/${context.data.root_artkey}/view/resolve${url_query_string()}`}
                route={`/market/pricelists/${context.data.root_artkey}/view/resolve{/*path}`}
                text={`Unresolved${context.data.supplier_pricelist.unresolved_source_lines_count ? ` (${context.data.supplier_pricelist.unresolved_source_lines_count})` : ''}`}
            >
                <TabPanel>
                    <ViewResolve/>
                </TabPanel>
            </Tab>
        </Tabs>
    }
}
