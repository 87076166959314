import m, {Child, ChildArrayOrPrimitive} from 'mithril'
import {classes} from '@bitstillery/common/lib/utils'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {fromEvent, Subscription} from 'rxjs'
import {debounceTime} from 'rxjs/operators'
import {Button, Spinner} from '@bitstillery/common/components'
import {$t} from '@bitstillery/common/app'

import {Pager} from '../pager/pager'

import {
    Api,
    construct_paged_query_parameters,
    construct_query_parameters,
    FactserverRequestData,
} from '@/factserver_api/api'

export interface FactserverPagedRequest extends FactserverRequestData {
    offset: number
    limit?: number
    search_terms: string[]
    sort_by: string
    ascending: boolean
    filters: unknown
}

export interface CollectionFetcher<RESPONSE> {
    /** The current search text. */
    search_text(): string

    /**
     * Fetches the next or the first slice of the collection. This method will fill the fetched_rows array and
     * update the state variables:
     * - is_fetching
     * - has_fetched_at_least_once
     * - no_more_results
     *
     * Optionally this method has to invoke the on_success parameter.
     **/
    fetch_next(on_success?: () => unknown): void

    /**
     * Fetches the given page of the collection. This method will fill the fetched_rows array and
     * update the state variables:
     * - is_fetching
     * - has_fetched_at_least_once
     * - no_more_results
     *
     * Optionally this method has to invoke the on_success parameter.
     **/
    fetch_page(page_index: number, on_success?: () => unknown): void

    /** Add or remove a search filter to/from the page url */
    set_param_in_url(key: string, value: any): void

    /** Indicates that the underlying connection is no longer synced up with the backend. For instance
     * if a value of a row is modified without calling this.reset_and_query(). */
    is_dirty: boolean

    /** Indicates that fetcher is currently working. */
    is_fetching: boolean

    /** Indicates that there are no more results to fetch. */
    no_more_results: boolean
    /** Indicates that there was at least one network action. */
    has_fetched_at_least_once: boolean

    /** The fetched results. */
    fetched_rows: RESPONSE[]
    /** Page size of the collection fetches. */
    page_size: number | null

    /** The current sort column. */
    sort_column: string
    /** The current sort direction. */
    sort_ascending: boolean

    /** Total number of records in filters **/
    total: number

    /** Get icon for the column_sort_name, depends on sort-ascending and the sort_column. */
    sort_column_for(column_sort_name: string | undefined): m.Children

    /** Include these search terms, null for reset. Will clear current results and fetch. */
    set_search_terms(search_terms: string | null): void

    /** Set the sort column. */
    set_sort_column(column_name: string): void

    /** Reset the internals and fetch. Required when filters are changed. */
    reset_and_query(): void

    count_fetched_rows(): number

    filters: SearchFilter
}

/** 'Remembers' the latest search per endpoint in this module. */
interface LatestSearchSettings {
    search_terms: string[]
    sort_column: string
    sort_ascending: boolean
    filters: SearchFilter
}
const _last_search_settings = new Map<string, LatestSearchSettings>()

export type SearchFilterValue = string | number | boolean | Array<string> | Array<number> | SearchFilter

export interface SearchFilter {
    [filter_name: string]: SearchFilterValue
}

abstract class AbstractCollectionFetcher<RESPONSE> implements CollectionFetcher<RESPONSE> {
    _reset_query_data(): void {
        this.current_offset = 0
        this.fetched_rows = []
        this.is_dirty = false
    }

    endpoint: string

    // currently fetched data.
    fetched_rows: RESPONSE[] = []
    on_reset_and_query: (() => unknown) | null

    // filtering and searching
    filters: SearchFilter = {}
    search_terms: string[] = []

    // sorting
    sort_column: string
    sort_ascending: boolean

    // maintaining collection state
    page_size: number | null
    total: number
    current_offset = 0
    has_fetched_at_least_once = false
    no_more_results = false // there are no more results.
    is_fetching = false // indicates that this is currently fetching. To prevent flooding of the server.
    is_dirty = false

    collection_table_type: CollectionTableType

    constructor(
        endpoint: string,
        default_sort_column: string,
        on_reset_and_query: (() => void) | null = null,
        page_size: number,
        sort_ascending: boolean,
        table_type: CollectionTableType = CollectionTableType.SCROLL,
    ) {
        this.endpoint = endpoint
        this.on_reset_and_query = on_reset_and_query
        this.page_size = page_size
        this.collection_table_type = table_type
        this.search_terms = m.route.param('search_terms') ? m.route.param('search_terms').split('+') : []

        const latest_settings = _last_search_settings.get(endpoint)
        if (latest_settings) {
            this.search_terms = latest_settings.search_terms
            this.sort_ascending = latest_settings.sort_ascending
            this.sort_column = latest_settings.sort_column
            this.filters = latest_settings.filters
        } else {
            this.sort_column = default_sort_column
            this.sort_ascending = sort_ascending
        }
    }

    count_fetched_rows(): number {
        return this.fetched_rows.length
    }

    abstract fetch_next(on_success?: () => unknown): void
    abstract fetch_page(page_index: number, on_success?: () => unknown): void

    _fetch(on_success?: () => unknown) {
        if (this.collection_table_type === CollectionTableType.PAGED) {
            const page_index = Number(m.route.param('page'))
            if (isNaN(page_index)) {
                this.fetch_page(1, on_success)
            } else {
                this.fetch_page(page_index, on_success)
            }
        } else {
            this.fetch_next(on_success)
        }
    }

    set_param_in_url(key: string, value?: any): void {
        let base_url = m.route.get()
        let existing_params = ''

        if (base_url.indexOf('?', base_url.indexOf('#')) >= 0) {
            const url_parts = base_url.split('?')
            existing_params = url_parts[url_parts.length - 1]
            base_url = url_parts.slice(0, -1).join('?')
        }
        const url_params = new URLSearchParams(existing_params)

        if (value) {
            url_params.set(key, String(value))
        }
        else {
            url_params.delete(key)
        }
        const params = url_params.toString()
        m.route.set(`${base_url}${params ? '?' : ''}${params}`)
    }

    reset_and_query(on_success?: () => unknown): void {
        this._reset_query_data()
        if (this.on_reset_and_query) {
            this.on_reset_and_query()
        }
        this._fetch(on_success)
    }

    search_text(): string {
        return this.search_terms.join(' ')
    }

    set_search_terms(search_terms: string | null): void {
        this._reset_query_data()
        this.search_terms = search_terms ? search_terms.split(' ') : []

        if (this.collection_table_type === CollectionTableType.PAGED) {
            this.fetch_page(1)
        } else {
            this.fetch_next()
        }
        this.set_param_in_url('search_terms', search_terms)
    }

    set_sort_column(column_name: string): void {
        this._reset_query_data()

        // flip sort if same column as current sort order
        if (this.sort_column === column_name) {
            this.sort_ascending = !this.sort_ascending
        }
        this.sort_column = column_name
        this._fetch()
    }

    sort_column_for(column_sort_name: string | undefined): m.Children {
        if (this.sort_column === column_sort_name && this.sort_ascending) {
            return <span class="glyphicon glyphicon-triangle-top" />
        }
        if (this.sort_column === column_sort_name && !this.sort_ascending) {
            return <span class="glyphicon glyphicon-triangle-bottom" />
        }
        return <span />
    }

    protected store_fetched_rows(new_rows: RESPONSE[]): void {
        this.fetched_rows = this.fetched_rows.concat(new_rows)
        if (this.page_size) {
            this.no_more_results = new_rows.length < this.page_size
        } else {
            this.no_more_results = true
        }
        // Remember these search settings for reinitialization.
        _last_search_settings.set(this.endpoint, {
            search_terms: this.search_terms,
            sort_column: this.sort_column,
            sort_ascending: this.sort_ascending,
            filters: this.filters,
        })
        this.current_offset += new_rows.length
        this.is_fetching = false
        this.has_fetched_at_least_once = true
    }

}

/**
 * Fetches results that are available through an api call on the Fact2server (GET).
 *
 * See the AbstractCollectionFetcher for details on how the search terms, sorting etc. is passed to the API.
 */
export class PagedCollectionFetcherWithGET<RESPONSE>
    extends AbstractCollectionFetcher<RESPONSE>
    implements CollectionFetcher<RESPONSE>
{
    api = new Api()

    constructor(
        endpoint: string,
        default_sort_column: string,
        on_reset_and_query: (() => void) | null = null,
        // Null means no limit.
        page_size = 50,
        sort_ascending = true,
        table_type = CollectionTableType.SCROLL,
    ) {
        super(endpoint, default_sort_column, on_reset_and_query, page_size, sort_ascending, table_type)
    }

    fetch_next(on_success?: () => unknown): void {
        if (this.is_fetching) {
            return
        }
        this.is_fetching = true

        // construct the url
        const url_params = construct_query_parameters(
            this.current_offset, this.search_terms, this.sort_column, this.sort_ascending, this.filters, this.page_size,
        )

        this.api.get<RESPONSE[]>(`${this.endpoint}?${url_params.toString()}`).subscribe({
            next: (value: RESPONSE[]) => {
                this.store_fetched_rows(value)
                if (on_success) {
                    on_success()
                }
                m.redraw()
            },
            error: () => {
                this.is_fetching = false
                m.redraw()
            },
        })
    }

    fetch_page(page_index: number, on_success?: () => unknown): void {
        if (this.is_fetching) {
            return
        }
        this.is_fetching = true

        this.set_param_in_url('page', page_index)

        const url_params = construct_paged_query_parameters(
            page_index, this.search_terms, this.sort_column, this.sort_ascending, this.filters, this.page_size,
        )

        this.api.get<RESPONSE>(`${this.endpoint}?${url_params.toString()}`).subscribe({
            next: (resp: RESPONSE) => {
                this.fetched_rows = resp.items
                this.total = resp.total

                _last_search_settings.set(this.endpoint, {
                    search_terms: this.search_terms,
                    sort_column: this.sort_column,
                    sort_ascending: this.sort_ascending,
                    filters: this.filters,
                })
                this.is_fetching = false
                this.has_fetched_at_least_once = true
                if (on_success) {
                    on_success()
                }
                m.redraw()
            },
            error: () => {
                this.is_fetching = false
                m.redraw()
            },
        })
    }
}

interface CollectionViewResponse<T> {
    total_count:number
    items: T[]
}
/**
 * Fetches results that are available through an api call on the Fact2server (GET) that returns items with total count.
 *
 * Main purpose is to be able to use endpoints that use the collection_view_result paradigm.
 *
 * See the AbstractCollectionFetcher for details on how the search terms, sorting etc. is passed to the API.
 */
export class PagedCollectionFetcherWithTotal<RESPONSE>
    extends AbstractCollectionFetcher<RESPONSE>
    implements CollectionFetcher<RESPONSE>
{
    api = new Api()

    constructor(
        endpoint: string,
        default_sort_column: string,
        on_reset_and_query: (() => void) | null = null,
        // Null means no limit.
        page_size = 50,
        sort_ascending = true,
        table_type = CollectionTableType.SCROLL,
    ) {
        super(endpoint, default_sort_column, on_reset_and_query, page_size, sort_ascending, table_type)
    }

    fetch_next(on_success?: () => unknown): void {
        if (this.is_fetching) {
            return
        }
        this.is_fetching = true

        // construct the url
        const url_params = construct_query_parameters(
            this.current_offset, this.search_terms, this.sort_column, this.sort_ascending, this.filters, this.page_size,
        )

        this.api.get<CollectionViewResponse<RESPONSE>>(`${this.endpoint}?${url_params.toString()}`).subscribe({
            next: (value: CollectionViewResponse<RESPONSE>) => {
                this.store_fetched_rows(value.items)
                this.total = value.total_count
                if (on_success) {
                    on_success()
                }
                m.redraw()
            },
            error: () => {
                this.is_fetching = false
                m.redraw()
            },
        })
    }

    fetch_page(): void {
        throw Error('Not implemented')
    }
}

/**
 * Fetches results that are available through an api call on the Factserver (POST).
 *
 * See the AbstractCollectionFetcher for details on how the search terms, sorting etc. is passed to the API.
 */
export class PagedCollectionFetcher<RESPONSE>
    extends AbstractCollectionFetcher<RESPONSE>
    implements CollectionFetcher<RESPONSE>
{
    api = new Api()

    constructor(
        endpoint: string,
        default_sort_column: string,
        on_reset_and_query: (() => void) | null = null,
        // Null means no limit.
        page_size = 50,
        sort_ascending = true,
        table_type: CollectionTableType = CollectionTableType.SCROLL,
    ) {
        super(endpoint, default_sort_column, on_reset_and_query, page_size, sort_ascending, table_type)
    }

    fetch_next(on_success?: () => unknown): void {
        if (this.is_fetching) {
            return
        }
        this.is_fetching = true

        const request: FactserverPagedRequest = {
            search_terms: this.search_terms,
            sort_by: this.sort_column,
            ascending: this.sort_ascending,
            offset: this.current_offset,
            filters: this.filters,
        }
        if (this.page_size) {
            request.limit = this.page_size
        }
        this.api.post_request<FactserverPagedRequest, RESPONSE[]>(this.endpoint, request).subscribe({
            next: (response: RESPONSE[]) => {
                this.store_fetched_rows(response)
                if (on_success) {
                    on_success()
                }
                m.redraw()
            },
            error: () => {
                this.is_fetching = false
                m.redraw()
            },
        })
    }

    fetch_page(page_index: number, on_success?: () => unknown): void {
        if (this.is_fetching) {
            return
        }
        this.is_fetching = true

        this.set_param_in_url('page', page_index)

        const request: FactserverPagedRequest = {
            search_terms: this.search_terms,
            sort_by: this.sort_column,
            ascending: this.sort_ascending,
            offset: (page_index - 1) * this.page_size,
            filters: this.filters,
        }

        if (this.page_size) {
            request.limit = this.page_size
        }
        this.api.post_request<FactserverPagedRequest, unknown>(this.endpoint, request).subscribe({
            next: (value: RESPONSE) => {
                this.fetched_rows = value.items
                this.total = value.total
                _last_search_settings.set(this.endpoint, {
                    search_terms: this.search_terms,
                    sort_column: this.sort_column,
                    sort_ascending: this.sort_ascending,
                    filters: this.filters,
                })

                this.is_fetching = false
                this.has_fetched_at_least_once = true

                if (on_success) {
                    on_success()
                }
                m.redraw()
            },
            error: () => {
                this.is_fetching = false
                m.redraw()
            },
        })
    }
}

interface CollectionTableColumnProps<RESPONSE> {
    header_title: () => JSX.Element | string
    td_class_name?: string
    sort_name?: string
    data_field?: (response: RESPONSE) => unknown
    disable_click_handler?: boolean
}

export class CollectionTableColumn<RESPONSE> extends MithrilTsxComponent<CollectionTableColumnProps<RESPONSE>> {
    // Not used. This CollectionTableColumn is only used to transfer data to its parent that will render <td />'s.
    view(): m.Children {
        return <div />
    }
}

interface CollectionTableProps<RESPONSE, ADDITIONAL_ROW_ARGS> {
    collection_fetcher: CollectionFetcher<RESPONSE>
    row_component?: MithrilTsxComponent<CollectionTableRowComponentProps<RESPONSE, ADDITIONAL_ROW_ARGS>>
    // These go into the row_component and the on_row_click_component.
    additional_row_component_args?: ADDITIONAL_ROW_ARGS
    on_row_click?: (row: RESPONSE) => unknown
    on_row_click_component?: MithrilTsxComponent<CollectionTableRowComponentProps<RESPONSE, ADDITIONAL_ROW_ARGS>>
    is_header_sticky?: boolean
    is_header_visible?: boolean
    map_rows_to?: (response: RESPONSE) => RESPONSE
    scrollable_dom_element?: () => HTMLElement | null
    dynamic_tr_classname?: (response: RESPONSE) => string
    className?: string
    collection_table_type?: CollectionTableType
}

export interface CollectionTableRowComponentProps<RESPONSE, ADDITIONAL_ARGS> {
    row: RESPONSE
    index: number
    additional_args?: ADDITIONAL_ARGS
    on_row_click?: (row: RESPONSE) => unknown
    additional_tr_classname: string
}

export enum CollectionTableType {
    SCROLL = 'SCROLL',
    PAGED = 'PAGED'
}

/**
 * A table that uses a CollectionFetcher to presents results in a HTMLTable. It monitors window.scroll events
 * to position itself under the Discover button-bar element. The TableHeader is sticky and stays in view.
 *
 * When the last element comes into view (the users scrolls) new items are requested from the collection_fetcher.
 */
export class CollectionTable<RESPONSE, ADDITIONAL_ROW_ARGS> extends MithrilTsxComponent<
    CollectionTableProps<RESPONSE, ADDITIONAL_ROW_ARGS>
> {
    collection_fetcher: CollectionFetcher<RESPONSE>
    row_component?: MithrilTsxComponent<CollectionTableRowComponentProps<RESPONSE, ADDITIONAL_ROW_ARGS>>
    additional_row_component_args?: ADDITIONAL_ROW_ARGS
    on_row_click?: (row: RESPONSE) => unknown
    scroll_sticky_header_subscription?: Subscription
    scroll_reload_subscription?: Subscription
    is_header_sticky = true
    is_header_visible = true
    row_mapper = (resp: RESPONSE): RESPONSE => resp
    collection_table_type: CollectionTableType

    constructor(vnode: m.VnodeDOM<CollectionTableProps<RESPONSE, ADDITIONAL_ROW_ARGS>>) {
        super()
        this.collection_fetcher = vnode.attrs.collection_fetcher
        this.row_component = vnode.attrs.row_component
        this.on_row_click = vnode.attrs.on_row_click
        this.is_header_sticky = vnode.attrs.is_header_sticky !== undefined ? vnode.attrs.is_header_sticky : true
        this.is_header_visible = vnode.attrs.is_header_visible !== undefined ? vnode.attrs.is_header_visible : true
        this.additional_row_component_args = vnode.attrs.additional_row_component_args
        if (vnode.attrs.map_rows_to) {
            this.row_mapper = vnode.attrs.map_rows_to
        }
        this.collection_table_type = vnode.attrs.collection_table_type ?? CollectionTableType.SCROLL
    }

    oncreate(vnode: m.Vnode<CollectionTableProps<RESPONSE, ADDITIONAL_ROW_ARGS>>): void {
        if (this.collection_table_type === CollectionTableType.PAGED) {
            let page_index = Number(m.route.param('page'))
            this.collection_fetcher.fetch_page(isNaN(page_index) ? 1 : Math.max(page_index, 1))
        } else {
            this.collection_fetcher.fetch_next()
        }

        /* Subscribe to scroll events so new rows are automatically fetched. */
        const scroll_dom_element = vnode.attrs.scrollable_dom_element
            ? vnode.attrs.scrollable_dom_element()
            : document.getElementById('app')
        const search_table_rows = document.getElementsByName('search-table-row')

        if (!scroll_dom_element) {
            // Programmer error. Please include these elements on your page to be able to use this component.
            throw new Error('Page does not contain the app or no suitable scroll element given, cannot proceed.')
        }

        if (this.collection_table_type === CollectionTableType.SCROLL) {
            this.scroll_reload_subscription = fromEvent(scroll_dom_element, 'scroll')
                .pipe(debounceTime(100))
                .subscribe((): void => {
                    const last_table_row = search_table_rows.item(search_table_rows.length - 1)
                    // check if last item is approaching for a new fetch.
                    if (
                        last_table_row?.getBoundingClientRect().top < window.innerHeight + 120 &&
                        !this.collection_fetcher.no_more_results
                    ) {
                        this.collection_fetcher?.fetch_next()
                    }
                    return
                })
        }
    }

    onremove(): void {
        if (this.scroll_sticky_header_subscription) {
            this.scroll_sticky_header_subscription?.unsubscribe()
        }
        if (this.scroll_reload_subscription) {
            this.scroll_reload_subscription.unsubscribe()
        }
    }

    /** Filter the abstract children component and cast to an array of CollectionTableColumnProps. */
    sanitize_children(children: ChildArrayOrPrimitive | undefined): Array<CollectionTableColumnProps<RESPONSE>> {
        if (children instanceof Array) {
            return children
                .filter((child) => {
                    // @ts-ignore
                    return child && (child as Child).tag !== undefined
                })
                .map((child) => {
                    // @ts-ignore
                    return child?.['attrs'] as CollectionTableColumnProps<RESPONSE>
                })
        }
        // eslint-disable-next-line no-console
        console.warn('Unable to map anything else than array like children')
        return []
    }

    set_sort_on(column_name: string | undefined): void {
        if (column_name) {
            this.collection_fetcher.set_sort_column(column_name)
        }
    }

    view(vnode: m.Vnode<CollectionTableProps<RESPONSE, ADDITIONAL_ROW_ARGS>>): m.Children {
        return (
            <div className={classes('c-collection-table', vnode.attrs.className)} id={'search-table-holder'}>
                <table id={'search-table'} className="table search-table clickable">
                    {this.is_header_visible && (
                        <thead id={'search-table-thead'} className={'thead-default'}>
                            <tr>
                                {this.sanitize_children(vnode.children)?.map((child) => {
                                    const th_class_name = this.is_header_sticky ? 'sticky' : ''
                                    const th_style = child.sort_name ? 'cursor: pointer' : 'cursor: default' // cannot style it in css somehow
                                    return (
                                        <th
                                            className={th_class_name}
                                            style={th_style}
                                            onclick={() => this.set_sort_on(child.sort_name)}
                                        >
                                            {child.header_title()}{' '}
                                            {this.collection_fetcher.sort_column_for(child.sort_name)}
                                        </th>
                                    )
                                })}
                            </tr>
                        </thead>
                    )}
                    {this.collection_fetcher.fetched_rows.length > 0 && !(this.collection_table_type === CollectionTableType.PAGED && this.collection_fetcher.is_fetching) && (
                        <tbody>
                            {this.collection_fetcher.fetched_rows.map((row, index) => {
                                const tr_dynamic_classname = vnode.attrs.dynamic_tr_classname
                                    ? vnode.attrs.dynamic_tr_classname(row)
                                    : ''
                                const children: m.Children = [
                                    <CollectionTableColumnRenderer
                                        index={index}
                                        row={row}
                                        additional_tr_classname={tr_dynamic_classname}
                                        on_row_click={this.on_row_click}
                                        collection_table_columns={this.sanitize_children(vnode.children)}
                                    />,
                                ]
                                if (this.row_component) {
                                    children.push(m(this.row_component, {
                                        row: row,
                                        index: index,
                                        on_row_click: this.on_row_click,
                                        additional_tr_classname: tr_dynamic_classname,
                                        additional_args: this.additional_row_component_args,
                                    }))
                                }
                                if (vnode.attrs.on_row_click_component) {
                                    children.push(
                                        m(vnode.attrs.on_row_click_component, {
                                            row: row,
                                            index: index,
                                            additional_tr_classname: tr_dynamic_classname,
                                            additional_args: this.additional_row_component_args,
                                        }))
                                }
                                return children
                            })}
                        </tbody>
                    )}
                </table>
                {/* Display status information, like loading or no more results.*/}
                {(this.collection_fetcher.is_fetching || !this.collection_fetcher.has_fetched_at_least_once) && (
                    <Spinner className="table-spinner" />
                )}
                {/* Show 'No more results' with the used filter information if no rows found. */}
                {this.collection_fetcher.no_more_results && !this.collection_fetcher.is_fetching && (
                    <span>
                        {this.collection_fetcher.fetched_rows.length === 0 && (
                            <div className={'alert alert-info'} style={'width: 70%'}>
                                <span className={'glyphicon glyphicon-info'} />
                                <b>Used filter information:</b>
                                <ul>
                                    {Object.keys(this.collection_fetcher.filters).map((filter_key: string) => {
                                        const value = this.collection_fetcher.filters[filter_key]
                                        const value_is_array = Array.isArray(value)
                                        const value_is_object = value !== null && typeof value === 'object'

                                        let value_to_render = value
                                        if (value_is_object) {
                                            // If value is an object, render it as array.
                                            value_to_render = [] as Array<string>
                                            for (const [k, v] of Object.entries(value)) {
                                                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                                                value_to_render.push(`${k}: ${v}`)
                                            }
                                        }
                                        return (
                                            <li>
                                                {filter_key}:{' '}
                                                {(value_is_array || value_is_object) && (
                                                    <span>{(value_to_render as []).join(', ')}</span>
                                                )}
                                                {!value_is_array && !value_is_object && (
                                                    <span>{value_to_render || 'not set'}</span>
                                                )}
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                        )}
                    </span>
                )}
                {this.collection_table_type === CollectionTableType.SCROLL &&
                    this.collection_fetcher.has_fetched_at_least_once &&
                    !this.collection_fetcher.no_more_results &&
                    !this.collection_fetcher.is_fetching && (
                    <Button
                        icon="chevronRightDouble"
                        onclick={() => this.collection_fetcher.fetch_next()}
                        text={$t('table.fetch_more')}
                        type="info"
                    />
                )}
                {this.collection_table_type === CollectionTableType.PAGED &&
                    !this.collection_fetcher.is_fetching &&
                    this.collection_fetcher.has_fetched_at_least_once && (
                    <Pager
                        count={this.collection_fetcher.total}
                        page_size={this.collection_fetcher.page_size}
                        fetch_page={(page_index: number) => this.collection_fetcher.fetch_page(page_index)}
                    />
                )}
            </div>
        )
    }
}

interface CollectionTableColumnRendererProps<RESPONSE> extends CollectionTableRowComponentProps<RESPONSE, null> {
    collection_table_columns: Array<CollectionTableColumnProps<RESPONSE>> | null
}

export class CollectionTableColumnRenderer<RESPONSE> extends MithrilTsxComponent<
    CollectionTableColumnRendererProps<RESPONSE>
> {
    on_row_click(vnode: m.Vnode<CollectionTableColumnRendererProps<RESPONSE>>, event: MouseEvent): void {
        // @ts-ignore
        if ($(event.target).closest('.no-click').length === 0 && vnode.attrs.on_row_click) {
            vnode.attrs.on_row_click(vnode.attrs.row)
        }
    }

    view(vnode: m.Vnode<CollectionTableColumnRendererProps<RESPONSE>>): m.Children {
        const row = vnode.attrs.row as any
        return (
            <tr
                onclick={(event: MouseEvent) => this.on_row_click(vnode, event)}
                className={classes({
                    even: vnode.attrs.index % 2 === 0,
                    odd: vnode.attrs.index % 2 !== 0,
                    selected: row.is_showing_details,
                }, vnode.attrs.additional_tr_classname)}
                name={'search-table-row'}

            >
                {vnode.attrs.collection_table_columns?.map((child) => (
                    <td
                        className={child.td_class_name}
                        onclick={(dom_event: Event) => {
                            if (child.disable_click_handler) {
                                dom_event.stopPropagation()
                                return false
                            }
                            return true
                        }}
                    >
                        {child.data_field && child.data_field(row)}
                    </td>
                ))}
            </tr>
        )
    }
}
