import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {Icon} from '@bitstillery/common/components'
import {classes} from '@bitstillery/common/lib/utils'
import {next_tick} from '@bitstillery/common/lib/proxy'
import {$s} from '@bitstillery/common/app'

type EventListenerType = (evt: Event) => void

const velocity_breakpoint = 8
export class BarMain extends MithrilTsxComponent<any> {

    locationchange_handler: EventListenerType
    scroll_element: HTMLElement | null = null
    scroll_handler: EventListenerType | null = null

    async oncreate() {
        this.locationchange_handler = (async() => {
            $s.page.header.collapsed = false
            setTimeout(() => {
                this.try_bind_scroll()
            }, 100)
        }) as EventListenerType
        await next_tick()
        this.try_bind_scroll()
        window.addEventListener('locationchange', this.locationchange_handler)
    }

    onremove() {
        if (this.scroll_element && this.scroll_handler) {
            this.scroll_element.removeEventListener('scroll', this.scroll_handler)
        }
        window.removeEventListener('locationchange', this.locationchange_handler)
    }

    async try_bind_scroll() {
        if (this.scroll_handler && this.scroll_element) {
            this.scroll_element.removeEventListener('scroll', this.scroll_handler)
        }

        // First check for a nested view-container
        this.scroll_element = document.querySelector('.c-tabs.type-nested-view .c-tab-panel.active .view') as HTMLElement | null
        if (!this.scroll_element) {
            // Fallback to the main view; newer views already include a .view-container wrapper;
            // older views just use the .view class directly.
            this.scroll_element = document.querySelector('.l-main-col .view') as HTMLElement
        }

        if (!this.scroll_element) {
            // The component containing the .view may not be ready yet;
            // retry until we have a valid scroll element.
            setTimeout(() => {
                this.try_bind_scroll()
            }, 100)
        } else {
            this.scroll_handler = this.scroll_header(this.scroll_element) as EventListenerType
            this.scroll_element.addEventListener('scroll', this.scroll_handler)
        }
    }

    /**
     * Attaches a scroll event handler to a given container to toggle visibility
     * of a header based on the scroll velocity and position. Hides the header
     * when scrolling down fast enough, shows the header when scrolling up or
     * when scrolled to the very top of the container.
     *
     * @param {HTMLElement} container - The container element to which the scroll event listener is attached.
     * @returns {Function} A function that serves as the scroll event handler.
     */
    scroll_header(container: HTMLElement) {
        let last_scroll_top = 0
        let last_time = Date.now()
        let ticking = false
        let collapse_timeout: number | null = null
        let last_direction = 0 // 0 for initial, 1 for down, -1 for up

        return function() {
            if (!ticking) {
                window.requestAnimationFrame(() => {
                    const current_time = Date.now()
                    const current_scroll_top = container.scrollTop
                    const scroll_velocity = Math.abs(current_scroll_top - last_scroll_top) / (current_time - last_time)
                    // Determine current scroll direction
                    const current_direction = current_scroll_top > last_scroll_top ? 1 :
                        current_scroll_top < last_scroll_top ? -1 : last_direction

                    // Only process if direction hasn't changed recently (prevents flickering)
                    if (current_direction !== last_direction) {
                        // Clear any pending timeout when direction changes
                        if (collapse_timeout !== null) {
                            window.clearTimeout(collapse_timeout)
                            collapse_timeout = null
                        }
                        last_direction = current_direction
                    }

                    if (scroll_velocity > velocity_breakpoint) {
                        if (current_scroll_top > last_scroll_top) {
                            if (container.scrollHeight / current_scroll_top > 0.2 && !$s.page.header.collapsed) {
                                // Add a small delay before collapsing to prevent overly sensitive behavior
                                if (collapse_timeout === null) {
                                    collapse_timeout = window.setTimeout(() => {
                                        $s.page.header.collapsed = true
                                        collapse_timeout = null
                                        m.redraw() // Ensure UI updates
                                    }, 150) // 150ms delay before actually collapsing
                                }
                            }
                        } else if (current_scroll_top < last_scroll_top && $s.page.header.collapsed) {
                            // Show immediately when scrolling up fast
                            $s.page.header.collapsed = false
                            if (collapse_timeout !== null) {
                                window.clearTimeout(collapse_timeout)
                                collapse_timeout = null
                            }
                        }
                    } else if (current_scroll_top === 0 && $s.page.header.collapsed) {
                        // At the top
                        $s.page.header.collapsed = false
                    }

                    last_time = current_time
                    last_scroll_top = current_scroll_top
                    ticking = false
                })
                ticking = true
            }
        }
    }

    slot_actions(_vnode:m.Vnode<any>) {
        throw new Error('BarMain slot_actions not implemented')
    }

    slot_search(_vnode:m.Vnode<any>) {
        throw new Error('BarMain slot_search not implemented')
    }

    view(vnode:m.Vnode<any>):m.Children {
        return <div className={classes('c-bar-main', vnode.attrs.className)}>
            <div className="bar-search">
                {this.slot_search(vnode)}
            </div>
            <div className="bar-header">
                <span className="title">{$s.page.title}</span>
                <div className="action-group">
                    {this.slot_actions(vnode)}
                </div>
                <div className="page-icon">
                    <Icon className="icon-l" name={$s.page.icon} type="unset" />
                </div>
            </div>
        </div>
    }
}
