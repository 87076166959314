import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {format_iso_to_date} from '@bitstillery/common/lib/format'
import {DateTime} from 'luxon'
import {notifier} from '@bitstillery/common/app'
import {Amount, FieldSelect, FieldText, Spinner, Tippy} from '@bitstillery/common/components'

import {Link} from '../components/discover'

import {$s} from '@/app'
import {
    CollectionTable,
    CollectionTableColumn,
    PagedCollectionFetcher,
} from '@/components/collection/collection_table'
import {GetProductsResponse} from '@/factserver_api/product_management_api'
import {DefaultButton} from '@/components/buttons'
import {MarginsApi, SalesOrderLowMarginResponse} from '@/factserver_api/margins_api'
import {CheckBox, Percent} from '@/components/html_components'

interface SalesOrderLowMarginRow extends SalesOrderLowMarginResponse {
    is_editing: boolean
    is_calling_api: boolean
}

export default class SalesOrdersLowMarginApproval extends MithrilTsxComponent<unknown> {
    sales_order_fetcher = new PagedCollectionFetcher<SalesOrderLowMarginRow>(
        'margin_threshold.low_margin_sales_orders',
        'artkey',
    )

    margin_api = new MarginsApi()

    oncreate() {
        this.sales_order_fetcher.filters['selected_year'] = `${DateTime.now().year}`
        this.sales_order_fetcher.filters['only_unapproved'] = true
    }

    update_selected_year(selected_year: string): void {
        this.sales_order_fetcher.filters['selected_year'] = selected_year
        this.sales_order_fetcher.filters['only_unapproved'] = true
        this.sales_order_fetcher.reset_and_query()
    }

    user_can_approve(row: SalesOrderLowMarginRow): boolean {
        if (row.low_margin_is_approved) {
            return false
        }
        return (
            $s.identity.user.is_sales_target_manager &&
            row.low_margin_approval_reason !== null &&
            row.low_margin_approval_reason !== ''
        )
    }

    add_approve_info(row: SalesOrderLowMarginRow, is_approved: boolean): void {
        this.margin_api
            .low_margin_sales_order_approval(row.artkey, row.low_margin_approval_reason, is_approved)
            .subscribe({
                next: () => {
                    row.is_editing = false
                    row.low_margin_is_approved = is_approved
                    notifier.notify('Successfully added low margin reason to the sales order', 'success')
                    m.redraw()
                },
                error: () => {
                    m.redraw()
                },
            })
    }

    view(): m.Children {
        return (
            <div className="c-sales-low-margin view">
                <div className="c-filter-group">
                    <FieldSelect
                        model={[this.sales_order_fetcher.filters, 'selected_year']}
                        onchange={(value: string) => this.update_selected_year(value)}
                        options={Array.from({length: DateTime.now().year - 2010}, (e, i) => i + 2011)
                            .map((year) => `${year}`)
                            .map((year) => ({label: year, value: year}))
                        }
                    />

                    <CheckBox
                        label="Only Unapproved"
                        checked={this.sales_order_fetcher.filters['only_unapproved']}
                        onchange={() => {
                            this.sales_order_fetcher.filters['only_unapproved'] =
                                !this.sales_order_fetcher.filters['only_unapproved']
                            this.sales_order_fetcher.reset_and_query()
                        }}
                    />
                </div>
                <CollectionTable<GetProductsResponse, void> collection_fetcher={this.sales_order_fetcher}>
                    <CollectionTableColumn<SalesOrderLowMarginRow>
                        header_title={() => 'Reference'}
                        sort_name={'artkey'}
                        data_field={(row: SalesOrderLowMarginResponse) => (
                            <Link href={`/sales/orders/${row.artkey}/view/manage`}>{row.reference}</Link>
                        )}
                    />
                    <CollectionTableColumn<SalesOrderLowMarginRow>
                        header_title={() => 'Relation'}
                        td_class_name={'col-sm-2'}
                        data_field={(row: SalesOrderLowMarginRow) => (
                            <Link href={`/crm/relations/${row.relation_artkey}/edit`}>{row.relation_name}</Link>
                        )}
                    />
                    <CollectionTableColumn<SalesOrderLowMarginRow>
                        header_title={() => 'Invoiced on'}
                        sort_name={'is_invoiced_on'}
                        data_field={(row: SalesOrderLowMarginRow) => format_iso_to_date(row.is_invoiced_on)}
                    />
                    <CollectionTableColumn<SalesOrderLowMarginRow>
                        header_title={() => 'Sales manager'}
                        sort_name={'is_invoiced_on'}
                        data_field={(row: SalesOrderLowMarginRow) => row.handled_by}
                    />
                    <CollectionTableColumn<SalesOrderLowMarginRow>
                        header_title={() => 'Turnover'}
                        data_field={(row: SalesOrderLowMarginRow) => (
                            <Amount amount={row.euro_turnover} currency={'EUR'} />
                        )}
                    />
                    <CollectionTableColumn<SalesOrderLowMarginRow>
                        header_title={() => 'Margin'}
                        data_field={(row: SalesOrderLowMarginRow) => <Amount amount={row.margin} currency={'EUR'} />}
                    />
                    <CollectionTableColumn<SalesOrderLowMarginRow>
                        header_title={() => 'Margin percentage'}
                        data_field={(row: SalesOrderLowMarginRow) => (
                            <Percent value={+row.margin_percentage / 100} number_of_fraction_digits={1} />
                        )}
                    />
                    <CollectionTableColumn<SalesOrderLowMarginRow>
                        header_title={() => 'Approved'}
                        data_field={(row: SalesOrderLowMarginRow) =>
                            row.low_margin_is_approved ? (
                                <span class={'fas fa-check'} />
                            ) : (
                                <span class={'fas fa-minus'} />
                            )
                        }
                    />
                    <CollectionTableColumn<SalesOrderLowMarginRow>
                        header_title={() => 'Reason'}
                        td_class_name={'col-sm-4'}
                        data_field={(row: SalesOrderLowMarginRow) => {
                            if (row.is_editing) {
                                return (
                                    <FieldText
                                        model={[row, 'low_margin_approval_reason']}
                                    />
                                )
                            } else {
                                return row.low_margin_approval_reason || ''
                            }
                        }}
                    />
                    <CollectionTableColumn<SalesOrderLowMarginRow>
                        header_title={() => ''}
                        data_field={(row: SalesOrderLowMarginRow) => (
                            <span>
                                {row.is_calling_api && <Spinner />}
                                {$s.identity.user.is_sales_target_manager &&
                                    (() => {
                                        if (!row.is_calling_api && !row.is_editing) {
                                            return [
                                                <DefaultButton
                                                    icon_class={'glyphicon glyphicon-pencil'}
                                                    onclick={() => {
                                                        row.is_editing = true
                                                        return false
                                                    }}
                                                />,
                                                <Tippy
                                                    content={'<div>Can only approve if <ul><li>Is currently not approved.</li><li>Has an approval reason.</li></ul></div>'}
                                                >
                                                    <DefaultButton
                                                        additional_class={'btn-success'}
                                                        disabled={!this.user_can_approve(row)}
                                                        onclick={() => {
                                                            this.add_approve_info(row, true)
                                                            return false
                                                        }}
                                                        icon_class={'glyphicon glyphicon-thumbs-up'}
                                                    />
                                                </Tippy>,
                                            ]
                                        } else if (!row.is_calling_api && row.is_editing) {
                                            return [
                                                <DefaultButton
                                                    additional_class={'btn-info'}
                                                    onclick={() => {
                                                        row.is_editing = false
                                                    }}
                                                    icon_class={'glyphicon glyphicon-ban-circle'}
                                                />,
                                                <Tippy content={'<div>Save and set to unapproved</div>'}>
                                                    <DefaultButton
                                                        additional_class={'btn-success'}
                                                        onclick={() => this.add_approve_info(row, false)}
                                                        icon_class={'glyphicon glyphicon-ok'}
                                                    />
                                                </Tippy>,
                                                <Tippy content={'<div>Add approval reason and approve</div>'}>
                                                    <DefaultButton
                                                        additional_class={'btn-success'}
                                                        disabled={!this.user_can_approve(row)}
                                                        onclick={() => {
                                                            this.add_approve_info(row, true)
                                                            return false
                                                        }}
                                                        icon_class={'glyphicon glyphicon-thumbs-up'}
                                                    />
                                                </Tippy>,
                                            ]
                                        }
                                    })()
                                }
                            </span>
                        )}
                    />
                </CollectionTable>
            </div>
        )
    }
}
