import m from 'mithril'
import {api} from '@bitstillery/common/app'
import {MithrilTsxComponent} from 'mithril-tsx-component'

import {$s} from '@/app'
import {SystemParameterResponse} from '@/factserver_api/fact2server_api'

export default class PortalDashboard extends MithrilTsxComponent<{}> {

    parameter: SystemParameterResponse

    async oninit() {
        const {result} = await api.get<SystemParameterResponse[]>('discover/data/system-parameters/collection-view?sort_by=name')
        this.parameter = result.filter((i) => i.name.includes('plausible-embed')).reduce((acc:any, i) => {
            if (i.name.includes('etr')) {
                if (!acc.etr) acc.etr = {}
                acc.etr[i.name.replace('plausible-embed-etr-', '')] = i
            } else if (i.name.includes('msp')) {
                if (!acc.msp) acc.msp = {}
                acc.msp[i.name.replace('plausible-embed-msp-', '')] = i
            }
            return acc
        }, {})
        m.redraw()
    }

    view(_vnode: m.Vnode<any>) {
        if (!this.parameter) return null
        const embed_config = 'embed=true&theme=light&background=transparent&width=manual'
        const parameter = this.parameter[$s.session.account.slug]

        return (
            <div className="c-portal-dashboard view">
                <iframe
                    frameborder="0"
                    loading="lazy"
                    plausible-embed
                    src={`https://plausible.io/share/${parameter.site.value}?auth=${parameter.secret.value}&${embed_config}`}
                    scrolling="no"
                ></iframe>
                <script async src="https://plausible.io/share/js/embed.host.js"></script>
            </div>
        )
    }
}
