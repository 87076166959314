import m from 'mithril'
import {classes} from '@bitstillery/common/lib/utils'
import {unique_id} from '@bitstillery/common/lib/utils'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {FieldCheckboxAttrs} from '@bitstillery/common/types/field'

export class FieldCheckbox extends MithrilTsxComponent<FieldCheckboxAttrs> {
    model_value:any
    name = unique_id()
    props: {off: boolean; on: boolean}

    constructor(vnode: m.Vnode<any>) {
        super()
        this.props = vnode.attrs.props ? vnode.attrs.props : {off: false, on: true}
    }

    view(vnode:m.Vnode<FieldCheckboxAttrs>) {
        const validation = vnode.attrs.validation
        const invalid = validation ? validation._invalid : false
        const disabled = vnode.attrs.disabled
        // Computed property that overrides the reference.
        if (vnode.attrs.computed) {
            this.model_value = vnode.attrs.computed()
        } else if (vnode.attrs.model) {
            this.model_value = vnode.attrs.model[0][vnode.attrs.model[1]]
        }

        return (
            <div className={classes('c-checkbox', 'field', vnode.attrs.className, vnode.attrs.type ? `type-${vnode.attrs.type}` : 'type-default')}>
                <input
                    checked={this.model_value === this.props.on}
                    disabled={disabled}
                    id={this.name}
                    name={this.name}
                    onclick={vnode.attrs.onclick}
                    onchange={() => {
                        if (vnode.attrs.validation) {
                            vnode.attrs.validation.dirty = true
                        }
                        if (vnode.attrs.computed) {
                            this.model_value = vnode.attrs.computed()
                        } else {
                            if (this.model_value === this.props.on) {
                                this.model_value = this.props.off
                            } else {
                                this.model_value = this.props.on
                            }
                            vnode.attrs.model[0][vnode.attrs.model[1]] = this.model_value
                        }

                        if (vnode.attrs.onAfterChange) {
                            vnode.attrs.onAfterChange(this.model_value)
                        }
                    }}
                    type="checkbox"
                />
                {vnode.attrs.label && <label for={this.name}>
                    {vnode.attrs.label}
                    {vnode.attrs.validation && <span className="validation">{validation.label}</span>}
                </label>}
                {(() => {
                    if (invalid && validation.dirty) {
                        return <div className="help validation">{typeof invalid.message === 'function' ? invalid.message() : invalid.message}</div>
                    } else if (vnode.attrs.help) {
                        return <div class="help">{vnode.attrs.help}</div>
                    }
                })()}
            </div>
        )
    }
}
