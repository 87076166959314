/** llm:tested */
import m from 'mithril'
import {all, concat, join, orList} from 'prelude-ls'
import {current_account_slug} from '@bitstillery/common/account/account'
import {continents, countries} from '@bitstillery/common/lib/countries'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {notifier} from '@bitstillery/common/app'

import {ProcessManageOffer} from './processes'

import {accountIcon} from '@/accounts'
import {RelationDropDownData} from '@/factserver_api/relation_api'
import api_ls from '@/api'
import {button_with_icon} from '@/components/_buttons'
import {Collection} from '@/components/collection/collection'
import SearchInput from '@/components/collection/search_input'
import {CollectionTable} from '@/components/collection_table'
import confirmation from '@/components/confirmation'
import inputs from '@/components/inputs'
import {languages} from '@/components/languages'
import {FilterSidebar} from '@/offer/components/relation_selection_sidebar'
import {Offer} from '@/models/offers'
import {$m} from '@/app'

export class OfferRelations extends MithrilTsxComponent<unknown> {
    offer: any
    offer_artkey: string
    relation_list: string[]
    initial_relation_list: string[]
    auto_update: any
    initial_auto_update: boolean
    relations: any
    search_input_ctrl: any
    saving: any
    email_batch_artkey: string
    next_step_text: string
    next_step_slug: string

    constructor() {
        super()
        // Offer details.
        this.offer = window.prop(new Offer())
        this.offer_artkey = m.route.param('artkey')
        this.query_offer(this.offer_artkey)

        // Relation selection.
        this.relation_list = []
        this.initial_relation_list = []
        this.auto_update = window.prop(false)
        this.initial_auto_update = false

        this.relations = new Collection({
            api_function_name: 'offer.get_relations_for_selection',
            filter_function: this.is_match,
            sort_order: [
                {name: 'name', direction: 'asc'},
            ],
            default_sort_by: 'name',
            default_sort_order: 'asc',
            additional_params: this.additional_params,
        })

        // Initialize relation filters with their default values.
        if (typeof this.relations.filters === 'undefined') {
            this.relations.filters = {
                sales_account: window.prop([current_account_slug()]),
                buyers: window.prop('true'),
                available_for_mail: window.prop(''),
                client_status: window.prop(['Client', 'Prospect']),
                company_type: window.prop([]),
                currency: window.prop([]),
                customs_status_visibility: window.prop([]),
                language: window.prop([]),
                portal_level: window.prop([]),
                price_list_frequency: window.prop([]),
                purchase_manager: window.prop([]),
                sales_manager: window.prop([]),
                suppliers: window.prop(''),
                active_sales_promotions: window.prop([]),
                active_vouchers_artkeys: window.prop([]),
                operates_online: window.prop(''),
                should_receive_offer_mails: window.prop('true'),
                should_receive_purchase_mails: window.prop(''),
            }

            for (const continent_code of Object.keys(continents)) {
                this.relations.filters[`countries_per_continent_${continent_code}`] = window.prop([])
            }
        }

        this.search_input_ctrl = new SearchInput.controller({
            collection: this.relations,
            placeholder: 'Search for relation, country, sales manager and status',
            autocomplete: true,
            suggestions: [],
        })

        this.saving = window.prop(false)

        this.email_batch_artkey = m.route.param('email_batch')

        this.next_step_text = 'select products'
        this.next_step_slug = 'select_products'
        if (m.route.param('skip_select_products')) {
            this.next_step_text = 'customize products'
            this.next_step_slug = 'custom_products'
        }
    }

    oncreate() {
        this.relations.init()
        $m.common.observable.subscribe('suppliers_updated', this, this.relations.requery)

        $m.common.observable.subscribe('collection.offer.get_relations_for_selection.after-call', this, () => {
            if (this.auto_update()) {
                this.toggle_all_relations(true)
            }
        })

        RelationDropDownData.names().subscribe((names) => this.search_input_ctrl.set_suggestions(names))
    }

    additional_params = () => {
        const all_selected_countries = concat(
            Object.keys(continents).map(code =>
                this.relations.filters[`countries_per_continent_${code}`](),
            ),
        )

        return {
            sales_account: this.relations.filters['sales_account'](),
            buyers: this.relations.filters['buyers'](),
            client_status: this.relations.filters['client_status'](),
            company_type: this.relations.filters['company_type'](),
            country: all_selected_countries,
            currency: this.relations.filters['currency'](),
            customs_status_visibility: this.relations.filters['customs_status_visibility'](),
            language: this.relations.filters['language'](),
            portal_level: this.relations.filters['portal_level'](),
            price_list_frequency: this.relations.filters['price_list_frequency'](),
            purchase_manager: this.relations.filters['purchase_manager'](),
            sales_manager: this.relations.filters['sales_manager'](),
            suppliers: this.relations.filters['suppliers'](),
            active_sales_promotions: this.relations.filters['active_sales_promotions'](),
            active_vouchers_artkeys: this.relations.filters['active_vouchers_artkeys'](),
            operates_online: this.relations.filters['operates_online'](),
            should_receive_offer_mails: this.relations.filters['should_receive_offer_mails'](),
            should_receive_purchase_mails: this.relations.filters['should_receive_purchase_mails'](),
        }
    }

    check_relation(supplier_artkey: string) {
        if (!this.relation_list.includes(supplier_artkey)) {
            this.relation_list.push(supplier_artkey)
        } else {
            const index = this.relation_list.indexOf(supplier_artkey)
            if (index > -1) {
                this.relation_list.splice(index, 1)
            }
        }
        m.redraw()
    }

    requery_relations() {
        this.relation_list.splice(0)
        this.relations.requery()
    }

    is_match = (relation: any, term: string) => {
        return orList([
            relation.name.toLowerCase().indexOf(term) > -1,
            relation.sales_manager.profile.name.toLowerCase().indexOf(term) > -1,
            (relation.company_type || '').toLowerCase().indexOf(term) > -1,
            (relation.client_status || '').toLowerCase().indexOf(term) > -1,
            (countries[relation.country_code] || '').toLowerCase().indexOf(term) > -1,
        ])
    }

    query_offer(artkey: string) {
        api_ls.callAndThen('offer.get_offer', {artkey: artkey, include_suppliers: true}, {
            success: (resp: any) => {
                try {
                    this.offer(new Offer(resp.result))
                    for (const supplier of resp.result.suppliers) {
                        this.relation_list.push(supplier.artkey)
                    }
                    this.initial_relation_list = this.relation_list.slice(0)

                    if (this.offer().relation_filters()) {
                        this.auto_update(true)
                        this.initial_auto_update = true
                    }
                } catch (e) {
                    $m.common.generic_error_handler(e)
                }
            },
            failure: () => {
                notifier.notify('Unknown offer.', 'danger')
                m.route.set('/offer/offers')
            },
        })
    }

    selection_changed() {
        return !(this.relation_list.sort() === this.initial_relation_list.sort()) ||
               (this.auto_update() !== this.initial_auto_update)
    }

    sort_by_checked = () => {
        for (const item of this.relations.items()) {
            item.checked = this.relation_list.includes(item.artkey)
        }
        this.relations.sort_by('checked')
        this.relations.ascending(!this.relations.ascending())
        this.relations.filter_items()
    }

    toggle_all_relations = (value?: boolean) => {
        if (value !== undefined) {
            if (value) {
                for (const relation of this.relations.search_result()) {
                    if (!this.relation_list.includes(relation.artkey)) {
                        this.relation_list.push(relation.artkey)
                    }
                }
            } else {
                for (const relation of this.relations.search_result()) {
                    const index = this.relation_list.indexOf(relation.artkey)
                    if (index > -1) {
                        this.relation_list.splice(index, 1)
                    }
                }
            }
        } else {
            if (this.relations.search_result().length === 0) return false
            for (const relation of this.relations.search_result()) {
                if (!this.relation_list.includes(relation.artkey)) {
                    return false
                }
            }
            return true
        }
    }

    toggle_auto_update = (value?: boolean) => {
        if (value !== undefined) {
            this.auto_update(value)
            if (value) {
                this.relations.requery()
            } else {
                this.toggle_all_relations(false)
            }
        } else {
            return this.auto_update()
        }
    }

    save_button_text() {
        if (this.selection_changed()) {
            return 'Save relation selection'
        } else if (this.relation_list.length === 0) {
            return 'No relations are selected yet'
        } else {
            return 'Relation selection is saved'
        }
    }

    proceed_button_text() {
        if (this.selection_changed()) {
            return `Save relation selection and proceed to ${this.next_step_text}`
        } else {
            return `Proceed to ${this.next_step_text}`
        }
    }

    save_selection(proceed: boolean) {
        if (!this.saving()) {
            this.saving(true)

            const data: any = {
                artkey: this.offer().artkey(),
                suppliers: this.relation_list,
            }

            if (this.auto_update()) {
                const serialized_filters: any = {}
                for (const parameter_name of Object.keys(this.relations.filters)) {
                    serialized_filters[parameter_name] = this.relations.filters[parameter_name]()
                }
                data['relation_filters'] = serialized_filters
            }

            api_ls.callAndThen('offer.select_relations', data, {
                success: (resp: any) => {
                    $m.common.observable.broadcast('offer_updated')
                    notifier.notify(`Successfully selected relations for offer "${this.offer().title()}"`, 'success')
                    if (proceed) {
                        this.redirect()
                    } else {
                        this.offer(new Offer(resp.result))
                        this.saving(false)
                        this.initial_relation_list = this.relation_list.slice(0)
                        this.initial_auto_update = this.auto_update()
                    }
                },
                failure: (resp: any) => {
                    notifier.notify(resp.message, 'danger')
                    this.saving(false)
                },
            })
        }
    }

    select_relations(proceed: boolean) {
        if (this.selection_changed()) {
            if (this.offer().published_timestamp() &&
                !all((x: string) => this.relation_list.includes(x), this.initial_relation_list)) {
                confirmation.show({
                    title: 'Deselecting relations',
                    message: 'You have deselected relations in an already published offer. This can change prices for those relations. Are you sure you want to continue?',
                    unique_name: 'deselect_confirm',
                    onconfirm: () => this.save_selection(proceed),
                })
            } else {
                this.save_selection(proceed)
            }
        } else if (proceed) {
            this.redirect()
        }
    }

    redirect() {
        let redirect_url = `/offer/offers/${this.offer_artkey}/${this.next_step_slug}`
        if (this.email_batch_artkey) {
            redirect_url += `/?email_batch=${this.email_batch_artkey}`
        }
        m.route.set(redirect_url)
    }

    view() {
        return <div class="c-process-new-offer-step-2 view process">
            <ProcessManageOffer
                active="relations"
                context={{
                    email_batch_artkey: this.email_batch_artkey,
                    offer_artkey: this.offer_artkey,
                    offer_title: this.offer().title(),
                }}
            />

            <div class="step-content">
                <div class="filter-sidebar-wrapper">
                    {this.offer().artkey() &&
                        <FilterSidebar
                            relations={this.relations}
                            requery={() => this.requery_relations()}
                            offer_relation_filters={this.offer().relation_filters()}
                        />
                    }

                    <div class="filter-result">
                        <p>When an offer is published, the prices in this offer are updated in the Portal for the selected relations.</p>

                        <div class="c-filter-group">
                            {SearchInput.view(this.search_input_ctrl)}

                            {button_with_icon(this.save_button_text(), 'fa-briefcase', {
                                class: this.selection_changed() ? 'btn-success' : 'btn-normal',
                                disabled: this.saving() || !this.selection_changed(),
                                onclick: () => this.select_relations(false),
                                title: `This button saves any changes you made to the relation selection.
If the offer is published, prices in this offer then apply to those relations.
It also makes it possible to email the offer to these relations. It keeps you on this page.`,
                            })}

                            {button_with_icon(this.proceed_button_text(), 'glyphicon-list', {
                                class: 'btn-success',
                                disabled: this.saving(),
                                onclick: () => this.select_relations(true),
                                title: `This button takes you to the next page where you can ${this.next_step_text} for this offer. Any changes you made to the relation selection will be saved.`,
                            })}
                        </div>

                        <div class="c-filter-group">
                            {inputs.checkbox(this.toggle_all_relations, {
                                disabled: this.auto_update(),
                                label: 'Select all relations in the filter result',
                            })}
                            {inputs.checkbox(this.toggle_auto_update, {
                                label: 'Use current filters to auto-update the relation selection',
                            })}
                        </div>

                        <CollectionTable
                            collection={this.relations}
                            options={{
                                search_table_style: true,
                                sticky_header: true,
                                with_buttons: true,
                                autoscale: true,
                                unique_name: 'offer_relations',
                            }}
                            columns={[
                                {
                                    width: 2,
                                    name: 'Select for offer',
                                    field: 'checked',
                                    sort_function: this.sort_by_checked,
                                    default_visible: true,
                                    function: (record: any) => (
                                        <span>
                                            <input
                                                type="checkbox"
                                                id={record.artkey}
                                                onclick={() => this.check_relation(record.artkey)}
                                                checked={this.relation_list.includes(record.artkey)}
                                                disabled={this.auto_update()}
                                            />
                                            <label for={record.artkey} />
                                        </span>
                                    ),
                                },
                                {
                                    width: 8,
                                    name: 'Relation',
                                    field: 'name',
                                    sort: true,
                                    ellipsis: true,
                                    default_visible: true,
                                    function: (relation: any) => [
                                        <span class="mr-05">{accountIcon(relation.sales_account)}</span>,
                                        relation.name,
                                    ],
                                },
                                {
                                    width: 6,
                                    name: 'Location',
                                    field: 'country_code',
                                    sort: true,
                                    default_visible: true,
                                    function: (record: any) =>
                                        <span>{countries[record.country_code] + ', ' + record.city}</span>,
                                },
                                {
                                    width: 4,
                                    name: 'Languages',
                                    field: 'contact_languages',
                                    sort: true,
                                    default_visible: true,
                                    function: (record: any) => {
                                        const language_list = record.contact_languages.map((language: string) =>
                                            language ? languages[language] : '-',
                                        )
                                        return language_list.length ? join(', ', language_list.sort()) : '-'
                                    },
                                },
                                {
                                    width: 4,
                                    name: 'Sales manager',
                                    field: 'sales_manager.profile.name',
                                    sort: true,
                                    default_visible: true,
                                },
                                {
                                    width: 2,
                                    name: 'Portal level',
                                    field: 'portal_level',
                                    sort: true,
                                    default_visible: true,
                                },
                                {
                                    width: 1,
                                    header: '',
                                    name: 'Column selector',
                                    value: ' ',
                                },
                            ]}
                        />
                    </div>
                </div>
            </div>
        </div>
    }
}
