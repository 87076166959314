/** llm:tested */
import m from 'mithril'
import {Country} from '@bitstillery/common/components'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {notifier} from '@bitstillery/common/app'

import {callAndThen} from '@/api'
import inputs from '@/components/inputs'
import {sales_order_dm, SalesOrderStatus} from '@/sales/models'
import {with_buttons} from '@/components/table/fixed_header'
import {DestinationsDropDown} from '@/components/destinations'
import {DestinationsDropDownData} from '@/factserver_api/destinations_api'
import {$s} from '@/app'

export class SalesOrdersInstock extends MithrilTsxComponent<unknown> {
    sales_order_artkey: number
    sales_order: any
    warehouse_artkey: any

    constructor() {
        super()
        this.sales_order = window.prop(null)
        this.warehouse_artkey = window.prop('')
        this.sales_order_artkey = +m.route.param('artkey')
        $s.page.title = 'Bring sales order credit items in stock'
    }

    oncreate() {
        this.load_sales_order(this.sales_order_artkey)
    }

    load_sales_order(sales_order_artkey: number) {
        const data = {
            sales_order_artkey: sales_order_artkey,
        }
        callAndThen('sales.core.get_fast_sales_order_with_items', data, {
            success: (resp) => this.set_sales_order(resp),
            redraw: false,
        })
    }

    set_sales_order(resp: any) {
        if (resp.success) {
            this.sales_order(sales_order_dm.create_sales_order(resp.result))
            if (![SalesOrderStatus.INVOICED].includes(this.sales_order().sales_order_status())) {
                notifier.notify('This order does not have the status invoiced. Only invoiced orders can be brought in stock.', 'danger')
                m.route.set(`/sales/orders/${this.sales_order().artkey()}/view/manage`)
            } else if (this.sales_order().credit_items_in_stock()) {
                notifier.notify('This order was already brought in stock.', 'info')
                m.route.set(`/sales/orders/${this.sales_order().artkey()}/view/manage`)
            }
        }
        m.redraw()
    }

    submit(event: Event) {
        event.preventDefault()

        if (!this.warehouse_artkey()) {
            notifier.notify('Please select a destination warehouse.', 'info')
            return
        }

        const sales_order_credit_item_to_lot: Record<number, any> = {}
        for (const sales_order_credit_item of this.sales_order().sales_order_credit_items()) {
            const lot = sales_order_credit_item.item().lot()
            if (lot) {
                sales_order_credit_item_to_lot[+sales_order_credit_item.artkey()] = sales_order_credit_item.item().lot()
            } else {
                notifier.notify('Please fill out all lot numbers.', 'info')
                return
            }
        }

        const data = {
            sales_order_artkey: +this.sales_order().artkey(),
            sales_order_credit_item_to_lot: sales_order_credit_item_to_lot,
            warehouse_artkey: this.warehouse_artkey(),
        }
        callAndThen('sales.credit.in_stock_sales_order_credit_items', data, {
            success: (resp) => this.handle_save_response(resp),
        })
    }

    handle_save_response(resp: any) {
        if (!resp.success) {
            const msg = resp.message || 'Failed bringing the sales order credit items in stock. Please try again later. If the problem persists, please contact us.'
            notifier.notify(msg, 'danger')
        } else {
            notifier.notify('The sales order credit items were brought in stock successfully.', 'success')
            m.route.set(`/sales/orders/${this.sales_order().artkey()}/view/manage`)
        }
    }

    view() {
        return <div class="c-soci-in-stock view">
            {this.sales_order() && [
                <form class="form-horizontal" role="form" onsubmit={(e) => this.submit(e)}>
                    <div class="field" style="display: flex">
                        <label class="control-label col-sm-2">Destination warehouse</label>
                        <DestinationsDropDown
                            get_all_for_drop_down_response$={DestinationsDropDownData.destinations()}
                            model={[this, 'warehouse_artkey']}
                        />
                    </div>

                    {with_buttons(
                        <table class="table search-table" style="width: 100%">
                            <thead class="thead-default">
                                <tr>
                                    <th>Product</th>
                                    <th>Btl / cs</th>
                                    <th>Specs</th>
                                    <th>GB</th>
                                    <th>Tax label</th>
                                    <th>Country of origin</th>
                                    <th>Cases</th>
                                    <th><div class="col-xs-12">Lot</div></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.sales_order().sales_order_credit_items().map((sales_order_credit_item: any, index: number) =>
                                    <SalesOrderCreditItem
                                        sales_order_credit_item={sales_order_credit_item}
                                        index={index}
                                    />,
                                )}
                            </tbody>
                        </table>,
                    )}
                </form>,
            ]}

            <button class="btn btn-primary" type="submit" onclick={(e) => this.submit(e)}>
                <span class="glyphicon glyphicon-play-home" /> Bring credit items in stock
            </button>
        </div>
    }
}

class SalesOrderCreditItem extends MithrilTsxComponent<unknown> {
    sales_order_credit_item: any
    index: number

    constructor(vnode: m.Vnode<{ sales_order_credit_item: any; index: number }>) {
        super()
        this.sales_order_credit_item = window.prop(vnode.attrs.sales_order_credit_item)
        this.index = vnode.attrs.index
    }

    view() {
        return <tr class={this.index % 2 === 0 ? 'odd' : 'even'}>
            <td>{this.sales_order_credit_item().sales_order_item().item().bottle().product().name()}</td>
            <td>{this.sales_order_credit_item().sales_order_item().item().number_of_bottles_per_case()}</td>
            <td>{this.sales_order_credit_item().sales_order_item().item().bottle().to_specs()}</td>
            <td>{this.sales_order_credit_item().sales_order_item().item().gift_box_type()}</td>
            <td>{this.sales_order_credit_item().sales_order_item().item().tax_label()}</td>
            <td>
                <Country
                    country_code={this.sales_order_credit_item().sales_order_item().item().country_of_origin()}
                    type="flag_with_country"
                />
            </td>
            <td>{this.sales_order_credit_item().number_of_cases()}</td>
            <td>{inputs.text(this.sales_order_credit_item().item().lot)}</td>
        </tr>
    }
}
