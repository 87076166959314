import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {CasesOrBottles} from '@bitstillery/common/pdf/pdf'
import {Button, ButtonGroup} from '@bitstillery/common/components'
import {$t} from '@bitstillery/common/app'

import {CommentTemplateDropDown, RadioSelection} from '@/components/html_components'
import {$m} from '@/app'
import {CommentTemplateDropDownData} from '@/factserver_api/comment_templates'

export class PDFHelper<T> {
    pdf: any = null
    pdf_renderer: (attrs: T) => any

    constructor(pdf_render_function: (attrs: T) => any) {
        this.pdf_renderer = pdf_render_function
    }

    render_base64_encoded(attrs: T): void {
        this.pdf = this.pdf_renderer(attrs)
    }

    /** Returns the pdf as base64 encoded, suitable for emailing etc. */
    pdf_as_base64_encoded(): string {
        if (!this.pdf) {
            throw Error('Programmer error.')
        }
        const output = this.pdf.output('dataurlstring')
        const base_64_offset = output.indexOf(';base64,') + 8
        return output.substr(base_64_offset)
    }

    current_account(): any {
        const ls_account = $m.accounts.current_account()
        return PDFHelper._as_account(ls_account)
    }

    account_by_slug(slug: string): any {
        const ls_account = $m.accounts.get_account_by_slug(slug)
        return PDFHelper._as_account(ls_account)
    }

    static _as_account(ls_account: any): any {
        if (!ls_account) {
            return null
        }

        return {
            artkey: ls_account.artkey(),
            slug: ls_account.slug(),
            city: ls_account.city(),
            name: ls_account.name(),
            telephone_number: ls_account.telephone_number(),
            address: ls_account.address(),
            zip_code: ls_account.zip_code(),
            chamber_of_commerce_number: ls_account.chamber_of_commerce_number(),
            country_code: ls_account.country_code(),
            emailaddress: ls_account.emailaddress(),
            informal_name: ls_account.informal_name(),
            vat_id: ls_account.vat_id(),
            website_url: ls_account.website_url(),
            iban_eur: ls_account.iban_eur(),
            iban_gbp: ls_account.iban_gbp(),
            iban_usd: ls_account.iban_usd(),
        }
    }
}
interface PdfComponentAttrs<T> {
    pdf_helper: PDFHelper<T>
}

/**
 * Display the rendered pdf in an IFrame, thus using the browsers native PDF displaying capabilities.
 *
 * T - The attributes required for the renderer.
 */
export class PDFComponent<T> extends MithrilTsxComponent<PdfComponentAttrs<T>> {
    view(vnode: m.Vnode<PdfComponentAttrs<T>>): m.Children {
        return (
            <iframe
                width={'100%'}
                style={'border: none'}
                src={vnode.attrs.pdf_helper.pdf?.output('dataurlstring') || ''}
            />
        )
    }
}

interface PDFUpdateAndDownloadAttrs<T> {
    rerender_pdf: () => unknown
    onclick?: () => unknown
    pdf_file_name: string
    pdf_helper: PDFHelper<T>
}

export class PDFUpdateAndDownload<T> extends MithrilTsxComponent<PDFUpdateAndDownloadAttrs<T>> {
    view(vnode: m.Vnode<PDFUpdateAndDownloadAttrs<T>>): m.Children {
        if (!vnode.attrs.pdf_helper) return ''
        return (
            <ButtonGroup>
                <Button
                    icon="refresh"
                    onclick={async() => {
                        if (vnode.attrs.onclick) {
                            await vnode.attrs.onclick()
                        }
                        vnode.attrs.rerender_pdf()
                    }}
                    text={$t('pdf.actions.refresh')}
                    variant="context"
                />
                <Button
                    icon="download"
                    text={$t('pdf.actions.download')}
                    type="info"
                    onclick={() => vnode.attrs.pdf_helper.pdf?.save(vnode.attrs.pdf_file_name)}
                    variant="context"
                />
            </ButtonGroup>
        )
    }
}

interface PDFCommentTemplateAttrs {
    onchange: (selected_comment: string) => unknown
    value: string
}

export class PDFCommentTemplate extends MithrilTsxComponent<PDFCommentTemplateAttrs> {
    selected_comment_artkey = ''

    view(vnode: m.Vnode<PDFCommentTemplateAttrs>): m.Children {
        return (
            <div className="field">
                <label>Comment</label>
                <CommentTemplateDropDown
                    get_all_for_drop_down_response$={CommentTemplateDropDownData.comment_templates()}
                    model={[this, 'selected_comment_artkey']}
                    onchange={(comment_template_artkey: string) => {
                        if (comment_template_artkey) {
                            CommentTemplateDropDownData.comment_template_for_artkey(
                                +comment_template_artkey,
                            ).subscribe({
                                next: (value) => {
                                    vnode.attrs.onchange(value.body)
                                },
                            })
                        }
                    }}
                />
                <textarea
                    className={'form-control mt-1'}
                    value={vnode.attrs.value}
                    rows={4}
                    onchange={(value: InputEvent) => {
                        vnode.attrs.onchange((value.target as HTMLTextAreaElement).value)
                    }}
                />
            </div>
        )
    }
}

interface PDFBottlesOrCasesSelectorAttrs {
    value: CasesOrBottles
    onchange: (new_value: CasesOrBottles) => unknown
}

export class PDFBottlesOrCasesSelector extends MithrilTsxComponent<PDFBottlesOrCasesSelectorAttrs> {
    view(vnode: m.Vnode<PDFBottlesOrCasesSelectorAttrs>): m.Children {
        return (
            <div className="field">
                <label>Show quantity in</label>
                <RadioSelection
                    value={vnode.attrs.value}
                    onclick={vnode.attrs.onchange}
                    choices={[
                        {
                            description: 'Cases',
                            value: CasesOrBottles.cases,
                        },
                        {
                            description: 'Bottles',
                            value: CasesOrBottles.bottles,
                        },
                    ]}
                />
            </div>
        )
    }
}

interface PDFDecimalPointSelectorAttrs {
    value: string
    onchange: (new_value: string) => unknown
}

export class PDFDecimalPointSelector extends MithrilTsxComponent<PDFDecimalPointSelectorAttrs> {
    view(vnode: m.Vnode<PDFDecimalPointSelectorAttrs>): m.Children {
        return (
            <div className="field">
                <label>Decimal point as</label>
                <RadioSelection
                    value={vnode.attrs.value}
                    onclick={vnode.attrs.onchange}
                    choices={[
                        {
                            description: 'Point',
                            value: 'en',
                        },
                        {
                            description: 'Comma',
                            value: 'nl',
                        },
                    ]}
                />
            </div>
        )
    }
}
