import m from 'mithril'
import {format_iso_to_date} from '@bitstillery/common/lib/format'
import {api, notifier} from '@bitstillery/common/app'
import {DataCard, Link} from '@bitstillery/common/components'
import {MithrilTsxComponent} from 'mithril-tsx-component'

import {download_binary_file_from_base64_str} from '@/_utils'
import {accountIconBySlug} from '@/accounts'
import {context} from '@/market/pricelists/view/lib/context'

export class ContextDataCard extends MithrilTsxComponent<any> {

    view(_vnode: m.Vnode<any>) {
        const spl = context.data.supplier_pricelist as any

        return <DataCard
            model={{
                data: [
                    {
                        label: 'Relation',
                        value: <Link href={`/crm/relations/${spl.supplier.artkey}/edit`}>
                            {accountIconBySlug(spl.supplier.sales_account.slug, 'Account')}
                            {spl.supplier.name}
                        </Link>,
                    },
                    {label: 'Creation date', value: format_iso_to_date(spl.created_on)},
                    {label: 'Start date', value: format_iso_to_date(spl.start_date)},
                    {label: 'End date', value: format_iso_to_date(spl.end_date)},
                    {label: 'Description', value: spl.supplier_price_lisxt_description},
                    {label: 'Status', value: spl.status.replaceAll('_', ' ')},
                    {label: 'File', value: <span className="link-action" onclick={async() => {
                        const {result, success} = await api.post('offerprocessing.get_supplier_price_list_file', {
                            spl_artkey: spl.artkey,
                        }) as any
                        if (success) {
                            download_binary_file_from_base64_str(result.file_base64_encoded, spl.file_name)
                        } else {
                            notifier.notify(result.message, 'danger')
                        }
                    }}>{spl.file_name}</span>},
                    {label: 'Suppressed items', value: spl.suppressed_source_line_count},
                ]}}
        />
    }
}
