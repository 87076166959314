import m from 'mithril'
import {classes} from '@bitstillery/common/lib/utils'
import {Icon} from '@bitstillery/common/components'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {modelref_adapter, modelref_assign} from '@bitstillery/common/lib/store'
import {FieldTextAttrs} from '@bitstillery/common/types/field'

export class FieldNumber extends MithrilTsxComponent<FieldTextAttrs> {

    view(vnode: m.Vnode<FieldTextAttrs>) {
        if (!vnode.attrs.model[0]) return
        const {model_value, model_ref} = modelref_adapter(vnode.attrs.model)
        const validation = vnode.attrs.validation

        if (vnode.attrs.validation && model_value) {
            vnode.attrs.validation.dirty = true
        }

        // Add human readable description to the validation object.
        if (validation && vnode.attrs.label) {
            validation.description = vnode.attrs.label
        }

        const invalid = validation ? validation._invalid : false
        const disabled = vnode.attrs.disabled

        return <div className={classes('c-field-number', vnode.attrs.composed ? null : 'field', vnode.attrs.className, {
            disabled: disabled,
            invalid: validation && invalid && validation.dirty,
            valid: validation && !invalid && validation.dirty,
        })}>
            {vnode.attrs.label && (
                <label>{vnode.attrs.label}
                    {vnode.attrs.icon && <Icon name={vnode.attrs.icon}/>}
                    {vnode.attrs.validation && <span className="validation">{validation.label}</span>}
                </label>
            )}

            <div className="input-wrapper">
                <input
                    autocomplete={vnode.attrs.autocomplete}
                    autofocus={vnode.attrs.autofocus}
                    disabled={vnode.attrs.disabled}

                    oninput={async(e) => {
                        if (vnode.attrs.validation) {
                            vnode.attrs.validation.dirty = true
                        }

                        let value = e.target.value

                        if (value) {
                            if (vnode.attrs.max && Number(value) > vnode.attrs.max) {
                                value = String(vnode.attrs.max)
                            }
                            if (vnode.attrs.min && Number(value) < vnode.attrs.min) {
                                value = String(vnode.attrs.min)
                            }
                        }

                        // Round the number to the specified number of decimals to avoid floating point precision issues
                        const num_value = value ? Number(Number(value).toFixed(vnode.attrs.decimals)) : null
                        modelref_assign(model_ref, num_value)
                    }}
                    placeholder={vnode.attrs.placeholder ? vnode.attrs.placeholder : ''}
                    min={'min' in vnode.attrs ? vnode.attrs.min : undefined}
                    max={'max' in vnode.attrs ? vnode.attrs.max : undefined}
                    step="any" // Use "any" to allow any decimal input
                    type="number"
                    value={model_value}
                />
                {vnode.children}
                {(() => {
                    if (invalid && validation.dirty) {
                        return <div className="help validation">{typeof invalid.message === 'function' ? invalid.message() : invalid.message}</div>
                    } else if (vnode.attrs.help) {
                        return <div class="help">{vnode.attrs.help}</div>
                    }
                })()}
            </div>
        </div>
    }
}
