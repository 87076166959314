import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'
import {Spinner} from '@bitstillery/common/components'
import {
    Tab,
    TabPanel,
    Tabs,
} from '@bitstillery/common/components'

import {EdiMessages} from '../../components/edi_messages'
import {CreateOrEditSalesOrderComponent} from '../edit_component'

import {AttachmentHelper, AttachmentList} from '@/components/attachment_list'
import {ManageGreenShipment} from '@/sales/orders/view/manage/green_shipment'
import {ViewInvoice} from '@/sales/orders/view/invoice'
import {ViewProforma} from '@/sales/orders/view/proforma'
import {ViewOuttake} from '@/sales/orders/view/outtake'
import {ViewManage} from '@/sales/orders/view/manage'
import {ViewMove} from '@/sales/orders/view/move'
import {LoenderslootOuttake} from '@/sales/orders/components/loendersloot_outtake'
import {context, methods} from '@/sales/orders/view/lib/context'
import {SalesOrderEmailCorrespondence} from '@/sales/components/sales_order_email_correspondence'
import {AttachmentType} from '@/factserver_api/attachment_api'
import {is_origin_loendersloot} from '@/factserver_api/sales_api'
import {CombinedOrderStatus} from '@/factserver_api/fact2server_api'
import {SalesOrdersInstock} from '@/sales/instock'

export class SalesOrdersView extends MithrilTsxComponent<unknown> {
    attachment_helper: AttachmentHelper

    is_loading = false
    is_loading_loendersloot_outtake = false

    async oninit() {
        const route_key = parseInt(m.route.param('artkey'), 10)
        this.attachment_helper = new AttachmentHelper(route_key, AttachmentType.SALES_ORDER)
    }

    is_invoiced(): boolean {
        return context.data.sales_order.combined_status === CombinedOrderStatus.Invoiced
    }

    view(): m.Children {
        if (!context.data.sales_order || !context.data.relation) return <Spinner/>
        const is_loendersloot = is_origin_loendersloot(context.data.sales_order)
        return <Tabs
            className="c-sales-order-view"
            header={{
                icon: 'list',
                onclick: () => {
                    m.route.set('/sales/orders/list/manage', {meta: true})
                },
                title: context.data.root_artkey ? `Manage Orders > S${context.data.root_artkey}` : 'Manage Orders',
            }}
            type="nested-view"
        >
            <Tab
                icon="bulkActions"
                id="manage_order"
                link={`/sales/orders/${context.data.root_artkey}/view/manage?meta=true`}
                route={`/sales/orders/${context.data.root_artkey}/view/manage{/*path}`}
                text="View Order"
            >
                <TabPanel>
                    <ViewManage attachment_helper={this.attachment_helper}/>
                </TabPanel>
            </Tab>

            <Tab
                disabled={
                    [CombinedOrderStatus.Invoiced, CombinedOrderStatus.Cancelled].includes(context.data.sales_order.combined_status) ||
                    context.data.sales_order.is_part_of_buy_from_account
                }
                icon="edit"
                id="edit"
                link={`/sales/orders/${context.data.root_artkey}/view/edit`}
                route={`/sales/orders/${context.data.root_artkey}/view/edit{/*path}`}
                text="Edit Order"
                tip={() => {
                    if ([CombinedOrderStatus.Invoiced, CombinedOrderStatus.Cancelled].includes(context.data.sales_order.combined_status)) {
                        return `Cannot edit a sales order with status "${context.data.sales_order.combined_status}"`
                    }

                    if (context.data.sales_order.is_part_of_buy_from_account) {
                        return 'Sales order is part of a buy from account and cannot be edited'
                    }

                    return 'Edit the sales order'
                }}
            >
                <TabPanel className="view">
                    <CreateOrEditSalesOrderComponent
                        onsaved={(artkey: number) => m.route.set(`/sales/orders/${artkey}/view/manage`)}
                    />
                </TabPanel>
            </Tab>

            <Tab
                icon="cartRight"
                id="move_items"
                link={`/sales/orders/${context.data.root_artkey}/view/move_items`}
                route={`/sales/orders/${context.data.root_artkey}/view/move_items{/*path}`}
                text="Move Items"
            >
                <TabPanel className="view">
                    <ViewMove />
                </TabPanel>
            </Tab>

            <Tab
                count={this.attachment_helper.attachments.length}
                icon="attachment"
                id="attachments"
                link={`/sales/orders/${context.data.root_artkey}/view/attachments`}
                route={`/sales/orders/${context.data.root_artkey}/view/attachments{/*path}`}
                text="Attachments"
            >
                <TabPanel className="view">
                    <AttachmentList attachment_helper={this.attachment_helper} />
                </TabPanel>
            </Tab>

            <Tab
                count={context.data.sales_order.number_of_emails}
                icon="email"
                id="email"
                link={`/sales/orders/${context.data.root_artkey}/view/email`}
                route={`/sales/orders/${context.data.root_artkey}/view/email{/*path}`}
                text="Email"
            >
                <TabPanel className="view">
                    <SalesOrderEmailCorrespondence
                        sales_order={() => {
                            return {
                                artkey: () => context.data.root_artkey,
                            }
                        }}
                    />
                </TabPanel>
            </Tab>

            {context.data.loendersloot_outtake &&
            <Tab
                icon="checked"
                id="loendersloot_outtake"
                link={`/sales/orders/${context.data.root_artkey}/view/loendersloot_outtake`}
                route={`/sales/orders/${context.data.root_artkey}/view/loendersloot_outtake{/*path}`}
                text="Warehouse Report"
            >
                <TabPanel className="view">
                    <LoenderslootOuttake />
                </TabPanel>
            </Tab>}

            {[
                CombinedOrderStatus.Confirmed,
                CombinedOrderStatus.Saved,
                CombinedOrderStatus.Finalized,
                CombinedOrderStatus.Pending,
            ].some((i) => i === context.data.sales_order.combined_status) &&
            <Tab
                icon="clipboard_check_outline"
                id="proforma"
                link={`/sales/orders/${context.data.root_artkey}/view/proforma`}
                onactivate={async() => {
                    await methods.fetch_sales_order_proforma(context.data.root_artkey)
                    methods.rerender_proforma_pdf(false)
                }}
                route={`/sales/orders/${context.data.root_artkey}/view/proforma{/*path}`}
                text="Proforma"
                tip="Proforma can be used to send to customers, while the sales order is saved, finalized or pending"
            >
                <TabPanel className="view">
                    <ViewProforma />
                </TabPanel>
            </Tab>}

            {context.data.sales_order.combined_status === CombinedOrderStatus.Invoiced &&
            <Tab
                icon="pdf"
                id="invoice"
                link={`/sales/orders/${context.data.root_artkey}/view/invoice`}
                route={`/sales/orders/${context.data.root_artkey}/view/invoice{/*path}`}
                text="Invoice"
            >
                <TabPanel className="view">
                    <ViewInvoice />
                </TabPanel>
            </Tab>}

            {
                context.data.sales_order.combined_status === CombinedOrderStatus.Invoiced &&
                !context.data.sales_order.all_credit_items_in_stock &&
            <Tab
                icon="fork_lift"
                id="credit_to_stock"
                link={`/sales/orders/${context.data.root_artkey}/view/credit_to_stock`}
                route={`/sales/orders/${context.data.root_artkey}/view/credit_to_stock{/*path}`}
                text="Stock Credit items"
            >
                <TabPanel className="view">
                    <SalesOrdersInstock />
                </TabPanel>
            </Tab>}

            <Tab
                icon="packaging"
                id="outtake"
                link={`/sales/orders/${context.data.root_artkey}/view/outtake`}
                route={`/sales/orders/${context.data.root_artkey}/view/outtake{/*path}`}
                text="Outtake"
            >
                <TabPanel className="view">
                    <ViewOuttake />
                </TabPanel>
            </Tab>

            {is_loendersloot &&
            <Tab
                count={context.data.sales_order.number_of_edi_messages}
                icon="edi"
                id="edi"
                link={`/sales/orders/${context.data.root_artkey}/view/edi`}
                route={`/sales/orders/${context.data.root_artkey}/view/edi{/*path}`}
                text="EDI"
            >
                <TabPanel className="view">
                    <EdiMessages sales_order_artkey={context.data.root_artkey} />
                </TabPanel>
            </Tab>}

            {!context.data.sales_order.delivery_status &&
            [CombinedOrderStatus.Confirmed, CombinedOrderStatus.Invoiced].includes(context.data.sales_order.combined_status) &&
            <Tab
                icon="truck"
                id="truck"
                link={`/sales/orders/${context.data.root_artkey}/view/green`}
                route={`/sales/orders/${context.data.root_artkey}/view/green{/*path}`}
                text='Transport'
            >
                <TabPanel className="view">
                    <ManageGreenShipment />
                </TabPanel>
            </Tab>}
        </Tabs>
    }
}
