/** llm:tested */

import m from 'mithril'
import {current_account_slug, AccountSlug} from '@bitstillery/common/account/account'
import {notifier} from '@bitstillery/common/app'
import {ButtonGroup, Button} from '@bitstillery/common/components'

import api from '@/api'
import {preventDefault} from '@/_utils'
import {Collection} from '@/components/collection/collection'
import confirmation from '@/components/confirmation'
import inputs from '@/components/inputs'
import {EmailTable} from '@/email/components/email_table'
import {ContactPerson} from '@/models/data'
import {Link} from '@/components/discover'
import {$m} from '@/app'

enum SHOWING {
    CONTACT_PERSON = 'CONTACT_PERSON',
    HELLO_DIALOG = 'HELLO_DIALOG'
}

interface HelloDialogInfo {
    id: string
    email: string
    contact_first_name: string
    contact_family_name: string
    contact_salutation: string
    contact_language: string
    supplier_status: string
    client_status: string
    company_type: string
    name: string
    city: string
    country_code: string
    nps_score: number
}

export class CrmRelationsContactPersonUpsert {
    private contact_person: ContactPerson
    private artkey: string
    private emails: Collection
    private has_portal_access: Function
    private enable_portal_access: Function
    private active_portal_user: Function
    private supplier_artkey: string
    private back_url: string
    private base_url: string
    private hello_dialog_info: HelloDialogInfo | null
    private currently_showing: SHOWING

    constructor() {
        this.contact_person = new ContactPerson()
        this.artkey = m.route.param('artkey')

        this.emails = new Collection({
            api_function_name: 'email.email.get_for_contact_person',
            additional_params: () => ({contact_person_artkey: this.artkey}),
            filter_function: this.is_match,
            query_limit: 25,
            default_sort_by: 'sent_on',
            default_sort_order: 'desc',
        })

        this.has_portal_access = window.prop(false)
        this.enable_portal_access = window.prop(false)
        this.active_portal_user = window.prop(null)

        this.supplier_artkey = m.route.param('supplier_artkey')
        this.back_url = `/crm/relations/${this.supplier_artkey}/edit?tabId=contacts`
        this.base_url = `/crm/relations/${this.supplier_artkey}/contact-persons/`

        this.hello_dialog_info = null
        this.currently_showing = SHOWING.CONTACT_PERSON

        if (this.artkey) {
            this.hello_dialog_fetch()
        }

        if (this.artkey) {
            this.emails.query()

            const data = {artkey: this.artkey}
            api.call('contact_persons.get', data, (resp: any) => {
                if (resp.success) {
                    this.contact_person = $m.data.contact_person.create_contact_person(resp.result)
                    if (this.contact_person.has_portal_access()) {
                        this.has_portal_access(true)
                        this.enable_portal_access(true)
                        this.active_portal_user(this.contact_person.portal_users().filter((portal_user: any) => !portal_user.is_deleted)[0])
                    }
                } else {
                    notifier.notify('Unknown contact person.', 'danger')
                    m.route.set(this.back_url)
                }
            })
        }
    }

    oncreate() {
        if (this.artkey) {
            this.emails.requery()
        }
    }

    create() {
        return !this.contact_person.artkey()
    }

    delete() {
        confirmation.show({
            title: 'Delete contact person',
            message: 'Are you sure you want to delete this contact person?',
            unique_name: 'contact_person_delete_confirm',
            onconfirm: () => {
                const data = {
                    artkey: this.contact_person.artkey(),
                }
                api.call('contact_persons.delete', data, this.handle_delete.bind(this))
            },
        })
    }

    save() {
        const data = {
            artkey: this.contact_person.artkey(),
            supplier_artkey: this.supplier_artkey,
            first_name: this.contact_person.first_name(),
            family_name: this.contact_person.family_name(),
            telephone_number: this.contact_person.telephone_number(),
            mobile_telephone_number: this.contact_person.mobile_telephone_number(),
            emailaddress: this.contact_person.emailaddress(),
            birthdate: this.contact_person.birthdate() ? this.contact_person.birthdate().split('T')[0] : null,
            position: this.contact_person.position(),
            salutation: this.contact_person.salutation(),
            language: this.contact_person.language(),
            portal_language: this.contact_person.portal_language,
            enable_portal_access: this.enable_portal_access(),
            should_receive_mails: this.contact_person.should_receive_mails(),
            should_receive_purchase_enquiries: this.contact_person.should_receive_purchase_enquiries(),
            add_to_hello_dialog: this.contact_person.add_to_hello_dialog(),
        }

        api.callAndThen('contact_persons.create_or_update', data, {
            success: () => {
                if (this.create()) {
                    notifier.notify('Successfully created new contact person.', 'success')
                } else {
                    notifier.notify('Successfully updated contact person.', 'success')
                }
                m.route.set(this.back_url)
            },
        })
    }

    handle_delete(result: any) {
        if (!result.success) {
            if (result.message) {
                notifier.notify(result.message, 'danger')
            } else {
                $m.common.generic_error_handler()
            }
        } else {
            notifier.notify('Successfully deleted contact person.', 'warning')
            m.route.set(this.back_url)
        }
    }

    hello_dialog_fetch() {
        const data = {
            contact_person_artkey: this.artkey,
        }
        api.callAndThen('hello_dialog.info', data, {
            success: (resp) => {
                this.hello_dialog_info = resp.result || null
            },
        })
    }

    hello_dialog_sync() {
        const data = {
            contact_person_artkey: this.contact_person.artkey(),
        }
        api.callAndThen('hello_dialog.sync_data', data, {
            success: () => {
                notifier.notify('Hello dialog synced', 'info')
                this.hello_dialog_info = null
                this.hello_dialog_fetch()
            },
        })
    }

    hello_dialog_remove() {
        const data = {
            contact_person_artkey: this.contact_person.artkey(),
        }
        api.callAndThen('hello_dialog.remove', data, {
            success: () => {
                notifier.notify('Hello dialog removed', 'info')
                this.hello_dialog_info = null
                this.hello_dialog_fetch()
                this.currently_showing = SHOWING.CONTACT_PERSON
            },
        })
    }

    view() {
        return <div class="c-contact-edit view">
            <div class="btn-toolbar">

                <ButtonGroup>
                    <Button
                        icon="arrow_left_circle_outline"
                        onclick={() => m.route.set(this.back_url)}
                        text="Back to list"
                        variant="context"
                    />
                </ButtonGroup>

                {!this.create() && <ButtonGroup>
                    <Button
                        text={this.hello_dialog_info === null ? ' Add to Hello Dialog' : ' Update Hello Dialog'}
                        icon="refresh"
                        onclick={() => this.hello_dialog_sync()}
                        type="info"
                        tip={() => {
                            if (this.hello_dialog_info === null) {
                                return 'Add contact to Hello Dialog mailing list'
                            }
                            return 'Update contact in Hello Dialog mailing list'
                        }}
                        variant="context"
                    />
                    <Button
                        disabled={this.hello_dialog_info === null}
                        text={'Remove from Hello Dialog'}
                        icon="trash"
                        onclick={() => this.hello_dialog_remove()}
                        tip={() => {
                            if (this.hello_dialog_info === null) {
                                return 'No Hello Dialog info found'
                            }
                            return 'Remove contact from Hello Dialog'
                        }}
                        type="danger"
                        variant="context"
                    />
                </ButtonGroup>}

                {!this.create() && <ButtonGroup>
                    <Button
                        text="Delete Contact"
                        icon="trash"
                        onclick={() => this.delete()}
                        type="danger"
                        variant="context"
                    />
                </ButtonGroup>}
            </div>

            {this.hello_dialog_info &&
                <ul class="nav nav-tabs">
                    <li role="presentation" class={this.currently_showing === SHOWING.CONTACT_PERSON ? 'active' : ''}>
                        <m.route.Link
                            href={m.route.get()}
                            onclick={(e: Event) => {
                                preventDefault(e)
                                this.currently_showing = SHOWING.CONTACT_PERSON
                            }}>
                            Contact person
                        </m.route.Link>
                    </li>
                    {this.hello_dialog_info &&
                        <li role="presentation" class={this.currently_showing === SHOWING.HELLO_DIALOG ? 'active' : ''}>
                            <m.route.Link
                                href={m.route.get()}
                                onclick={(e: Event) => {
                                    preventDefault(e)
                                    this.currently_showing = SHOWING.HELLO_DIALOG
                                }}>
                                <span>
                                    <img
                                        class="hello-dialog-logo"
                                        disabled={true}
                                        style="height: 20px;width: 20px"
                                        src="/img/logo-hello-dialog.svg"
                                    />
                                </span>
                            </m.route.Link>
                        </li>
                    }
                </ul>
            }

            {this.currently_showing === SHOWING.CONTACT_PERSON &&
                <form class="flex-form" onsubmit={(e) => {
                    e.preventDefault()
                    this.save()
                }}>
                    <div class="fieldset">
                        <div class="field-group">
                            {inputs.text(this.contact_person.first_name, {label: 'First name', required: true})}
                            {inputs.text(this.contact_person.family_name, {label: 'Last name'})}
                        </div>
                        {inputs.text(this.contact_person.telephone_number, {label: 'Phone number'})}
                        {inputs.text(this.contact_person.mobile_telephone_number, {label: 'Mobile number'})}
                        {inputs.text(this.contact_person.emailaddress, {label: 'Email address', required: true})}
                        {inputs.date(this.contact_person.birthdate, {label: 'Birthdate', max: new Date(), min: '1900-01-01'})}

                        <div class="field-group">
                            {inputs.text(this.contact_person.position, {label: 'Position'})}
                            {inputs.text(this.contact_person.salutation, {label: 'Salutation'})}
                        </div>

                        {inputs.language(this.contact_person.language, {
                            help: 'The native language of this contactperson; not to be confused with the portal user language',
                            label: 'Native Language',
                            required: true,
                        })}
                    </div>

                    <div class="fieldset">
                        {inputs.checkbox(this.contact_person.should_receive_mails, {
                            checked: this.contact_person.should_receive_mails(),
                            label: 'Receive commercial mailings',
                        })}

                        {inputs.checkbox(this.contact_person.should_receive_purchase_enquiries, {
                            checked: this.contact_person.should_receive_purchase_enquiries(),
                            label: 'Receive purchase enquiries',
                        })}

                        {this.create() && current_account_slug() === AccountSlug.MSP &&
                            inputs.checkbox(this.contact_person.add_to_hello_dialog, {
                                checked: this.contact_person.add_to_hello_dialog(),
                                label: 'Add contact to newsletter',
                            })
                        }

                        {inputs.checkbox(this.enable_portal_access, {
                            disabled: this.has_portal_access(),
                            label: 'Portal Access',
                            help: this.has_portal_access() ?
                                'Use the portal / users page to edit the portal user.' :
                                'Contact person will get an email with login details for the portal after creation',
                        })}

                        {this.active_portal_user() &&
                            <Link href={`/portal/portal-users/${this.active_portal_user().artkey}/edit`}>
                                Edit the portal user
                            </Link>
                        }
                    </div>

                    <button class="btn btn-success btn-submit mb-2">
                        {this.create() ? 'Create Contact' : 'Update Contact'}
                    </button>
                </form>
            }

            {this.currently_showing === SHOWING.HELLO_DIALOG && this.hello_dialog_info &&
                <div class="col-sm-6">
                    <img
                        class="hello-dialog-logo"
                        src="/img/logo-hello-dialog.svg"
                    />
                    <dl class="dl-horizontal">
                        <dt>#Id</dt>
                        <dd>{this.hello_dialog_info.id}</dd>
                        <dt>Email</dt>
                        <dd>{this.hello_dialog_info.email}</dd>
                        <dt>First name</dt>
                        <dd>{this.hello_dialog_info.contact_first_name}</dd>
                        <dt>Last name</dt>
                        <dd>{this.hello_dialog_info.contact_family_name}</dd>
                        <dt>Salutation</dt>
                        <dd>{this.hello_dialog_info.contact_salutation}</dd>
                        <dt>Language</dt>
                        <dd>{this.hello_dialog_info.contact_language}</dd>
                        <dt>Status</dt>
                        <dd>{this.hello_dialog_info.supplier_status}</dd>
                        <dt>Relation status</dt>
                        <dd>{this.hello_dialog_info.client_status}</dd>
                        <dt>Company type</dt>
                        <dd>{this.hello_dialog_info.company_type}</dd>
                        <dt>Relation name</dt>
                        <dd>{this.hello_dialog_info.name}</dd>
                        <dt>City</dt>
                        <dd>{this.hello_dialog_info.city}</dd>
                        <dt>Country code</dt>
                        <dd>{this.hello_dialog_info.country_code}</dd>
                        <dt>NPS</dt>
                        <dd>{this.hello_dialog_info.nps_score}</dd>
                    </dl>
                </div>
            }

            {this.artkey &&
                <div>
                    <div class="row">
                        <div class="col-sm-4">
                            <h2>Mailings</h2>
                        </div>
                    </div>
                    <EmailTable email_collection={this.emails} />
                </div>
            }
        </div>
    }
}
