import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'
import {classes} from '@bitstillery/common/lib/utils'
import {notifier} from '@bitstillery/common/app'

interface FileDropAreaAttrs {
    onupload: (file: File, base64_contents: string) => unknown
}

export class FileDropArea extends MithrilTsxComponent<FileDropAreaAttrs> {
    entered = 0

    process_selected_files(vnode: m.Vnode<FileDropAreaAttrs>, file_list: FileList): void {
        for (let i = 0; i < file_list.length; i++) {
            const file = file_list.item(i)
            if (file) {
                const reader = new FileReader()
                reader.onload = () => {
                    if (reader.result) {
                        vnode.attrs.onupload(file, reader.result as string)
                    } else {
                        notifier.notify(`Cannot upload file ${file.name}`, 'warning')
                    }
                }
                reader.onerror = () => {
                    let msg = `Cannot upload file ${file.name}`
                    const error = reader.error
                    if (error) {
                        msg += `: ${error.name} - ${error.message}`
                    }
                    notifier.notify(msg, 'warning')
                }
                reader.readAsDataURL(file)
            }
        }
    }

    view(vnode: m.Vnode<FileDropAreaAttrs>): m.Children {
        return <div
            className={classes('c-file-drop-area', {
                active: this.entered > 0,
            })}
            ondragover={(drag_event: DragEvent) => {
                drag_event.preventDefault()
                return false
            }}
            ondragenter={() => {
                this.entered++
                return false
            }}
            ondragleave={() => {
                this.entered--
                return false
            }}
            ondrop={(drag_event: DragEvent) => {
                this.entered = 0
                drag_event.preventDefault()
                if (drag_event.dataTransfer) {
                    this.process_selected_files(vnode, drag_event.dataTransfer.files)
                }
            }}
        >
            <input
                type="file"
                value={''}
                multiple={true}
                onchange={(e: InputEvent) => {
                    const input_element = e.target as HTMLInputElement
                    if (input_element.files) {
                        this.process_selected_files(vnode, input_element.files)
                        input_element.files = null
                        input_element.value = ''
                    }
                }}
            />
        </div>
    }
}
