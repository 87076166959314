/** llm:tested */
import {format_iso_to_fixed_date_format} from '@bitstillery/common/lib/format'

import api from '@/api'
import {download_binary_file_from_base64_str, formatDate} from '@/_utils'

interface FileType {
    file_hash: string
    file_name: string
}

interface Context {
    artkey(): string
    date(): Date
    reference(): string
    supplier(): {
        name(): string
    }
    name(): string
}

interface ApiResponse {
    result: string
}

export function download_file(file: FileType, type: string): void {
    if (type === 'attachment') {
        const data = {
            file_hash: file.file_hash,
            file_name: file.file_name,
        }
        api.call2('attachment.get_order_attachment_file', data, (resp: ApiResponse) => handle_download_file(resp, file))
    }
}

export function handle_download_file(resp: ApiResponse, product_file: FileType): void {
    download_binary_file_from_base64_str(resp.result, product_file.file_name)
}

export function download_selected_files(file_selection: any, context: Context, type: string): void {
    if (type === 'attachment') {
        const data = {
            selection: file_selection,
        }
        api.call2('attachment.get_selected_attachment_file_batch', data, (resp: ApiResponse) => handle_file_batch(resp, context, type))
    }
}

export function download_all_files(context: Context, type: string): void {
    if (type === 'purchase-attachment') {
        const data = {
            purchase_order_artkey: context.artkey(),
        }
        api.call2('attachment.get_purchase_order_attachment_file_batch', data, (resp: ApiResponse) => handle_file_batch(resp, context, type))
    } else if (type === 'relation-attachment') {
        const data = {
            supplier_artkey: context.artkey(),
        }
        api.call2('attachment.get_relation_attachment_file_batch', data, (resp: ApiResponse) => handle_file_batch(resp, context, type))
    }
}

export function handle_file_batch(resp: ApiResponse, context: Context, type: string): void {
    if (['purchase-attachment', 'sales-attachment', 'attachment'].includes(type)) {
        const date = format_iso_to_fixed_date_format(new Date(context.date()).toISOString())
        download_binary_file_from_base64_str(resp.result,
            `${context.reference()} - ${context.supplier().name()} - ${date} files.zip`)
    } else if (['relation-attachment'].includes(type)) {
        const date = formatDate(context.date())
        download_binary_file_from_base64_str(resp.result,
            `${context.reference()} - ${context.name()} - ${date} files.zip`)
    }
}
