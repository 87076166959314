import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {
    proforma_or_invoice_renderer,
    ProformaInvoice,
    ProformaInvoiceRendererAttrs,
} from '@bitstillery/common/pdf/proforma_invoice_renderer'
import {CasesOrBottles} from '@bitstillery/common/pdf/pdf'
import {SalesOrder} from '@bitstillery/common/models/sales_order'
import {Spinner} from '@bitstillery/common/components'
import {notifier} from '@bitstillery/common/app'

import {PDFEmailer} from '@/components/pdf_emailer'
import {
    PDFBottlesOrCasesSelector,
    PDFCommentTemplate,
    PDFComponent,
    PDFDecimalPointSelector,
    PDFHelper,
    PDFUpdateAndDownload,
} from '@/components/pdf_helper'
import {GetFastSalesOrderWithItemsResponse, SalesApi} from '@/factserver_api/sales_api'
import {DocumentType} from '@/factserver_api/email_api'
import {CheckBox} from '@/components/html_components'

export class ViewInvoice extends MithrilTsxComponent<unknown> {
    sales_order_artkey: number
    sales_api = new SalesApi()
    sales_order: GetFastSalesOrderWithItemsResponse | null = null
    pdf_helper = new PDFHelper<ProformaInvoiceRendererAttrs>(proforma_or_invoice_renderer)
    show_in_cases_or_bottles: CasesOrBottles | null = null
    show_decimal_point_as = 'en'
    show_cbs_code = true
    show_bottle_lot = false
    show_article_code = false
    show_country_of_origin = true
    show_liters_of_alcohol = false
    group_similar_items = false

    constructor() {
        super()

        if (!m.route.param('artkey')) {
            m.route.set('/sales/orders')
            throw Error('No sales order artkey in route.')
        } else {
            this.sales_order_artkey = +m.route.param('artkey')
            this.fetch_sales_order()
        }
    }

    fetch_sales_order(): void {
        this.sales_api.get_fast_sales_order_with_items(this.sales_order_artkey, this.group_similar_items).subscribe({
            next: (response: GetFastSalesOrderWithItemsResponse) => {
                this.sales_order = response
                if (!this.show_in_cases_or_bottles) {
                    this.show_in_cases_or_bottles =
                        this.sales_order.supplier.price_preference === 'bottle'
                            ? CasesOrBottles.bottles
                            : CasesOrBottles.cases
                }
                this.rerender_pdf(false)
                m.redraw()
            },
            error: () => {
                notifier.notify(`Cannot load sales order with artkey ${this.sales_order_artkey}`, 'warning')
                m.route.set('/sales/orders')
            },
        })
    }

    pdf_file_name(): string {
        return `Invoice - ${this.sales_order?.invoice_number || ''} - ${this.sales_order?.supplier.name || ''}.pdf`
    }

    /** Rerender the pdf. Any pending changes concerning the pdf will be saved. */
    rerender_pdf(save_settings = true): void {
        const account = this.pdf_helper.current_account()
        if (!this.sales_order || !account) {
            return
        }
        if (save_settings) {
            this.save_pdf_settings()
        }
        this.pdf_helper.render_base64_encoded({
            sales_order: this.sales_order as SalesOrder,
            account: account,
            show_article_code: this.show_article_code,
            show_country_of_origin: this.show_country_of_origin,
            show_cbs_code: this.show_cbs_code,
            show_bottle_lot: this.show_bottle_lot,
            cases_or_bottles: this.show_in_cases_or_bottles || CasesOrBottles.cases,
            decimal_locale: this.show_decimal_point_as,
            show_liters_of_alcohol: this.show_liters_of_alcohol,
            group_similar_items: this.group_similar_items,
            proforma_or_invoice: ProformaInvoice.INVOICE,
        })
    }

    save_pdf_settings(): void {
        if (!this.sales_order) {
            return
        }
        this.sales_api.update_invoice_comment(this.sales_order.artkey, this.sales_order.invoice_comment).subscribe({
            next: () => notifier.notify('Settings for PDF saved', 'success'),
            error: () => notifier.notify('Could not save the settings for the PDF', 'warning'),
        })
    }

    view(): m.Children {
        if (!this.sales_order) return <Spinner/>
        return (
            <div className="c-view-invoice sales orders pdf-view">
                <div className="pdf-wrapper">
                    <form className="flex-form">
                        <div className="fieldset">
                            <PDFBottlesOrCasesSelector
                                onchange={(new_value: CasesOrBottles) => {
                                    this.show_in_cases_or_bottles = new_value
                                    this.rerender_pdf(false)
                                }}
                                value={this.show_in_cases_or_bottles}
                            />

                            <PDFDecimalPointSelector
                                onchange={(new_value: string) => {
                                    this.show_decimal_point_as = new_value
                                    this.rerender_pdf(false)
                                }}
                                value={this.show_decimal_point_as}
                            />

                            <CheckBox
                                label="CBS Code"
                                checked={this.show_cbs_code}
                                onchange={() => {
                                    this.show_cbs_code = !this.show_cbs_code
                                    this.rerender_pdf(false)
                                }}
                            />
                            <CheckBox
                                label="Bottle lot"
                                checked={this.show_bottle_lot}
                                onchange={() => {
                                    this.show_bottle_lot = !this.show_bottle_lot
                                    this.rerender_pdf(false)
                                }}
                            />
                            <CheckBox
                                label="Liters of alcohol"
                                checked={this.show_liters_of_alcohol}
                                onchange={() => {
                                    this.show_liters_of_alcohol = !this.show_liters_of_alcohol
                                    this.rerender_pdf(false)
                                }}
                            />

                            <CheckBox
                                label="Country of origin"
                                checked={this.show_country_of_origin}
                                onchange={() => {
                                    this.show_country_of_origin = !this.show_country_of_origin
                                    this.rerender_pdf(false)
                                }}
                            />
                            <CheckBox
                                label="Article code"
                                checked={this.show_article_code}
                                onchange={() => {
                                    this.show_article_code = !this.show_article_code
                                    this.rerender_pdf(false)
                                }}
                            />

                            <CheckBox
                                label="Group similar items"
                                checked={this.group_similar_items}
                                onchange={() => {
                                    this.group_similar_items = !this.group_similar_items
                                    this.fetch_sales_order()
                                }}
                            />

                            <PDFCommentTemplate
                                value={this.sales_order.invoice_comment}
                                onchange={(value: string) => {
                                    if (this.sales_order) {
                                        this.sales_order.invoice_comment = value
                                        this.rerender_pdf(true)
                                    }
                                }}
                            />

                            <PDFEmailer
                                sales_order={this.sales_order}
                                supplier={this.sales_order.supplier}
                                document_type={DocumentType.INVOICE}
                                pdf_file_name={this.pdf_file_name()}
                                pdf_helper={this.pdf_helper}
                            />
                        </div>

                        <PDFUpdateAndDownload
                            rerender_pdf={() => this.rerender_pdf()}
                            pdf_file_name={this.pdf_file_name()}
                            pdf_helper={this.pdf_helper}
                        />
                    </form>
                    <PDFComponent pdf_helper={this.pdf_helper} />
                </div>
            </div>
        )
    }
}
