import m from 'mithril'
import {proxy} from '@bitstillery/common/lib/proxy'
import {$s, events, logger} from '@bitstillery/common/app'
import {copy_object, merge_deep, reset_deep, url_query_string} from '@bitstillery/common/lib/utils'
import {reset_validation} from '@bitstillery/common/lib/validation'
import {watch} from '@bitstillery/common/lib/store'
import {match, compile} from 'path-to-regexp'

// One-time trigger to try to restore the current step from the url.
let onpageloaded = false

export interface EntityCommon {
    artkey?: number
    meta: boolean
    reference?: string
    title?: string
}

export interface ContextProviderDataGeneric {
    /** The root artkey of the context; e.g. /sales/orders/{"root_artkey}/view/manage/{:entity_type}/{:entity_artkey} */
    root_artkey: number
    /** The artkey of the entity that is currently being upserted. */
    entity_artkey: number | null
    /** One context can change several attributes ("entities") within the context. */
    entities: {
        [key: string]: EntityCommon
    }
    /** Path for the root route */
    root_path?: string
    /** Type of the current entity */
    entity_type: string
    /** Title of the context */
    title?: string
    /** Allow any additional properties on the context object */
    [key: string]: any
}

interface ContextProviderAttrs<T extends ContextProviderDataGeneric> {
    /** This is a common state structure to deal with upsert forms. */
    data: T
    /** A hook that is called when the context is active and the page is loaded. */
    onpageload?: Function
    /** Identifies each context provider; mainly for logging. */
    name: string
    /** A path-to-regexp string that describes the route matching */
    route: {
        match: string
        root: string
    }
    /** A set of functions that provide data to the context. */
    transforms: {
        bootstrap: Function
        fetch_entity: Function
        /** This hook is called when the entity artkey changed to another or null */
        reset_entity?: Function
    }
    validation?: Function
}

/**
 * A ContextProvider is inserted at a list or view level, in
 * order to handle state transitions through the use of routing
 * and transforms.
 */
export class ContextProvider<T extends ContextProviderDataGeneric> {
    /** This is the initial context data state; which is restored when the context becomes inactive. */
    bootstrapped_data: typeof this.data
    /** The initialized validation object */
    $v: Record<string, any>

    entity_artkey = null! as number
    onpageload: Function
    root_artkey = null! as number
    root_processed: boolean = false
    route_entity: any
    route_match: any
    route_root: any

    name: string

    data: ContextProviderAttrs<T>['data']
    data_copy: typeof this.data
    route: ContextProviderAttrs<T>['route']
    transforms: ContextProviderAttrs<T>['transforms']

    constructor({data, name, onpageload, route, transforms, validation}:ContextProviderAttrs<T>) {
        // _data is restored when the context becomes inactive.
        this.data_copy = copy_object(data)
        this.data = proxy(data)

        this.name = name
        this.onpageload = onpageload ? onpageload : () => {}
        this.route_match = match(route.match)
        this.route_root = compile(route.root)
        this.route_entity = compile(route.match)

        this.route = route
        this.transforms = transforms

        // The store is not ready yet when the context provider is constructed;
        // it is waiting until the application is ready before it becomes active.
        events.once('app.routed', async() => {
            this.$v = validation ? validation() : null
            watch($s.env, 'uri', (uri) => {
                this.match_uri(uri)
            })

            this.match_uri($s.env.uri)
        })
    }

    bootstrap_data() {
        logger.debug(`[${this.name}] bootstrap data snapshot`)
        this.bootstrapped_data = copy_object(this.data)
    }

    deactivate_context() {
        merge_deep(this.data, {
            root_artkey: null,
            entity_artkey: null,
            entity_type: null,
        })

        logger.debug(`[${this.name}] context inactive; return to initial state`)
        const restored_data = copy_object(this.data_copy)
        // Reset the whole context state.
        logger.debug(`[${this.name}] reset entities: ${Object.keys(this.data.entities).join(', ')}`)
        reset_deep(this.data.entities, restored_data.entities, ['meta', 'title'])
    }

    link_entity(collection, entity_type: string, entity_artkey: number | null = null) {
        if (this.data.entity_type === entity_type) {
            if (entity_artkey) {
                if (this.data.entity_artkey === entity_artkey) {
                    return `${this.data.root_path}${url_query_string()}`
                }
                return `${this.data.root_path}/${entity_type}/${entity_artkey}${url_query_string()}`
            }
            if (!this.data.entity_artkey) {
                // Make sure the collection doesn't have anything selected.
                if (collection.state.selection.ids.length > 0) {
                    collection.select_none()
                }
                return `${this.data.root_path}${url_query_string()}`
            }
        } else {
            if (entity_artkey) {
                return `${this.data.root_path}/${entity_type}/${entity_artkey}${url_query_string()}`
            }
        }
        return `${this.data.root_path}/${entity_type}${url_query_string()}`
    }

    link_entity_active(entity_type: string | Array<string>, entity_artkey: any = undefined) {
        let matched_entity_type = false
        if (Array.isArray(entity_type)) {
            matched_entity_type = entity_type.includes(this.data.entity_type)
        } else {
            matched_entity_type = this.data.entity_type === entity_type
        }
        if (entity_artkey === undefined) {
            return matched_entity_type
        } else if (entity_artkey === null) {
            return matched_entity_type && !this.data.entity_artkey
        }
        return matched_entity_type && this.data.entity_artkey === entity_artkey
    }

    async match_uri(uri: string) {
        const root_matched = this.route_match(uri)

        if (!root_matched) {
            if (this.data.root_artkey || this.data.entity_artkey) {
                this.deactivate_context()
            }
            return
        }

        const root_artkey = +root_matched.params.root_artkey

        if (root_artkey) {
            if (root_artkey !== this.data.root_artkey) {
                this.root_processed = false
                this.data.root_artkey = root_artkey
                this.data.root_path = this.route_root({root_artkey: String(root_artkey)})
                logger.debug(`[${this.name}] init context root`)
                await this.transforms.bootstrap(this)
            }
        } else {
            this.data.root_path = this.route_root()
            logger.debug(`[${this.name}] root path: ${this.data.root_path}`)
            // THIS MUST ONLY HAPPEN ONCE; WHEN THE CONTEXT BECOMES ACTIVE
            if (!this.root_processed) {
                logger.debug(`[${this.name}] init context root`)
                await this.transforms.bootstrap(this)
            }
        }
        // Normally, the data is bootstrapped in the bootstrap transform.
        // When the method call is left out, we just use the initial data
        // as bootstrapped_data.
        if (!this.bootstrapped_data) {
            this.bootstrap_data()
        }

        const new_entity_artkey = root_matched.params.entity_artkey ? +root_matched.params.entity_artkey : null
        const new_entity_type = root_matched.params.entity_type
        const old_entity_type = this.data.entity_type

        this.data.entity_type = new_entity_type

        if (new_entity_artkey && new_entity_artkey !== this.data.entity_artkey) {
            logger.debug(`[${this.name}] fetch entity: ${new_entity_type}/${new_entity_artkey}`)
            this.data.entity_artkey = new_entity_artkey

            if (this.transforms.fetch_entity) {
                const {collection} = await this.transforms.fetch_entity(this)

                if (collection) {
                    let selection_artkey = new_entity_artkey as number | string
                    collection.state.selection.all = false
                    collection.select_one({artkey: selection_artkey})
                }
            }
        } else if (this.data.entity_artkey && !new_entity_artkey || !new_entity_type) {
            this.data.entity_artkey = null
            // Restore the entity to the bootstrapped state, since the context is still active.
            const bootstrapped_data = copy_object(this.bootstrapped_data)
            logger.debug(`[${this.name}] reset entity: ${old_entity_type}/${this.data.entity_artkey}`)
            reset_deep(this.data.entities[old_entity_type], bootstrapped_data.entities[old_entity_type], ['meta', 'title'])

            // This hook is probably not needed if we further refine the data model
            // into entities, shared and root state.
            if (this.transforms.reset_entity) {
                this.transforms.reset_entity(old_entity_type)
            }
        }

        if (this.$v && new_entity_type && this.$v[new_entity_type]) {
            reset_validation(this.$v[new_entity_type])
        }

        if (this.data.entity_type) {
            if ($s.panels.context.collapsed) {
                $s.panels.context.collapsed = false
            }
            this.data.title = this.data.entities[this.data.entity_type].title
        } else if (!$s.panels.context.collapsed) {
            $s.panels.context.collapsed = true
        }

        if (!onpageloaded) {
            this.onpageload()
            onpageloaded = true
        }

        this.root_processed = true
    }

    meta_entity_active() {
        return Object.entries(this.data.entities)
            .filter(([, entity]) => entity.meta)
            .map(([key]) => key)
            .includes(this.data.entity_type)
    }

    /**
     * Manual way to clear and reset the current entity.
     * @param entity_type
     */
    reset_entity(entity_type: string) {
        const restored_data = copy_object(this.bootstrapped_data)
        logger.debug(`[${this.name}] reset entity: ${entity_type}/${this.data.entity_artkey}`)
        reset_deep(this.data.entities[entity_type], restored_data.entities[entity_type], ['meta', 'title'])

        if (this.transforms.reset_entity) {
            this.transforms.reset_entity(entity_type)
        }
        reset_validation(this.$v[entity_type])
    }

    select_next(collection) {
        collection.select_next()
        const root_matched = this.route_match($s.env.uri)
        root_matched.params.entity_artkey = String(collection.state.selection.ids[0])
        const new_path = this.route_entity(root_matched.params)
        m.route.set(`${new_path}${url_query_string()}`, {}, {replace: true})
    }

    select_one(collection, item, entity_type) {
        collection.select_one(item)
        const root_matched = this.route_match($s.env.uri)
        merge_deep(root_matched.params, {
            entity_artkey: String(item.artkey),
            entity_type,
        })
        const new_path = this.route_entity(root_matched.params)
        m.route.set(`${new_path}${url_query_string()}`, {}, {replace: true})
    }

    select_none(collection) {
        // Deselect with an entity like data_card and
        // without a collection must be possible.
        if (collection) {
            collection.select_none()
        }
        const root_matched = this.route_match($s.env.uri)
        merge_deep(root_matched.params, {
            entity_artkey: null,
            entity_type: null,
        })
        const new_path = this.route_entity(root_matched.params)
        m.route.set(`${new_path}${url_query_string()}`, {}, {replace: true})
    }

    select_previous(collection) {
        collection.select_previous()
        const root_matched = this.route_match(m.route.get())
        root_matched.params.entity_artkey = String(collection.state.selection.ids[0])
        const new_path = this.route_entity(root_matched.params)
        m.route.set(`${new_path}${url_query_string()}`, {}, {replace: true})
    }
}
