import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {countries} from '@bitstillery/common/lib/countries'
import {
    CollectionHeader,
    CollectionItems,
    CollectionSelection,
    CollectionView,
    Link,
    PanelFilters,
} from '@bitstillery/common/components'
import {CollectionTransforms, CollectionProxy} from '@bitstillery/common/lib/collection'
import {proxy} from '@bitstillery/common/lib/proxy'
import {generate_filters} from '@bitstillery/common/lib/filters'
import {api} from '@bitstillery/common/app'

import {ProcessVouchers} from './processes'

import {GetAllUsersResponse} from '@/factserver_api/fact2server_api'
import {$m} from '@/app'

const filters = generate_filters<any>({
    buyers: {
        icon: 'profile',
        options: [['true', 'Yes'], ['false', 'No']],
        placement: {order: 3},
        serialize: 'string',
        type: 'SELECT_SINGLE',
    },
    client_status: {
        icon: 'profile',
        placement: {order: 4},
        serialize: ['string'],
        type: 'SELECT_MULTIPLE',
    },
    company_type: {
        icon: 'company',
        options: ['MSI', 'Trader', 'Retail', 'Wholesale', 'Travel Retail / Duty Free'].map((i) => [i, i]),
        placement: {order: 5},
        serialize: ['string'],
        type: 'SELECT_MULTIPLE',
    },
    operates_online: {
        icon: 'online',
        options: [['true', 'Yes'], ['false', 'No']],
        placement: {order: 8},
        serialize: 'string',
        type: 'SELECT_SINGLE',
    },
    purchase_manager: {
        icon: 'profile',
        placement: {order: 6},
        serialize: ['number'],
        type: 'SELECT_MULTIPLE',
    },
    sales_manager: {
        icon: 'profile',
        placement: {order: 7},
        serialize: ['number'],
        type: 'SELECT_MULTIPLE',
    },
    search_terms: {
        placement: {order: 0},
        serialize: 'string',
        type: 'TEXT',
    },
    show_mode: {
        options: [
            ['ONLY_IN_VOUCHER_PROMOTION', ' Only Included'],
            ['ONLY_IN_NOT_VOUCHER_PROMOTION', 'Only Excluded'],
        ],
        serialize: 'string',
        type: 'SELECT_SINGLE',
    },
    suppliers: {
        icon: 'profile',
        options: [['true', 'Yes'], ['false', 'No']],
        placement: {order: 2},
        serialize: 'string',
        type: 'SELECT_SINGLE',
    },
})

const transforms:CollectionTransforms = {
    filters_to_query: (filters) => {
        const query = {
            filters: {
                buyers: filters.buyers.selection,
                client_status: filters.client_status.selection,
                company_type: filters.company_type.selection,
                operates_online: filters.operates_online.selection,
                purchase_manager: filters.purchase_manager.selection,
                sales_manager: filters.sales_manager.selection,
                search_terms: [filters.search_terms.selection ? filters.search_terms.selection : ''],
                show_mode: filters.show_mode.selection ? filters.show_mode.selection : undefined,
                suppliers: filters.suppliers.selection,
            },
            page_size: collection.state.page_size,
            sort_by: collection.state.sort.by,
            sort_ascending: collection.state.sort.order === 'asc' ? 'ASC' : 'DESC',
        }
        return query
    },
    filter_metadata: {
        transform: async(filters) => {
            if (!filters.purchase_manager.options.length) {
                const query_param = '?only_active=true&only_traders=true'
                const {status_code, result} = await api.get<GetAllUsersResponse[]>(`discover/users${query_param}`)
                if (status_code > 299) {
                    return
                }
                const traders = result.map((i) => [i.artkey, i.name])
                filters.purchase_manager.options.splice(0, 0, ...traders)
                filters.sales_manager.options.splice(0, 0, ...traders)
            }

            if (!filters.client_status.options.length) {
                filters.client_status.options.splice(0, 0, ...$m.data.client_statuses.filter(
                    (status: string) => status !== 'Not Active' && status !== 'Do Not Approach',
                ).map((val: string) => [val, val]))
            }
        },
    },
    selection: async() => {
        const current = collection.state.selection.current as any
        const {result:voucher_relations} = await api.get(`discover/vouchers/${m.route.param('artkey')}/relations`) as any
        current.splice(current, current.length, ...voucher_relations.map((i) => i.relation_artkey))
    },
}

const collection = new CollectionProxy()

const columns = [
    {
        name: 'Relation',
        render: (row) => {
            return <div className="td-group">
                <span className={'header'}>
                    {row.name ? <Link
                        target="_blank"
                        href={`/crm/relations/${row.artkey}/edit`}
                    >
                        {row.name}
                    </Link> : 'Unknown relation'}
                </span>
            </div>
        },
    },
    {
        name: 'Location',
        render: (row) => {
            return `${countries[row.country_code]}, ${row.city}`
        },
        width: '2fr',
    },
    {
        name: 'Sales manager',
        render: (row) => row.sales_manager_name,
    },
    {
        name: 'Included / Excluded',
        render: (row) => {
            return row.is_in_voucher_promotion ? 'Included' : 'Excluded'
        },
    },
]

export class VoucherRelationSelection extends MithrilTsxComponent<any> {
    selected_relations: number[] = []

    data = proxy({
        voucher: null,
        voucher_relations: [],
    })

    voucher_artkey:number = +m.route.param('artkey')

    async oninit() {
        collection.init({
            endpoint: {
                // path depends on voucher id and is therefor filled dynamically
                // from the endpoint transform
                method: 'get',
                path: `discover/vouchers/${this.voucher_artkey}/relations/collection-view`,
            },
            selection: {
                all: false,
                field: 'is_in_voucher_promotion',
            },
            sort: {
                by: 'name',
                order: 'asc',
                options: [
                    ['name', 'Name'],
                    ['location', 'Location'],
                    ['sales_manager', 'Sales manager'],
                ],
            },
        }, filters, transforms)

        const [{result: voucher}, {result:voucher_relations}] = await Promise.all([
            api.get(`discover/vouchers/${this.voucher_artkey}`),
            api.get(`discover/vouchers/${this.voucher_artkey}/relations`),
        ]) as any
        Object.assign(this.data, {voucher, voucher_relations})
        collection.state.selection.current = voucher_relations.map((i) => i.relation_artkey)
    }

    view(): m.Children {
        return <div className="c-voucher-relation-selection view-container">
            <PanelFilters collection={collection}>
                <CollectionSelection
                    collection={collection}
                    filter={filters.show_mode}
                    on_submit={async() => {
                        const query_data = transforms.filters_to_query(filters)
                        const data = {
                            relation_filter: null,
                            relation_artkeys_to_exclude: [],
                            relation_artkeys_to_use: [],
                        }

                        if (collection.state.selection.mode === 'select') {
                            if (collection.state.selection.all) {
                                const exclude_ids = collection.state.selection.ids
                                // Send the filter query
                                data.relation_filter = query_data.filters
                                data.relation_artkeys_to_exclude = exclude_ids
                            } else {
                                const include_ids = collection.state.selection.ids
                                data.relation_artkeys_to_use = include_ids
                            }
                            await api.post(`discover/vouchers/${this.voucher_artkey}/relations`, data, true)
                        } else if (collection.state.selection.mode === 'deselect') {
                            if (collection.state.selection.all) {
                                const exclude_ids = collection.state.selection.ids
                                // Send the filter query
                                data.relation_filter = query_data.filters
                                data.relation_artkeys_to_exclude = exclude_ids
                            } else {
                                const include_ids = collection.state.selection.ids
                                data.relation_artkeys_to_use = include_ids
                            }

                            await api.post(`discover/vouchers/${this.voucher_artkey}/relations/remove`, data, true)
                        }
                    }}
                />
            </PanelFilters>

            <CollectionView mode="table">
                <ProcessVouchers
                    active='relations'
                    context={{
                        artkey: this.voucher_artkey,
                    }}
                />

                <CollectionHeader collection={collection} columns={columns}/>
                <CollectionItems
                    collection={collection}
                    columns={columns}
                    on_selection_mode_change={(mode) => {
                        filters.show_mode.disabled = (mode !== '')

                        // Make sure the show_mode filters matches selection state,
                        // so only appropriate items show up in the filter results.
                        if (mode === 'select') {
                            filters.show_mode.selection = 'ONLY_IN_NOT_VOUCHER_PROMOTION'
                        } else if (mode === 'deselect') {
                            filters.show_mode.selection = 'ONLY_IN_VOUCHER_PROMOTION'
                        }
                    }}
                />
            </CollectionView>
        </div>
    }

}
