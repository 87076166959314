import {Observable} from 'rxjs'

import {Api, construct_query_parameters, FactserverEmptyResponse, FactserverRequestData} from './api'
import {Bottle, Case, Item} from './item_model'
import {DownloadExcelResponse, LOENDERSLOOT_WAREHOUSE_ARTKEY} from './factserver_generic'
import {
    CombinedOrderStatus,
    GetBuyFromAccountPurchaseOrderResponse,
    GetBuyFromAccountSalesOrderResponse,
    GetSalesOrderFiguresResponse, GetSalesOrderResponse,
    UpdateSalesOrderRequest,
} from './fact2server_api'

import {convert_from_currency_to_euro} from '@/factserver_api/currencies'
import {SearchFilter} from '@/components/collection/collection_table'

export interface CreateEntityResponse {
    artkey: number
}

export interface Target {
    artkey?: number
    month: number
    year: number
    turnover: number
    gross_margin_percentage: number
}

export interface GetTargetsForUserRequest extends FactserverRequestData {
    user_artkey: number | undefined
}

export interface BuyItemsFromAccountResponse {
    purchase_order_artkey: number
    purchase_order_reference: string
    purchase_order_account_slug: string
    sales_order_artkey: number
    sales_order_reference: string
    sales_order_account_slug: string
}

export interface GetTargetsForYearResponse {
    targets: Target[]
}

export enum SalesOrderAdditionalValueType {
    FIXED = 'FIXED',
    PERCENTAGE = 'PERCENTAGE',
}

export enum SalesOrderAdditionalType {
    REVENUE = 'revenue',
    DISCOUNT = 'discount',
    VOUCHER = 'voucher',
    TRANSPORT = 'transport',
    WASTE_FUND = 'waste_fund',
    PALLET = 'pallet',
    OTHER = 'other',
}

export enum SalesOrderTBOItemStatus {
    CONFIRMED = 'Confirmed',
    ORDERED = 'Ordered',
    OPEN = 'Open',
    NOT_APPROVED = 'Not yet approved',
}

export interface SalesOrderTBOItem {
    artkey: number
    created_on: string
    was_last_updated_on: string
    was_last_updated_by_discover_user: {
        name: string
    }
    was_last_updated_by_portal_user: {
        name: string
    }
    number_of_cases: number
    number_of_bottles: number
    was_first_sold_for: number
    price_per_case: string
    price_per_case_excl_excise: string
    total_was_sold_for_incl_excise: string
    total_excl_excise: string
    vat_percentage: string
    vat_total: string
    excise_per_case: string
    status: SalesOrderTBOItemStatus
    confirmed_for_purchase_date: boolean
    have_case_in_stock: boolean

    purchase_order_reference: string
    purchase_order_account_slug: string
    purchase_order_eta: string | null
    created_from_portal_timestamp: string | null
    purchase_order_date: string | null

    portal_comment: string

    case: Case
}

export interface SalesOrderItem {
    artkey: number
    created_on: string
    was_last_updated_on: string
    was_last_updated_by_discover_user: {
        name: string
    }
    was_last_updated_by_portal_user: {
        name: string
    }
    sales_order_artkey: number
    number_of_cases: number
    number_of_bottles: number
    is_reselling_item: boolean
    description: string | null
    portal_comment: string | null
    created_from_portal_timestamp: string | null

    price_per_case: string
    price_per_case_excl_excise: string
    euro_was_sold_for: string
    excise_per_case: string
    was_first_sold_for: string
    total_was_sold_for: string
    total_excl_excise: string

    vat_percentage: string
    vat_per_case: string
    vat_total: string

    margin_percentage: string
    total_margin: string
    loendersloot_outtake_item: {
        artkey: number | null
        article_lo: string | null
        lot: string | null
        quantity: number | null
        description: string | null
    }

    sales_order: {
        artkey: number
        reference: string
        created_on: string
        was_sold_in: string
        sold_against_rate: number
        sales_order_status: string
        account: {
            slug: string
            name: string
        }
        supplier: {
            name: string
        }
        was_handled_by: {
            profile: {
                name: string
            }
        }
    }

    item: Item
}

export enum SalesOrderStatus {
    PENDING = 'Pending',
    FINALIZED = 'Finalized',
    CONFIRMED = 'Confirmed',
    SAVED = 'Saved',
    INVOICED = 'Invoiced',
    CANCELLED = 'Cancelled',
}

export enum DeliveryStatus {
    CREATED = 'Created',
    CONFIRMED = 'Confirmed',
}

export interface SalesOrderBasketItem {
    artkey: number
    number_of_cases: number

    price_per_case: number
    excise_per_case: number
    total_value: number
    updated_timestamp: string
    portal_comment: string

    offer_item: {
        case: Case
    }
}

export interface SalesOrderCreditItem {
    artkey: number
    number_of_cases: number
    number_of_bottles: number
    total_value: number
    vat_total: string
    total_margin: string
    costs: string

    item: {
        lot: string | null
    }
    sales_order_item: SalesOrderItem
}

export interface SalesOrderAdditionalItem {
    artkey: number
    description: string | null
    quantity: number
    vat_percentage: string
    // The calculated total value.
    total_value: string
    // The value per quantity to compute. Type depends on value_type field.
    value_per_quantity: string
    // Calculated value (always an amount).
    price_per_unit: string
    value_type: SalesOrderAdditionalValueType
    sales_order_additional_type: SalesOrderAdditionalType
}

/* ------------------------------------------------------------
 * ---- Derived fields: credit items.
 * ------------------------------------------------------------*/
export function calculate_total_value_credit(sales_order_credit_item: SalesOrderCreditItem): number {
    return sales_order_credit_item.number_of_cases * +sales_order_credit_item.sales_order_item.price_per_case
}

export function calculate_total_margin_credit(
    sales_order_credit_item: SalesOrderCreditItem,
    sales_order: GetFastSalesOrderWithItemsResponse,
): number {
    const eur_price = convert_from_currency_to_euro(
        +sales_order_credit_item.sales_order_item.price_per_case,
        sales_order.was_sold_in,
        sales_order.sold_against_rate,
    )
    return (
        -1 *
        sales_order_credit_item.number_of_cases *
        (eur_price - sales_order_credit_item.sales_order_item.item.euro_was_bought_for)
    )
}

export function calculate_total_ex_duty_credit(sales_order_credit_item: SalesOrderCreditItem): number {
    return (
        sales_order_credit_item.number_of_cases *
        (+sales_order_credit_item.sales_order_item.price_per_case -
            +sales_order_credit_item.sales_order_item.excise_per_case)
    )
}

export function calculate_number_of_bottles_credit(sales_order_credit_item: SalesOrderCreditItem): number {
    return (
        sales_order_credit_item.sales_order_item.item.case.number_of_bottles * sales_order_credit_item.number_of_cases
    )
}

/* ------------------------------------------------------------
 * ---- Derived fields: tbo items.
 * ------------------------------------------------------------*/
export function calculate_total_value_tbo(sales_order_tbo_item: SalesOrderTBOItem): number {
    return sales_order_tbo_item.number_of_cases * +sales_order_tbo_item.price_per_case
}

export function calculate_total_ex_duty_tbo(sales_order_tbo_item: SalesOrderTBOItem): number {
    return sales_order_tbo_item.number_of_cases * +sales_order_tbo_item.price_per_case_excl_excise
}

export function calculate_number_of_bottles_tbo(sales_order_tbo_item: SalesOrderTBOItem): number {
    return sales_order_tbo_item.number_of_cases * sales_order_tbo_item.case.number_of_bottles
}

/* ------------------------------------------------------------
 * ---- Derived fields: soi items.
 * ------------------------------------------------------------*/
export function calculate_total_value_soi(sales_order_item: SalesOrderItem): number {
    return sales_order_item.number_of_cases * +sales_order_item.price_per_case
}

export function calculate_total_ex_duty_soi(sales_order_item: SalesOrderItem): number {
    return sales_order_item.number_of_cases * (+sales_order_item.price_per_case - +sales_order_item.excise_per_case)
}

type SalesOrderItemForMargin = SalesOrderItem |
    {price_per_case: number; excise_per_case: number; number_of_cases: number; item: {euro_was_bought_for: number} }

export function calculate_total_margin_soi(
    sales_order_item: SalesOrderItemForMargin,
    sales_order: GetFastSalesOrderWithItemsResponse,
): number {
    const euro_price = convert_from_currency_to_euro(
        +sales_order_item.price_per_case - +sales_order_item.excise_per_case,
        sales_order.was_sold_in,
        sales_order.sold_against_rate,
    )
    return sales_order_item.number_of_cases * (euro_price - sales_order_item.item.euro_was_bought_for)
}

export function calculate_margin_percentage(
    sales_order_item: SalesOrderItemForMargin,
    sales_order: GetFastSalesOrderWithItemsResponse): number {

    const euro_price = convert_from_currency_to_euro(
        +sales_order_item.price_per_case - +sales_order_item.excise_per_case,
        sales_order.was_sold_in,
        sales_order.sold_against_rate,
    )
    const was_bought_for = sales_order_item.item.euro_was_bought_for
    return (euro_price - was_bought_for) / euro_price
}

export function calculate_number_of_bottles(sales_order_item: SalesOrderItem): number {
    return sales_order_item.number_of_cases * sales_order_item.item.case.number_of_bottles
}

export function is_exw_loendersloot(sales_order: GetSalesOrderResponse): boolean {
    const is_exw = sales_order.incoterm === 'EXW'
    const destination_is_loendersloot = sales_order.destination_artkey === LOENDERSLOOT_WAREHOUSE_ARTKEY
    const origin_is_loendersloot = is_origin_loendersloot(sales_order)
    return is_exw && origin_is_loendersloot && destination_is_loendersloot
}

export function is_origin_loendersloot(sales_order: GetSalesOrderResponse): boolean {
    return sales_order.origin_artkey === LOENDERSLOOT_WAREHOUSE_ARTKEY
}

export interface GetFastSalesOrderWithItemsResponse {
    artkey: number
    combined_status: SalesOrderStatus
    reference: string
    remark: string
    needs_settings_confirmation: boolean
    account: {
        slug: string
        name: string
    }

    additional_total: number
    credit_total: number
    number_of_emails: number
    number_of_edi_messages: number

    date: string
    is_invoiced_on: string
    invoice_number?: number
    was_sent_to_financial_information_system_on: string

    costs: number
    vat_id: string
    is_vat_shifted: boolean
    vat_country_code: string

    delivery: {
        external_reference: string
        status: DeliveryStatus
        estimated_weight_in_kilos: number
        estimated_number_of_pallets: number
        pickup_timeslot_from: string
    }

    destination: {
        artkey: number
        name: string
        city: string
        country_code: string
        excise_id: string | null
        street_address: string
        vat_id: string
        warehouse_id: string | null
        zip_code: string
        destination_type: string
    }
    destination_location: string

    origin: {
        artkey: number
        name: string
    }

    requested_delivery_date: string
    incoterm_and_location: string
    incoterm_location: string
    incoterm: string
    insurance_type: {
        artkey: number
        name: string
    }

    escrow_loendersloot: boolean
    includes_excise: boolean
    invoice_comment: string
    outtake_instruction_comment: string | null
    excise_total: number

    euro_purchasing_value: number
    euro_turnover: number
    euro_was_sold_for: number
    margin: number
    margin_percentage: number
    net_margin: number
    product_total: number
    product_total_excl_excise: number
    turnover: number
    vat_total: string
    vat_code: string | null
    sold_against_rate: number
    was_sold_for: string
    was_sold_for_excl_excise: number
    was_sold_for_incl_excise: number
    was_sold_for_incl_vat: number
    was_sold_in: string

    number_of_confirmed_cases: number
    sent_pre_advice_to_loendersloot_on: string | null
    sent_to_loendersloot_on: string | null
    estimated_loading_date: string | null
    frontoffice_instruction: string | null
    warehouse_instruction: string | null
    freight_instruction: string | null

    buy_from_account_sales_order_artkey: number | null
    buy_from_account_sales_order_reference: string | null
    buy_from_account_sales_order_account_slug: string | null
    buy_from_account_sales_order_account_name: string | null
    buy_from_account_purchase_order_artkey: number | null
    buy_from_account_purchase_order_reference: string | null
    buy_from_account_purchase_order_account_slug: string | null
    buy_from_account_purchase_order_account_name: string | null
    is_part_of_buy_from_account: boolean
    is_part_of_buy_from_account_sales_order_reference: string | null
    is_part_of_buy_from_account_sales_order_artkey: number | null
    is_part_of_buy_from_account_sales_order_account_slug: string | null

    pre_advice_reference: string | null
    pre_advice_cancelled_on: string | null
    pre_advice_confirmed_on: string | null
    pre_advice_failure_reason: string | null
    warehouse_status: string | null
    warehouse_reference: number | null
    planned_loading_date: string | null
    // Contains a datetime.
    last_warehouse_message: string

    has_items_missing_eu_address: boolean

    supplier: {
        artkey: number
        name: string
        street_address: string
        zip_code: string
        city: string
        country_code: string
        vat_id: string
        relation_nr: number
        sales_payment_term: {
            description: string
        }
        currency: string
        portal_customs_visibility: string
        price_preference: string
        sales_manager: {
            profile: {
                name: string
            }
        }
        memo: string
        requires_eu_address: boolean
    }

    was_created_by: {
        name: string
    }
    was_handled_by: {
        profile: {
            name: string
        }
    }

    sales_order_items: SalesOrderItem[]
    sales_order_credit_items: SalesOrderCreditItem[]
    sales_order_additionals: SalesOrderAdditionalItem[]
    sales_order_tbo_items: SalesOrderTBOItem[]
    basket_items: SalesOrderBasketItem[]
}

export interface SellableBottlesItem {
    artkey: number
    lot: string | null
    relation_sales_price: string | null
    euro_was_bought_for: string
    case: {
        number_of_bottles: number
        no_eu_address: boolean
    }
    number_of_cases_available: number
}

export interface SellableBottlesWithItems
    extends Pick<Bottle, 'artkey' | 'alcohol_percentage' | 'volume' | 'refill_status'> {
    items: SellableBottlesItem[]
}

export interface GetSalesOrderForRelationResponse {
    artkey: number
    reference: string
    combined_status: string
    created_on: string
    euro_turnover: number
    incoterm_location: string
    number_of_cases: number
    was_sold_in: string
    product_total: number
    destination_location: string | null
    destination: {
        name: string
    }
}

export enum HistoricalItemType {
    SALES_ORDER_ITEM = 'SALES_ORDER_ITEM',
    SALES_ORDER_TBO_ITEM = 'SALES_ORDER_TBO_ITEM',
    SALES_ORDER_CREDIT_ITEM = 'SALES_ORDER_CREDIT_ITEM',
    SALES_ORDER_ADDITIONAL = 'SALES_ORDER_ADDITIONAL',
    SALES_ORDER_PORTAL_ITEM = 'SALES_ORDER_PORTAL_ITEM',
}

export interface GetHistoricalItemsResponse {
    artkey: number
    number_of_units: number
    price_per_unit: number
    currency: string
    relation_artkey: number
    source: HistoricalItemType
    description: string
    case_artkey?: number
    product_name?: string
    volume?: number
    alcohol_percentage?: number
    refill_status?: string
    created_on: string
    created_by: string
}

export interface LoenderslootOuttakeResponse {
    artkey: number
    pallets: number
    weight: number
    contact_email_address: string
    loendersloot_outtake_items: [
        {
            article: string
            article_lo: string
            artkey: number
            description: string
            quantity: number
            unit: number
            lot: string
            is_matched_with_artkey: number | null
        }
    ]
}

export interface DownloadPhotosRequest {
    include_attachments: boolean
    include_item_photos: boolean
}

export interface GetSalesOrderByItemResponse
    extends Pick<
        SalesOrderItem,
        'sales_order' | 'price_per_case_excl_excise' | 'excise_per_case' | 'item' | 'number_of_cases'
    > {
    artkey: number
}

export class SalesApi {
    api = new Api()

    batch_move_item_to_sales_order(
        sales_order_item_artkeys: number[],
        sales_order_tbo_item_artkeys: number[],
        source_sales_order_artkey: number,
        target_sales_order_artkey?: number,
    ): Observable<FactserverEmptyResponse> {
        return this.api.post_request('sales.move.batch_move_item_to_sales_order', {
            source_sales_order_artkey: source_sales_order_artkey,
            target_sales_order_artkey: target_sales_order_artkey,
            sales_order_item_artkeys: sales_order_item_artkeys,
            sales_order_tbo_item_artkeys: sales_order_tbo_item_artkeys,
        })
    }

    buy_items_from_account(
        sales_order_artkey: number,
        selected_soi_artkeys: number[],
    ): Observable<BuyItemsFromAccountResponse> {
        return this.api.post_request('sales.move.buy_items_from_account', {
            sales_order_artkey: sales_order_artkey,
            selected_soi_artkeys: selected_soi_artkeys,
        })
    }

    cancel_tbo_item(tbo_item_artkey: number): Observable<FactserverEmptyResponse> {
        return this.api.post_request('sales.tbo.change_tbo_item_status_from_sales_order', {
            artkey: tbo_item_artkey,
            action: 'cancel',
        })
    }

    confirm_green_transport(sales_order_artkey: number): Observable<FactserverEmptyResponse> {
        return this.api.post_request('sales.green.confirm_shipment_at_green', {
            artkey: sales_order_artkey,
        })
    }

    confirm_tbo_item(tbo_item_artkey: number): Observable<FactserverEmptyResponse> {
        return this.api.post_request('sales.tbo.change_tbo_item_status_from_sales_order', {
            artkey: tbo_item_artkey,
            action: 'confirm',
        })
    }

    create_sales_order(update_sales_order_request: UpdateSalesOrderRequest): Observable<CreateEntityResponse> {
        return this.api.post_fact2server_request('discover/sales-orders', update_sales_order_request)
    }

    creditable_soi_for_supplier_and_product(
        sales_order_item_artkey: number,
        supplier_artkey: number,
        product_artkey: number,
    ): Observable<SalesOrderItem[]> {
        return this.api.post_request('sales.credit.get_creditable_sales_order_items_for_supplier_and_product', {
            sales_order_item_artkey: sales_order_item_artkey,
            supplier_artkey: supplier_artkey,
            product_artkey: product_artkey,
        })
    }

    delete_sales_order_additional_item(sales_order_additional_artkey: number): Observable<FactserverEmptyResponse> {
        return this.api.post_request('sales.additionals.delete_sales_order_additional_from_sales_order', {
            sales_order_additional_artkey: sales_order_additional_artkey,
        })
    }

    delete_sales_order_credit_item(sales_order_credit_item_artkey: number): Observable<FactserverEmptyResponse> {
        return this.api.post_request('sales.credit.delete_sales_order_credit_item_from_sales_order', {
            sales_order_credit_item_artkey: sales_order_credit_item_artkey,
        })
    }

    delete_sales_order_item(sales_order_item_artkey: number): Observable<FactserverEmptyResponse> {
        return this.api.post_request('sales.items.delete_sales_order_item_from_sales_order', {
            sales_order_item_artkey: sales_order_item_artkey,
        })
    }

    delete_sales_order_tbo_item(sales_order_tbo_item_artkey: number): Observable<FactserverEmptyResponse> {
        return this.api.post_request('sales.tbo.delete_tbo_item_from_sales_order', {
            sales_order_tbo_item_artkey: sales_order_tbo_item_artkey,
        })
    }

    update_sales_order(sales_order_artkey: number, update_sales_order_request: UpdateSalesOrderRequest): Observable<any> {
        return this.api.put(`discover/sales-orders/${sales_order_artkey}`, update_sales_order_request)
    }

    update_sales_order_remark(sales_order_artkey: number, remark: string | null): Observable<FactserverEmptyResponse> {
        return this.api.post_fact2server_request(`discover/sales-orders/${sales_order_artkey}/update-remark`, {
            remark: remark,
        })
    }

    export_invoice_to_excel(sales_order_artkey: number): Observable<DownloadExcelResponse> {
        return this.api.post_request('sales.invoice.export_invoice_to_excel', {
            sales_order_artkey: sales_order_artkey,
        })
    }

    to_exact(data): Observable<void> {
        return this.api.post_request('sales.exact.export_sales_orders_to_exact', data)
    }

    get_fast_sales_order_with_items(
        sales_order_artkey: number,
        group_similar_items = false,
    ): Observable<GetFastSalesOrderWithItemsResponse> {
        return this.api.post_request('sales.core.get_fast_sales_order_with_items', {
            sales_order_artkey: sales_order_artkey,
            group_similar_items: group_similar_items,
        })
    }

    get_loendersloot_outtake(sales_order_artkey: number): Observable<LoenderslootOuttakeResponse> {
        return this.api.post_request('sales.loendersloot.get_outtake', {
            sales_order_artkey: sales_order_artkey,
        })
    }

    get_sales_orders_for_relation(
        sales_order_status_list: SalesOrderStatus[],
        supplier_artkey: number,
    ): Observable<GetSalesOrderForRelationResponse[]> {
        return this.api.post_request('sales.core.get_sales_orders', {
            supplier_artkey: supplier_artkey,
            status: sales_order_status_list,
        })
    }

    update_invoice_comment(artkey: number, comment: string): Observable<FactserverEmptyResponse> {
        return this.api.post_request('sales.invoice.update_invoice_comment', {
            artkey: artkey,
            invoice_comment: comment,
        })
    }

    update_outtake_instruction_comment(artkey: number, comment: string | null): Observable<FactserverEmptyResponse> {
        return this.api.post_request('sales.invoice.update_outtake_instruction_comment', {
            artkey: artkey,
            outtake_instruction_comment: comment,
        })
    }

    set_sales_order_status(
        sales_order_artkey: number,
        new_status: CombinedOrderStatus,
    ): Observable<FactserverEmptyResponse> {
        return this.api.post_request('sales.core.set_sales_order_status', {
            sales_order_artkey: sales_order_artkey,
            status: new_status,
        })
    }

    update_tbo_item(
        tbo_item_artkey: number,
        price_per_case: number,
        number_of_cases: number,
        portal_comment: string,
    ): Observable<FactserverEmptyResponse> {
        return this.api.post_request('sales.tbo.update_sales_order_tbo_item', {
            artkey: tbo_item_artkey,
            price_per_case: price_per_case,
            number_of_cases: number_of_cases,
            portal_comment: portal_comment,
        })
    }

    update_credit_item(
        sales_order_credit_item_artkey: number,
        sales_order_item_artkey: number,
        number_of_cases: number,
    ): Observable<FactserverEmptyResponse> {
        return this.api.post_request('sales.credit.add_credit_item_to_sales_order', {
            artkey: sales_order_credit_item_artkey,
            sales_order_item_artkey: sales_order_item_artkey,
            number_of_cases: number_of_cases,
        })
    }

    update_additional_item(
        sales_order_additional_artkey: number,
        sales_order_additional_type: string,
        description: string | null,
        quantity: number,
        value_type: SalesOrderAdditionalValueType,
        value_per_quantity: number,
    ): Observable<FactserverEmptyResponse> {
        return this.api.post_request('sales.additionals.add_additional_to_sales_order', {
            artkey: sales_order_additional_artkey,
            sales_order_additional_type: sales_order_additional_type,
            description: description,
            quantity: quantity,
            value_type: value_type,
            value_per_quantity: value_per_quantity,
        })
    }

    update_sales_order_item(
        sales_order_item_artkey: number,
        item_artkey: number,
        number_of_cases: number,
        price_per_case: number,
        description: string | null,
        portal_comment: string | null,
    ): Observable<FactserverEmptyResponse> {
        return this.api.post_request('sales.items.add_item_to_sales_order', {
            artkey: sales_order_item_artkey,
            item_artkey: item_artkey,
            number_of_cases: number_of_cases,
            price_per_case: price_per_case,
            description: description,
            portal_comment: portal_comment,
        })
    }

    move_item_to_sales_order(
        number_of_cases_to_move: number,
        source_sales_order_artkey: number,
        target_sales_order_artkey?: number,
        sales_order_item_artkey?: number,
        sales_order_tbo_item_artkey?: number,
    ): Observable<FactserverEmptyResponse> {
        return this.api.post_request('sales.move.move_item_to_sales_order', {
            number_of_cases_to_move: number_of_cases_to_move,
            source_sales_order_artkey: source_sales_order_artkey,
            target_sales_order_artkey: target_sales_order_artkey,
            sales_order_item_artkey: sales_order_item_artkey,
            sales_order_tbo_item_artkey: sales_order_tbo_item_artkey,
        })
    }

    queue_download_all_sales_order_photos(sales_order_artkey: number, download_request: DownloadPhotosRequest): Observable<FactserverEmptyResponse> {
        return this.api.post_fact2server_request(`discover/sales-orders/${sales_order_artkey}/queue-photo-download`, download_request)
    }

    key_figures(search_terms: string[], filters: SearchFilter): Observable<GetSalesOrderFiguresResponse> {
        const url_params = construct_query_parameters(
            0,
            search_terms,
            'artkey',
            false,
            filters,
            null,
        )
        return this.api.get(`discover/sales-orders/key-figures?${url_params.toString()}`)
    }

    async get_buy_from_account_sales_orders(sales_order_artkey: number): Promise<GetBuyFromAccountSalesOrderResponse[]> {
        return await this.api.get_async(`discover/sales-orders/${sales_order_artkey}/buy-from-account/sales-orders`)
    }

    async get_buy_from_account_purchase_orders(sales_order_artkey: number): Promise<GetBuyFromAccountPurchaseOrderResponse[]> {
        return await this.api.get_async(`discover/sales-orders/${sales_order_artkey}/buy-from-account/purchase-orders`)
    }
}
