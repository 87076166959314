/** llm:tested*/
import m from 'mithril'
import {Amount, FieldMoney, Spinner} from '@bitstillery/common/components'
import {deref} from '@bitstillery/common/utils'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {notifier} from '@bitstillery/common/app'

import api from '@/api'
import inputs from '@/components/inputs'
import {formatDate, preventDefault} from '@/_utils'
import {SalesOrder, SalesOrderStatus} from '@/sales/models'
import {Case} from '@/models/stock'
import {CreateOrEditSalesOrderComponent} from '@/sales/edit_component'
import {convert_from_source_to_target, convert_from_euro_to_currency} from '@/factserver_api/currencies'
import {$m, $s} from '@/app'

class AddMode {
    static OFFER = 'offer'
    static PURCHASE = 'purchase'
    static MARKET = 'market'
    static CUSTOM = 'custom'
}

export class AddToSalesOrder extends MithrilTsxComponent<unknown> {
    supplier_artkey: number
    supplier_currency: string
    sales_order_artkey: number
    sales_order_currency: any
    on_added_item: () => void
    new_sales_order: any
    sales_orders: any
    empty_sales_order: SalesOrder
    selected_sales_order: any
    stock_items: any
    selected_stock_item: any
    quantity: any
    price_per_case: any
    case_artkey: any
    cases: Case[]
    adding_tbo: boolean
    adding_from_stock: boolean
    avg_purchase_price: number
    list_price: number
    list_price_is_offer: boolean
    offer_item_artkey: string | null
    product_name: string
    offer_item: any
    custom_offer_item: any
    purchase_order_item: any
    spli: any
    loading: boolean
    minimum_quantity: number
    maximum_quantity: number
    mode: string

    constructor(vnode: m.Vnode<any, any>) {
        super()
        this.supplier_artkey = vnode.attrs.supplier_artkey
        this.supplier_currency = vnode.attrs.supplier_currency || $s.currencies.default
        this.sales_order_artkey = vnode.attrs.sales_order_artkey
        this.sales_order_currency = window.prop(vnode.attrs.sales_order_currency || this.supplier_currency)

        this.on_added_item = vnode.attrs.on_added_item

        this.new_sales_order = window.prop(false)
        this.sales_orders = window.prop([])

        this.empty_sales_order = new SalesOrder()
        this.empty_sales_order.was_handled_by_artkey($s.identity.artkey)
        this.empty_sales_order.supplier_artkey(this.supplier_artkey)
        this.selected_sales_order = window.prop(this.empty_sales_order)
        this.selected_sales_order().artkey(this.sales_order_artkey)

        this.stock_items = window.prop([])
        this.selected_stock_item = window.prop(null)

        this.quantity = window.prop(1)
        this.price_per_case = window.prop(null)

        this.case_artkey = window.prop(null)
        this.cases = []

        this.adding_tbo = false
        this.adding_from_stock = false
        this.avg_purchase_price = 0
        this.list_price = 0
        this.list_price_is_offer = false
        this.offer_item_artkey = null
        this.product_name = ''

        this.offer_item = vnode.attrs.offer_item
        this.custom_offer_item = vnode.attrs.custom_offer_item
        this.purchase_order_item = vnode.attrs.purchase_order_item
        this.spli = vnode.attrs.spli

        this.loading = false

        if (this.offer_item || this.custom_offer_item) {
            this.mode = AddMode.OFFER

            if (this.offer_item) {
                this.minimum_quantity = deref(this.offer_item.minimum_quantity) || 1
                this.maximum_quantity = deref(this.offer_item.maximum_quantity)

                this.adding_tbo = this.offer_item.offer_item_type === 'tbo'
                this.case_artkey(this.offer_item.case_artkey)
                this.offer_item_artkey = this.offer_item.artkey
                this.product_name = this.offer_item.product_name
                this.avg_purchase_price = this.offer_item.avg_purchase_price
                if (this.offer_item.supplier_list_price) {
                    this.list_price = +this.offer_item.supplier_list_price
                } else {
                    this.list_price = +this.offer_item.list_price
                }
                this.list_price_is_offer = (this.list_price < +this.offer_item.price_per_case)
            } else {
                this.minimum_quantity = this.custom_offer_item.minimum_quantity || 1
                this.maximum_quantity = this.custom_offer_item.quantity
                this.adding_tbo = this.custom_offer_item.offer_item_type === 'tbo'
                this.case_artkey(this.custom_offer_item.case_artkey)
                this.offer_item_artkey = this.custom_offer_item.linked_offer_item_artkey
                this.product_name = this.custom_offer_item.name
                this.avg_purchase_price = this.custom_offer_item.avg_purchase_price
                this.list_price = +this.custom_offer_item.list_price
                this.list_price_is_offer = (this.list_price < +this.custom_offer_item.original_price)
            }

            this.query_case(this.case_artkey())
            this.price_per_case(this.suggested_offer_price())

            if (this.adding_tbo && this.minimum_quantity !== null) {
                this.quantity(+this.minimum_quantity)
            }

            this.adding_from_stock = !this.adding_tbo
        } else if (this.purchase_order_item) {
            this.mode = AddMode.PURCHASE
            this.minimum_quantity = 1
            this.maximum_quantity = this.purchase_order_item.number_of_cases_available
            if (this.purchase_order_item.suggested_price_per_case) {
                this.price_per_case(convert_from_euro_to_currency(
                    this.purchase_order_item.suggested_price_per_case,
                    this.sales_order_currency(),
                    $s.currencies.exchange_rates[this.sales_order_currency()].portal_rate,
                ))
            }

            this.case_artkey(this.purchase_order_item.case_artkey)
            this.query_case(this.case_artkey())
        } else if (this.spli) {
            this.mode = AddMode.MARKET
            this.minimum_quantity = 1
            this.maximum_quantity = null

            const data = {
                bottle_artkey: this.spli.bottle.artkey,
                search_exact_case: true,
                number_of_bottles: this.spli.number_of_bottles_per_case,
                customs_status: this.spli.customs_status,
            }

            api.callAndThen('product_management.get_cases_for_bottle', data, {
                success: (resp: any) => {
                    this.cases = resp.result.map((cs: any) => new Case(cs))
                    if (this.cases.length) {
                        this.case_artkey(this.cases[0].artkey())
                    } else {
                        notifier.notify('No existing case found for this supplier price list item. It unfortunately cannot be added in this way for now.', 'danger')
                        this.on_added_item()
                    }
                },
            })
        }
        this.query_sales_orders()
    }

    query_sales_order(sales_order_artkey: number) {
        this.loading = true
        const data = {sales_order_artkey: sales_order_artkey}
        api.callAndThen('sales.core.get_sales_order', data, {
            success: (resp: any) => {
                this.loading = false
                this.sales_orders([new SalesOrder(resp.result)])
                this.select_sales_order(this.sales_order_artkey)
                this.update_currency()
            },
        })
    }

    query_case(case_artkey: number) {
        api.callAndThen('product_management.get_case', {artkey: case_artkey}, {
            success: (resp: any) => {
                const resp_case = new Case(resp.result)
                this.cases = [resp_case]

                this.query_stock_items({
                    case_artkey: case_artkey,
                })
            },
        })
    }

    query_sales_orders() {
        const data: any = {
            status: [SalesOrderStatus.SAVED],
            sort_by: 'created_on',
            ascending: false,
        }

        if (this.supplier_artkey) {
            data['supplier_artkey'] = this.supplier_artkey
        }

        this.loading = true
        api.callAndThen('sales.core.get_sales_orders', data, {
            success: (resp: any) => {
                this.loading = false
                this.sales_orders(resp.result.map((sales_order: any) => new SalesOrder(sales_order)))
                if (this.sales_order_artkey) {
                    this.select_sales_order(this.sales_order_artkey)
                    this.update_currency()
                }
            },
        })
    }

    query_stock_items(item_data: any) {
        const data: any = {
            sort_by: 'stock_age',
            ascending: false,
            all_accounts: true,
        }

        Object.assign(data, item_data)

        api.callAndThen('stock.get_stock_records', data, {
            success: (resp: any) => {
                this.stock_items(resp.result.stock_items)
                if (this.stock_items().length === 1) {
                    this.select_stock_item(this.stock_items()[0].artkey)
                } else {
                    this.stock_items().sort((first: any, second: any) => first.stock_age > second.stock_age ? -1 : 1)
                    this.select_stock_item()
                }
            },
        })
    }

    select_sales_order(artkey: number) {
        if (artkey) {
            this.selected_sales_order(this.sales_orders().filter((so: any) => so.artkey() === +artkey).at(0))
            this.update_currency()
        } else {
            this.selected_sales_order(this.empty_sales_order)
            this.price_per_case(convert_from_source_to_target(
                +this.price_per_case(),
                this.sales_order_currency(),
                this.supplier_currency,
                $s.currencies.exchange_rates[this.sales_order_currency()].portal_rate,
                $s.currencies.exchange_rates[this.supplier_currency].portal_rate,
            ))
            this.sales_order_currency(this.supplier_currency)
        }
    }

    update_currency() {
        this.price_per_case(convert_from_source_to_target(
            +this.price_per_case(),
            this.sales_order_currency(),
            this.selected_sales_order().was_sold_in(),
            $s.currencies.exchange_rates[this.sales_order_currency()].portal_rate,
            $s.currencies.exchange_rates[this.selected_sales_order().was_sold_in()].portal_rate,
        ))
        this.sales_order_currency(this.selected_sales_order().was_sold_in())
    }

    create_new_sales_order() {
        this.selected_sales_order(this.empty_sales_order)
        this.new_sales_order(true)
    }

    select_stock_item(artkey?: number) {
        if (artkey) {
            this.selected_stock_item(this.stock_items().filter((item: any) => item.artkey === +artkey).at(0))
        } else {
            this.selected_stock_item(null)
        }
    }

    add_to_sales_order() {
        this.loading = true
        if (this.adding_from_stock) {
            const data = {
                sales_order_artkey: this.selected_sales_order().artkey(),
                item_artkey: this.selected_stock_item().artkey,
                price_per_case: +this.price_per_case(),
                number_of_cases: this.quantity(),
            }

            api.callAndThen('sales.items.add_item_to_sales_order', data, {
                success: () => {
                    notifier.notify(`Added ${this.selected_stock_item().product_name} to ${this.selected_sales_order().reference()} of ${this.selected_sales_order().supplier().name()}.`, 'success')
                    this.loading = false
                    this.on_added_item()
                },
            })
        } else if (this.adding_tbo) {
            const data = {
                sales_order_artkey: this.selected_sales_order().artkey(),
                item_artkey: this.offer_item_artkey,
                price_per_case: this.price_per_case(),
                number_of_cases: this.quantity(),
            }

            api.callAndThen('sales.items.add_tbo_to_sales_order', data, {
                success: () => {
                    notifier.notify(`Added ${this.product_name} to ${this.selected_sales_order().reference()} of ${this.selected_sales_order().supplier().name()}.`, 'success')
                    this.loading = false
                    this.on_added_item()
                },
            })
        } else {
            const data = {
                sales_order_artkey: this.selected_sales_order().artkey(),
                case_artkey: this.case_artkey(),
                purchase_order_item_artkey: this.purchase_order_item ? this.purchase_order_item.artkey : null,
                supplier_artkey: this.spli ? this.spli.supplier_price_list.supplier.artkey : null,
                price_per_case: this.price_per_case(),
                number_of_cases: this.quantity(),
            }

            api.callAndThen('sales.items.add_case_to_sales_order', data, {
                success: () => {
                    notifier.notify(`Added case to ${this.selected_sales_order().reference()} of ${this.selected_sales_order().supplier().name()}.`, 'success')
                    this.loading = false
                    this.on_added_item()
                },
            })
        }
    }

    suggested_offer_price() {
        return convert_from_source_to_target(
            this.list_price,
            this.supplier_currency,
            this.sales_order_currency(),
            $s.currencies.exchange_rates[this.supplier_currency].portal_rate,
            $s.currencies.exchange_rates[this.sales_order_currency()].portal_rate,
        )
    }

    stock_item_text(item: any) {
        const was_bought_for = convert_from_euro_to_currency(
            +item.item_euro_was_bought_for,
            this.sales_order_currency(),
        )

        let stock_item_text = ''
        if (item.item_lot) {
            stock_item_text += `${item.item_lot} - `
        } else {
            stock_item_text += `${item.purchase_order_reference} - `
        }
        stock_item_text += `In: ${was_bought_for.formatMoney()} ${this.sales_order_currency()}`

        if (this.list_price_is_offer) {
            stock_item_text += ' - Offer: '
        } else {
            stock_item_text += ' - Target: '
        }

        stock_item_text += `${(this.suggested_offer_price()).formatMoney()} ${this.sales_order_currency()}`
        stock_item_text += ` - ${item.case_number_of_bottles} btl/cs`
        stock_item_text += ` - ${item.item_number_of_cases_available} available`
        stock_item_text += ` - ${item.case_customs_status}`
        if (item.case_gift_box_type) {
            stock_item_text += ` - ${item.case_gift_box_type}`
        }
        if (item.case_tax_label) {
            stock_item_text += ` - ${item.case_tax_label}`
        }

        const features = []
        if (item.item_cases_per_pallet) {
            features.push(`CPP: ${item.item_cases_per_pallet}`)
        }
        if (item.item_cases_per_pallet_layer) {
            features.push(`CPL: ${item.item_cases_per_pallet_layer}`)
        }
        if (item.item_best_before_date) {
            features.push(`BBD: ${formatDate(item.item_best_before_date)}`)
        }
        if (item.item_damages) {
            features.push(item.item_damages.replace(', ', ' / '))
        }
        if (item.item_general_tags) {
            features.push(item.item_general_tags.replace(', ', ' / '))
        }
        if (item.item_pack_size) {
            features.push(item.item_pack_size)
        }
        if (item.item_packaging) {
            features.push(item.item_packaging)
        }
        if (features.length) {
            stock_item_text += ` - ${features.join(' / ')}`
        }
        if (item.case_no_eu_address) {
            stock_item_text += ' - No EU address'
        }

        return stock_item_text
    }

    case_text(cs: Case) {
        let case_text = `${cs.bottle().product().name()}`
        case_text += ` - ${cs.number_of_bottles()} / ${cs.bottle().volume()} / ${cs.bottle().alcohol_percentage()} / ${cs.bottle().refill_status()}`
        if (cs.gift_box_type()) {
            case_text += ` / ${cs.gift_box_type()}`
        }

        case_text += ` - ${cs.customs_status()}`

        if (cs.tax_label()) {
            case_text += ` - ${cs.tax_label()}`
        }
        if (cs.best_before_date()) {
            case_text += ` - ${formatDate(cs.best_before_date())}`
        }
        if (cs.item_tags().length) {
            case_text += ` - ${$m.data.item_tag.get_tag_names(cs.item_tags()).join(' / ')}`
        }

        if (cs.no_eu_address()) {
            case_text += ' - No EU address'
        }

        return case_text
    }

    view() {
        if (this.new_sales_order()) {
            return <CreateOrEditSalesOrderComponent
                relation_artkey={this.supplier_artkey}
                override_is_new={true}
                onsaved={(artkey: number) => {
                    this.new_sales_order(false)
                    this.sales_order_artkey = artkey
                    this.query_sales_order(artkey)
                }}
            />
        }

        return (
            <form className="flex-form" onsubmit={preventDefault(this.add_to_sales_order.bind(this))}>
                <div className="fieldset large">
                    {this.sales_order_artkey ? (
                        <div className="field-readonly">
                            <div className="key">Sales order to add to</div>
                            <div className="value">
                                {this.loading ? (
                                    <Spinner />
                                ) : (
                                    `${this.selected_sales_order().reference()} - ${formatDate(this.selected_sales_order().created_on())} - ${this.selected_sales_order().supplier().name()}`
                                )}
                            </div>
                        </div>
                    ) : (
                        <div className="field">
                            {this.loading ? (
                                <Spinner />
                            ) : (
                                <select onchange={(ev) => this.select_sales_order(+ev.target.value)}>
                                    <option value=""></option>
                                    {this.sales_orders().map((so: any) => (
                                        <option
                                            value={+so.artkey()}
                                            selected={+so.artkey() === +this.selected_sales_order().artkey()}
                                        >
                                            {`${so.reference()} - ${formatDate(so.created_on())} - ${so.supplier().name()} (${so.portal_status()} - ${so.sales_order_status()})`}
                                        </option>
                                    ))}
                                </select>
                            )}
                            <div className="btn-toolbar">
                                <button
                                    className="btn btn-default"
                                    type="button"
                                    onclick={this.create_new_sales_order.bind(this)}
                                >
                                    <span className="glyphicon glyphicon-plus"></span>
                                    {' New Sales Order'}
                                </button>
                            </div>
                        </div>
                    )}

                    {this.adding_from_stock ? (
                        this.selected_sales_order().artkey() ? (
                            this.stock_items().length === 1 ? (
                                <div className="field-readonly">
                                    <div className="key">Stock Item</div>
                                    <div className="value">{this.stock_item_text(this.stock_items()[0])}</div>
                                </div>
                            ) : (
                                <div className="field">
                                    <label>Stock Item</label>
                                    <select
                                        required={true}
                                        onchange={(ev) => this.select_stock_item(+ev.target.value)}
                                    >
                                        <option value="" selected>-</option>
                                        {this.stock_items().map((item: any) => (
                                            <option
                                                value={+item.artkey}
                                                selected={+item.artkey === +this.selected_stock_item()?.artkey}
                                            >
                                                {this.stock_item_text(item)}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            )
                        ) : (
                            <div className="field-readonly">
                                <div className="key">Please select a sales order first.</div>
                            </div>
                        )
                    ) : (
                        <div className="field">
                            <label>Case</label>
                            {this.cases ? (
                                this.cases.length === 1 ? (
                                    this.case_text(this.cases[0])
                                ) : (
                                    <select
                                        className="form-control"
                                        required={true}
                                        onchange={(ev) => this.case_artkey(+ev.target.value)}
                                    >
                                        {this.cases.map((cs: Case) => (
                                            <option
                                                value={+cs.artkey()}
                                                selected={+cs.artkey() === +this.case_artkey()}
                                            >
                                                {this.case_text(cs)}
                                            </option>
                                        ))}
                                    </select>
                                )
                            ) : (
                                <Spinner />
                            )}
                        </div>
                    )}

                    <div className="field-readonly">
                        {this.mode === AddMode.OFFER ? [
                            <div className="key">Relation sales / cs</div>,
                            <div className="value">
                                <Amount
                                    amount={this.suggested_offer_price()}
                                    currency={this.sales_order_currency()}
                                    rate={$s.currencies.exchange_rates[this.sales_order_currency()].portal_rate}
                                    key={`${this.suggested_offer_price()}${this.sales_order_currency()}`}
                                />
                                <span key={`${this.suggested_offer_price()}${this.sales_order_currency()}`}>
                                    {!this.adding_tbo ? (() => {
                                        const purchase_price = convert_from_euro_to_currency(
                                            this.avg_purchase_price,
                                            this.sales_order_currency(),
                                        )
                                        const margin_percentage = ((this.suggested_offer_price() / purchase_price) - 1) * 100
                                        return ` (margin with average purchase price: ${margin_percentage.toFixed(2)}%)`
                                    })() : ''}
                                </span>
                            </div>,
                        ] : this.mode === AddMode.PURCHASE ? [
                            <div className="key">Purchase price / cs</div>,
                            <div className="value">
                                <Amount
                                    amount={this.purchase_order_item.was_bought_for}
                                    currency={this.purchase_order_item.was_bought_in}
                                />
                            </div>,
                        ] : this.mode === AddMode.MARKET ? [
                            <div className="key">Market price / cs</div>,
                            <div className="value">
                                <Amount
                                    amount={this.spli.price_per_case}
                                    currency={$s.currencies.default}
                                />
                            </div>,
                        ] : null}
                    </div>

                    <div className="field-group">
                        {this.adding_from_stock ? (
                            inputs.number(this.quantity, {
                                label: 'Quantity (cases)',
                                required: true,
                                min: 1,
                                max: this.selected_stock_item() ? this.selected_stock_item().item_number_of_cases_available : undefined,
                            })
                        ) : (
                            inputs.number(this.quantity, {
                                help: this.minimum_quantity && this.maximum_quantity
                                    ? `Minimum: ${this.minimum_quantity}, Maximum: ${this.maximum_quantity}.`
                                    : this.minimum_quantity
                                        ? `Minimum: ${this.minimum_quantity}.`
                                        : this.maximum_quantity
                                            ? `Maximum: ${this.maximum_quantity}.`
                                            : undefined,
                                label: 'Quantity (cases)',
                                required: true,
                                min: 1,
                            })
                        )}

                        <FieldMoney
                            currency={[this, 'sales_order_currency']}
                            label="Price per case"
                            model={[this, 'price_per_case']}
                            required={true}
                        />
                    </div>

                    <button
                        className="btn btn-primary"
                        type="submit"
                        disabled={
                            this.loading ||
                            !this.selected_sales_order().artkey() ||
                            this.quantity() < this.minimum_quantity ||
                            this.maximum_quantity !== null && this.quantity() > this.maximum_quantity ||
                            this.adding_from_stock &&
                            (!this.selected_stock_item() || this.quantity() > this.selected_stock_item().item_number_of_cases_available)
                        }
                    >
                        Add to Order
                    </button>
                </div>
            </form>
        )
    }
}
