/** llm:tested */
import {compact, filter, groupBy, head, map} from 'prelude-ls'

import {Bottle} from '@/models/bottles'
import {Warehouse} from '@/models/data'
import {Account} from '@/models/accounts'
import {User} from '@/models/users'
import {$m} from '@/app'
import {sales_order_items_dm} from '@/sales/models'
import {PurchaseOrderItem} from '@/models/purchase_orders'

export class ItemMutationReason {
    static STOCK_DIFFERENCE = 'Stock Difference'
    static DAMAGES = 'Damages'
    static MOVE_TO_WAREHOUSE = 'Move to Warehouse'
    static CORRECT_BOTTLES_PER_CASE = 'Correct bottles per case'
    static CHANGE_LOT_NR = 'Change lot number'
    static OTHER = 'Other'

    static all = {
        [ItemMutationReason.STOCK_DIFFERENCE]: 'Stock difference',
        [ItemMutationReason.DAMAGES]: 'Damages',
        [ItemMutationReason.CHANGE_LOT_NR]: 'Change lot number',
        [ItemMutationReason.OTHER]: 'Other',
    }

    static update_cases_options = {
        [ItemMutationReason.STOCK_DIFFERENCE]: 'Stock difference',
        [ItemMutationReason.DAMAGES]: 'Damages',
        [ItemMutationReason.OTHER]: 'Other',
    }

    static options = {
        [ItemMutationReason.STOCK_DIFFERENCE]: 'Stock difference',
        [ItemMutationReason.DAMAGES]: 'Damages',
        [ItemMutationReason.MOVE_TO_WAREHOUSE]: 'Move to warehouse',
        [ItemMutationReason.CORRECT_BOTTLES_PER_CASE]: 'Correct bottles per case',
        [ItemMutationReason.CHANGE_LOT_NR]: 'Change lot number',
        [ItemMutationReason.OTHER]: 'Other',
    }
}

export class ItemMutationStatus {
    static INSTOCK = 'In stock'
    static INTRANSIT = 'In transit'
}

export class Item {
    artkey = window.prop('')
    account = window.prop(new Account({}))
    account_artkey = window.prop('')
    reference = window.prop('')
    purchase_order_item_artkey = window.prop('')
    purchase_order_item = window.prop(new PurchaseOrderItem({}))
    sales_order_items = window.prop([])
    lot = window.prop('')
    entry_date = window.prop('')
    number_of_bottles_per_case = window.prop('')
    number_of_cases = window.prop('')
    item_description = window.prop('')
    case_artkey = window.prop('')
    case = window.prop(new Case({}))
    warehouse_artkey = window.prop('')
    warehouse = window.prop(new Warehouse({}))
    is_source_in_artkey = window.prop('')
    is_source_in = window.prop(new ItemMutation({}))
    is_part_source_in_artkey = window.prop('')
    is_part_source_in = window.prop(new ItemMutationPart({}))
    is_target_in_artkey = window.prop('')
    is_target_in = window.prop(new ItemMutation({}))
    is_part_target_in_artkey = window.prop('')
    is_part_target_in = window.prop(new ItemMutationPart({}))
    is_in_stock = window.prop('')
    number_of_cases_in_stock = window.prop('')
    number_of_cases_in_purchase = window.prop('')
    number_of_shipped_cases = window.prop('')
    number_of_cases_in_sales = window.prop('')
    number_of_cases_available = window.prop('')
    was_bought_for = window.prop('')
    was_bought_for_plus_costs = window.prop('')
    euro_was_bought_for = window.prop('')
    euro_was_bought_for_plus_costs = window.prop('')
    creation_date = window.prop('')
    neutral_case = window.prop('')
    bottle_gtin_code = window.prop('')
    case_gtin_code = window.prop('')
    list_price_per_case = window.prop(null)
    loendersloot_inspection_item = window.prop({})
    hide_from_pricelist_for_suppliers = window.prop([])
    hide_from_pricelist_for_countries = window.prop([])
    cases_per_pallet = window.prop(null)
    cases_per_pallet_layer = window.prop(null)
    remark = window.prop('')
    country_of_origin = window.prop('')
    was_bought_in = window.prop('')
    bought_against_rate = window.prop('')
    purchase_order_artkey = window.prop('')
    purchase_order_reference = window.prop('')
    purchase_order_account_slug = window.prop('')

    // These will be set in constructor
    bottle_artkey: any
    bottle: any
    gift_box_type: any
    tax_label: any
    customs_status: any
    best_before_date: any
    item_tags: any

    constructor(json: any = {}) {
        for (const prop in json) {
            if (prop === 'purchase_order_item' && json[prop]) {
                this.purchase_order_item_artkey(json[prop].artkey)
                this.purchase_order_item(new PurchaseOrderItem(json[prop]))
            } else if (prop === 'case') {
                this.case_artkey(json[prop].artkey)
                this.case(new Case(json[prop]))
            } else if (prop === 'account') {
                this.account_artkey(json[prop].artkey)
                this.account(new Account(json[prop]))
            } else if (prop === 'warehouse' && json[prop]) {
                this.warehouse_artkey(json[prop].artkey)
            } else if (prop === 'is_source_in' && json[prop]) {
                this.is_source_in(new ItemMutation(json[prop]))
                this.is_source_in_artkey(json[prop].artkey)
            } else if (prop === 'is_target_in' && json[prop]) {
                this.is_target_in(new ItemMutation(json[prop]))
                this.is_target_in_artkey(json[prop].artkey)
            } else if (prop === 'is_part_source_in' && json[prop]) {
                this.is_part_source_in(new ItemMutationPart(json[prop]))
                this.is_part_source_in_artkey(json[prop].artkey)
            } else if (prop === 'is_part_target_in' && json[prop]) {
                this.is_part_target_in(new ItemMutationPart(json[prop]))
                this.is_part_target_in_artkey(json[prop].artkey)
            } else if (prop === 'sales_order_items') {
                this.sales_order_items(sales_order_items_dm.create_sales_order_items(json[prop]))
            } else {
                this[prop] = window.prop(json[prop])
            }
        }

        // Set derived properties from case
        this.bottle_artkey = this.case().bottle_artkey
        this.bottle = this.case().bottle
        this.number_of_bottles_per_case = this.case().number_of_bottles
        this.gift_box_type = this.case().gift_box_type
        this.tax_label = this.case().tax_label
        this.customs_status = this.case().customs_status
        this.best_before_date = this.case().best_before_date
        this.item_tags = this.case().item_tags
    }

    can_be_mutated() {
        const has_been_mutated = !!this.is_source_in_artkey()
        if (has_been_mutated) {
            return false
        }

        const is_result_of_mutation = !this.is_target_in_artkey()
        if (is_result_of_mutation) {
            if (this.is_target_in() && this.is_target_in().status() === ItemMutationStatus.INTRANSIT) {
                if (this.lot() !== '') {
                    return true
                }
            }
        }

        if (!this.is_in_stock()) {
            return false
        }
        return true
    }

    item_tags_per_category() {
        return groupBy(
            (tag) => tag.category_artkey(),
            compact(map((tag) => $m.data.item_tag.get_item_tag(tag), this.item_tags())),
        )
    }

    // eslint-disable-next-line @typescript-eslint/naming-convention
    toObject() {
        return {
            artkey: +this.artkey(),
            purchase_order_item_artkey: +this.purchase_order_item_artkey(),
            lot: this.lot(),
            entry_date: this.entry_date(),
            number_of_cases: +this.number_of_cases(),
            number_of_bottles_per_case: +this.number_of_bottles_per_case(),
            bottle_artkey: +this.bottle_artkey(),
            customs_status: this.customs_status(),
            country_of_origin: this.country_of_origin(),
            gift_box_type: this.gift_box_type(),
            warehouse_artkey: +this.warehouse_artkey(),
            number_of_cases_in_stock: +this.number_of_cases_in_stock(),
            tax_label: this.tax_label(),
            best_before_date: this.best_before_date(),
            cases_per_pallet: this.cases_per_pallet() ? +this.cases_per_pallet() : null,
            remark: this.remark(),
            item_tags: this.item_tags(),
        }
    }
}

export class ItemMutation {
    artkey = window.prop('')
    reference = window.prop('')
    created_on = window.prop(new Date())
    item_mutation_reason = window.prop('')
    user_artkey = window.prop('')
    user = window.prop(new User({}))
    description = window.prop('')
    number_of_cases = window.prop('')
    expected_delivery_date = window.prop('')
    target_warehouse_artkey = window.prop('')
    target_warehouse = window.prop(new Warehouse({}))
    stock_value_difference = window.prop('')
    transport_costs = window.prop('')
    sources = window.prop([])
    targets = window.prop([])
    status = window.prop('')
    parts = window.prop([])
    incoterm = window.prop('')
    incoterm_location = window.prop('')
    number_of_euro_pallets = window.prop('')
    number_of_block_pallets = window.prop('')

    constructor(json: any = {}) {
        for (const prop in json) {
            if (prop === 'sources' && json[prop]) {
                this.sources(json[prop].map((item: any) => new Item(item)))
            } else if (prop === 'targets' && json[prop]) {
                this.targets(json[prop].map((item: any) => new Item(item)))
            } else if (prop === 'target_warehouse' && json[prop]) {
                this.target_warehouse_artkey(json[prop].artkey)
            } else if (prop === 'user') {
                this.user($m.users.create_user(json[prop]))
                this.user_artkey(this.user().artkey())
            } else {
                this[prop] = window.prop(json[prop])
            }
        }

        this.parts(this.parts().map((part: any) => {
            part.item_mutation_artkey = this.artkey()
            part.sources = this.sources().filter((s: any) => s.is_part_source_in_artkey() === part.artkey)
            part.targets = this.targets().filter((t: any) => t.is_part_target_in_artkey() === part.artkey)
            return new ItemMutationPart(part)
        }))
    }
}

export class ItemMutationPart {
    artkey = window.prop('')
    item_mutation = window.prop(new ItemMutation({}))
    item_mutation_artkey = window.prop('')
    sources = window.prop([])
    targets = window.prop([])

    constructor(json: any) {
        for (const prop in json) {
            if (prop === 'item_mutation' && json[prop]) {
                this.item_mutation(new ItemMutation(json[prop]))
                this.item_mutation_artkey(json[prop].artkey)
            } else {
                this[prop] = window.prop(json[prop])
            }
        }
    }

    source_item() {
        return this.sources()[0]
    }

    target_item() {
        return head(filter((item) => item.lot() === '', this.targets()))
    }

    leftover_item() {
        return head(filter((item) => item.lot() !== '', this.targets()))
    }
}

export class Case {
    artkey = window.prop('')
    bottle_artkey = window.prop('')
    bottle = window.prop(new Bottle())
    number_of_bottles = window.prop('')
    gift_box_type = window.prop('')
    tax_label = window.prop('')
    customs_status = window.prop('')
    best_before_date = window.prop('')
    item_tags = window.prop([])
    no_eu_address = window.prop(false)
    cases_per_pallet = window.prop('')
    cases_per_pallet_layer = window.prop('')

    constructor(json: any = {}) {
        for (const prop in json) {
            if (prop === 'bottle') {
                this.bottle_artkey(json[prop].artkey)
                this.bottle($m.bottles.create_bottle(json[prop], $m.products.create_product(json[prop]['product'])))
            } else if (prop === 'item_tags') {
                this.item_tags(json[prop] || [])
            } else {
                this[prop] = window.prop(json[prop])
            }
        }
    }

    item_tags_per_category() {
        return groupBy(
            (tag) => tag.category_artkey(),
            compact(map((tag) => $m.data.item_tag.get_item_tag(tag), this.item_tags())),
        )
    }

    // eslint-disable-next-line @typescript-eslint/naming-convention
    toJS() {
        return {
            artkey: +this.artkey(),
            bottle_artkey: +this.bottle_artkey(),
            number_of_bottles: +this.number_of_bottles(),
            gift_box_type: this.gift_box_type(),
            tax_label: this.tax_label(),
            customs_status: this.customs_status(),
            best_before_date: this.best_before_date(),
            item_tags: this.item_tags(),
            no_eu_address: this.no_eu_address(),
            cases_per_pallet: +this.cases_per_pallet(),
            cases_per_pallet_layer: +this.cases_per_pallet_layer(),
        }
    }
}

export class StockSearchMode {
    static NOT_INVOICED = 'not_invoiced'
    static AVAILABLE_ONLY = 'available_only'
    static INSTOCK_ONLY = 'instock_only'
    static INPURCHASE_ONLY = 'inpurchase_only'
    static INSALE_ONLY = 'insale_only'
    static INTRANSIT_ONLY = 'intransit_only'
    static AVAILABLE_OR_INSTOCK = 'available_or_instock'
    static INSTOCK_AND_NOT_AVAILABLE = 'instock_and_not_available'
    static INSALE_OTHER_ACCOUNT = 'insale_other_account'
    static NO_GTIN_PHOTOS_IN_STOCK = 'no_gtin_photos_in_stock'
    static NO_GTIN_PHOTOS = 'no_gtin_photos_all'
    static NO_BOTTLE_LOT = 'no_bottle_lot'
    static ALL = 'all'

    static options = {
        [StockSearchMode.NOT_INVOICED]: 'Show items that are not yet invoiced',
        [StockSearchMode.AVAILABLE_ONLY]: 'Only show available items',
        [StockSearchMode.INSTOCK_ONLY]: 'Only show items that are in stock',
        [StockSearchMode.INPURCHASE_ONLY]: 'Only show items that are in purchase orders',
        [StockSearchMode.INSALE_ONLY]: 'Only show items that are in sale orders',
        [StockSearchMode.INTRANSIT_ONLY]: 'Only show items that are in transit',
        [StockSearchMode.AVAILABLE_OR_INSTOCK]: 'Show items that are available or in stock',
        [StockSearchMode.INSTOCK_AND_NOT_AVAILABLE]: 'Show items that are in stock and not available',
        [StockSearchMode.INSALE_OTHER_ACCOUNT]: 'Show items that are in a sales order in another account',
        [StockSearchMode.NO_GTIN_PHOTOS]: 'Show items that have photos, no GTIN',
        [StockSearchMode.NO_GTIN_PHOTOS_IN_STOCK]: 'Show items that have photos, in stock, no GTIN',
        [StockSearchMode.NO_BOTTLE_LOT]: 'Show available items without a bottle lot',
        [StockSearchMode.ALL]: 'Show all items',
    }
}

export function bind() {

}
