import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {Button, ButtonGroup, Country, FieldMoney, Icon, ReadMoreLessToggle, Tippy} from '@bitstillery/common/components'
import {mount_dialog} from '@bitstillery/common/lib/dialog'
import {classes} from '@bitstillery/common/lib/utils'
import {proxy} from '@bitstillery/common/lib/proxy'
import {$t, api, notifier} from '@bitstillery/common/app'
import {Amount, CellBottleAndCase, CellProduct, FieldSelect, SubAmount} from '@bitstillery/common/components'
import {format_money_with_symbol} from '@bitstillery/common/lib/format.ts'

import {EditPurchaseOrderItem} from './edit_purchase_order_item'
import {HiddenInformation, HiddenPurchaseOrderItemHelper} from './hidden_information'

import {accountIcon} from '@/accounts'
import {OfferHistory} from '@/components/market_info/offer_history'
import {
    CollectionTable,
    CollectionTableColumn,
    PagedCollectionFetcherWithGET,
} from '@/components/collection/collection_table'
import {CaseLabels} from '@/components/labels'
import {Link} from '@/components/discover'
import {CaseInfoPanel} from '@/components/market_info/case_info_panel'
import {PurchaseApi} from '@/factserver_api/purchase_api'
import {LoggedInUserData, User} from '@/factserver_api/user_api'
import {
    ErrorResponse,
    GetHiddenInformationResponse,
    GetPurchaseOrderItemsCollectionViewResponse,
    GetPurchaseOrderResponse,
    GetRelatedSalesOrderItemResponse,
    PurchaseOrderStatus,
    RefillStatus,
} from '@/factserver_api/fact2server_api'
import {NumberInput} from '@/components/input_numbers'

export interface PurchaseOrderItemsAttrs {
    purchase_order: GetPurchaseOrderResponse
    poi_fetcher: PagedCollectionFetcherWithGET<GetPurchaseOrderItemsCollectionViewResponse>
}

interface InlineEditPurchaseOrderItem {
    number_of_cases: number
    number_of_bottles: number
    was_bought_for: string
    suggested_price_per_case?: number
    alcohol_percentage: number
    volume: number
    refill_status: RefillStatus
}

interface PurchaseOrderItemView extends GetPurchaseOrderItemsCollectionViewResponse {
    is_showing_details?: boolean
    is_soi_loading?: boolean
    is_tbo_loading?: boolean
    is_soi_expanded?: boolean
    is_tbo_expanded?: boolean
    related_soi: GetRelatedSalesOrderItemResponse[] | null
    related_tbo: GetRelatedSalesOrderItemResponse[] | null

    is_hidden_information_loading?: boolean
    is_hidden_information_expanded?: boolean
    hidden_information: GetHiddenInformationResponse

    is_editing?: boolean
    inline_edit_data?: InlineEditPurchaseOrderItem
    tag_model?: any
}

interface PurchaseOrderItemsDetailAttrs {
    row: PurchaseOrderItemView
    additional_args: {
        onsave: (row: GetPurchaseOrderItemsCollectionViewResponse) => unknown
        oncancel: (row: GetPurchaseOrderItemsCollectionViewResponse) => unknown
    }
}

/** The row details component. Shows the Edit, Market and Hide information. */
export class PurchaseOrderItemsDetail extends MithrilTsxComponent<PurchaseOrderItemsDetailAttrs> {
    selected_tab: 'market' | 'edit' | 'hide-information-item' = 'market'
    view(vnode: m.Vnode<PurchaseOrderItemsDetailAttrs>) {
        const children: m.Children = []
        const purchase_order_item = vnode.attrs.row
        if (!purchase_order_item.is_showing_details) {
            return children
        }

        children.push(
            <div className="c-tabs" style='padding-left: 8px;'>
                <ul className="nav nav-tabs">
                    <li
                        className={classes('nav-link', {active: this.selected_tab === 'market'})}
                        onclick={() => this.selected_tab = 'market'}
                    >
                        <a>Market</a>
                    </li>
                    {purchase_order_item.lots.length === 0 && <li
                        className={classes('nav-link', {active: this.selected_tab === 'edit'})}
                        onclick={() => this.selected_tab = 'edit'}
                    >
                        <a>Edit</a>
                    </li>}
                    <li
                        className={classes('nav-link', {active: this.selected_tab === 'hide-information-item'})}
                        onclick={() => this.selected_tab = 'hide-information-item'}
                    >
                        <a>Hide information item</a>
                    </li>
                </ul>
            </div>,
        )

        if (this.selected_tab === 'market') {
            children.push(<div className='well-container'>
                <CaseInfoPanel
                    customs_status={purchase_order_item.customs_status}
                    bottle_artkey={purchase_order_item.bottle_artkey}
                    ignore_ref={true}
                />
                <OfferHistory
                    customs_status={purchase_order_item.customs_status}
                    bottle_artkey={purchase_order_item.bottle_artkey}
                />
            </div>)
        } else if (this.selected_tab === 'hide-information-item') {
            children.push(
                <HiddenInformation
                    hidden_information_helper={new HiddenPurchaseOrderItemHelper(purchase_order_item.purchase_order_artkey, purchase_order_item.artkey)}
                />)
        } else if (this.selected_tab === 'edit') {
            children.push(<EditPurchaseOrderItem
                purchase_order_item={purchase_order_item}
                onsave={(row) => {
                    vnode.attrs.additional_args.onsave(row)
                }}
                oncancel={(row) => {
                    vnode.attrs.additional_args.oncancel(row)
                }}
            />)
        }
        return <tr className="well">
            <td colspan={'99'} style='padding-top: 4px;'>
                {children}
            </td>
        </tr>
    }
}

export class PurchaseOrderItems extends MithrilTsxComponent<PurchaseOrderItemsAttrs> {

    data = proxy({
        dialog: {
            loading: false,
            title: 'Remove Purchase Order Item',
            type: 'danger',
        },
    })

    poi_fetcher: PagedCollectionFetcherWithGET<GetPurchaseOrderItemsCollectionViewResponse>
    purchase_api = new PurchaseApi()
    purchase_order: GetPurchaseOrderResponse

    user: User | null

    constructor(vnode: m.Vnode<PurchaseOrderItemsAttrs>) {
        super()
        this.purchase_order = vnode.attrs.purchase_order
        this.poi_fetcher = vnode.attrs.poi_fetcher
    }

    oncreate(): any {
        LoggedInUserData.user().subscribe({
            next: (user) => this.user = user,
        })
    }

    async load_related_soi(row: PurchaseOrderItemView) {
        row.is_soi_expanded = !row.is_soi_expanded
        row.is_soi_loading = true
        const {result} = await api.get(`discover/purchase-orders/${this.purchase_order.artkey}/items/${row.artkey}/sales-order-items`)
        row.is_soi_loading = false
        row.related_soi = result as any
        m.redraw()
        return result
    }

    async load_related_tbo(row: PurchaseOrderItemView) {
        row.is_tbo_expanded = !row.is_tbo_expanded
        row.is_tbo_loading = true
        const {result} = await api.get(`discover/purchase-orders/${this.purchase_order.artkey}/items/${row.artkey}/sales-order-tbo-items`)
        row.is_tbo_loading = false
        row.related_tbo = result as any
        m.redraw()
        return result
    }

    async load_hidden_information(row: PurchaseOrderItemView) {
        row.is_hidden_information_expanded = !row.is_hidden_information_expanded
        if (row.is_hidden_information_expanded) {
            row.is_hidden_information_loading = true
            const {result} = await api.get<GetHiddenInformationResponse>(`discover/purchase-orders/${this.purchase_order.artkey}/items/${row.artkey}/hidden-information`)
            row.is_hidden_information_loading = false
            row.hidden_information = result
            m.redraw()
        }
    }

    async remove_purchase_order_item(row: PurchaseOrderItemView) {
        const sales_order_items = await this.load_related_soi(row) as any
        const mounted = mount_dialog({
            body: () => [
                <span>{$t('poi.remove.dialog.confirm', {
                    alcohol_percentage: row.alcohol_percentage,
                    product_name: row.product_name,
                    volume: row.volume,
                })}</span>,

                row.number_of_cases_in_soi > 0 ? [
                    <br/>,
                    <span>{$t('poi.remove.dialog.soi_list', {count: sales_order_items.length})}</span>,
                    <ul>
                        {sales_order_items.map((i) => <li>
                            <Link href={`/sales/orders/${i.sales_order_artkey}/view/manage`}>
                                {i.sales_order_reference} - {i.relation_name}
                            </Link>
                        </li>)}
                    </ul>,
                    <span>{$t('poi.remove.dialog.soi_confirm')}</span>,
                ] : null,
            ],
            confirm: {
                action: async() => {
                    mounted.close()
                    this.data.dialog.loading = true
                    await api.post('purchase.purchase_order_item.delete_purchase_order_item_from_purchase_order', {
                        purchase_order_item_artkey: row.artkey,
                    })
                    this.poi_fetcher.fetched_rows = this.poi_fetcher.fetched_rows.filter((it) => it.artkey !== row.artkey)
                    this.data.dialog.loading = false
                    notifier.notify($t('notifications.poi_removed', {name: row.artkey}), 'info')
                },
                icon: 'trash',
                text: $t('poi.remove.button'),
            },
            model: this.data.dialog,
        })
    }

    async save_purchase_order_item(row: PurchaseOrderItemView) {
        const {result, response, status_code} = await api.put<ErrorResponse>(`discover/purchase-orders/${row.purchase_order_artkey}/items/${row.artkey}`, {
            bottle_artkey: row.bottle_artkey,
            volume: row.volume,
            alcohol_percentage: row.alcohol_percentage,
            suggested_sales_price: row.suggested_price_per_case,
            refill_status: row.refill_status,
            was_bought_for: row.was_bought_for,
            number_of_cases: row.number_of_cases,
            number_of_bottles_per_case: row.number_of_bottles,
            customs_status: row.customs_status,
            tax_label: row.tax_label ? row.tax_label : null,
            gift_box_type: row.gift_box_type ? row.gift_box_type : null,
            suggested_price_per_case: row.suggested_price_per_case,
            remark: row.remark,
            bottle_gtin_code: row.bottle_gtin_code,
            case_gtin_code: row.case_gtin_code,
            // The tag_model reference was already attached in EditPurchaseOrderItem where
            // the tags are being edited. The edit inline method doesn't initiate a tag
            // model and uses the serialized_item_tags property directly, because
            // it is not being modified. (!) The serialized_item_tags field actuallly
            // expects an array here.
            serialized_item_tags: row.tag_model ? row.tag_model.serialize_selection() : row.serialized_item_tags.length ? row.serialized_item_tags.split(',') : [],
            country_of_origin: row.country_of_origin,
            best_before_date: row.best_before_date,
            cases_per_pallet: row.cases_per_pallet,
            cases_per_pallet_layer: row.cases_per_pallet_layer,
        })
        if (response.ok) {
            row.is_showing_details = false
            row.is_editing = false
            row.inline_edit_data = undefined
            notifier.notify('Successfully updated purchase order item.', 'success')
        } else if (status_code === 400) {
            notifier.notify(`Could not save item: ${result.detail}.`, 'warning')
        } else {
            notifier.notify(`Could not save: ${response.statusText}.`, 'warning')
        }
        m.redraw()
    }

    start_editing(row: PurchaseOrderItemView): void {
        row.is_editing = true
        row.inline_edit_data = {
            number_of_bottles: row.number_of_bottles,
            number_of_cases: row.number_of_cases,
            was_bought_for: row.was_bought_for,
            suggested_price_per_case: row.suggested_price_per_case,
            alcohol_percentage: row.alcohol_percentage,
            volume: row.volume,
            refill_status: row.refill_status,
        }
    }

    cancel_editing(row: PurchaseOrderItemView): void {
        row.is_editing = false
        if (row.inline_edit_data) {
            row.volume = row.inline_edit_data.volume
            row.alcohol_percentage = row.inline_edit_data.alcohol_percentage
            row.number_of_cases = row.inline_edit_data.number_of_cases
            row.number_of_bottles = row.inline_edit_data.number_of_bottles
            row.was_bought_for = row.inline_edit_data.was_bought_for
            row.suggested_price_per_case = row.inline_edit_data.suggested_price_per_case
            row.refill_status = row.inline_edit_data.refill_status
        }
        row.inline_edit_data = undefined
    }

    save_editing(row: PurchaseOrderItemView): void {
        this.save_purchase_order_item(row)
    }

    view(): m.Children {
        return (
            <div className="c-purchase-order-items">
                <CollectionTable<GetPurchaseOrderItemsCollectionViewResponse, void>
                    collection_fetcher={this.poi_fetcher}
                    on_row_click={(row: PurchaseOrderItemView) => (row.is_showing_details = !row.is_showing_details)}
                    on_row_click_component={PurchaseOrderItemsDetail}
                    additional_row_component_args={{
                        onsave: async(row) => this.save_purchase_order_item(row),
                        oncancel: () => this.poi_fetcher.reset_and_query(),
                    }}
                >
                    <CollectionTableColumn<GetPurchaseOrderItemsCollectionViewResponse>
                        header_title={() => 'Name'}
                        sort_name={'product_name'}
                        data_field={(row) =>
                            row.is_editing
                                ? <div className="c-cell-product td-group">
                                    <div className='header'>
                                        {row.requires_import_statement && (
                                            <Icon
                                                size={'xs'}
                                                name={'warning'}
                                                tip={'This item requires a Signed Import statement'}
                                            />
                                        )}
                                        {row.product_name}
                                    </div>
                                    <span className={'c-specs-edit no-click'}>
                                        <FieldSelect
                                            className={'refill-select'}
                                            label={'Refill'}
                                            model={[row, 'refill_status']}
                                            onchange={(value) => row.refill_status = Object.values(RefillStatus).find(ref => ref === value) || RefillStatus.Nonref}
                                            options={Object.entries(RefillStatus).map(([label, value]) => ({label, value}))}
                                        />

                                        <NumberInput
                                            label={'Alc%'}
                                            value={row.alcohol_percentage}
                                            oninput={(val) => row.alcohol_percentage = val}
                                        />
                                        <NumberInput
                                            label={'Volume'}
                                            value={row.volume}
                                            oninput={(val) => row.volume = val}
                                        />
                                        <NumberInput
                                            label={'Btls/cs'}
                                            value={row.number_of_bottles}
                                            oninput={(val) => row.number_of_bottles = val}
                                        />
                                    </span>
                                    <CaseLabels
                                        show_as_icons={true}
                                        case={{...row}}
                                    />
                                </div> :
                                <CellProduct
                                    {...row}
                                    product_category_name={row.category_name}
                                    product_name={<span>{row.requires_import_statement && <Icon size={'s'} name={'warning'} tip={'This item requires a Signed Import statement'}/>}{row.product_name}</span>}
                                    additional_details={<span>
                                        <CaseLabels
                                            show_as_labels={true}
                                            case={{
                                                best_before_date: row.best_before_date,
                                            }}
                                        />
                                        <CaseLabels
                                            show_as_icons={true}
                                            case={{
                                                ...row,
                                                best_before_date: undefined,
                                                cases_per_pallet: undefined,
                                                cases_per_pallet_layer: undefined,
                                            }}
                                        />
                                    </span>}
                                />
                        }
                    />
                    <CollectionTableColumn<GetPurchaseOrderItemsCollectionViewResponse>
                        header_title={() => 'Quantity'}
                        data_field={(row: PurchaseOrderItemView) =>
                            row.is_editing
                                ? <NumberInput
                                    value={row.number_of_cases}
                                    oninput={(val) => row.number_of_cases = val}
                                />
                                : <CellBottleAndCase
                                    bottle_text={
                                        row.number_of_cases ? `${row.number_of_cases * row.number_of_bottles} btl` : ''
                                    }
                                    case_text={row.number_of_cases ? `${row.number_of_cases} cs` : ''}
                                />
                        }
                    />
                    <CollectionTableColumn<PurchaseOrderItemView>
                        header_title={() => 'TBO'}
                        data_field={(row: PurchaseOrderItemView) => {
                            if (row.number_of_cases_in_tbo === 0) {
                                return '-'
                            }
                            return <div>
                                <ReadMoreLessToggle
                                    is_expanded={row.is_tbo_expanded}
                                    onclick={() => this.load_related_tbo(row)}
                                    is_loading={row.is_tbo_loading}
                                    summary={`${row.number_of_cases_in_tbo} cs`}
                                >
                                    {row.related_tbo?.map((tbo) =>
                                        <div>
                                            {accountIcon({
                                                slug: tbo.sales_order_account_slug,
                                                name: tbo.sales_order_account_name,
                                            })}
                                            <Tippy
                                                content={`${tbo.relation_name} (${tbo.sales_manager_name})`}
                                                allowHTML={true}
                                            >
                                                <Link href={`/sales/orders/${tbo.sales_order_artkey}/view/manage`}>
                                                    {`${tbo.sales_order_reference} (${tbo.number_of_cases} cs)`}
                                                </Link>
                                            </Tippy>
                                        </div>,
                                    )}
                                </ReadMoreLessToggle>
                            </div>
                        }
                        }
                    />
                    <CollectionTableColumn<GetPurchaseOrderItemsCollectionViewResponse>
                        header_title={() => 'Sales order item'}
                        data_field={(row: PurchaseOrderItemView) => {
                            if (row.number_of_cases_in_soi === 0) {
                                return '-'
                            }
                            return <span>
                                <ReadMoreLessToggle
                                    is_expanded={row.is_soi_expanded}
                                    onclick={() => this.load_related_soi(row)}
                                    is_loading={row.is_soi_loading}
                                    summary={`${row.number_of_cases_in_soi} cs`}
                                >
                                    {row.related_soi?.map((soi) =>
                                        <div>
                                            {accountIcon({
                                                slug: soi.sales_order_account_slug,
                                                name: soi.sales_order_account_name,
                                            })}
                                            <Tippy
                                                content={`${soi.relation_name} (${soi.sales_manager_name})`}
                                                allowHTML={true}
                                            >
                                                <Link href={`/sales/orders/${soi.sales_order_artkey}/view/manage`}>
                                                    {`${soi.sales_order_reference} (${soi.number_of_cases} cs)`}
                                                </Link>
                                            </Tippy>
                                        </div>,
                                    )}
                                </ReadMoreLessToggle>
                            </span>

                        }}
                    />
                    <CollectionTableColumn<PurchaseOrderItemView>
                        header_title={() => 'Price'}
                        data_field={(row: PurchaseOrderItemView) => {
                            if (row.is_editing) {
                                return <FieldMoney
                                    precision={5}
                                    currency={[row, 'was_bought_in']}
                                    model={[row, 'was_bought_for']}
                                />
                            }

                            const spli_price: number | null = row.spli_price_per_case ? +row.spli_price_per_case : null
                            const is_bid_price = !!spli_price && +row.was_bought_for !== spli_price
                            const bottle_price = +row.was_bought_for / row.number_of_bottles

                            let btl_cs_price = <CellBottleAndCase
                                bottle_text={format_money_with_symbol(bottle_price, row.was_bought_in)}
                                case_text={format_money_with_symbol(+row.was_bought_for, row.was_bought_in)}
                            />
                            if (row.was_bought_for && is_bid_price) { // show tooltip if price differs from source
                                btl_cs_price = <div className={`fl ${is_bid_price ? 'warning' : ''}`}>
                                    <Tippy content='Differs from source price list item'>
                                        {btl_cs_price}
                                    </Tippy>
                                </div>
                            }

                            return <div className={classes('td-group')}>
                                {btl_cs_price}
                                <div className={'details'}>
                                    <SubAmount
                                        label={'With costs:'}
                                        amount={row.euro_was_bought_for_plus_costs}
                                        currency={'EUR'}
                                    />
                                </div>
                            </div>
                        }}
                    />
                    <CollectionTableColumn<GetPurchaseOrderItemsCollectionViewResponse>
                        header_title={() => 'Total value'}
                        data_field={(row: GetPurchaseOrderItemsCollectionViewResponse) =>
                            <Amount amount={row.total_price} currency={row.was_bought_in} rate={row.bought_against_rate}/>
                        }
                    />
                    <CollectionTableColumn<GetPurchaseOrderItemsCollectionViewResponse>
                        header_title={() => 'Sales price / cs'}
                        data_field={(row: PurchaseOrderItemView) =>
                            row.is_editing
                                ? <FieldMoney
                                    currency={[row, 'was_bought_in']}
                                    model={[row, 'suggested_price_per_case']}
                                />

                                : <Amount amount={row.suggested_price_per_case} currency={'EUR'}/>
                        }
                    />
                    <CollectionTableColumn<GetPurchaseOrderItemsCollectionViewResponse>
                        header_title={() => 'Hide information'}
                        data_field={(row: PurchaseOrderItemView) =>
                            <ReadMoreLessToggle
                                is_expanded={row.is_hidden_information_expanded}
                                onclick={() => this.load_hidden_information(row)}
                                disabled={(row.hidden_for_supplier_artkeys.length + row.hidden_in_country_codes.length) === 0}
                                is_loading={row.is_hidden_information_loading}
                                summary={`${row.hidden_in_country_codes.length} countries / ${row.hidden_for_supplier_artkeys.length} relations`}
                            >
                                {row.hidden_information && <div>
                                    <label>Countries: </label>
                                    {row.hidden_information.hidden_country_codes.map(
                                        (country_code) => <Country country_code={country_code} type={'flag_with_country'}/>,
                                    )}
                                    <label>Relations: </label>
                                    {row.hidden_information.hidden_relations.map(
                                        (relation) => <div>{relation.name}</div>)}
                                </div>}
                            </ReadMoreLessToggle>
                        }
                    />
                    <CollectionTableColumn<GetPurchaseOrderItemsCollectionViewResponse>
                        header_title={() => 'Lot nrs'}
                        data_field={(row: GetPurchaseOrderItemsCollectionViewResponse) => <span>
                            <Link
                                href={`/stock/manage?search=P${row.purchase_order_artkey}+${row.product_name}+${row.volume}+${row.alcohol_percentage}`}>
                                <Icon name={'stock'}/>
                            </Link>
                            {row.lots.map((lot) => <Link href={`/stock/manage?search=${lot}`}>{' ' + lot}</Link>)}
                        </span>
                        }
                    />
                    <CollectionTableColumn<GetPurchaseOrderItemsCollectionViewResponse>
                        header_title={() => 'Aux'}
                        data_field={(row: GetPurchaseOrderItemsCollectionViewResponse) => <span>
                            {row.spli_suppliers_item_code &&
                                <div><label>Supplier code:</label>{` ${row.spli_suppliers_item_code}`}</div>}
                            {row.spli_hs_code && <div><label>Supplier HS code:</label>{` ${row.spli_hs_code}`}</div>}
                            {row.spli_case_gross_weight_in_kg &&
                                <div><label>Supplier weight:</label>{` ${row.spli_case_gross_weight_in_kg}`}</div>}
                            {row.spli_aux_info && <div><label>AUX:</label>{` ${row.spli_aux_info}`}</div>}
                        </span>
                        }
                    />
                    <CollectionTableColumn<GetPurchaseOrderItemsCollectionViewResponse>
                        header_title={() => 'Dimensions'}
                        data_field={(row: GetPurchaseOrderItemsCollectionViewResponse) => <span>
                            {row.dimensions && <div><label>LxBxH:</label>{` ${row.dimensions}`}</div>}
                            {row.weight && <div><label>Weight:</label>{` ${row.weight} kg`}</div>}
                        </span>}
                    />
                    <CollectionTableColumn<PurchaseOrderItemView>
                        td_class_name="actions"
                        header_title={() => ''}
                        data_field={(row: PurchaseOrderItemView) => {
                            const is_delete_present = [PurchaseOrderStatus.Saved, PurchaseOrderStatus.Confirmed].includes(row.purchase_order_status)
                            return <div className="actions-group no-click">
                                <ButtonGroup>
                                    {row.spl_artkey && <Button
                                        link={`/market/pricelists/${row.spl_artkey}/view/manage?search=${
                                            encodeURI(`${row.product_name} ${row.alcohol_percentage} ${row.volume}`)
                                        }`}
                                        target="_blank"
                                        icon="pricelist"
                                        tip="View in pricelist"
                                    />
                                    }
                                    {!row.is_editing && <Button
                                        icon="edit"
                                        onclick={() => this.start_editing(row)}
                                        tip={'Edit this row'}
                                    />}

                                    {row.is_editing && [
                                        <Button
                                            icon="cancel"
                                            onclick={() => this.cancel_editing(row)}
                                            tip="Cancel the editing operation"
                                        />,
                                        <Button
                                            icon="save"
                                            onclick={() => this.save_editing(row)}
                                            tip="Save the editing operation"
                                            type="info"
                                        />,
                                    ]}

                                    {is_delete_present && <Button
                                        icon="trash"
                                        onclick={() => this.remove_purchase_order_item(row)}
                                        tip={() => {
                                            if (row.number_of_cases_in_soi > 0) return 'Delete this item and convert sales order items'
                                            return 'Delete this item'
                                        }}
                                        type="danger"
                                    />}
                                </ButtonGroup>
                            </div>
                        }}
                    />
                </CollectionTable>
            </div>
        )
    }
}
