// (!) Do not import from tsconfig paths; e.g. no $s, $t, $... The reason is that this file
// is imported inside tests. The current ts-node config does not support ts-config.json paths.
// Package https://www.npmjs.com/package/tsconfig-paths could enable this.

import {next_tick} from '@bitstillery/common/lib/proxy'
import m from 'mithril'
import classnames from 'classnames'

// Accepts multiple arguments and types ('', [], {})
// See https://github.com/JedWatson/classnames
export const classes = classnames

export function add_unique_to_array(array, value) {
    if (!array.includes(value)) {
        array.push(value)
    }
    return array
}

export function copy_object(obj) {
    return JSON.parse(JSON.stringify(obj))
}

export async function blob_to_base64(blob): Promise<String> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onloadend = () => {
            // The "data:image/...;base64," prefix is part of the data URL scheme,
            // and must be omitted from the raw base64.
            let base64_data = reader.result as string
            let raw_base64_data = base64_data.split(',')[1]

            // Make sure the base64 is padded correctly, before sending to the backend.
            const padding = '='.repeat((4 - raw_base64_data.length % 4) % 4)
            raw_base64_data += padding
            resolve(raw_base64_data)
        }
        reader.onerror = reject
        reader.readAsDataURL(blob)
    })
}

/**
 * Converts a base64 encoded string to a Uint8Array of bytes.
 * This function is useful for decoding a base64 string to its original binary form.
 * @param {string} base64 The base64 encoded string to convert.
 * @returns {Uint8Array} A Uint8Array representing the decoded bytes of the base64 string.
 */
export function base64_to_bytes(base64: string): Uint8Array {
    const binary_string = atob(base64)
    const len = binary_string.length
    const bytes = new Uint8Array(len)

    for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i)
    }

    return bytes
}

/**
 * Converts "2024-09-25T00:00:00Z" to "2024-09-25"
 * @param dateTimeString
 * @returns
 */
export function datetime_to_date(datetime) {
    if (typeof datetime !== 'string') {
        return null
    }

    if (!datetime.includes('T')) {
        throw new Error('invalid datetime string')
    }
    return datetime.split('T')[0]
}

export function data_from_blueprint(data, blueprint) {
    for (const key of Object.keys(data)) {
        if (Object.hasOwnProperty.call(blueprint, key)) {
            if (is_nested_object(data[key])) {
                data_from_blueprint(data[key], blueprint[key])
            } else {
                data[key] = blueprint[key]
            }
        }
    }
    return data
}

/**
 * Dereference a prop followed by a path of optional selectors.
 * This function is useful if you don't know if the prop you have
 * is a window.prop (function) or just a plain value.
 *
 * Example:
 * const purchaseOrder = { reference: () => 'P123' };
 * console.log(deref(purchaseOrder, (d) => d.reference)); // 'P123'
 *
 * // The following inputs give the same answer:
 * // const purchaseOrder = { reference: () => 'P123' };
 * // const purchaseOrder = { reference: 'P123' };
 * // const purchaseOrder = () => ({ reference: 'P123' });
 */
export function deref(
    prop: any,
    ...selectors: Array<(input: any) => any>
): any {
    if (selectors.length > 0) {
        const [fn, ...rest] = selectors
        return deref(fn(deref(prop)), ...rest)
    } else if (typeof prop === 'function') {
        return prop()
    } else {
        return prop
    }
}

/**
 * Downloads a file using a base64 encoded string.
 * @param {string} base64 The base64 encoded string representing the file content.
 * @param {string} filename The name of the file to be downloaded.
 */
export function download_base64_file(base64: string, filename: string) {
    const byte_array = base64_to_bytes(base64)
    const blob = new Blob([byte_array], {type: 'application/octet-stream'})
    const a = document.createElement('a')
    document.body.appendChild(a) // necessary for Firefox(?)
    const url = window.URL.createObjectURL(blob)
    a.href = url
    a.download = filename
    a.click()

    window.URL.revokeObjectURL(url)
    document.body.removeChild(a)
}

/**
 * Converts a nested object where values are function calls into a regular object
 * by evaluating all function calls recursively
 * @param obj The object to convert
 * @returns A new object with all function calls evaluated
 */
export function evaluate_props(obj: any): any {
    if (typeof obj !== 'object' || obj === null) {
        return obj
    }

    if (Array.isArray(obj)) {
        return obj.map(item => evaluate_props(item))
    }

    const result: any = {}
    for (const [key, value] of Object.entries(obj)) {
        if (typeof value === 'function') {
            // Evaluate the function and then evaluate its result if it's an object
            const evaluated_value = value()
            result[key] = evaluate_props(evaluated_value)
        } else if (typeof value === 'object' && value !== null) {
            result[key] = evaluate_props(value)
        } else {
            result[key] = value
        }
    }
    return result
}

export async function focus_field(tabindex: number) {
    await next_tick()
    await next_tick()
    const element = document.querySelector(`[tabindex="${tabindex}"]`) as HTMLElement
    if (element) {
        requestAnimationFrame(() => {
            element.focus({focusVisible: true})
        })
    }
}

/**
 * Generates a random transaction id.
 *
 * See https://blog.sentry.io/2019/04/04/trace-errors-through-stack-using-unique-identifiers-in-sentry
 */
export function generate_transaction_id(): string {
    return generate_unique_id()
}

/**
 * Generate unique ID.
 * See https://gist.github.com/gordonbrander/2230317.
 * @returns string
 */
export function generate_unique_id(): string {
    return Math.random().toString(36).substr(2, 9)
}

/**
 * Fast and simple insecure string hash for JavaScript
 * See https://gist.github.com/jlevy/c246006675becc446360a798e2b2d781
 */
export function hash(str) {
    let hash = 0
    for (let i = 0; i < str.length; i++) {
        const char = str.charCodeAt(i)
        hash = (hash << 5) - hash + char
        hash &= hash // Convert to 32bit integer
    }
    return new Uint32Array([hash])[0].toString(36)
}

export function is_nested_object(value) {
    return value !== null && typeof value === 'object' && !Array.isArray(value)
}

export function is_object(v) {
    return (v && typeof v === 'object' && !Array.isArray(v))
}

export function is_promise(value) {
    return Boolean(value && typeof value.then === 'function')
}

/**
 * Returns a function, that, as long as it continues to be invoked, will not be
 * triggered. The function will be called after it stops being called for N
 * milliseconds.
 */
export function debounce(wait: number, func) {
    type Timeout = ReturnType<typeof setTimeout>
    type Context = {timeout: Timeout | undefined}
    let ctx: Context = {timeout: undefined}
    let callback = function(this: any, ...args: Parameters) {
        return new Promise((resolve) => {
            let later = async() => {
                ctx.timeout = undefined
                resolve(func.apply(this, args))
            }
            if (ctx.timeout) {
                clearTimeout(ctx.timeout)
            }

            ctx.timeout = setTimeout(later, wait)
        })
    }
    return callback
}

export function delay(msec, value) {
    return new Promise(done => window.setTimeout((() => done(value)), msec))
}

/**
* See https://git.osso.nl/springtimegroup/discover/-/issues/1615
* Artkeys need to be splitted in reference: SOI & artkey 123
* Until that's fixed, we need to split the artkey.
*/
export function entity_artkey(artkey) {
    return typeof artkey === 'string' && artkey.includes('-') ? Number(artkey.split('-')[1]) : artkey
}

/**
 * Get the descendant of an object by string. E.g.:
 * get_descendant_prop({ a: { b: { c: { d: 3 } } } }, "a.b.c.d")
 * returns 3
 * @param obj An object
 * @param desc A string representing the path to the descendant
 */
export function get_descendant_prop(obj: Record<string, any>, desc: string): any {
    const arr = desc.split('.')
    for (const key of arr) {
        obj = obj[key]
        if (!obj) {
            break
        }
    }
    return obj
}

export function get_route(path, include_params = {} as any, exclude_params = []) {
    const current_params = include_params

    if (exclude_params.length) {
        for (const param_key of Object.keys(current_params)) {
            if (exclude_params.includes(param_key)) {
                delete current_params[param_key]
            }
        }
    }

    if (Object.keys(current_params).length) {
        return `${path}?${new URLSearchParams(current_params).toString()}`
    }
    return path
}

export function group_by(values, keyFinder) {
    // using reduce to aggregate values
    return values.reduce((a, b) => {
        // depending upon the type of keyFinder
        // if it is function, pass the value to it
        // if it is a property, access the property
        const key = typeof keyFinder === 'function' ? keyFinder(b) : b[keyFinder]

        // aggregate values based on the keys
        if (!a[key]) {
            a[key] = [b]
        } else {
            a[key] = [...a[key], b]
        }

        return a
    }, {})
}

/**
 * Identifies keys present in the reference object but missing in the target object,
 * concatenating them into a dot notation path and adding to the diff array.
 * @param {Object} reference - The reference object to compare keys from.
 * @param {Object} target - The target object to compare keys against.
 * @param {Array} diff - An array to store the paths of keys that are in reference
 * but not in target.
 * @param {Array} currentPath - The current path being traversed, for nested objects.
 */
export function key_diff(reference: Record<string, any>, target:Record<string, any>, diff, currentPath?) {
    if (!currentPath) {
        currentPath = []
    }
    for (const key of Object.keys(reference)) {
        if (typeof target[key] === 'object') {
            currentPath.push(key)
            key_diff(reference[key], target[key], diff, currentPath)
        } else if (!(key in target)) {
            diff.push([...currentPath, key].join('.'))
        }
    }
    currentPath.pop()
}

export function key_path(obj, path) {
    if (typeof path !== 'string') return null
    const _path = path.split('.')
    let _obj = obj
    while (_path.length) {
        _obj = _obj[_path.shift()]
    }

    return _obj
}

/**
 * Deeply merges multiple source objects into a target object. It recursively
 * merges only own and enumerable properties of the source objects into the
 * target object. Arrays and primitive types are overwritten by assignment.
 * @param {Object} target - The target object to merge properties into.
 * @param {...Object} sources - One or more source objects from which to copy properties.
 * @returns {Object} The target object after merging.
 */
export function merge_deep(target, ...sources) {
    if (!sources.length) return target
    const source = sources.shift()

    if (is_object(target) && is_object(source)) {
        for (const key in source) {
            if (is_object(source[key])) {
                if (!target[key]) Object.assign(target, {[key]: {}})
                merge_deep(target[key], source[key])
            } else {
                Object.assign(target, {[key]: source[key]})
            }
        }
    }

    return merge_deep(target, ...sources)
}

export function object_to_query_string(obj) {
    return Object.entries(obj).map(([key, value]) => {
        if (typeof value === 'object' && value !== null) {
            // Convert nested objects to a stringified JSON format
            return `${encodeURIComponent(key)}=${encodeURIComponent(JSON.stringify(value))}`
        } else {
            // Convert other values to a URL-encoded format
            return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        }
    }).join('&')
}

export function product_photo_image_location(
    host: string,
    product_photo: { s3_location: string; s3_location_thumbnail?: string | null },
): string {
    const image_location = (
        product_photo.s3_location_thumbnail || product_photo.s3_location
    )
        .replace('product_photos/', '')
        .split('/')
        .map((segment) => encodeURIComponent(segment))
        .join('/')

    return `${host}/${image_location}`
}

/**
 * Generate random string of length.
 * @param length Length of string to generate.
 * @returns
 */
export function random_string(
    length: number,
    include_numbers: boolean = true,
): string {
    let result = ''
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
    if (include_numbers) {
        characters += '0123456789'
    }
    const characters_length = characters.length
    for (var i = 0; i < length; i++) {
        result += characters.charAt(
            Math.floor(Math.random() * characters_length),
        )
    }
    return result
}

// Removes the value from a mutable array.
// Assumes that the value won't occur more than once.
export function remove_from_array(array, value) {
    const idx = array.indexOf(value)
    if (idx >= 0) {
        array.splice(idx, 1)
    }
    return array
}

/**
 * Deeply copies properties from source to target, and resets properties in target
 * that don't exist in source (but skips specified keys).
 * @param {Object} target - The target object to modify
 * @param {Object} source - The source object to copy from (optional)
 * @param {string[]} skip_keys - Keys to skip when resetting (optional)
 */
export function reset_deep(target, source = null, skip_keys: string[] = []) {
    // If no source is provided, just reset all values in target
    if (source === null) {
        for (const key in target) {
            if (typeof target[key] === 'object' && target[key] !== null) {
                reset_deep(target[key], null, skip_keys)
            } else {
                if (skip_keys.includes(key)) {
                    continue
                }
                if (typeof target[key] === 'string') {
                    target[key] = ''
                } else if (typeof target[key] === 'number') {
                    target[key] = undefined
                } else if (typeof target[key] === 'boolean') {
                    target[key] = false
                }
            }
        }
        return
    }

    // First, copy all properties from source to target (deep merge)
    for (const key in source) {
        if (is_object(source[key]) && is_object(target[key])) {
            // Recursively merge nested objects
            reset_deep(target[key], source[key], skip_keys)
        } else {
            // Copy value from source to target
            target[key] = source[key]
        }
    }

    // Then, reset properties that exist in target but not in source
    for (const key in target) {
        if (!(key in source)) {
            if (skip_keys.includes(key)) {
                continue
            }
            if (typeof target[key] === 'object' && target[key] !== null) {
                reset_deep(target[key], null, skip_keys)
            } else {
                if (typeof target[key] === 'string') {
                    target[key] = ''
                } else if (typeof target[key] === 'number') {
                    target[key] = undefined
                } else if (typeof target[key] === 'boolean') {
                    target[key] = false
                }
            }
        }
    }
}

/**
 * Converts a string into a URL-friendly slug.
 * The function takes a string, converts it to lowercase, then replaces spaces with dashes.
 * @param {string} name - The string to be converted into a slug.
 * @returns {string} The resulting slug.
 */
export function slugify(name:string) {
    return name.toLowerCase().split(' ').join('-')
}
/**
 * Splits an incoterm string into its components.
 * If the incoterm string contains a single part, it assumes the incoterm is 'EXW'
 * and the location is the single part. If the incoterm string contains multiple parts, t
 * he first part is considered the incoterm and the rest is joined as the location.
 *
 * @param {string} incoterm - The incoterm string to be split.
 * @returns {Object} An object containing the incoterm and location.
 */
export function split_incoterm(incoterm) {
    if (!incoterm) return
    const parts = incoterm.split(' - ')
    // If we split more with our -, join the rest on -
    if (parts.length === 1) {
        return {
            incoterm: 'EXW',
            location: parts[0],
        }
    } else {
        return {
            incoterm: parts[0],
            location: parts.slice(1).join('-'),
        }
    }
}

export function stringify_json(data) {
    return JSON.stringify(data, function(k,v) {
        if (v instanceof Array) {
            return JSON.stringify(v)
        }
        return v
    },2).replace(/\\/g, '')
        .replace(/"\[/g, '[')
        .replace(/]"/g,']')
        .replace(/"\{/g, '{')
        .replace(/}"/g,'}')
}

export function strip_empty_values_nested_obj(obj: object, skip_keys: string[] = []) {
    Object.keys(obj).forEach(key => {
        if (skip_keys.includes(key)) return
        if ((obj[key] === '' || obj[key] === undefined || obj[key] === null)) {
            delete obj[key]
        } else if (typeof obj[key] === 'object') {
            strip_empty_values_nested_obj(obj[key], skip_keys)
        }
    })
    return obj
}

/**
 * Calculates the sum of an array of numbers.
 *
 * @param {number[]} number_array: An array with numbers to calculate the sum of.
 * @return {number}: The calculated sum.
 */
export function sum(number_array: number[]): number {
    return number_array.reduce((a, b) => a + b, 0)
}

/**
 * Constructs a template string from the provided inputs.
 * @param {TemplateStringsArray} strings The template strings array.
 * @param {...any} keys The keys to replace within the template.
 * @returns {Function} Returns a function that accepts replacements for the template and returns the resulting string.
 */
export function template(strings, ...keys) {
    return (...values) => {
        const dict = values[values.length - 1] || {}
        const result = [strings[0]]
        keys.forEach((key, i) => {
            const value = Number.isInteger(key) ? values[key] : dict[key]
            result.push(value, strings[i + 1])
        })
        return result.join('')
    }
}

/**
 * Returns a function that will only apply a the provided function call once
 * every threshold milliseconds at most
 */
export const throttle = function<F extends(this: any, ...args: any[]) => void>(threshold: number, func: F): F {
    type Timeout = ReturnType<typeof setTimeout>
    type Context = {last: number | null; timeout: Timeout | undefined}
    let ctx: Context = {last: null, timeout: undefined}
    let callback = function(this: any, ...args: Parameters<F>) {
        const now = new Date().getTime()
        if (ctx.last && now < ctx.last + threshold) {
            if (ctx.timeout) {
                clearTimeout(ctx.timeout)
            }
            ctx.timeout = setTimeout(() => {
                ctx.last = now
                func.apply(this, args)
            }, threshold - (now - ctx.last))
        } else {
            ctx.last = now
            func.apply(this, args)
        }
    }
    return callback as F
}

export function titleize(str) {
    return str.replace(/\b[a-z]/g, (_str) => (str.toUpperCase()))
}

/**
 * Creates a string that can be used for unique dynamic id attributes
 * @param length The length of the string
 * @param include_numbers Whether to include numbers in the string
 */
export function unique_id(length: number = 9, include_numbers: boolean = true) {
    let result = ''
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
    if (include_numbers) {
        characters += '0123456789'
    }
    const characters_length = characters.length
    for (let i = 0; i < length; i++) {
        result += characters.charAt(
            Math.floor(Math.random() * characters_length),
        )
    }
    return result
}

/**
 * Converts the first character of a string to uppercase.
 * If the input string is empty or null, returns an empty string.
 * @param {string} str The string to convert.
 * @returns {string} The string with the first character in uppercase.
 */
export function ucfirst(str) {
    if (!str || !str.length) return ''
    return str[0].toUpperCase() + str.substring(1)
}

/**
 * Returns the current URL search parameters as a query string
 */
export function url_query_string(extra_params: Record<string, string> = {}) {
    if (!m.route.get()) return ''
    const {params} = m.parsePathname(m.route.get())
    merge_deep(params, extra_params)
    if (!Object.keys(params).length) {
        return ''
    }
    return `?${new URLSearchParams(params as Record<string, string>).toString()}`
}

/**
 * Compares two URLSearchParams objects to determine if they have different parameters.
 * @param {URLSearchParams} searchParams1 The first URLSearchParams object for comparison.
 * @param {URLSearchParams} searchParams2 The second URLSearchParams object for comparison.
 * @returns {boolean} Returns true if there is a difference in the parameters, otherwise false.
 */
export function url_search_params_diff(searchParams1:URLSearchParams, searchParams2:URLSearchParams) {
    const params1 = Object.fromEntries(searchParams1)
    const params2 = Object.fromEntries(searchParams2)
    const params2_diff = Object.entries(params2).some(([k, v]) => v !== params1[k])
    const params1_diff = Object.entries(params1).some(([k, v]) => v !== params2[k])
    return params2_diff || params1_diff
}

export function validate_bottle_gtin(gtin) {
    if (gtin.match(/^[0-9]+$/) === null) {
        return 'Please enter a valid bottle GTIN'
    }

    if (![12, 13].includes(gtin.length)) {
        return 'Bottle GTIN contains either 12 or 13 digits'
    }

    if (!validate_check_digit(gtin)) {
        return 'GTIN check digit is incorrect'
    }
}

export function validate_check_digit(gtin) {
    const gtin_min_check = gtin.substring(0, gtin.length - 1).split('').map(Number).reverse()

    let sum = 0
    let i = 1
    for (const digit of gtin_min_check) {
        if (i % 2 === 0) {
            sum += digit
        } else {
            sum += digit * 3
        }
        i++
    }

    const check_digit = (10 - (sum % 10)) % 10
    const same_digit_bool = check_digit.toString() === gtin.charAt(gtin.length - 1)
    return same_digit_bool
}
