/* eslint-disable @typescript-eslint/naming-convention */

import m from 'mithril'
import {Gauge} from 'gaugeJS'
import {
    id, empty, head,
    Obj, objToPairs, pairsToObj,
    apply, map, splitAt,
    isType, drop,
    tail, first, groupBy, sum, maximum,
    sortWith,
} from 'prelude-ls'
import {format_iso_to_date, format_iso_to_date_time, format_iso_to_fixed_date_format} from '@bitstillery/common/lib/format'

// Type definitions
type CustomEventDetail = any
type CustomEventCallback = (event: CustomEvent<CustomEventDetail>) => void

interface Target {
    broadcast: (type: string, payload: any) => void
    subscribe: (type: string, that: any, callback: CustomEventCallback) => void
    onremove?: () => void
}

export const eventsMixin = (target: Target): Target => {
    target.broadcast = (type: string, payload: any) => {
        const ev = new CustomEvent(type, {detail: payload, bubbles: true, cancelable: true})
        document.dispatchEvent(ev)
    }

    target.subscribe = (type: string, that: any, callback: CustomEventCallback) => {
        const old_unremove = that.onremove
        that.onremove = () => {
            document.removeEventListener(type, callback)
            if (old_unremove) {
                old_unremove()
            }
        }
        document.addEventListener(type, callback)
    }

    return target
}

/** @deprecated: Use the @bitstillery/common/lib/format. */
export const formatDate = (date: Date | string | null): string => {
    if (!date) {
        return ''
    }
    if (!(date instanceof Date)) {
        date = new Date(date)
    }
    return format_iso_to_date(date.toISOString())
}

/** @deprecated: Use the @bitstillery/common/lib/format. */
export const format_date_html5 = (date: Date | string | null): string => {
    if (!date) {
        return ''
    }
    if (!(date instanceof Date)) {
        date = new Date(date)
    }
    return format_iso_to_fixed_date_format(date.toISOString())
}

export const formatTime = (date: Date | string | null): string => {
    if (!date) {
        return ''
    }
    if (!(date instanceof Date)) {
        date = new Date(date)
    }
    const m = date.getMinutes()
    const minutes = m < 10 ? '0' + m : m
    const h = date.getHours()
    const hours = h < 10 ? '0' + h : h
    return `${hours}:${minutes}`
}

/** @deprecated: Use the @bitstillery/common/lib/format. */
export const formatDateTime = (date: Date | string | null): string => {
    if (!date) {
        return ''
    }
    if (!(date instanceof Date)) {
        date = new Date(date)
    }
    return format_iso_to_date_time(date.toISOString())
}

Number.prototype.formatMoney = function(): string {
    const n = this
    const c = 2
    const d = ','
    const t = '.'
    const s = n < 0 ? '-' : ''
    const i = parseInt(Math.abs(Number(n) || 0).toFixed(c)) + ''
    const j = (i.length) > 3 ? i.length % 3 : 0
    return s + (j ? i.substr(0, j) + t : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + t) + (c ? d + Math.abs(n - parseInt(i)).toFixed(c).slice(2) : '')
}

String.prototype.capitalizeFirstLetter = function(): string {
    return this.charAt(0).toUpperCase() + this.slice(1)
}

export const capitalize = (str: string): string => {
    return str.replace(/\b[a-z]/g, (match) => match.toUpperCase())
}

export const escapeRegExp = (str: string): string => {
    return str.replace(/([.*+?^=!:${}()|[\]/\\])/g, '\\$1')
}

export const url = (url: string | null): string | null => {
    if (url && !url.startsWith('http://') && !url.startsWith('https://')) {
        return 'http://' + url
    }
    return url
}

interface Incoterm {
    incoterm: string
    location: string
}

export const split_incoterm = (incoterm: string | null): Incoterm | undefined => {
    if (!incoterm) {
        return undefined
    }
    const parts = incoterm.split(' - ')
    if (parts.length === 1) {
        return {
            incoterm: 'EXW',
            location: parts[0],
        }
    } else {
        return {
            incoterm: parts[0],
            location: parts.slice(1).join('-'),
        }
    }
}

String.prototype.startsWith = function(searchString: string, position: number = 0): boolean {
    return this.indexOf(searchString, position) === position
}

export const get_descendant_prop = (obj: any, desc: string): any => {
    const arr = desc.split('.')
    let current = obj
    for (const prop of arr) {
        if (!current) return undefined
        current = current[prop]
    }
    return current
}

export const base64_str_to_byte_array = (base64_str: string): Uint8Array => {
    const byte_chars = atob(base64_str)
    const byte_numbers = Array.from(byte_chars).map((bc, index) => byte_chars.charCodeAt(index))
    return new Uint8Array(byte_numbers)
}

export const download_binary_file_from_base64_str = (base64_str: string, file_name: string): void => {
    download_binary_file_from_base64_str_with_type(base64_str, file_name, 'application/octet-stream')
}

export const download_binary_file_from_base64_str_with_type = (
    base64_str: string,
    file_name: string,
    content_type: string,
): void => {
    const byte_array = base64_str_to_byte_array(base64_str)
    const blob = new Blob([byte_array], {type: content_type})
    const a = document.createElement('a')
    const url = window.URL.createObjectURL(blob)
    a.href = url
    a.download = file_name
    a.click()
    window.URL.revokeObjectURL(url)
}

export const tooltip = (e: JQuery): void => {
    $(e).tooltip({container: 'body'})
}

export const randomString = (length: number): string => {
    let text = ''
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

    for (let i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length))
    }

    return text
}

export const multiline = (str: string | null): m.Vnode[] => {
    return (str || '').split('\n').map(line =>
        m('span', [line, m('br')]),
    )
}

Date.prototype.getWeek = function(): string {
    const date = new Date(this.getTime())
    date.setHours(0, 0, 0, 0)
    date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7)
    const week1 = new Date(date.getFullYear(), 0, 4)
    const week_number = 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + (week1.getDay() + 6) % 7) / 7)
    const leading_zero = week_number < 10 ? '0' : ''
    return `${date.getFullYear()}-W${leading_zero}${week_number}`
}

Date.prototype.getMonthInputFormat = function(): string {
    const month = ('0' + (this.getMonth() + 1)).slice(-2)
    return `${this.getFullYear()}-${month}`
}

export const rounded_number_to_two = (num: number): number => {
    return Math.round((+num + Number.EPSILON) * 100) / 100
}

export const withDefault = <T>(default_value: T, value: T | null | undefined): T => {
    return value !== null && value !== undefined ? value : default_value
}

export const percentage = (first_value: number, second_value: number): string => {
    if (+first_value === 0 && +second_value === 0) {
        return '0.0%' // This is actually undefined, but we default to 0 here.
    } else {
        return formatPercentage(+first_value / +second_value)
    }
}

export const formatPercentage = (value: number): string => {
    return (100 * value).toFixed(1) + '%'
}

export const formatPercentageBase100 = (value: number): string => {
    return `${value}%`
}

export const onSet = (prop_ref, callback: (value) => void): any => {
    return (new_value) => {
        if (new_value !== undefined) {
            prop_ref(new_value)
            callback(new_value)
        }
        return prop_ref()
    }
}

export const afterUpdate = (callback: (value) => void, prop_ref: any)=> {
    return (new_value) => {
        if (new_value !== undefined) {
            const old_value = prop_ref()
            prop_ref(new_value)
            if (new_value !== old_value) {
                callback(new_value)
            }
        }
        return prop_ref()
    }
}

export const stopPropagation = (fn: (e: Event) => void = () => {}): (e: Event) => void => {
    return (e: Event) => {
        e.stopPropagation()
        fn(e)
    }
}

export const preventDefault = (fn: (e: Event) => void): (e: Event) => void => {
    return (e: Event) => {
        e.preventDefault()
        fn(e)
    }
}

export const maybeMap = (fn, maybe_value) => {
    return maybe_value ? fn(maybe_value) : maybe_value
}

export const allEqual = (list) => {
    if (empty(list)) {
        return false
    } else {
        return list.every(item => item === head(list))
    }
}

export const joinMaybes = (separator: string, list_of_maybes: any[]): string => {
    return list_of_maybes.filter(id).join(separator)
}

export const randomUuid = (): string => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        const r = Math.random() * 16 | 0
        const v = c === 'x' ? r : (r & 0x3 | 0x8)
        return v.toString(16)
    })
}

export const update = (prop, fn) => {
    prop(fn(prop()))
}

export const dec = (x: number): number => x - 1
export const inc = (x: number): number => x + 1

export const copy = <T extends object>(obj: T): T => {
    return Obj.map(id, obj)
}

export const assoc = <T extends object>(key: string, value: any, obj: T): T => {
    const res = copy(obj);
    (res as any)[key] = value
    return res
}

export const filterObj = <T extends object>(fn: (key: string, value: any) => boolean, obj: T): Partial<T> => {
    return pairsToObj(
        objToPairs(obj).filter(pair => apply(fn, pair)),
    ) as Partial<T>
}

export const str = (value: any): string => '' + value

export const unassoc = <T extends object>(key: string, obj: T): Partial<T> => {
    return filterObj((k) => k !== str(key), obj)
}

export const mapObj = <T extends object>(fn: (entry: [string, any]) => [string, any], obj: T): object => {
    return pairsToObj(
        objToPairs(obj).map(fn),
    )
}

export const matchTermIn = (term: string, list: string[]): boolean => {
    return list.some(item => item.indexOf(term) >= 0)
}

export const partitions = <T>(n: number, list: T[]): T[][] => {
    if (empty(list)) return []
    const [h, t] = splitAt(n, list)
    return [h].concat(partitions(n, t))
}

export const mExt = (tag: string, init_attrs: any = {}) => {
    return (optional_attrs: any, ...children: any[]) => {
        if (isType('Object', optional_attrs)) {
            const final_attrs = {...init_attrs, ...optional_attrs}
            if (init_attrs['class'] && optional_attrs['class']) {
                final_attrs['class'] = `${init_attrs.class} ${optional_attrs.class}`
            }
            return m(tag, final_attrs, ...children)
        } else {
            return m(tag, init_attrs, optional_attrs, ...children)
        }
    }
}

export const joinClasses = (classes: string | string[]): string => {
    if (Array.isArray(classes)) {
        return classes.filter(Boolean).join(' ')
    } else if (classes) {
        return classes
    } else {
        return ''
    }
}

export const indirectProp = (prop_ref) => {
    return (value) => {
        if (value !== undefined) {
            prop_ref()(value)
        }
        return prop_ref()()
    }
}

export const toString = (value: any): string => '' + value

export const toInt = (value: any): number => +value

export const toBool = (value: any): boolean => !!value

export const compare = (a: any, b: any): number => {
    if (a < b) return -1
    if (a > b) return 1
    return 0
}

export const compareOn = (...props: ((a: any) => any)[]): (a: any, b: any) => number => {
    return (a: any, b: any) => {
        for (const fn of props) {
            const result = compare(fn(a), fn(b))
            if (result !== 0) return result
        }
        return 0
    }
}

export const ifLet = <T, U>(value: T | null | undefined, fn: (value: T) => U): U | undefined => {
    return value ? fn(value) : undefined
}

export const pluralize = (count: number, singular: string, plural: string): string => {
    return Math.abs(count) === 1 ? singular : plural
}

export const append = <T>(suffix: T[] | string, list: T[] | string): T[] | string => {
    return (list as any).concat(suffix)
}

export const prepend = <T>(prefix: T[] | string, list: T[] | string): T[] | string => {
    return (prefix as any).concat(list)
}

export const toList = <T>(value: T | T[]): T[] => {
    return Array.isArray(value) ? value : [value]
}

export const mergeObj = <T extends object, U extends object>(a: T, b: U): T & U => {
    return pairsToObj([...objToPairs(a), ...objToPairs(b)]) as T & U
}

export const isList = (value: any): boolean => {
    return Array.isArray(value)
}

export const indexedMap = <T, U>(fn: (value: T, index: number) => U, list: T[]): U[] => {
    return list.map(fn)
}

export const removeRange = <T>(start: number, count: number, list: T[]): T[] => {
    const [prefix, suffix] = splitAt(start, list)
    return [...prefix, ...drop(count, suffix)]
}

export const contains = (sub: string, str: string): boolean => {
    return str.indexOf(sub) >= 0
}

export const clamp = (min: number, max: number, value: number): number => {
    if (value < min) return min
    if (value > max) return max
    return value
}

export const ifNull = <T>(fn: () => T, value: T | null | undefined): T => {
    return value !== null && value !== undefined ? value : fn()
}

export const orElse = <T>(fn: () => T, value: T | null | undefined | false): T => {
    return value ? value : fn()
}

export const ifEmpty = <T>(fn: () => T[], list: T[]): T[] => {
    return empty(list) ? fn() : list
}

export const invert = (b: boolean): boolean => !b

export const propWithWatch = (init_value, change_handler) => {
    const prop = window.prop(init_value)
    return onSet(prop, change_handler)
}

export const compareStrI = (a: any, b: any): boolean => {
    if (typeof a === 'string' && typeof b === 'string') {
        return a.toLowerCase() === b.toLowerCase()
    }
    return false
}

export const toLower = (str: string): string => str.toLowerCase()

export const toUpper = (str: string): string => str.toUpperCase()
export const dup = <T>(a: T): [T, T] => [a, a]

export const condClasses = (classes: { [key: string]: boolean }): string[] => {
    return Object.keys(classes).filter(key => classes[key])
}

export const intersperse = <T>(sep: T, list: T[]): T[] => {
    if (empty(list)) {
        return []
    } else if (empty(tail(list))) {
        return list
    } else {
        return [head(list), sep, ...intersperse(sep, tail(list))]
    }
}

export const eff = (fn) => {
    return (value) => {
        fn(value)
        return value
    }
}

export const removeFromArray = (array, value) => {
    const idx = array.indexOf(value)
    if (idx >= 0) array.splice(idx, 1)
    return array
}

export const removeFromArrayProp = (prop, value) => {
    const idx = prop().indexOf(value)
    if (idx >= 0) prop().splice(idx, 1)
    return prop
}

export const addUniqueToArray = <T>(array: T[], value: T): T[] => {
    if (!array.includes(value)) {
        array.push(value)
    }
    return array
}

export const mapKeys = (fn: (key: string) => string) => {
    return map(([k, v]: [string, any]): [string, any] => [fn(k), v])
}

export const mapValues = (fn: (value: any, key: string) => any) => {
    return map(([k, v]: [string, any]): [string, any] => [k, fn(v, k)])
}

export const second = <T>(list: T[]): T => first(tail(list))

export const groupByKey = <T>(pairs: [string, T][]): { [key: string]: T[] } => {
    return Obj.map(
        map(second),
        groupBy(first, pairs),
    )
}

export const mapKv = <T, K, V>(key: (item: T) => K, value: (item: T) => V) => {
    return map((item: T): [K, V] => [key(item), value(item)])
}

export const listToPairs = <T, K>(key: (item: T) => K) => mapKv(key, id)

export const isInt = isType('Number')

export const maximumOf = <T>(prop: (item: T) => number, list: T[]): number => {
    return maximum(list.map(prop).filter(isInt))
}

export const sumOf = <T>(prop: (item: T) => number, list: T[]): number => {
    return sum(list.map(prop).filter(isInt))
}

export const compareVersions = (a: number[], b: number[]): number => {
    const [a_head, ...a_tail] = a
    const [b_head, ...b_tail] = b

    if (a_head !== undefined) {
        if (b_head !== undefined) {
            const diff = a_head - b_head
            if (diff) return diff
            return compareVersions(a_tail, b_tail)
        }
        return 1
    } else if (b_head !== undefined) {
        return -1
    }
    return 0
}

export const fmtVersion = (version: number[]): string => version.join('.')

export const sortByWith = <T>(prop: (item: T) => any, compare: (a: any, b: any) => number) => {
    return sortWith((a: T, b: T) => compare(prop(a), prop(b)))
}

export const delay = (ms: number, fn: () => void): ReturnType<typeof setTimeout> => {
    return setTimeout(fn, ms)
}

export const asap = (fn: () => void): number => delay(0, fn)

export const redirectTo = (url: string): m.Component => ({
    oninit: () => m.route.set(url),
    view: () => null,
})

export const uniqueId = (): string => {
    return Math.random().toString(36).substr(2, 16)
}

export const gauge = (
    target_element: string,
    min_value: number,
    max_value: number,
    value: number,
    arc_angle: number,
): void => {
    const opts = {
        angle: arc_angle,
        lineWidth: 0.15,
        radiusScale: 1,
        pointer: {
            length: 0.5,
            strokeWidth: 0.035,
            color: '#000000',
        },
        limitMax: false,
        limitMin: false,
        strokeColor: '#E0E0E0',
        generateGradient: true,
        highDpiSupport: true,
        percentColors: [
            [0.0, '#DD0000'],
            [0.4, '#DD0000'],
            [0.5, '#FFB50A'],
            [0.68, '#FFE603'],
            [0.78, '#a9d70b'],
            [0.82, '#4CBB17'],
        ],
        staticLabels: {
            font: '10px sans-serif',
            labels: [100],
            color: '#000000',
            fractionDigits: 0,
        },
    }

    const target = document.getElementById(target_element)
    if (!target) return

    const gauge = new Gauge(target).setOptions(opts)
    gauge.maxValue = max_value
    gauge.setMinValue(min_value)
    gauge.animationSpeed = 5
    gauge.set(value)
}

// Convert arguments to array (LiveScript spread operator conversion)
export const a = <T>(...args: T[]): T[] => args

export default {
    a,
    eventsMixin,
    formatDate,
    format_date_html5,
    formatTime,
    formatDateTime,
    capitalize,
    escapeRegExp,
    url,
    split_incoterm,
    get_descendant_prop,
    base64_str_to_byte_array,
    download_binary_file_from_base64_str,
    download_binary_file_from_base64_str_with_type,
    tooltip,
    randomString,
    multiline,
    rounded_number_to_two,
    withDefault,
    percentage,
    formatPercentage,
    formatPercentageBase100,
    onSet,
    afterUpdate,
    stopPropagation,
    preventDefault,
    maybeMap,
    allEqual,
    joinMaybes,
    randomUuid,
    update,
    dec,
    inc,
    copy,
    assoc,
    filterObj,
    str,
    unassoc,
    mapObj,
    matchTermIn,
    partitions,
    mExt,
    joinClasses,
    indirectProp,
    toString,
    toInt,
    toBool,
    compare,
    compareOn,
    ifLet,
    pluralize,
    append,
    prepend,
    toList,
    mergeObj,
    isList,
    indexedMap,
    removeRange,
    contains,
    clamp,
    ifNull,
    orElse,
    ifEmpty,
    invert,
    propWithWatch,
    compareStrI,
    toLower,
    toUpper,
    dup,
    condClasses,
    intersperse,
    eff,
    removeFromArray,
    removeFromArrayProp,
    addUniqueToArray,
    mapKeys,
    mapValues,
    second,
    groupByKey,
    mapKv,
    listToPairs,
    isInt,
    maximumOf,
    sumOf,
    compareVersions,
    fmtVersion,
    sortByWith,
    delay,
    asap,
    redirectTo,
    uniqueId,
    gauge,
}
