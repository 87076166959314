/** llm:production */
import m from 'mithril'
import {Amount} from '@bitstillery/common/components'
import {Icon, Country} from '@bitstillery/common/components'
import {format_iso_to_date} from '@bitstillery/common/lib/format'

import {CaseLabels} from '@/components/labels'

export const items_table = (items: any, options?: any) => {
    if (!options) {
        options = {
            include_prices: true,
        }
    }

    const purchase_order_link = (item: any) => {
        return `/purchase-orders/manage/${item.purchase_order_item().purchase_order().reference()}`
    }

    const a = (...args: any[]) => args

    return m('table.table', a(
        m('thead.thead-default',
            m('tr', a(
                m('th', '#'),
                m('th', 'Intake'),
                m('th', 'Warehouse'),
                m('th', 'Lot #'),
                m('th', 'Product'),
                m('th', 'Country of origin'),
                m('th.number', 'Btl / cs'),
                m('th.number', 'Size'),
                m('th.number', 'Alc %'),
                m('th', 'Ref'),
                m('th', 'GB'),
                ...(options.include_prices ? a(
                    m('th.price', 'Purchase / cs'),
                    m('th.price', 'Cost / cs'),
                ) : []),
                m('th.number', 'Cases'),
                m('th.number', 'Stock'),
                m('th.number', 'P.O.'),
                m('th.number', 'S.O.'),
                m('th.number', 'Avail.'),
                m('th', 'Customs status'),
                m('th'), // link to item
            )),
        ),

        items().map((item: any) => m('tbody.table-row', a(
            m('tr', a(
                m('td', m('a', {onclick: () => m.route.set(purchase_order_link(item))}, item.purchase_order_item().purchase_order().reference())),
                m('td', format_iso_to_date(item.entry_date()) || '-'),
                m('td', item.warehouse_artkey() ? item.warehouse().name() : undefined),
                m('td', m('samp', item.lot())),
                m('td', item.bottle().product().name()),
                m('td', m(Country, {
                    country_code: item.country_of_origin(),
                    type: 'flag_with_country',
                })),
                m('td.number', item.number_of_bottles_per_case()),
                m('td.number', item.bottle().display_volume()),
                m('td.number', item.bottle().display_alcohol_percentage()),
                m('td', item.bottle().refill_status()),
                m('td', item.gift_box_type()),

                ...(options.include_prices ? a(
                    m('td.price', m(Amount, {
                        amount: item.was_bought_for(),
                        currency: item.was_bought_in(),
                    })),
                    m('td.price', m(Amount, {
                        amount: item.was_bought_for_plus_costs(),
                        currency: item.was_bought_in(),
                    })),
                ) : []),

                m('td.number', item.number_of_cases() || 0),
                m('td.number', item.number_of_cases_in_stock() || 0),
                m('td.number', item.number_of_cases_in_purchase() || 0),
                m('td.number', item.number_of_cases_in_sales() || 0),
                m('td.number', item.number_of_cases_available() || 0),
                m('td', item.customs_status()),

                m('td.actions.no-click',
                    m('.actions-group',
                        m('.action-toggles',
                            m.route.get() !== `/stock/manage/${item.reference()}` ?
                                m(Icon, {
                                    name: 'search',
                                    onclick: () => window.open(`#!/stock/manage/${item.reference()}`),
                                    tip: `View stock item ${item.reference()}`,
                                    type: 'info',
                                }) : null,
                        ),
                    ),
                ),
            )),

            item.case() && item.case().serialized_item_tags ?
                m('tr', m('td', {colspan: '100%'},
                    m(CaseLabels, {
                        show_as_labels: true,
                        case: {
                            serialized_item_tags: item.case().serialized_item_tags(),
                            gift_box_type: item.case().gift_box_type(),
                            cases_per_pallet: item.cases_per_pallet(),
                            remark: item.remark(),
                            best_before_date: item.case().best_before_date(),
                            tax_label: item.case().tax_label(),
                        },
                    }),
                )) : null,
        ))),
    ))
}
