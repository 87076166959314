import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {classes} from '@bitstillery/common/lib/utils'
import {Icon} from '@bitstillery/common/components'
import {modelref_adapter} from '@bitstillery/common/lib/store'
import {$t} from '@bitstillery/common/app'
import {FieldSelectAttrs} from '@bitstillery/common/types/field'

export class FieldSelect extends MithrilTsxComponent<FieldSelectAttrs> {

    oninit(vnode: m.Vnode<FieldSelectAttrs>) {
        const empty_option_value = ['', null, undefined]
        const [obj, key] = vnode.attrs.model
        const selected_value = obj[key]

        // The default selected value is the first option; but that is only
        // the visible value, not the actual value. With a placeholder, we
        // assume the user will select an option, so we don't auto-select.
        if (!vnode.attrs.placeholder && vnode.attrs.options.length && empty_option_value.includes(selected_value)) {
            vnode.attrs.model[0][vnode.attrs.model[1]] = vnode.attrs.options[0].value
        }
    }

    view(vnode: m.Vnode<FieldSelectAttrs>) {
        const validation = vnode.attrs.validation
        const invalid = validation ? validation._invalid : false
        // Add human readable description to the validation object.
        if (validation && vnode.attrs.label) {
            validation.description = vnode.attrs.label
        }

        const {model_value} = modelref_adapter(vnode.attrs.model)

        // Augment the options with a disabled property if it is missing.
        for (const option of vnode.attrs.options) {
            if (!('disabled' in option)) {
                option.disabled = false
            }
        }

        return (
            <div className={classes(vnode.attrs.composed ? null : 'c-field-select field', vnode.attrs.className, {
                disabled: vnode.attrs.disabled || vnode.attrs.loading,
                invalid: validation && invalid && validation.dirty,
                valid: !validation || !invalid,
            })}>
                {vnode.attrs.label && (
                    <label>{vnode.attrs.label}
                        {vnode.attrs.icon && <Icon name={vnode.attrs.icon}/>}
                        {vnode.attrs.validation && <span className="validation">{validation.label}</span>}
                    </label>
                )}
                <select
                    disabled={vnode.attrs.disabled || vnode.attrs.loading}
                    onchange={(e) => {
                        if (vnode.attrs.validation) {
                            vnode.attrs.validation.dirty = true
                        }
                        let matched_value
                        if (e.target.value !== '') {
                            matched_value = vnode.attrs.options[e.target.value].value
                        } else {
                            matched_value = ''
                        }
                        const model_ref = vnode.attrs.model[0][vnode.attrs.model[1]]

                        if (typeof model_ref === 'function') {
                            model_ref(matched_value)
                        } else {
                            vnode.attrs.model[0][vnode.attrs.model[1]] = matched_value
                        }

                        // Please do not use this method to adjust state, but only
                        // for non-state side-effects where a watcher is overkill.
                        if (vnode.attrs.onchange) {
                            vnode.attrs.onchange(matched_value)
                        }
                    }}
                    tabindex={vnode.attrs.tabindex}
                >
                    {vnode.attrs.loading && <option>Loading...</option>}
                    {vnode.attrs.placeholder && <option value="">{vnode.attrs.placeholder}</option>}
                    {!vnode.attrs.loading && vnode.attrs.options.map((option, index) => {
                        const attrs = {value: `${index}`} as any

                        const option_is_on_artkey = model_value && model_value['artkey'] && model_value['artkey'] === option.value['artkey']
                        const option_is_selected = model_value === option.value

                        if (!option.disabled && (option_is_selected || option_is_on_artkey)) {
                            attrs.selected = true
                        }

                        attrs.disabled = option.disabled ? true : false

                        let label = option.label
                        if (vnode.attrs.translate) {
                            label = $t(`${vnode.attrs.translate.prefix}${option.label}`)
                        }
                        return <option {...attrs}>{label}</option>
                    })}
                </select>
                {(() => {
                    if (invalid && validation.dirty) {
                        return <div className="help validation">{typeof invalid.message === 'function' ? invalid.message() : invalid.message}</div>
                    } else if (vnode.attrs.help) {
                        return <div class="help">{vnode.attrs.help}</div>
                    }
                })()}
            </div>
        )
    }
}
