import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'
import {FieldText} from '@bitstillery/common/components'
import {FieldTextAttrs} from '@bitstillery/common/types/field'
import {api} from '@bitstillery/common/app'
import {modelref_adapter, modelref_assign} from '@bitstillery/common/lib/store'
import {proxy} from '@bitstillery/common/lib/proxy'

export class ContactPersonDropdown extends MithrilTsxComponent<FieldTextAttrs> {
    options = []

    data = (() => {
        return proxy({
            model: '',
            search_options: [],
        })
    })()

    async oninit() {
        const {result} = await api.post('contact_persons.get_data_for_dropdown', {}, false) as any
        this.data.search_options.splice(0, this.data.search_options.length, ...result.filter((i) => i.name.trim()))
    }

    view(vnode: m.Vnode<FieldTextAttrs>): m.Children {
        const {model_value, model_ref} = modelref_adapter(vnode.attrs.model)
        return <FieldText
            disabled={vnode.attrs.disabled}
            label={vnode.attrs.label}
            model={[this.data, 'model']}
            placeholder="Search & select a contact person..."
            search={{
                linked: model_value,
                onreset: () => {
                    modelref_assign(model_ref, null)
                },
                onsuggestion: (suggestion) => {
                    modelref_assign(model_ref, suggestion.artkey)
                },
                options: this.data.search_options
                    .filter((i) => i.name.toLowerCase().includes(this.data.model.toLowerCase()))
                    .map((i) => ({value: i.name, label: i.name, ...i})),
            }}
        />
    }
}
