import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {proxy} from '@bitstillery/common/lib/proxy'

import {CollectionProductCategories, collection} from '@/data/product_categories/list/lib/collection_prdcat'
import {PanelProductCategory} from '@/data/product_categories/panel_product_category'

export class ProductCategoryList extends MithrilTsxComponent<unknown> {

    edit_model = proxy({
        product_category_artkey: null,
    })

    oninit() {
        collection.init({
            endpoint: {
                meta: true,
                method: 'get',
                path: 'discover/product-categories/collection-view',
            },
        })
    }

    view(_vnode: m.Vnode<any>) {
        return <div className="c-product-categories-list view-container">
            <CollectionProductCategories />
            <PanelProductCategory
                collection={collection}
                icon={'list'}
                edit_model={this.edit_model}
            />
        </div>
    }
}
