import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {outtake_instruction_renderer, OuttakeInstructionRenderer} from '@bitstillery/common/pdf/outtake_instruction_renderer'
import {CasesOrBottles} from '@bitstillery/common/pdf/pdf'
import {SalesOrder} from '@bitstillery/common/models/sales_order'
import {Spinner} from '@bitstillery/common/components'
import {notifier} from '@bitstillery/common/app'

import {PDFEmailer} from '@/components/pdf_emailer'
import {
    PDFBottlesOrCasesSelector,
    PDFCommentTemplate,
    PDFComponent,
    PDFHelper,
    PDFUpdateAndDownload,
} from '@/components/pdf_helper'
import {GetFastSalesOrderWithItemsResponse, SalesApi} from '@/factserver_api/sales_api'
import {DocumentType} from '@/factserver_api/email_api'

export class ViewOuttake extends MithrilTsxComponent<unknown> {
    sales_order_artkey: number
    sales_api = new SalesApi()
    sales_order: GetFastSalesOrderWithItemsResponse | null = null
    pdf_helper = new PDFHelper<OuttakeInstructionRenderer>(outtake_instruction_renderer)
    show_in_cases_or_bottles: CasesOrBottles = CasesOrBottles.cases

    oninit() {
        this.sales_order_artkey = +m.route.param('artkey')
        this.fetch_sales_order()
    }

    fetch_sales_order(): void {
        this.sales_api.get_fast_sales_order_with_items(this.sales_order_artkey).subscribe({
            next: (response: GetFastSalesOrderWithItemsResponse) => {
                this.sales_order = response
                this.rerender_pdf(false)
                m.redraw()
            },
            error: () => {
                notifier.notify(`Cannot load sales order with artkey ${this.sales_order_artkey}`, 'warning')
                m.route.set('/sales/orders')
            },
        })
    }

    save_pdf_settings(): void {
        if (!this.sales_order) {
            return
        }
        this.sales_api
            .update_outtake_instruction_comment(this.sales_order.artkey, this.sales_order.outtake_instruction_comment)
            .subscribe({
                next: () => notifier.notify('Settings for PDF saved', 'success'),
                error: () => notifier.notify('Could not save the settings for the PDF', 'warning'),
            })
    }

    /** Rerender the pdf for this purchase order. Any pending changes concerning the pdf will be saved. */
    rerender_pdf(save_settings = true): void {
        const account = this.pdf_helper.current_account()
        if (!this.sales_order || !account) {
            return
        }
        if (save_settings) {
            this.save_pdf_settings()
        }
        this.pdf_helper.render_base64_encoded({
            sales_order: this.sales_order as SalesOrder,
            account: account,
            cases_or_bottles: this.show_in_cases_or_bottles || CasesOrBottles.cases,
            decimal_locale: 'en',
        })
    }

    pdf_file_name(): string {
        return `Outtake Instruction - ${this.sales_order?.reference || ''} - ${this.sales_order?.supplier.name || ''}.pdf`
    }

    view(): m.Children {
        if (!this.sales_order) return <Spinner/>
        return (
            <div className="c-view-outtake sales orders pdf-view">

                <div className="pdf-wrapper">
                    <form className="flex-form">
                        <div className="btn-toolbar">
                            <PDFUpdateAndDownload
                                rerender_pdf={() => this.rerender_pdf()}
                                pdf_file_name={this.pdf_file_name()}
                                pdf_helper={this.pdf_helper}
                            />
                        </div>
                        <div className="fieldset">
                            <PDFBottlesOrCasesSelector
                                onchange={(new_value: CasesOrBottles) => {
                                    this.show_in_cases_or_bottles = new_value
                                    this.rerender_pdf(false)
                                }}
                                value={this.show_in_cases_or_bottles}
                            />

                            <PDFCommentTemplate
                                value={this.sales_order.outtake_instruction_comment}
                                onchange={(value: string) => {
                                    if (this.sales_order) {
                                        this.sales_order.outtake_instruction_comment = value
                                        this.rerender_pdf(true)
                                    }
                                }}
                            />

                            <PDFEmailer
                                sales_order={this.sales_order}
                                supplier={this.sales_order.supplier}
                                document_type={DocumentType.OUTTAKE}
                                pdf_file_name={this.pdf_file_name()}
                                pdf_helper={this.pdf_helper}
                            />
                        </div>

                    </form>
                    <PDFComponent pdf_helper={this.pdf_helper} />
                </div>
            </div>
        )
    }
}
