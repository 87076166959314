import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {
    CollectionHeader,
    CollectionItems,
    CollectionStatus,
    CollectionView,
    Icon,
    Spinner,
} from '@bitstillery/common/components'
import {CollectionProxy} from '@bitstillery/common/lib/collection'
import {debounce} from '@bitstillery/common/lib/utils'
import {api, logger} from '@bitstillery/common/app'
import {watch} from '@bitstillery/common/lib/store'

import {ProductAudit, ProductSpecs} from '../../components/product_audit'

import {ColumnsPicker} from './columns_picker'

import {context, methods} from '@/market/pricelists/list/lib/context'
import {EntityAlt} from '@/factserver_api/fact2server_api'

const collection = new CollectionProxy()

export class SetupColumns extends MithrilTsxComponent<any> {

    watchers = [] as any

    async oninit(_vnode: m.Vnode<any>) {
        const entity = context.data.entities[EntityAlt.SPL]
        await collection.init({
            endpoint: {
                meta: false,
                method: 'get',
                path: `discover/supplier-price-lists/${entity.artkey}/candidates/collection-view`,
            },
            sort: {
                by: 'artkey',
                order: 'asc',
                options: [
                    ['artkey', 'Artkey'],
                ],
            },
        }, undefined, {
            collection_updated: async() => {
                const columns_invalid = methods.columns_are_invalid()
                if (!columns_invalid) {
                    await this.verify_candidates()
                } else {
                    // Don't try to verify candidates while the column mapping is invalid.
                    // When it becomes valid again, the collection is reset.
                    for (const key of Object.keys(context.data.candidates)) {
                        delete context.data.candidates[key]
                    }
                }
            },
            filters_to_query: () => {
                return {
                    cache_key: context.data.cache_key, // caches repeated download from swift & excel parsing
                    sort_ascending: collection.state.sort.order === 'asc' ? 'ASC' : 'DESC',
                    sort_by: collection.state.sort.by,
                }
            },
            items_queried: async(data): Promise<[]> => {
                const items = data.result.map((i: any) => ({loading: true, ...i}))
                return items as []
            },
        })

        const watch_column_mapping = debounce(100, async() => {
            const entity = context.data.entities[EntityAlt.SPL]

            // Make sure to uncheck cpp/cpl when the column configuration changes.
            if (!methods.column_is_mapped('cpp') && context.data.store_cpp_on_case) {
                logger.debug('unchecking cpp')
                context.data.store_cpp_on_case = false
            }
            if (!methods.column_is_mapped('cpl') && context.data.store_cpl_on_case) {
                logger.debug('unchecking cpl')
                context.data.store_cpl_on_case = false
            }

            const {status_code} = await api.put(`discover/supplier-price-lists/${entity.artkey}/config`, {
                column_mapping: context.data.column_mapping,
                header: context.data.header,
            })
            if (status_code > 299) {
                return
            }

            const columns_invalid = methods.columns_are_invalid()

            if (!columns_invalid) {
                // When the column mapping is valid and has changed, we reset the collection.
                // and delete the old candidate information.
                collection.reset_query()
            }

            for (const artkey of Object.keys(context.data.candidates)) {
                delete context.data.candidates[artkey]
            }

            for (const item of collection.state.items) {
                item.loading = true
            }
        })

        this.watchers.push(watch(context.data.column_mapping, watch_column_mapping))
    }

    onremove() {
        this.watchers.forEach((unwatch) => unwatch())
    }

    async verify_candidates() {
        context.data.candidates_loaded = false
        try {
            const {result:candidates} = await api.post(`discover/supplier-price-lists/${context.data.entity_artkey}/candidates/verify`, {
                cache_key: context.data.cache_key,
                column_mapping: context.data.column_mapping,
                limit: collection.state.page_size,
                offset: collection.state.query.offset,
            }, true) as any

            for (const candidate of candidates) {
                context.data.candidates[candidate.artkey] = {loading: false, ...candidate}
            }
            for (const item of collection.state.items) {
                if (candidates.find((candidate: any) => candidate.artkey === item.artkey)) {
                    item.loading = false
                }
            }

            logger.debug('[pricelist] candidates verified')
            context.data.candidates_loaded = true
        } catch (error) {
            // Parsing is error prone; we want to show a message when the preview parsing fails,
            // so the column selection can be corrected and the final parsing can be attempted.
            logger.debug(`[pricelist] candidates failed to verify: ${error}`)
        }
    }

    view() {
        if (!context.data.config_loaded) {
            return <div className="view"></div>
        }
        return <CollectionView className='c-market-setup-columns' mode="table" size="s">
            <CollectionHeader>
                <CollectionStatus collection={collection}/>
                <ColumnsPicker type="table-header"/>
            </CollectionHeader>

            <CollectionItems
                collection={collection}
                columns={context.data.columns_preview}
                row_detail={(row) => {
                    if (!context.data.candidates[row.artkey]) return
                    const matched_as = context.data.candidates[row.artkey].matched_as

                    let specs: ProductSpecs | undefined
                    let line_content = context.data.candidates[row.artkey].candidate.join(';')
                    if (matched_as && matched_as.bottle) {
                        const bottle = matched_as.bottle
                        specs = {
                            bottle_alcohol_percentage: bottle.alcohol_percentage,
                            bottle_refill_status: bottle.refill_status,
                            bottle_volume: bottle.volume,
                            case_gift_box_type: matched_as.gift_box_type,
                            case_number_of_bottles: matched_as.number_of_bottles_per_case,
                            case_customs_status: matched_as.customs_status,
                            product_category_name: bottle.product.product_category.name,
                            product_artkey: bottle.product.artkey,
                            product_name: bottle.product.name,
                        }
                    }

                    return <ProductAudit
                        audit_logs={context.data.candidates[row.artkey].audit_logs}
                        header={context.data.header}
                        line_content={line_content}
                        specs={specs}
                        type={matched_as && matched_as.bottle ? 'success' : 'warning'}
                    />
                }}
                row_status={(row) => {
                    const columns_invalid = methods.columns_are_invalid()
                    const matched_as = context.data.candidates[row.artkey]?.matched_as

                    if (row.loading || columns_invalid) {
                        return {
                            render: !columns_invalid ? <Spinner/> : null,
                            type: !columns_invalid ? 'loading' : 'disabled',
                        }
                    }
                    let match = false
                    if (matched_as && matched_as.bottle) {
                        match = true
                    }

                    return {
                        render: [
                            <Icon
                                name="bottle"
                                tip={() => {
                                    if (match) {
                                        return 'This product matches'
                                    }
                                    return 'This product did not match'
                                }}
                                type={match ? 'success' : 'warning'}
                            />,
                        ],
                        type: match ? 'success' : 'warning',
                    }
                }}
            />
        </CollectionView>
    }
}
