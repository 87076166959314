import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {
    Tab,
    TabPanel,
    Tabs,
} from '@bitstillery/common/components'
import {proxy} from '@bitstillery/common/lib/proxy'
import {get_route} from '@bitstillery/common/lib/utils'

import {CreateOrEditSalesOrderComponent} from '../edit_component'

import {ListExact} from './list/exact'
import {ListManage} from './list/manage'

export const model = (() => {
    return {
        data: proxy({
            key_figures: {},
            tabs: {
                active: 'manage_order',
            },
        }),
    }
})()

export class SalesOrdersList extends MithrilTsxComponent<unknown> {

    view(): m.Children {
        return <Tabs
            className="sales orders c-list"
            data={model.data}
            header={{
                icon: 'list',
                onclick: () => {
                    const route = get_route('/sales/orders/list/manage', {meta: true})
                    m.route.set(route)
                },
                title: 'Manage Orders',
            }}
            type="nested-view"
        >
            <Tab
                icon="bulkActions"
                id="manage_order"
                link={'/sales/orders/list/manage'}
                route={'/sales/orders/list/manage{/*path}'}
                text="List Orders"
            >
                <TabPanel>
                    <ListManage />
                </TabPanel>
            </Tab>

            <Tab
                icon="plus"
                id="add"
                link={'/sales/orders/list/create'}
                route={'/sales/orders/list/create{/*path}'}
                text="Create Order"
                tip="Add a new sales order"
            >
                <TabPanel className="view">
                    <CreateOrEditSalesOrderComponent
                        onsaved={(artkey: number) => m.route.set(`/sales/orders/${artkey}/view/manage`) }
                    />
                </TabPanel>
            </Tab>

            <Tab
                icon="exact"
                id="add"
                link="/sales/orders/list/exact"
                route="/sales/orders/list/exact{/*path}"
                text="Exact"
                tip="Export Orders to Exact"
            >
                <TabPanel className="view">
                    <ListExact />
                </TabPanel>
            </Tab>

        </Tabs>
    }
}
