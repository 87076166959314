import m from 'mithril'
import {
    Button,
    ButtonGroup,
    CollectionHeader,
    CollectionItems,
    CollectionView,
    Country,
    PanelFilters,
    RowActionDelete,
} from '@bitstillery/common/components'
import {api, notifier} from '@bitstillery/common/app'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {CollectionProxy} from '@bitstillery/common/lib/collection'

import {context, EntityType} from '@/data/product_categories/list/lib/context'
import {GetProductCategoryCollectionResponse} from '@/factserver_api/fact2server_api'

export const collection = new CollectionProxy()

const columns = [
    {
        name: 'Name',
        render: (row: GetProductCategoryCollectionResponse) => row.name,
    },
    {
        name: 'VAT rate',
        render: (row: GetProductCategoryCollectionResponse) => <span>
            <div>
                <Country country_code={'nl'}/>
                {` ${row.nl_vat_rate_percentage}%`}
            </div>
            <div>
                <Country country_code={'de'}/>
                {` ${row.de_vat_rate_percentage}%`}
            </div>
            <div>
                <Country country_code={'be'}/>
                {` ${row.be_vat_rate_percentage}%`}
            </div>
        </span>,
    },
    {
        name: 'CBS code',
        render: (row: GetProductCategoryCollectionResponse) => <span>
            <div>{row.cbs_code}</div>
            {row.cbs_code_gte_200 && <div>{`>= 200: ${row.cbs_code_gte_200}`}</div>}
            {row.cbs_code_gte_1000 && <div>{`>= 1000: ${row.cbs_code_gte_1000}`}</div>}
        </span>,
    },
    {
        name: 'Parent category',
        render: (row: GetProductCategoryCollectionResponse) => row.parent_product_category_name,
    },
    {
        name: 'Bottle type',
        render: (row: GetProductCategoryCollectionResponse) => row.default_product_bottle_type,
    },
]

export class CollectionProductCategories extends MithrilTsxComponent<unknown> {
    view():m.Children {
        return [
            <PanelFilters collection={collection}/>,
            <CollectionView mode="table">
                <div className="btn-toolbar">
                    <ButtonGroup>
                        <Button
                            active={context.link_entity_active(EntityType.PRDCAT, null)}
                            link={context.link_entity(collection, EntityType.PRDCAT)}
                            link_options={{
                                deactivate: context.data.root_path,
                                replace: true,
                            }}
                            icon="plus"
                            text="Add Category"
                            type="info"
                            variant="context"
                        />
                    </ButtonGroup>
                </div>

                <CollectionHeader collection={collection} columns={columns}/>
                <CollectionItems
                    collection={collection}
                    columns={columns}
                    row_actions={(row: GetProductCategoryCollectionResponse) => {
                        return [
                            <Button
                                active={context.link_entity_active(EntityType.PRDCAT, row.artkey)}
                                disabled={false}
                                icon="edit"
                                link={context.link_entity(collection, EntityType.PRDCAT, row.artkey)}
                                tip="Edit product category"
                                type="info"
                                variant="toggle"
                            />,
                            <RowActionDelete
                                icon={'trash'}
                                row={row}
                                row_delete={async() => {
                                    const {status_code, result} = await api.delete(`/discover/product-categories/${row.artkey}`) as any
                                    if (status_code > 299) {
                                        notifier.notify(`Cannot delete the category: ${result.detail}`)
                                        return
                                    }
                                    notifier.notify('Category deleted')
                                    collection.soft_delete(row.artkey)
                                }}
                                variant="deactivate"
                            />,
                        ]
                    }}
                />
            </CollectionView>,
        ]
    }
}
