import m from 'mithril'
import {Observable, of, Subject} from 'rxjs'
import {filter, map, mergeAll, tap, toArray} from 'rxjs/operators'
import {current_account_slug} from '@bitstillery/common/account/account'

import {is_not_null_or_undefined_type_guard} from '../rxjs_utils'

import {Api, FactserverEmptyResponse, FactserverEmtpyRequest, FactserverRequestData} from './api'
import {
    GetRelationResponse,
    GetHiddenInformationResponse,
    AlternateRelationResponse,
} from './fact2server_api'

export interface GetSubDivisionRequest extends FactserverRequestData {
    country_code: string
}

export interface SubDivision {
    code: string
    name: string
}

export interface GetSubDivisionResponse {
    sub_divisions: SubDivision[]
}

export interface Profile {
    name: string
    telephone_number: string
    email_address: string
}

export interface User {
    artkey: number
    profile: Profile
}

export interface Account {
    artkey: number
    name: string
    slug: string
    informal_name: string
    address: string
    zip_code: string
    city: string
    country_code: string
    telephone_number: string
    emailaddress: string
    vat_id: string
    chamber_of_commerce_number: string
    website_url: string
    iban_eur?: string
    iban_gbp?: string
    iban_usd?: string
}

export interface GetAllForDropDownResponse {
    artkey: number
    name: string
    is_buyer: boolean
    is_supplier: boolean
    city: string
    country_code: string
    zip_code: string
    street_address: string
    purchase_manager: User
    sales_manager: User
    sales_account: Account
}

export interface Relation {
    artkey: number
    name: string
    city: string
    currency: string
    incoterm: string
    memo: string | null
    country_code: string
    minimum_order_amount: number | null
    portal_level: string | null
    portal_customs_visibility: string | null
    price_preference: string
    price_markup_percentage: number | null
    transport_costs_per_case: number | null
    sales_account: Account
    relation_price_list_generated_on: string | null
    include_excise_in_price: boolean
    show_excise_in_portal: boolean
}

export type GetFactserverRelationResponse = Relation

export class RelationApi {
    api = new Api()

    load_alternative_supplier(relation_artkey: number): Observable<AlternateRelationResponse> {
        return this.api.get(`discover/relations/${relation_artkey}/msi-alternative`)
    }

    get_relation_fact2(artkey: number): Observable<GetRelationResponse> {
        return this.api.get(`discover/relations/${artkey}`)
    }

    get_hidden_information_for_relation(artkey: number): Observable<GetHiddenInformationResponse> {
        return this.api.get(`discover/relations/${artkey}/hidden-information`)
    }

    delete_hidden_country(supplier_artkey: number, country_code: string): Observable<GetHiddenInformationResponse> {
        return this.api.delete(`discover/relations/${supplier_artkey}/hidden-information/countries/${country_code}`)
    }

    add_hidden_country(supplier_artkey: number, country_code: string): Observable<GetHiddenInformationResponse> {
        return this.api.post_fact2server_request(`discover/relations/${supplier_artkey}/hidden-information/countries`, {
            country_code: country_code,
        })
    }

    add_hidden_relation(supplier_artkey: number, relation_artkey: number): Observable<GetHiddenInformationResponse> {
        return this.api.post_fact2server_request(`discover/relations/${supplier_artkey}/hidden-information/relations`, {
            relation_artkey: relation_artkey,
        })
    }

    delete_hidden_relation(supplier_artkey: number, relation_artkey: number): Observable<GetHiddenInformationResponse> {
        return this.api.delete(`discover/relations/${supplier_artkey}/hidden-information/relations/${relation_artkey}`)
    }

    sub_divisions_for_country(request: GetSubDivisionRequest): Observable<GetSubDivisionResponse[]> {
        return this.api.post_request('suppliers.get_sub_divisions_for_country', request)
    }

    get_relation(artkey: number): Observable<GetFactserverRelationResponse> {
        return this.api.post_request('suppliers.get_supplier', {
            artkey: artkey,
        })
    }

    refresh_relation_price_list(artkey: number): Observable<boolean> {
        return this.api.post_fact2server_request(`discover/relations/${artkey}/price-list/generate`, {})
    }

    set_emailaddress_financial_documents(artkey: number, email_address_financial_documents: string| undefined): Observable<FactserverEmptyResponse> {
        return this.api.post_request('suppliers.set_emailaddress_financial_documents', {
            emailaddress_financial_documents: email_address_financial_documents,
            supplier_artkey: artkey,
        })
    }

    set_emailaddress_rfp_documents(artkey: number, email_address_rfp_documents: string| undefined): Observable<FactserverEmptyResponse> {
        return this.api.post_request('suppliers.set_emailaddress_rfp_documents', {
            emailaddress_rfp_documents: email_address_rfp_documents,
            supplier_artkey: artkey,
        })
    }
}

/**
 * Caches the Relation data for usage in drop down, search auto complete elements etc. This singleton
 * is available during the browser lifespan of the application. It waits on the
 * GetAllForDropDownResponse[]. This value is null if the data is not yet retrieved.
 *
 * Usage:
 * RelationDropDownData.get(98765).subscribe((r) => console.log("RDD3 got 98765", r))
 * RelationDropDownData.names().subscribe((r) => console.log("RDD3 name", r))
 * RelationDropDownData.relations().subscribe((res) => console.log("result1", res))
 */
export class RelationDropDownData {
    api = new Api()

    /** Singleton instance of this class. */
    private static _instance: RelationDropDownData | null = null
    /** Cached results, when available */
    private result: GetAllForDropDownResponse[] = []

    /** Subject used when data is not yet available. */
    private drop_down_data$: Subject<GetAllForDropDownResponse[]> = new Subject<GetAllForDropDownResponse[]>()

    private constructor() {
        this.api
            .post_request<FactserverEmtpyRequest, GetAllForDropDownResponse[]>('suppliers.get_all_for_drop_down', {})
            .subscribe({
                next: (response: GetAllForDropDownResponse[]) => {
                    this.drop_down_data$.next(response)
                    if (response) {
                        this.result = response
                    }
                    m.redraw()
                },
                error: (v) => this.drop_down_data$.error(v),
                complete: () => this.drop_down_data$.complete(),
            })
    }

    private static instance(): RelationDropDownData {
        if (RelationDropDownData._instance === null) {
            RelationDropDownData._instance = new RelationDropDownData()
        }

        return RelationDropDownData._instance
    }

    /** Return or the cached results or the pending fetch of the data. */
    public static relations(): Observable<GetAllForDropDownResponse[]> {
        if (RelationDropDownData.instance().drop_down_data$.isStopped) {
            return of(RelationDropDownData.instance().result)
        }

        return RelationDropDownData.instance().drop_down_data$
    }

    /** Relation with artkey. */
    public static get(artkey: number): Observable<GetAllForDropDownResponse> {
        return RelationDropDownData.relations().pipe(
            filter(is_not_null_or_undefined_type_guard),
            mergeAll(),
            filter((item) => Number(item.artkey) === artkey),
        )
    }

    /** Relation with artkey. */
    public static for_current_account(): Observable<GetAllForDropDownResponse[]> {
        return RelationDropDownData.relations().pipe(
            filter(is_not_null_or_undefined_type_guard),
            mergeAll(),
            filter((item) => item.sales_account.slug === current_account_slug()),
            toArray(),
        )
    }

    /** List of names. */
    public static names(): Observable<string[]> {
        return RelationDropDownData.relations().pipe(
            filter(is_not_null_or_undefined_type_guard),
            mergeAll(),
            map((it) => it.name),
            toArray(),
        )
    }
}

/**
 * Maintains a cache of region codes per country.
 *
 * Usage:
 * region_drop_down_data.for_country_code(country_code).subscribe(responses => ...)
 */
export class RegionDropDownData {
    cached_results = new Map<string, GetSubDivisionResponse[]>()
    relation_api = new RelationApi()

    for_country_code(country_code: string): Observable<GetSubDivisionResponse[]> {
        const cached_result = this.cached_results.get(country_code)
        if (cached_result) {
            return of(cached_result)
        } else {
            return this.relation_api
                .sub_divisions_for_country({
                    country_code: country_code,
                })
                .pipe(
                    filter(is_not_null_or_undefined_type_guard),
                    tap((result) => this.cached_results.set(country_code, result)),
                )
        }
    }
}
