import {MithrilTsxComponent} from 'mithril-tsx-component'
import {Button} from '@bitstillery/common/components'
import {classes} from '@bitstillery/common/lib/utils'
import m from 'mithril'
import {$t} from '@bitstillery/common/app'

interface CollectionBulkControlAttrs {
    className: string
    collection: any
}

export class CollectionBulkControl extends MithrilTsxComponent<CollectionBulkControlAttrs> {

    /**
     * Either restore the original value from the item or
     * update the item with the restore value.
     * @param bulk_data
     * @param restore
     */
    update_changed_bulk_items(bulk_data, restore = false) {
        const changed_bulk_items = Object.values(bulk_data.actions).filter((i) => i.changed) as any[]
        for (const bulk_item of changed_bulk_items) {
            // Set all field restore values to the new value to indicate it hasn't changed.
            for (const field_name of Object.keys(bulk_data.fields)) {
                if (restore) {
                    bulk_item.item[field_name] = bulk_item.restore[field_name]
                } else {
                    bulk_item.restore[field_name] = bulk_item.item[field_name]
                }
            }
        }
        m.redraw()
    }

    view(vnode:m.Vnode<CollectionBulkControlAttrs>) {
        if (!vnode.attrs.collection.state.ready) return null

        const bulk_data = vnode.attrs.collection.bulk
        const changed = Object.values(bulk_data.actions).filter((i) => i.changed)
        const has_changes = changed.length > 0
        const is_invalid = Object.values(bulk_data.actions).some((i) => i.invalid)

        return <div className={classes('c-collection-bulk', vnode.attrs.className)}>
            <Button
                icon={vnode.attrs.collection.state.bulk_mode === '' ? 'edit' : 'cancel'}
                onclick={() => {
                    if (vnode.attrs.collection.state.bulk_mode === '') {
                        vnode.attrs.collection.state.bulk_mode = vnode.attrs.action
                    } else {
                        if (has_changes) {
                            this.update_changed_bulk_items(bulk_data, true)
                        }
                        vnode.attrs.collection.state.bulk_mode = ''
                    }
                }}
                tip={() => {
                    if (vnode.attrs.collection.state.bulk_mode === '') {
                        return $t('collection.bulk.tip.toggle')
                    } else {
                        if (Object.values(bulk_data.actions).filter((i) => i.changed).length > 0) {
                            return $t('collection.bulk.tip.cancel_changes')
                        }
                        return $t('collection.bulk.tip.cancel')
                    }

                }}
                type={has_changes ? 'warning' : 'info'}
                variant='toggle'
            />

            {vnode.attrs.collection.state.bulk_mode === 'edit' && <Button
                disabled={!has_changes || is_invalid}
                icon="save"
                onclick={async() => {
                    const changed_bulk_items = Object.values(bulk_data.actions).filter((i) => i.changed)
                    for (const action of changed_bulk_items) {
                        action.persist = true
                    }

                    await bulk_data.persist(changed_bulk_items.map((i) => i.item))
                    this.update_changed_bulk_items(bulk_data, false)

                }}
                tip={() => {
                    const count = Object.values(bulk_data.actions).filter((i) => i.changed).length
                    return $t('collection.bulk.tip.persist', {count})
                }}
                type='success'
                variant='toggle'
            />}
        </div>
    }
}
