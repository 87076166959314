import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {format_iso_to_date} from '@bitstillery/common/lib/format'
import {Amount, Button, Icon} from '@bitstillery/common/components'
import {to_specs} from '@bitstillery/common/lib/specs'
import {Spinner} from '@bitstillery/common/components'

import {
    GetOfferHistoryRequest,
    GetOfferHistoryResponse,
    OfferApi,
    OfferHistoryItem,
} from '@/factserver_api/offer_api'

interface OfferHistoryAttrs {
    bottle_artkey: number
    customs_status: string
    supplier_artkey: number
}

export class OfferHistory extends MithrilTsxComponent<OfferHistoryAttrs> {
    offer_history_items: Array<OfferHistoryItem> = []
    relation_suffix = ''
    count = 0
    loading = true
    offer_api = new OfferApi()
    request_data: GetOfferHistoryRequest

    constructor(vnode: m.Vnode<OfferHistoryAttrs>) {
        super()

        this.request_data = {
            bottle_artkey: vnode.attrs.bottle_artkey,
            customs_status: vnode.attrs.customs_status,
            supplier_artkey: vnode.attrs.supplier_artkey,
            limit: 5,
        }

        if (vnode.attrs.supplier_artkey) {
            this.relation_suffix = ' for the selected relation'
        }

        this.query_offer_history_items()
    }

    /*
     * Query offer history items from the factserver and show them in the table.
     */
    query_offer_history_items(): void {
        this.loading = true
        this.offer_api.get_offer_history_for_bottle(this.request_data).subscribe({
            next: (response: GetOfferHistoryResponse) => {
                this.offer_history_items = response.offer_history_items
                this.count = response.count
                this.loading = false
                m.redraw()
            },
            error: () => {
                this.loading = false
            },
        })
    }

    /*
     * Load up to 5 additional offer history items and show them in the table.
     */
    show_more(): void {
        if (this.request_data.limit) {
            this.request_data.limit += 5
            this.query_offer_history_items()
        }
    }

    view(): m.Children {
        return (
            <div className="c-offer-history collection-widget">
                <div className="header">
                    <div className="title">
                        <Icon name="specialOffer" type="info" />Offer history{this.relation_suffix}
                    </div>
                </div>
                <div className="content">
                    {this.offer_history_items.length === 0 ? (
                        <div class="c-empty-message">
                            {this.loading ? <Spinner /> : `There is no offer history${this.relation_suffix}.`}
                        </div>
                    ) : (
                        <div>
                            <OfferHistoryTable offer_history_items={this.offer_history_items} />
                            {this.loading && <Spinner />}
                            {!this.loading && this.offer_history_items.length !== this.count && (
                                <Button
                                    className="btn-show-more"
                                    icon="chevronDown"
                                    size="s"
                                    text="Show more results"
                                    onclick={() => this.show_more()}
                                />
                            )}
                        </div>
                    )}
                </div>
            </div>
        )
    }
}

interface OfferHistoryTableAttrs {
    offer_history_items: Array<OfferHistoryItem>
    show_specs: boolean
    show_product_name: boolean
}

export class OfferHistoryTable extends MithrilTsxComponent<OfferHistoryTableAttrs> {
    view(vnode: m.Vnode<OfferHistoryTableAttrs>): m.Children {
        return <table className={'table table-condensed'}>
            <thead className="thead-default">
                <tr>
                    <th>Offer title</th>
                    {vnode.attrs.show_product_name && <th>Product</th>}
                    <th>Start date</th>
                    <th>End date</th>
                    {!vnode.attrs.show_specs && (<th>Btl / cs</th>)}
                    <th>GB</th>
                    <th>Tax label</th>
                    <th>Price per case</th>
                </tr>
            </thead>
            <tbody>
                {vnode.attrs.offer_history_items.map((offer_history_item) => (
                    <tr>
                        <td>
                            <div className={'td-group'}>
                                <span className={'header'}>
                                    <a
                                        target="_blank"
                                        href={`#!/offer/offers/${offer_history_item.offer_artkey}/custom_products`}
                                    >
                                        {offer_history_item.offer_title}
                                    </a>
                                </span>
                                {vnode.attrs.show_specs && (
                                    <span className={'details'}>
                                        {to_specs({
                                            bottle_volume: offer_history_item.bottle_volume,
                                            bottle_alcohol_percentage: offer_history_item.bottle_alcohol_percentage,
                                            bottle_refill_status: offer_history_item.bottle_refill_status,
                                            case_customs_status: offer_history_item.case_customs_status,
                                            case_gift_box_type: offer_history_item.case_gift_box_type,
                                            case_number_of_bottles: offer_history_item.case_number_of_bottles,
                                            case_tax_label: offer_history_item.case_tax_label,
                                        })}
                                    </span>
                                )}
                            </div>
                        </td>
                        {vnode.attrs.show_product_name && <td>{offer_history_item.product_name}</td>}
                        <td>
                            {format_iso_to_date(
                                offer_history_item.offer_start_date,
                            )}
                        </td>
                        <td>
                            {format_iso_to_date(
                                offer_history_item.offer_end_date,
                            )}
                        </td>
                        {!vnode.attrs.show_specs && (
                            <td>{offer_history_item.case_number_of_bottles}</td>
                        )}
                        <td>{offer_history_item.case_gift_box_type}</td>
                        <td>{offer_history_item.case_tax_label}</td>
                        <td>
                            <Amount
                                currency={'EUR'}
                                amount={offer_history_item.euro_price_per_case}
                            />
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    }
}
