// eslint-disable-next-line no-unused-vars
import m from 'mithril'
import {CollectionProxy} from '@bitstillery/common/lib/collection'
import {to_specs} from '@bitstillery/common/lib/specs'
import {AccountSlug} from '@bitstillery/common/account/account'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {api} from '@bitstillery/common/app'
import {
    Button,
    CollectionItems,
    Country,
    Icon,
} from '@bitstillery/common/components'
import {mount_dialog} from '@bitstillery/common/lib/dialog'
import {proxy} from '@bitstillery/common/lib/proxy'

import {ClipboardCopy} from '@/components/clipboard'
import {accountIconBySlug} from '@/accounts'
import {context, EntitySplsl, EntityType, methods} from '@/market/pricelists/view/lib/context'
import {SuggestionsResponse, EntityAlt} from '@/factserver_api/fact2server_api'

const ENTITY_ALT_STATUS = {
    [EntityAlt.DG_SUGGESTION]: {
        icon: 'lightBulb',
        text: 'Drankgigant Suggestions',
        type: 'info',
    },
    [EntityAlt.PROD_SUGGESTION]: {
        icon: 'lightBulb',
        text: 'Discover Suggestions',
        type: 'success',
    },
}

export const collection = new CollectionProxy()

export const columns = [
    {
        render: (row, context) => {
            return <div className="row-image">
                {row.image_url ? <img
                    onclick={() => context.dialog_image(row)}
                    src={row.image_url}
                /> : <Icon className="placeholder"name="image" type="unset" />}
            </div>
        },
        width: '100px',
    }, {
        render: (row) => {
            if (row.entity_alt === EntityAlt.DG_SUGGESTION) {
                return '/theme/img/discover/dg-favicon.ico'
            } else {
                return accountIconBySlug(AccountSlug.MSP)
            }
        },
        width: '32px',
    },
    {
        render: (row) => {
            return <div className={'flex'}>
                <div className="td-group">
                    <span className="header">
                        {row.product_name}
                    </span>
                    <span className="details">
                        {row.volume && row.alcohol_percentage && to_specs({
                            bottle_alcohol_percentage: row.alcohol_percentage,
                            bottle_refill_status: row.refill_status,
                            bottle_volume: row.volume,
                            case_customs_status: row.customs_status,
                            case_gift_box_type: row.gift_box_type,
                            case_number_of_bottles: row.number_of_bottles_per_case,
                        })}
                    </span>
                    {row.country_of_origin && <span className="details">
                        {row.country_of_origin && <Country country_code={row.country_of_origin} size='xs'/>}&nbsp;
                        {row.product_category}
                    </span>}
                    {row.entity_alt === EntityAlt.DG_SUGGESTION && <span className="details">
                        {row.bottle_gtin}<ClipboardCopy text={row.bottle_gtin} />
                    </span>}
                    {row.entity_alt === EntityAlt.PROD_SUGGESTION && row.bottle_gtins && row.bottle_gtins.split(',').map((gtin) => {
                        return <span className="details">{gtin} <ClipboardCopy text={gtin} /></span>
                    })}
                </div>
            </div>
        },
    },
    {
        render: (row) => {
            return <div className="td-group">
                <div className="header">
                    {(() => {
                        const ratio = Number(row.ratio)
                        if (ratio === -1) {
                            return 'Unknown'
                        }
                        return `${ratio}% match`
                    })()}
                </div>
                {!row.specs_match && <span className="details">
                    Specs do not match
                </span>}
            </div>
        },
    },
]

export class CollectionSplslSuggestions extends MithrilTsxComponent<any> {

    data = proxy({
        dialog: {
            loading: false,
            title: 'Product suggestion',
            type: 'info',
        },
        search_level: ['suggestions'],
    })

    dialog_image(row) {
        this.data.dialog.title = `Product suggestion: ${row.product_name}`
        mount_dialog({
            body: () => [
                <img src={row.image_url} />,
            ],
            model: this.data.dialog,
        })
    }

    view(_vnode: m.Vnode<any>) {
        const entity = context.data.entities[EntityType.SPLSL] as EntitySplsl
        return <div className="c-collection-splsl-suggestions">
            {this.data.search_level.length > 1 && <div className="breadcrumbs">
                <Icon name="lightBulb" type="info" />
                {this.data.search_level.map((breadcrumb, index) => {
                    return <div className="breadcrumb" onclick={async() => {
                        this.data.search_level.splice(index + 1)

                        if (breadcrumb === 'suggestions') {
                            await methods.fetch_suggestions(entity, false)
                        } else if (breadcrumb === 'drank gigant') {
                            await methods.fetch_suggestions(entity, true, entity.product_name)
                        }
                    }}>
                        {index > 0 ? <Icon name="chevronRight" type="info" /> : null}
                        {breadcrumb}
                    </div>
                })}
            </div>}

            <CollectionItems
                context={this}
                disable_scroll={true}
                collection={collection}
                columns={columns}
                row_actions={(row: SuggestionsResponse) => {
                    return [
                        row.entity_alt === EntityAlt.DG_SUGGESTION ? <Button
                            icon="chevronRight"
                            variant="toggle"
                            onclick={async() => {
                                const entity = context.data.entities[EntityType.SPLSL] as EntitySplsl
                                this.data.search_level.push('Drankgigant')
                                await methods.fetch_suggestions(entity, true, row.product_name)
                            }}
                            tip="Search in Discover, based on the DG name"
                        /> : null,
                        <Button
                            icon="copy"
                            variant="toggle"
                            onclick={async() => {
                                let bottles = []
                                const entity = context.data.entities.SPLSL

                                if (row.entity_alt === EntityAlt.PROD_SUGGESTION) {
                                    ({result: bottles} = await api.get(`discover/products/${row.product_artkey}/bottles`) as any)
                                }

                                methods.match_from_data({
                                    alcohol_percentage: entity.alcohol_percentage || '',
                                    bottle_artkey: entity.bottle_artkey || undefined,
                                    country_of_origin: entity.country_of_origin || '',
                                    product_artkey: entity.product_artkey || null,
                                    product_name: row.product_name || '',
                                    product_category: '',
                                    product_category_artkey: row.product_category_artkey || undefined,
                                    refill_status: entity.refill_status || '',
                                    volume: row.volume || '',
                                }, bottles, entity)
                            }}
                            tip="Copy product details to form"
                            type={ENTITY_ALT_STATUS[row.entity_alt].type}
                        />,
                    ]
                }}
                row_separator={(row: SuggestionsResponse, index: number, items: SuggestionsResponse[]) => {
                    if (index === 0) return ENTITY_ALT_STATUS[row.entity_alt]

                    const prev_item = items[index - 1]
                    if (row.entity_alt !== prev_item.entity_alt) {
                        return ENTITY_ALT_STATUS[row.entity_alt]
                    }

                    return null
                }}
                row_status={(row: SuggestionsResponse) => {
                    return {
                        type: ENTITY_ALT_STATUS[row.entity_alt].type,
                    }
                }}
                row_status_no_cell_status={true}
            />
        </div>
    }
}
