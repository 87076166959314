import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'

import {PanelPricelist} from '@/market/pricelists/view/components/panel_pricelist'
import {collection, CollectionSpli} from '@/market/pricelists/view/lib/collection_spli'

export class ViewManage extends MithrilTsxComponent<unknown> {

    view(): m.Children {
        return <div className="c-view-manage view-container">
            <CollectionSpli />
            <PanelPricelist collection={collection} />
        </div>
    }
}
