import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {CasesOrBottles} from '@bitstillery/common/pdf/pdf'
import {Dialog, Spinner} from '@bitstillery/common/components'
import {notifier} from '@bitstillery/common/app'
import {merge_deep} from '@bitstillery/common/lib/utils'

import {
    PDFBottlesOrCasesSelector,
    PDFCommentTemplate,
    PDFComponent,
    PDFDecimalPointSelector,
    PDFUpdateAndDownload,
} from '@/components/pdf_helper'
import {PDFEmailer} from '@/components/pdf_emailer'
import {GetFastSalesOrderWithItemsResponse, SalesApi} from '@/factserver_api/sales_api'
import {DocumentType} from '@/factserver_api/email_api'
import {CheckBox} from '@/components/html_components'
import {DialogSettings} from '@/sales/orders/view/components/dialog_settings'
import {context, methods, pdf_helper} from '@/sales/orders/view/lib/context'

export class ViewProforma extends MithrilTsxComponent<unknown> {
    sales_order_artkey: number
    sales_api = new SalesApi()
    sales_order: GetFastSalesOrderWithItemsResponse | null = null

    async oninit() {
        // Until ContextProvider has the concept of root state, we
        // reset the Proforma initial settings here.
        merge_deep(context.data.proforma, {
            group_similar_items:false,
            show_article_code:false,
            show_bottle_lot:false,
            show_cbs_code: true,
            show_decimal_point_as: 'en',
            show_country_of_origin: true,
            show_liters_of_alcohol:false,
        })
    }

    pdf_file_name(): string {
        return `Proforma - ${context.data.proforma.sales_order?.reference || ''} - ${context.data.proforma.sales_order?.supplier.name || ''}.pdf`
    }

    view(): m.Children {
        if (!context.data.proforma.sales_order?.supplier) return <Spinner/>
        return <div className="c-view-proforma sales orders pdf-view">
            {context.data.proforma.sales_order.needs_settings_confirmation && <Dialog
                title={`Confirm settings for ${context.data.proforma.sales_order.reference}`}
                onclose={() => {
                    m.redraw()
                }}
            >
                <DialogSettings
                    cancel={async() => {
                        m.route.set(`/sales/orders/${context.data.root_artkey}/view/manage`)
                        notifier.notify('Settings need to be confirmed before accessing the Proforma')
                    }}
                    ok={async() => {
                        methods.fetch_sales_order_proforma(context.data.root_artkey)
                    }}
                />
            </Dialog>}

            <div className="pdf-wrapper">
                <form className="flex-form">
                    <div className="btn-toolbar">
                        <PDFUpdateAndDownload
                            onclick={async() => await methods.fetch_sales_order_proforma(context.data.root_artkey)}
                            rerender_pdf={() => methods.rerender_proforma_pdf(false)}
                            pdf_file_name={this.pdf_file_name()}
                            pdf_helper={pdf_helper}
                        />
                    </div>

                    <div className="fieldset">
                        <PDFBottlesOrCasesSelector
                            onchange={(new_value: CasesOrBottles) => {
                                context.data.proforma.show_in_cases_or_bottles = new_value
                                methods.rerender_proforma_pdf(false)
                            }}
                            value={context.data.proforma.show_in_cases_or_bottles}
                        />

                        <PDFDecimalPointSelector
                            onchange={(new_value: string) => {
                                context.data.proforma.show_decimal_point_as = new_value
                                methods.rerender_proforma_pdf(false)
                            }}
                            value={context.data.proforma.show_decimal_point_as}
                        />

                        <CheckBox
                            label="CBS Code"
                            checked={context.data.proforma.show_cbs_code}
                            onchange={() => {
                                context.data.proforma.show_cbs_code = !context.data.proforma.show_cbs_code
                                methods.rerender_proforma_pdf(false)
                            }}
                        />
                        <CheckBox
                            label="Bottle lot"
                            checked={context.data.proforma.show_bottle_lot}
                            onchange={() => {
                                context.data.proforma.show_bottle_lot = !context.data.proforma.show_bottle_lot
                                methods.rerender_proforma_pdf(false)
                            }}
                        />
                        <CheckBox
                            label="Liters of alcohol"
                            checked={context.data.proforma.show_liters_of_alcohol}
                            onchange={() => {
                                context.data.proforma.show_liters_of_alcohol = !context.data.proforma.show_liters_of_alcohol
                                methods.rerender_proforma_pdf(false)
                            }}
                        />
                        <CheckBox
                            label="Country of origin"
                            checked={context.data.proforma.show_country_of_origin}
                            onchange={() => {
                                context.data.proforma.show_country_of_origin = !context.data.proforma.show_country_of_origin
                                methods.rerender_proforma_pdf(false)
                            }}
                        />
                        <CheckBox
                            label="Article code"
                            checked={context.data.proforma.show_article_code}
                            onchange={() => {
                                context.data.proforma.show_article_code = !context.data.proforma.show_article_code
                                methods.rerender_proforma_pdf(false)
                            }}
                        />

                        <CheckBox
                            label="Group similar items"
                            checked={context.data.proforma.group_similar_items}
                            onchange={() => {
                                context.data.proforma.group_similar_items = !context.data.proforma.group_similar_items
                                methods.fetch_sales_order_proforma(context.data.root_artkey)
                            }}
                        />

                        <PDFCommentTemplate
                            value={context.data.proforma.sales_order.invoice_comment}
                            onchange={(value: string) => {
                                if (context.data.proforma.sales_order) {
                                    context.data.proforma.sales_order.invoice_comment = value
                                    methods.rerender_proforma_pdf(true)
                                }
                            }}
                        />

                        <PDFEmailer
                            sales_order={context.data.proforma.sales_order}
                            supplier={context.data.proforma.sales_order.supplier}
                            document_type={DocumentType.PROFORMA_INVOICE}
                            pdf_file_name={this.pdf_file_name()}
                            pdf_helper={pdf_helper}
                        />
                    </div>
                </form>
                <PDFComponent pdf_helper={pdf_helper} />
            </div>
        </div>
    }
}
