import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {Icon} from '@bitstillery/common/components'

import {accountIcon} from '@/accounts'
import {Link} from '@/components/discover'
import {GetPurchaseOrderResponse} from '@/factserver_api/fact2server_api'

interface BuyFromAccountBannerAttrs {
    purchase_order: GetPurchaseOrderResponse
}

export class BuyFromAccountBanner extends MithrilTsxComponent<BuyFromAccountBannerAttrs> {
    view(vnode: m.Vnode<BuyFromAccountBannerAttrs>): m.Children {
        const buy_from_account_sales_order = vnode.attrs.purchase_order.is_part_of_buy_from_account_sales_order
        if (!buy_from_account_sales_order) {
            return null
        }

        return <div class="row">
            <div class="col-sm-12">
                <div class="alert alert-info">
                    <Icon name={'info'} />
                    This purchase order is part of a buy-from-account sales order:
                    <Link href={`/sales/orders/${buy_from_account_sales_order.artkey}/view/manage?account=${buy_from_account_sales_order.account.slug}`}>
                        {' '}
                        {buy_from_account_sales_order.reference}
                        {' '}
                        {accountIcon(buy_from_account_sales_order.account)}
                    </Link>
                </div>
            </div>
        </div>
    }
}
