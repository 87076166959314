import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'

import {BackToLinkButton} from '../components/discover'
import {EditAssist} from '../components/form'

import {UpsertPurchaseOrder} from './upsert_purchase_order'

export default class CreateOrEditPurchaseOrder extends MithrilTsxComponent<unknown> {

    edit_assist: EditAssist

    constructor() {
        super()
        this.edit_assist = new EditAssist(m.route)
    }

    view(): m.Children {
        return (
            <div className="c-sales-order-upsert view">
                <div className="btn-toolbar">
                    {this.edit_assist.is_creating && <BackToLinkButton href={'/purchase-orders'} text={'Back to list'}/>}
                    {!this.edit_assist.is_creating && <BackToLinkButton href={`/purchase-orders/manage/${this.edit_assist.artkey}`} text={'Back to order'}/>}
                </div>

                <UpsertPurchaseOrder
                    onsaved={(artkey) => m.route.set(`/purchase-orders/manage/${artkey}`)}
                />
            </div>
        )
    }
}
