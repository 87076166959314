import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {Icon, Link, DataCard} from '@bitstillery/common/components'
import {classes} from '@bitstillery/common/lib/utils'
import {proxy} from '@bitstillery/common/lib/proxy'
import {to_specs} from '@bitstillery/common/lib/specs'
import {api} from '@bitstillery/common/app'

import {GetSPLAuditLogResponse} from '@/factserver_api/fact2server_api'

const ICON_TYPE = {
    danger: 'danger',
    success: 'checked',
    warning: 'warning',
}
interface ProductAuditAttrs {
    audit_endpoint?: string
    audit_logs?: []
    header: string[]
    line_content: string
    source_line?: {}
    specs?: ProductSpecs
    type: string
}

interface ProductAuditState {
    audit_logs: GetSPLAuditLogResponse[]
}

export interface ProductSpecs {
    bottle_alcohol_percentage: number
    bottle_refill_status: string
    bottle_volume: number
    case_gift_box_type: string
    case_number_of_bottles: number
    case_customs_status: string
    product_category_name: string
    product_artkey: string
    product_name: string
}

export class ProductAudit extends MithrilTsxComponent<ProductAuditAttrs> {

    data: ProductAuditState = proxy({
        audit_logs: [],
    })

    async oninit(vnode:m.Vnode<ProductAuditAttrs>) {
        let audit_logs: GetSPLAuditLogResponse[]
        if (vnode.attrs.audit_endpoint) {
            ({result: audit_logs} = await api.get(vnode.attrs.audit_endpoint) as any)
        } else {
            audit_logs = vnode.attrs.audit_logs || []
        }

        this.data.audit_logs.splice(0, this.data.audit_logs.splice.length, ...audit_logs)
    }

    view(vnode: m.Vnode<ProductAuditAttrs>): m.Children {
        const row_values = vnode.attrs.line_content?.split(';')
        return <div className={classes('c-sourceline-info', 'no-click', `type-${vnode.attrs.type}`)}>
            <div className="audit-logs">
                <Icon
                    name={ICON_TYPE[vnode.attrs.type]}
                    size="xl"
                    tip={() => {
                        if (vnode.attrs.type === 'success') {
                            return this.data.audit_logs.map((i:any) => i.message).join('\n')
                        }
                        return 'Unresolved product'
                    }}
                    type="unset"
                />

                <div className="details">
                    {vnode.attrs.specs ? [
                        <Link href={`data/products/${vnode.attrs.specs.product_artkey}`} target="_blank" type="success">{vnode.attrs.specs.product_name}</Link>,
                        <span>{to_specs(vnode.attrs.specs)}</span>,
                    ] : this.data.audit_logs.map((i) => {
                        return <div className="audit-line">{i.message}</div>
                    })}
                </div>
            </div>

            <DataCard
                model={{
                    data: vnode.attrs.header.map((cell, index) => {
                        return {
                            label: cell,
                            value: row_values[index],
                        }
                    }),
                }}
                type={vnode.attrs.specs ? 'success' : 'warning'}
                variant="dense"
            />
        </div>
    }
}
