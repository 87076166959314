import {MithrilTsxComponent} from 'mithril-tsx-component'
import {unique_id} from '@bitstillery/common/lib/utils'
import m from 'mithril'
import {FieldText} from '@bitstillery/common/components/fields/text/text'
import {Button} from '@bitstillery/common/components/ui/button/button'

interface FieldListInputAttrs {
    items: [{}, string]
    model: [{}, string]
    validation: {}
    disabled: boolean
    label: string
    placeholder: string
    onadd: () => void
    ondelete: (item: string) => void
}

export class FieldListInput extends MithrilTsxComponent<FieldListInputAttrs> {
    id = unique_id()

    view(vnode: m.Vnode<FieldListInputAttrs>) {
        const {disabled, model, items, validation} = vnode.attrs
        return (
            <div className='c-list-input' id={this.id}>
                <div className='field-group list-input'>
                    <FieldText
                        label={vnode.attrs.label}
                        placeholder={vnode.attrs.placeholder}
                        model={model}
                    >
                        <Button
                            disabled={disabled || validation['new_item']._invalid}
                            icon='plus'
                            onclick={vnode.attrs.onadd}
                        />
                    </FieldText>
                </div>
                <div className="unit-list">
                    {items.map((item) => {
                        return (
                            <div className='list-item'>
                                <span className='content'>{item}</span>
                                <Button
                                    icon='trash'
                                    type='danger'
                                    disabled={disabled}
                                    onclick={vnode.attrs.ondelete.bind(null, item)}
                                />
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }
}
