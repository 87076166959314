import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'

import {PanelPricelist} from '@/market/pricelists/view/components/panel_pricelist'
import {
    collection as collection_splsl,
    CollectionSplsl,
} from '@/market/pricelists/view/lib/collection_splsl'

export class ViewResolve extends MithrilTsxComponent<any> {

    view():m.Children {
        return <div className="c-spli-unresolved view-container">
            <CollectionSplsl />
            <PanelPricelist collection={collection_splsl}/>
        </div>
    }
}
