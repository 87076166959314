// eslint-disable-next-line no-unused-vars
import m from 'mithril'
import isEmail from 'validator/es/lib/isEmail'
import isFQDN from 'validator/es/lib/isFQDN'
import isVAT from 'validator/es/lib/isVAT'
import {european_union_countries} from '@bitstillery/common/lib/countries'
import {modelref_adapter} from '@bitstillery/common/lib/store'
import {$t} from '@bitstillery/common/app'

/**
 * The result of a validation function, see for example required().
 * If required is invoked and the field is invalid:
 *  - The label and string will be set to the validation message.
 *  - The validate method will return a True value.
 *
 * If the validation function is invoked and the is valid:
 *  - The validate method will return False.
 */
type ValidationEvaluationResult = {
    /** A validation method that takes a value and determines if it meets certain criteria. */
    validate: (modelValue: any) => boolean | ValidationEvaluationResult
    /** A short, identifiable name for the validation rule being applied. */
    label: string
    /** A descriptive message that explains the validation rule or error. */
    message: string
}

export interface Validator {
    /** Indicates whether the field has been modified by the user. */
    dirty: boolean
    /** An optional description of the validator, providing more detail. */
    description?: string
    /** A human-readable label for the field being validated. */
    label: string
    /** The validation message to display if validation fails. */
    message: string
    /**
     * A method that determines whether the field is invalid, returning either
     * a boolean or a `ValidationEvaluationResult`.
     */
    _invalid: () => boolean | ValidationEvaluationResult
    /** The validation result object that contains validation status and message. */
    validator: ValidationEvaluationResult
}
export interface ValidationData {
    [key: string]: Validator
}

/**
 * Composes multiple validators into a single validation that checks conditions sequentially
 * using logical AND operator. It stops evaluating further once any validator fails,
 * providing an aggregated validation that requires all conditions to be met.
 *
 * @param {Array} validators - An array of validators to be composed together.
 * @returns {ValidationEvaluationResult} Object with a validate method that performs
 * the combined validation, and fields for storing the validation label and message.
 */
export function and(validators: ValidationEvaluationResult[]): ValidationEvaluationResult {
    return {
        validate: function(modelvalue) {
            for (const validator of validators) {
                const invalid_validator = validator.validate(modelvalue)
                this.label = validator.label
                if (invalid_validator) {
                    this.message = invalid_validator.message
                    return invalid_validator
                }
            }

            return false
        },
        label: '',
        message: '',
    }
}

export function bottle_gtin(): ValidationEvaluationResult {
    return {
        validate: function(modelvalue) {
            if (!modelvalue) return false

            if (modelvalue.match(/^[0-9]+$/) === null) { // Check if the string only consists of numbers
                this.message = 'Please enter a valid bottle GTIN'
                return this
            } if (![12, 13].includes(modelvalue.length)) {
                this.message = 'Bottle GTIN contains either 12 or 13 digits'
                return this
            }
            const gtin_digits = modelvalue.substring(0, modelvalue.length - 1).split('').map(Number).reverse()

            let sum = 0
            let i = 1
            for (let digit of gtin_digits) {
                if (i % 2 === 0) {
                    sum += +digit
                } else {
                    sum += +digit * 3
                }
                i++
            }

            const check_digit = (10 - (sum) % 10) % 10
            if (check_digit !== +modelvalue.charAt(modelvalue.length - 1)) {
                this.message = 'GTIN check digit is incorrect'
                return this
            }
            return false
        },
        label: '',
        message: '',
    }
}

/**
 * Conditionally applies a validator based on a supplied condition. This is useful
 * for cases where validation should only be applied under certain circumstances.
 * The function checks if the condition is met and, if so, proceeds to validate
 * the model value with the provided validator. If the validation fails, it sets
 * the message to the validator's message and returns the validation result.
 * Otherwise, it resets the label to an empty string when the condition is not met.
 *
 * @param condition A function that takes a model value and returns true if
 * the condition for applying the validator is met.
 * @param validator The validator to be conditionally applied. It must have
 * a validate method and a label property.
 * @returns {ValidationEvaluationResult} Object with a validate method that performs
 * conditional validation, and fields for storing the validation label and message.
 */
export function conditional(condition, validator): ValidationEvaluationResult {
    return {
        validate: function(modelvalue) {
            if (condition(modelvalue)) {
                const validation = validator.validate(modelvalue)
                this.label = validator.label
                if (validation) {
                    this.message = validation.message
                    return validation
                }
                return false
            }
            // Unset the required label otherwise.
            this.label = ''
        },
        label: '',
        message: '',
    }
}

/**
 * Validates an email address using a predefined validator and sets a validation
 * message based on the outcome. The function checks if the provided email address
 * is valid. If it is not, it sets the validation message to a localized email
 * validation message. If the email address is valid, the validation message is cleared.
 * The function returns itself when the validation fails, providing access to the
 * validation message, or false when the email passes validation.
 *
 * @returns {ValidationEvaluationResult} Validation object with a validate method to check the
 *      email, and fields to hold the validation state.
 */
export function email(): ValidationEvaluationResult {
    return {
        validate: function(modelValue) {
            if (!modelValue) return false

            if (!isEmail(modelValue)) {
                this.message = $t('validation.email')
            } else {
                this.message = ''
            }
            if (this.message) {
                return this
            }

            return false
        },
        label: '*',
        message: '',
    }
}

export function greater_than_or_equal_to(value: number): ValidationEvaluationResult {
    return {
        validate: function(modelValue) {
            this.message = ''
            if (isNaN(modelValue)) {
                this.message = 'The value must be numeric'
            }
            if (+modelValue < value) {
                this.message = `The value must be at least ${value}`
            }
            if (this.message) {
                return this
            }

            return false
        },
        label: '*',
        message: '',
    }
}

export function invalid_fields(validations) {
    // Filters and returns entries from the `validations` object where the
    // `_invalid` function returns true
    return Object.entries(validations).filter((i:any) => i[1]._invalid)
}

/**
 * Formats and displays validation feedback for fields not validated. It constructs a
 * list of fields that have not been validated and presents them with their respective
 * messages. If all fields are validated, it displays a success message.
 *
 * @param {Array} invalid_fields - An array containing information about fields that have not passed validation.
 * @returns A JSX element that either lists the not validated fields with their messages or displays a success message.
 */
export function invalid_fields_format(invalid_fields, type) {
    if (type === 'tip') {
        return invalid_fields.length ? `<b>Invalid</b>:<br/>${invalid_fields.map((i) => `<i>${i[0]}</i><br/>`)}` : '<b>Form is valid</b>'
    }
    return <div className="submit-feedback">
        {(() => {
            if (invalid_fields.length) {
                return [
                    <div className="description warning">
                        {$t('validation.form.action_required')}
                    </div>,
                    ...invalid_fields.map((i) => {
                        return (
                            <div className="invalid-field">
                                <div className="field-name">{i[1].description ? i[1].description : i[0]}</div>
                                <div className="message">{i[1].message}</div>
                            </div>
                        )
                    }),
                ]
            } else {
                return (
                    <div className="description success">
                        {$t('validation.form.valid')}
                    </div>
                )
            }
        })()}

    </div>
}

/**
 * Validates the length of a given string within specified minimum and maximum values.
 * This function ensures that a model value's length meets certain criteria, such as
 * fixed length, minimum length, or falls within a range. It allows for customizable
 * error messages through an options parameter.
 *
 * @param {number} min_length - The minimum length requirement for the model value.
 * A value of 0 skips this validation.
 * @param {number} max_length - The maximum length requirement for the model value.
 * A value of 0 skips this validation.
 * @param {Object} [options={}] - Optional parameters, including a custom message
 * to override default validation messages.
 * @returns {ValidationEvaluationResult} A validation object that contains the logic
 * to validate the model value length, with provision for validation state and messaging.
 * @throws {Error} Throws an error if the maximum length is smaller than the minimum length.
 */
export function length(min_length, max_length, options:any = {}): ValidationEvaluationResult {
    if ((min_length > 0 && max_length > 0) && max_length < min_length) {
        throw new Error('maximum length must be smaller than minimum length')
    }
    return {
        validate: function(modelValue) {
            // Fixed length validation
            if (min_length === max_length && (modelValue.length < min_length)) {
                if (options.message) this.message = options.message
                else this.message = $t('validation.length.fixed_length', {fixed_length: min_length})
                return this
            }

            // Minimal length validation
            if (min_length > 0 && (modelValue.length < min_length)) {
                if (options.message) this.message = options.message
                else this.message = $t('validation.length.min_length', {min_length})
                return this
            }

            // Range length validation
            if (max_length > 0 && (modelValue.length > max_length)) {
                if (options.message) this.message = options.message
                else this.message = $t('validation.length.max_length', {max_length})
                return this
            }
            return false
        },
        label: '*',
        message: '',
    }
}

/**
 * Validates a password based on specific criteria: minimum length, at least one lowercase letter,
 * and at least one uppercase letter. It checks the provided password against these conditions and
 * sets a validation message accordingly. If the password does not meet the criteria,
 * the corresponding message is set, and the function returns itself, allowing for
 * easy identification of the failed validation. If the password meets all
 * criteria, the function returns false, indicating no validation errors.
 *
 * @returns {ValidationEvaluationResult} Validation object with a validate method for
 * password checking, and fields to hold validation state and messages.
 */
export function password(): ValidationEvaluationResult {
    const one_lower_case = /(?=.*[a-z])/
    const one_upper_case = /(?=.*[A-Z])/

    return {
        validate: function(modelValue) {
            if (modelValue.length <= 8) {
                this.message = $t('validation.password.min_length')
            } else if (!modelValue.match(one_lower_case)) {
                this.message = $t('validation.password.one_lower_case')
            } else if (!modelValue.match(one_upper_case)) {
                this.message = $t('validation.password.one_upper_case')
            } else if (modelValue.endsWith(' ')) {
                this.message = $t('validation.password.cannot_end_with_space')
            } else {
                this.message = ''
            }

            if (this.message) {
                return this
            }

            return false
        },
        label: '*',
        message: '',
    }
}
const empty_values = ['', false, undefined, null]

/**
 * Validates a field to ensure it is not empty. An empty field is defined by the
 * inclusion in the provided list of empty values. This function is commonly used
 * to ensure that required fields are filled out in a form.
 *
 * @returns {ValidationEvaluationResult} Returns a validation object containing a validation
 * function, a label to indicate the field is required, and a validation failure message.
 */
export function required(): ValidationEvaluationResult {
    return {
        validate: function(modelValue) {
            if (empty_values.includes(modelValue)) {
                return this
            }

            return false
        },
        label: '*',
        message: () => $t('validation.required'),
    }
}

/**
 * Validates a field to ensure it is matched to another modelValue.
 *
 * @returns {ValidationEvaluationResult} Returns a validation object containing a validation
 * function, a label to indicate the field is required, and a validation failure message.
 */
export function must_match(other_value): ValidationEvaluationResult {
    return {
        validate: function(model_value) {
            return model_value !== modelref_adapter(other_value).model_value
        },
        label: '',
        message: () => $t('validation.must_match'),
    }
}

/**
 * Resets validation states for a set of validators by marking them as not dirty.
 * This is typically used to reset forms to their initial validation states.
 *
 * @param $v - An object containing validators to be reset.
 */
export function reset_validation($v) {
    for (const validator of Object.values($v)) {
        validator.dirty = false
    }
}

/**
 * Creates a validation object for the given model reference and validator.
 * It performs model-based validation by using a provided validator, which can either be
 * a validation function or an already instantiated validator object.
 * This function is integral in validating user input based on dynamic or static rules.
 *
 * @param {Array} modelref - An array that can either hold a model and
 * property name or a value directly.
 * @param {*} validator - The validator to use. Can be a validation function
 * or a predefined validator object.
 * @returns {Validator} The validation object which contains the validation state and methods.
 */
export function validation(modelref: [Object | Function, string] | [any], validator: any): Validator {
    return {
        dirty: false,
        label: '',
        message: '',
        /**
         * Find the model reference and pass its value down to all related
         * validators. Return a validator when invalid of false when valid.
         */
        get _invalid() {
            const {model_value} = modelref_adapter(modelref)
            let _validator
            // A validator may be a function returning a validator object,
            // or a validator object itself. When nothing is returned, the
            // validation is assumed to be valid.
            if (typeof validator === 'function') _validator = validator()
            else _validator = validator

            if (typeof _validator === 'object') {
                const invalid_validator = _validator.validate(model_value, this)
                this.label = _validator.label

                if (typeof _validator.message === 'function') {
                    this.message = _validator.message()
                } else {
                    this.message = _validator.message
                }
                return invalid_validator
            }

            return false
        },
        validator,
    }
}

/**
 * Validates a VAT ID based on the provided country code reference.
 * This function checks if a given VAT ID is valid for a specified EU country.
 * It accounts for special cases where certain EU countries have a different
 * VAT country code from their official country code.
 *
 * @param {Array|string} country_code_ref - The country code or reference needed
 * to validate the VAT ID.
 * @returns {ValidationEvaluationResult} Returns a validation object containing
 * a validation function, label, and message.
 */
export function vat_id(country_code_ref) {
    // Some EU countries may have a different VAT country code than the
    // official country code in their VAT ID.
    const VAT_COUNTRIES = {
        GR: 'EL',
    }
    return {
        validate: function(modelvalue: string) {
            if (!modelvalue) return false
            if (!Array.isArray(country_code_ref)) return false
            // Country code to check the VAT ID for.
            let {model_value: country_code} = modelref_adapter(country_code_ref)
            country_code = country_code.toUpperCase()
            // Only verify EU country VAT ids.
            if (!european_union_countries.includes(country_code)) {
                return false
            }

            const alpha2 = modelvalue.slice(0, 2).toUpperCase()
            let vat_country = country_code
            if (VAT_COUNTRIES[country_code]) {
                vat_country = VAT_COUNTRIES[country_code]
            }

            if (vat_country !== alpha2) {
                // Country code in the VAT ID must be correct.
                this.message = $t('validation.vat_id.country_incorrect', {
                    expected: vat_country,
                    validated: alpha2,
                })
                return this
            }

            let is_vat = false
            if (vat_country === 'ES') {
                // Allow foreign tax numbers as well; formats: ES12345678, ESX1234567X, ESX12345678
                // See https://github.com/validatorjs/validator.js/pull/2375
                is_vat = /^(ES)?(\d{8}|[a-zA-Z]\d{7}[0-9a-zA-Z])/.test(modelvalue)
            } else if (vat_country === 'IE') {
                is_vat = /^IE\d{7}[A-Z]{1,2}$/.test(modelvalue)
            } else {
                is_vat = isVAT(modelvalue, alpha2)
            }

            if (!is_vat) {
                this.message = m.trust($t('validation.vat_id.invalid_format', {
                    country_code,
                    link: 'https://en.wikipedia.org/wiki/VAT_identification_number',
                    interpolation: {escapeValue: false}}))
                return this
            }

            return false
        },
        label: '*',
        message: '',
    }
}

/**
 * Validates a website URL by stripping protocol and verifying the domain.
 * This function takes a model value (URL) as input, removes 'http://' or 'https://'
 * from the beginning, and then checks if the remaining part is a valid
 * Fully Qualified Domain Name (FQDN). The validation message is set based
 * on the outcome of the FQDN validation. If the URL is invalid, the
 * validation message is set accordingly, otherwise, it is cleared. The function
 * returns itself when validation fails, providing access to the validation message,
 * or false when the URL passes validation.
 *
 * @returns {ValidationEvaluationResult} Validation object with a validate method to check the URL,
 * and fields to hold validation results.
 */
export function website() {
    return {
        validate: function(modelValue) {
            if (!modelValue) return false
            const fqdn = modelValue.replace('http://', '').replace('https://', '')
            if (!isFQDN(fqdn)) {
                this.message = $t('validation.website')
            } else {
                this.message = ''
            }
            if (this.message) {
                return this
            }

            return false
        },
        label: '',
        message: '',
    }
}
