import {$s} from '@/app'
import {SalesOrderItem} from '@/factserver_api/sales_api'
import {GetSalesOrderResponse} from '@/factserver_api/fact2server_api'

type SalesOrderItemForMargin = SalesOrderItem |
    {price_per_case: number; excise_per_case: number; number_of_cases: number; item: {euro_was_bought_for: number} }

export function calculate_margin_percentage(
    sales_order_item: SalesOrderItemForMargin,
    sales_order: GetSalesOrderResponse): number {

    const euro_price = convert_from_currency_to_euro(
        +sales_order_item.price_per_case - +sales_order_item.excise_per_case,
        sales_order.was_sold_in,
        +sales_order.sold_against_rate,
    )
    const was_bought_for = sales_order_item.item.euro_was_bought_for
    return (euro_price - was_bought_for) / euro_price
}

export function calculate_total_margin_soi(
    sales_order_item: SalesOrderItemForMargin,
    sales_order: GetSalesOrderResponse,
): number {
    const euro_price = convert_from_currency_to_euro(
        +sales_order_item.price_per_case - +sales_order_item.excise_per_case,
        sales_order.was_sold_in,
        +sales_order.sold_against_rate,
    )
    return sales_order_item.number_of_cases * (euro_price - sales_order_item.item.euro_was_bought_for)
}

export function convert_from_currency_to_euro(amount_to_convert: number, source_currency: string, use_rate?: number | null): number {
    let converted_amount = amount_to_convert
    if (source_currency === $s.currencies.default || use_rate === 1) {
        return converted_amount
    }
    let rate = use_rate || $s.currencies.exchange_rates[source_currency].rate
    converted_amount = converted_amount / rate
    return converted_amount
}
