import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'
import {format_iso_to_date} from '@bitstillery/common/lib/format'
import {Country} from '@bitstillery/common/components'
import {Amount} from '@bitstillery/common/components'

import {RequeryListener} from './reports'

import {
    CollectionTable,
    CollectionTableColumn,
    PagedCollectionFetcher,
} from '@/components/collection/collection_table'
import {ReportArguments} from '@/factserver_api/reporting_api'

interface LedgerPerSalesOrder {
    artkey: number
    description: string
    category: string
    quantity: number
    ledger_code: number
    ledger_description: string
    ledger_type: string
    sales_order_artkey: number
    sales_order_reference: string
    is_invoiced_on: string
    was_sent_to_financial_information_system_on: string
    supplier_name: string
    supplier_country_code: string
    amount_in_euro: number
}

export interface LedgerSalesReportAttrs {
    report_arguments: ReportArguments
    add_requery_listener: (component: RequeryListener) => void
    remove_requery_listener: (component: RequeryListener) => void
}

export class LedgerSalesReport
    extends MithrilTsxComponent<LedgerSalesReportAttrs>
    implements RequeryListener
{
    reporting_fetcher = new PagedCollectionFetcher<LedgerPerSalesOrder>(
        'reporting.ledger_sales_report.get_all',
        'sales_order_artkey',
    )

    constructor(vnode: m.Vnode<LedgerSalesReportAttrs>) {
        super()
        this.reporting_fetcher.filters['start_date'] = vnode.attrs.report_arguments.start_date?.toISODate() || ''
        this.reporting_fetcher.filters['end_date'] = vnode.attrs.report_arguments.end_date?.toISODate() || ''

        vnode.attrs.add_requery_listener(this)
    }

    onremove(vnode: m.Vnode<LedgerSalesReportAttrs>): void {
        vnode.attrs.remove_requery_listener(this)
    }

    fire(report_arguments: ReportArguments): void {
        this.reporting_fetcher.filters['start_date'] = report_arguments.start_date?.toISODate() || ''
        this.reporting_fetcher.filters['end_date'] = report_arguments.end_date?.toISODate() || ''
        this.reporting_fetcher.reset_and_query()
    }

    view(): m.Children {
        return (
            <CollectionTable<LedgerPerSalesOrder, void> collection_fetcher={this.reporting_fetcher}>
                <CollectionTableColumn<LedgerPerSalesOrder>
                    header_title={() => 'Code'}
                    sort_name={'ledger_code'}
                    data_field={(row: LedgerPerSalesOrder) => row.ledger_code}
                />
                <CollectionTableColumn<LedgerPerSalesOrder>
                    header_title={() => 'Ledger description'}
                    data_field={(row: LedgerPerSalesOrder) => row.ledger_description}
                />
                <CollectionTableColumn<LedgerPerSalesOrder>
                    header_title={() => 'Sales Order'}
                    sort_name={'sales_order_reference'}
                    data_field={(row: LedgerPerSalesOrder) => (
                        <a href={`#!/sales/orders/${row.sales_order_artkey}/view/manage`}>{row.sales_order_reference}</a>
                    )}
                />
                <CollectionTableColumn<LedgerPerSalesOrder>
                    header_title={() => 'Relation'}
                    sort_name={'supplier_name'}
                    data_field={(row: LedgerPerSalesOrder) => <span>
                        {row.supplier_name}
                        &nbsp;
                        <Country country_code={row.supplier_country_code} type={'flag_with_country'}/>
                    </span>}
                />
                <CollectionTableColumn<LedgerPerSalesOrder>
                    header_title={() => 'Description'}
                    data_field={(row: LedgerPerSalesOrder) => row.description}
                />
                <CollectionTableColumn<LedgerPerSalesOrder>
                    header_title={() => 'Category'}
                    data_field={(row: LedgerPerSalesOrder) => row.category}
                />
                <CollectionTableColumn<LedgerPerSalesOrder>
                    header_title={() => 'Quantity'}
                    data_field={(row: LedgerPerSalesOrder) => row.quantity}
                />
                <CollectionTableColumn<LedgerPerSalesOrder>
                    header_title={() => 'Amount'}
                    data_field={(row: LedgerPerSalesOrder) => <Amount amount={row.amount_in_euro} currency={'EUR'} />}
                />
                <CollectionTableColumn<LedgerPerSalesOrder>
                    header_title={() => 'Sent to FIS'}
                    sort_name={'was_sent_to_financial_information_system_on'}
                    data_field={(row: LedgerPerSalesOrder) =>
                        format_iso_to_date(row.was_sent_to_financial_information_system_on)
                    }
                />
                <CollectionTableColumn<LedgerPerSalesOrder>
                    header_title={() => 'Invoiced on'}
                    data_field={(row: LedgerPerSalesOrder) => format_iso_to_date(row.is_invoiced_on)}
                />
            </CollectionTable>
        )
    }
}
