/** llm:tested */
import m from 'mithril'
import {filter, toArray, mergeAll, map} from 'rxjs/operators'
import {Amount, CellProduct, CellMargin, FieldSelectMany, Link, Dialog} from '@bitstillery/common/components'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {notifier} from '@bitstillery/common/app'
import {merge_deep} from '@bitstillery/common/lib/utils'
import {proxy} from '@bitstillery/common/lib/proxy'

import {DiscoverTable} from './discover_table'
import {AddPoiToOffer} from './components/add_poi_to_offer'
import {ProcessManageOffer} from './processes'
import {ProductFilterSidebar, ProductSearchMode} from './components/product_selection_sidebar'

import {callAndThen} from '@/api'
import {CollectionTable} from '@/components/collection_table'
import {Collection} from '@/components/collection/collection'
import {icon, icon_with_popover} from '@/components/icon'
import {Modal} from '@/components/modal/modal'
import {RelationDropDown} from '@/components/relation'
import {AddToOffer} from '@/market/pricelists/components/add_to_offer'
import {RelationDropDownData} from '@/factserver_api/relation_api'
import {formatDate, formatPercentage, pluralize} from '@/_utils'
import {Offer} from '@/models/offers'
import {SearchBar} from '@/components/collection/search_bar'
import {ProductManagementApi} from '@/factserver_api/product_management_api'
import {accountIconBySlug} from '@/accounts'
import {$m, $s} from '@/app'
import {Popover} from '@/components/popover/popover'
import {button_with_icon, text_button} from '@/components/_buttons'
import {header_with_popover} from '@/components/popover/popover'
import {CaseLabels} from '@/components/labels'
import {model as add_to_offer_model} from '@/market/pricelists/components/add_to_offer'

export class OfferSelectProducts extends MithrilTsxComponent<unknown> {
    offer: any
    offer_artkey: string
    offer_item_list: string[]
    keep_select_all_checked: any
    saving: any
    email_batch_artkey: string
    preview_supplier_artkey: any
    supplier_choices: any[]
    hide_supplier_artkeys: any
    search_mode: ProductSearchMode
    offer_items: any
    purchase_order_items: any
    market_items: any
    add_purchase_order_item: any
    add_market_item: any
    search_bar_controller: any
    product_management_api: ProductManagementApi
    current_search_text: string

    data = proxy({
        dialog: {
            add_to_offer: {
                open: false,
            },
        },
    })

    constructor() {
        super()
        this.offer = window.prop(new Offer())
        this.offer_artkey = m.route.param('artkey')

        this.offer_item_list = []
        this.keep_select_all_checked = window.prop(false)

        this.saving = window.prop(false)

        this.email_batch_artkey = m.route.param('email_batch')

        this.preview_supplier_artkey = window.prop('')
        this.supplier_choices = []

        RelationDropDownData.relations().pipe(
            mergeAll(),
            map((relation) => ({
                name: () => relation.name,
                artkey: () => relation.artkey,
            })),
            toArray(),
        ).subscribe((result) => {
            this.supplier_choices.splice(0, this.supplier_choices.length, ...result)
        })

        this.hide_supplier_artkeys = window.prop([])
        this.search_mode = ProductSearchMode.OFFER

        this.offer_items = new Collection({
            additional_params: () => this.offer_items_params(),
            api_function_name: 'offer.get_offer_items_for_selection',
            query_limit: 50,
            sort_order: [
                {name: 'case_number_of_bottles', direction: 'asc'},
                {name: 'bottle_volume', direction: 'asc'},
                {name: 'bottle_alcohol_percentage', direction: 'asc'},
                {name: 'bottle_refill_status', direction: 'desc'},
            ],
            default_sort_by: 'product_name',
            default_sort_order: 'asc',
            dont_reuse: true,
            filter_serverside: true,
        })

        if (typeof this.offer_items.filters === 'undefined') {
            this.offer_items.filters = {
                refill_status: window.prop(''),
                tax_label: window.prop('any'),
                customs_status: window.prop(''),
                hidden_selection: window.prop('visible_only'),
                arriving_soon: window.prop(false),
                categories: window.prop([]),
                brands: window.prop([]),
                gift_box_types: window.prop([]),
                offer_item_types: window.prop(['stock', 'tbo', 'purchase']),
                alcohol_percentage_range: window.prop([]),
                base_price_range: window.prop([]),
                bottles_per_case_range: window.prop([]),
                list_quantity_range: window.prop([]),
                stock_age_range: window.prop([]),
                last_price_change_age_days_range: window.prop([]),
                volume_range: window.prop([]),
                account_slugs: window.prop(['msi', 'msp', 'a2bc', 'etr']),
            }
        }

        this.purchase_order_items = new Collection({
            additional_params: () => this.purchase_order_items_params(),
            api_function_name: 'offer.get_purchase_order_items_for_selection',
            query_limit: 50,
            sort_order: [
                {name: 'case_number_of_bottles', direction: 'asc'},
                {name: 'bottle_volume', direction: 'asc'},
                {name: 'bottle_alcohol_percentage', direction: 'asc'},
                {name: 'bottle_refill_status', direction: 'desc'},
            ],
            default_sort_by: 'product_name',
            default_sort_order: 'asc',
            dont_reuse: true,
            filter_serverside: true,
        })

        if (typeof this.purchase_order_items.filters === 'undefined') {
            this.purchase_order_items.filters = {
                refill_status: window.prop(''),
                tax_label: window.prop('any'),
                customs_status: window.prop(''),
                categories: window.prop([]),
                brands: window.prop([]),
                gift_box_types: window.prop([]),
                alcohol_percentage_range: window.prop([]),
                bottles_per_case_range: window.prop([]),
                volume_range: window.prop([]),
            }
        }

        this.market_items = new Collection({
            additional_params: () => this.market_items_params(),
            api_function_name: 'marketanalysis.get_market_items_per_bottle',
            query_limit: 50,
            default_sort_by: 'product.name',
            default_sort_order: 'asc',
            dont_reuse: true,
            filter_serverside: true,
        })

        if (typeof this.market_items.filters === 'undefined') {
            this.market_items.filters = {
                refill_status: window.prop(''),
                customs_status: window.prop(''),
                categories: window.prop([]),
                brands: window.prop([]),
                gift_box_types: window.prop([]),
                alcohol_percentage_range: window.prop([]),
                bottles_per_case_range: window.prop([]),
                volume_range: window.prop([]),
            }
        }

        this.add_purchase_order_item = window.prop(null)
        this.add_market_item = window.prop(null)

        this.search_bar_controller = null
        this.product_management_api = new ProductManagementApi()
        this.current_search_text = ''
    }

    submit_search(text: string) {
        this.current_search_text = text
        if (this.search_mode === ProductSearchMode.PURCHASE) {
            this.purchase_order_items.update_search_term(text)
            this.purchase_order_items.submit_search()
        } else if (this.search_mode === ProductSearchMode.OFFER) {
            this.offer_items.update_search_term(text)
            this.offer_items.submit_search()
        } else if (this.search_mode === ProductSearchMode.MARKET) {
            this.market_items.update_search_term(text)
            this.market_items.submit_search()
        }
    }

    oncreate() {
        this.query_offer(this.offer_artkey)
        $m.common.observable.subscribe('collection.offer.get_offer_items_for_selection.after-call', this, this.handle_offer_items_query.bind(this))
    }

    offer_items_params() {
        const params: any = {
            offer_artkey: this.offer_artkey,
            supplier_artkey: this.preview_supplier_artkey(),
            hide_suppliers: this.hide_supplier_artkeys(),
            bottle_refill_status: this.offer_items.filters['refill_status'](),
            case_gift_box_types: this.offer_items.filters['gift_box_types'](),
            customs_status: this.offer_items.filters['customs_status'](),
            hidden_selection: this.offer_items.filters['hidden_selection'](),
            arriving_soon: this.offer_items.filters['arriving_soon'](),
            only_show_type: this.offer_items.filters['offer_item_types'](),
            show_accounts: this.offer_items.filters['account_slugs'](),
            show_categories: this.offer_items.filters['categories'](),
            show_brands: this.offer_items.filters['brands'](),
            bottle_alcohol_percentage_range: this.offer_items.filters['alcohol_percentage_range'](),
            bottle_volume_range: this.offer_items.filters['volume_range'](),
            case_number_of_bottles_range: this.offer_items.filters['bottles_per_case_range'](),
            list_quantity_range: this.offer_items.filters['list_quantity_range'](),
            price_per_case_range: this.offer_items.filters['base_price_range'](),
            stock_age_range: this.offer_items.filters['stock_age_range'](),
            last_price_change_age_days_range: this.offer_items.filters['last_price_change_age_days_range'](),
        }

        const tax_label = this.offer_items.filters['tax_label']()
        if (tax_label !== 'any') {
            params['case_tax_label'] = tax_label
        }

        return params
    }

    purchase_order_items_params() {
        const params: any = {
            offer_artkey: this.offer_artkey,
            hide_suppliers: this.hide_supplier_artkeys(),
            bottle_refill_status: this.purchase_order_items.filters['refill_status'](),
            case_gift_box_types: this.purchase_order_items.filters['gift_box_types'](),
            customs_status: this.purchase_order_items.filters['customs_status'](),
            show_categories: this.purchase_order_items.filters['categories'](),
            show_brands: this.purchase_order_items.filters['brands'](),
            bottle_alcohol_percentage_range: this.purchase_order_items.filters['alcohol_percentage_range'](),
            bottle_volume_range: this.purchase_order_items.filters['volume_range'](),
            case_number_of_bottles_range: this.purchase_order_items.filters['bottles_per_case_range'](),
        }

        const tax_label = this.purchase_order_items.filters['tax_label']()
        if (tax_label !== 'any') {
            params['case_tax_label'] = tax_label
        }

        return params
    }

    market_items_params() {
        const params: any = {
            offer_artkey: this.offer_artkey,
            hide_suppliers: this.hide_supplier_artkeys(),
            bottle_refill_status: this.market_items.filters['refill_status'](),
            case_gift_box_types: this.market_items.filters['gift_box_types'](),
            customs_status: this.market_items.filters['customs_status'](),
            show_categories: this.market_items.filters['categories'](),
            show_brands: this.market_items.filters['brands'](),
            bottle_alcohol_percentage_range: this.market_items.filters['alcohol_percentage_range'](),
            bottle_volume_range: this.market_items.filters['volume_range'](),
            case_number_of_bottles_range: this.market_items.filters['bottles_per_case_range'](),
            hide_internal_accounts: true,
        }

        return params
    }

    check_all() {
        for (const offer_item of this.offer_items.search_result()) {
            if (!this.offer_item_list.includes(offer_item.artkey)) {
                this.offer_item_list.push(offer_item.artkey)
            }
        }
    }

    check_offer_item(offer_item_artkey: string) {
        if (!this.offer_item_list.includes(offer_item_artkey)) {
            this.offer_item_list.push(offer_item_artkey)
        } else {
            const index = this.offer_item_list.indexOf(offer_item_artkey)
            if (index > -1) {
                this.offer_item_list.splice(index, 1)
            }
        }
        m.redraw()
    }

    extract_rank(record: any) {
        return {
            rank: record.market_rank,
            total: record.market_total,
        }
    }

    handle_offer_items_query() {
        if (this.keep_select_all_checked()) {
            this.check_all()
            this.keep_select_all_checked(false)
        }
    }

    hide_suppliers(value?: any) {
        if (value !== undefined) {
            this.hide_supplier_artkeys(value)
            if (this.search_mode === ProductSearchMode.OFFER) {
                this.offer_items.requery()
            } else if (this.search_mode === ProductSearchMode.PURCHASE) {
                this.purchase_order_items.requery()
            } else if (this.search_mode === ProductSearchMode.MARKET) {
                this.market_items.requery()
            }
        } else {
            return this.hide_supplier_artkeys()
        }
    }

    products_are_loading() {
        if (this.search_mode === ProductSearchMode.OFFER) {
            return this.offer_items.loading()
        } else if (this.search_mode === ProductSearchMode.PURCHASE) {
            return this.purchase_order_items.loading()
        } else if (this.search_mode === ProductSearchMode.MARKET) {
            return this.market_items.loading()
        }
    }

    query_offer(artkey: string) {
        const data = {
            artkey: artkey,
            include_suppliers: true,
        }

        callAndThen('offer.get_offer', data, {
            success: (resp: any) => {
                this.offer(new Offer(resp.result))

                if (resp.result.suppliers.length === 1) {
                    this.set_supplier(resp.result.suppliers[0].artkey)
                } else {
                    if (this.search_mode === ProductSearchMode.OFFER) {
                        this.offer_items.init()
                    } else if (this.search_mode === ProductSearchMode.PURCHASE) {
                        this.purchase_order_items.init()
                    } else if (this.search_mode === ProductSearchMode.MARKET) {
                        this.market_items.init()
                    }
                }
            },
            failure: () => {
                notifier.notify('Unknown offer.', 'danger')
                m.route.set('/offer/offers')
            },
        })
    }

    save(e: Event) {
        e.preventDefault()
        if (!this.saving()) {
            this.saving(true)
            const data = {
                artkey: this.offer().artkey(),
                offer_items: this.offer_item_list,
            }

            callAndThen('offer.select_offer_items', data, {
                success: () => {
                    $m.common.observable.broadcast('offer_updated')
                    notifier.notify(`Successfully selected offer items for offer "${this.offer().title()}"`, 'success')

                    let redirect_url = `/offer/offers/${this.offer_artkey}/custom_products`
                    if (this.email_batch_artkey) {
                        redirect_url += `/?email_batch=${this.email_batch_artkey}`
                    }
                    m.route.set(redirect_url)
                },
                failure: (resp: any) => {
                    notifier.notify(resp.message, 'danger')
                    this.saving(false)
                },
            })
        }
    }

    set_supplier(supplier_artkey: string) {
        this.preview_supplier_artkey(`${supplier_artkey}`)

        if (this.search_mode === ProductSearchMode.OFFER) {
            this.offer_items.requery()
        } else if (this.search_mode === ProductSearchMode.PURCHASE) {
            this.purchase_order_items.requery()
        }
    }

    toggle_all_offer_items(value?: boolean) {
        if (value !== undefined) {
            if (value) {
                if (!this.offer_items.showing_all()) {
                    this.keep_select_all_checked(true)
                    this.offer_items.show_all()
                } else {
                    this.check_all()
                }
            } else {
                for (const item of this.offer_items.search_result()) {
                    const index = this.offer_item_list.indexOf(item.artkey)
                    if (index > -1) {
                        this.offer_item_list.splice(index, 1)
                    }
                }
            }
        } else {
            if (this.offer_items.search_result().length === 0) return false
            for (const item of this.offer_items.search_result()) {
                if (!this.offer_item_list.includes(item.artkey)) {
                    return false
                }
            }
            return true
        }
    }

    offer_search_mode() {
        this.search_mode = ProductSearchMode.OFFER
        this.submit_search(this.current_search_text)
        this.offer_items.requery()
    }

    purchase_order_search_mode() {
        this.search_mode = ProductSearchMode.PURCHASE
        this.submit_search(this.current_search_text)
        this.purchase_order_items.requery()
    }

    market_search_mode() {
        this.search_mode = ProductSearchMode.MARKET
        this.submit_search(this.current_search_text)
        this.market_items.requery()
    }

    close_add_purchase_order_item_to_offer_modal() {
        this.add_purchase_order_item(null)
    }

    close_add_market_item_to_offer_modal() {
        this.data.dialog.add_to_offer.open = false
    }

    open_add_market_item_to_offer_modal(market_item: any, bottle: any) {
        merge_deep(add_to_offer_model, market_item, {
            bottle_artkey: bottle.artkey,
            bottle_gtin_code: bottle.bottle_gtin_code,
            offer_artkey: null,
            product_name: bottle.product.name,
            supplier_name: market_item.supplier.name,
            volume: bottle.volume,
        })
        this.data.dialog.add_to_offer.open = true
    }

    view() {
        return <div class="c-process-new-offer-step-3 view process">
            <ProcessManageOffer
                active="select_products"
                context={{
                    offer_artkey: this.offer_artkey,
                    offer_title: this.offer().title(),
                    email_batch_artkey: this.email_batch_artkey,
                }}
            />

            <div class="step-content">
                {this.search_mode}
                <div class="filter-sidebar-wrapper">
                    {this.search_mode === ProductSearchMode.OFFER && (
                        <ProductFilterSidebar
                            collection={this.offer_items}
                            mode={ProductSearchMode.OFFER}
                        />
                    )}
                    {this.search_mode === ProductSearchMode.PURCHASE && (
                        <ProductFilterSidebar
                            collection={this.purchase_order_items}
                            mode={ProductSearchMode.PURCHASE}
                        />
                    )}
                    {this.search_mode === ProductSearchMode.MARKET && (
                        <ProductFilterSidebar
                            collection={this.market_items}
                            mode={ProductSearchMode.MARKET}
                        />
                    )}

                    <div class="filter-result">
                        <div class="c-filter-group">
                            <div class="filter-field vertical">
                                {this.offer().suppliers && (
                                    <RelationDropDown
                                        disabled={this.products_are_loading()}
                                        get_all_for_drop_down_response$={
                                            this.offer().suppliers().length > 0
                                                ? RelationDropDownData.relations().pipe(
                                                    mergeAll(),
                                                    filter((relation) =>
                                                        this.offer()
                                                            .suppliers()
                                                            .map((supplier: any) => supplier.artkey)
                                                            .includes(relation.artkey),
                                                    ),
                                                    toArray(),
                                                )
                                                : RelationDropDownData.relations()
                                        }
                                        help="Optionally select a relation to preview its list prices"
                                        label="Select relation"
                                        model={[this, 'preview_supplier_artkey']}
                                        onchange={(supplier_artkey: string) => this.set_supplier(supplier_artkey)}
                                    />
                                )}
                            </div>
                        </div>

                        <div class="c-filter-group">
                            <SearchBar
                                placeholder="Search products..."
                                on_submit={this.submit_search.bind(this)}
                                default_search_text={this.offer_items.search_term()}
                                search_bar_controller={(search_bar_controller: any) =>
                                    this.search_bar_controller = search_bar_controller
                                }
                                on_get_suggestions$={(filter_text: string) =>
                                    this.product_management_api.get_simple_product_names(filter_text)
                                }
                            />
                        </div>

                        <div class="c-filter-group">
                            <FieldSelectMany
                                model={this.hide_suppliers()}
                                options={this.supplier_choices.map((i) => ({
                                    label: i.name(),
                                    value: i.artkey(),
                                }))}
                                placeholder="Exclude items from suppliers"
                            />
                        </div>

                        <div class="c-filter-group">
                            <div className="btn-group">
                                {text_button('Offer items', {
                                    class: this.search_mode === ProductSearchMode.OFFER ? 'btn-primary' : 'btn-default',
                                    onclick: this.search_mode !== ProductSearchMode.OFFER ? () => this.offer_search_mode() : undefined,
                                })}
                                {text_button('Purchase order items', {
                                    class: this.search_mode === ProductSearchMode.PURCHASE ? 'btn-primary' : 'btn-default',
                                    onclick: this.search_mode !== ProductSearchMode.PURCHASE ? () => this.purchase_order_search_mode() : undefined,
                                })}
                                {text_button('Market items', {
                                    class: this.search_mode === ProductSearchMode.MARKET ? 'btn-primary' : 'btn-default',
                                    onclick: this.search_mode !== ProductSearchMode.MARKET ? () => this.market_search_mode() : undefined,
                                })}
                            </div>
                        </div>

                        <div class="c-filter-group">
                            {button_with_icon('Save selection and proceed to customize items', 'glyphicon-list', {
                                class: 'btn-success',
                                disabled: this.saving(),
                                onclick: this.save.bind(this),
                            })}
                        </div>

                        {this.search_mode === ProductSearchMode.MARKET && (
                            <div>
                                {this.data.dialog.add_to_offer.open && this.offer().artkey() && (
                                    <Dialog
                                        title="Add market item to offer"
                                        onclose={this.close_add_market_item_to_offer_modal.bind(this)}
                                    >
                                        <AddToOffer
                                            model={add_to_offer_model}
                                            offer_artkey={this.offer().artkey()}
                                            done={() => {
                                                this.close_add_market_item_to_offer_modal()
                                                notifier.notify('Successfully added the market item to the offer.', 'success')
                                            }}
                                            oncancel={this.close_add_market_item_to_offer_modal.bind(this)}
                                        />
                                    </Dialog>
                                )}

                                <h3>Market items</h3>

                                {this.market_items.search_term() !== '' && this.market_items.show_counter()}

                                <div class="offer offer-items">
                                    {this.market_items.no_results() ? (
                                        this.market_items.search_term() === '' ? (
                                            <p>Enter a product name in the search field to start a new search.</p>
                                        ) : null
                                    ) : this.market_items.queried() ? (
                                        <DiscoverTable
                                            bottles={this.market_items}
                                            open_add_to_offer_modal={this.open_add_market_item_to_offer_modal.bind(this)}
                                        />
                                    ) : null}
                                </div>
                            </div>
                        )}

                        {this.search_mode === ProductSearchMode.PURCHASE && (
                            <div>
                                {this.add_purchase_order_item() && this.offer().artkey() && (
                                    <Modal
                                        title="Add purchase order item to offer"
                                        onclose={this.close_add_purchase_order_item_to_offer_modal.bind(this)}
                                    >
                                        <AddPoiToOffer
                                            purchase_order_item={this.add_purchase_order_item()}
                                            offer_artkey={this.offer().artkey()}
                                            offer_title={this.offer().title()}
                                            preview_supplier_artkey={this.preview_supplier_artkey()}
                                            done={() => {
                                                this.close_add_purchase_order_item_to_offer_modal()
                                                this.purchase_order_items.requery()
                                                notifier.notify('Successfully added the purchase order item to the offer.', 'success')
                                            }}
                                            cancel={this.close_add_purchase_order_item_to_offer_modal.bind(this)}
                                        />
                                    </Modal>
                                )}

                                <h3>Purchase order items</h3>

                                {this.purchase_order_items.show_counter()}

                                <CollectionTable
                                    collection={this.purchase_order_items}
                                    options={{
                                        search_table_style: true,
                                        sticky_header: true,
                                        with_buttons: false,
                                        autoscale: true,
                                        unique_name: 'purchase_order_item_list',
                                        row_classes: (record: any) => record.offer_item_artkey ? ['is-hidden'] : [],
                                    }}
                                    columns={[
                                        {
                                            width: 3,
                                            header: '#',
                                            name: 'Purchase Order Reference',
                                            sort: true,
                                            field: 'purchase_order_reference',
                                            function: (record: any) => (
                                                <Link
                                                    target="_blank"
                                                    href={`/purchase-orders/manage/${record.purchase_order_artkey}`}
                                                    class="no-click"
                                                >
                                                    <div class="fl-c">
                                                        {accountIconBySlug(record.purchase_order_account_slug)}
                                                        {record.purchase_order_reference}
                                                    </div>
                                                </Link>
                                            ),
                                        },
                                        {
                                            width: 4,
                                            name: 'Article code',
                                            sort: true,
                                            field: 'case_article_code',
                                            default_visible: false,
                                        },
                                        {
                                            width: 12,
                                            name: 'Product',
                                            sort: true,
                                            field: 'product_name',
                                            ellipsis: true,
                                        },
                                        {
                                            width: 6,
                                            name: 'Category',
                                            sort: true,
                                            field: 'product_category',
                                            ellipsis: true,
                                            function: (record: any) => (
                                                <span className="text-capitalize">{record.product_category}</span>
                                            ),
                                        },
                                        {
                                            width: 6,
                                            name: 'Brand',
                                            sort: true,
                                            field: 'brand_name',
                                            ellipsis: true,
                                        },
                                        {
                                            width: 8,
                                            name: 'Supplier',
                                            sort: true,
                                            field: 'supplier_name',
                                            ellipsis: true,
                                        },
                                        {
                                            width: 4,
                                            name: 'Btl / cs',
                                            sort: true,
                                            field: 'case_number_of_bottles',
                                            default_visible: false,
                                            classes: ['number'],
                                        },
                                        {
                                            width: 4,
                                            name: 'Size',
                                            sort: true,
                                            field: 'bottle_volume',
                                            default_visible: false,
                                            function: (record: any) => (
                                                <span>{(+record.bottle_volume).toFixed(1)}cl</span>
                                            ),
                                            classes: ['number'],
                                        },
                                        {
                                            width: 4,
                                            name: 'Alc %',
                                            sort: true,
                                            field: 'bottle_alcohol_percentage',
                                            default_visible: false,
                                            function: (record: any) => (
                                                <span>{(+record.bottle_alcohol_percentage).toFixed(1)}%</span>
                                            ),
                                            classes: ['number'],
                                        },
                                        {
                                            width: 3,
                                            name: 'Ref',
                                            sort: true,
                                            field: 'bottle_refill_status',
                                            default_visible: false,
                                        },
                                        {
                                            width: 10,
                                            name: 'Specs',
                                            function: (record: any) => {
                                                const specs = [
                                                    record.case_number_of_bottles,
                                                    (+record.bottle_volume).toFixed(1),
                                                    (+record.bottle_alcohol_percentage).toFixed(1),
                                                    record.bottle_refill_status,
                                                ]
                                                return specs.join(' / ')
                                            },
                                        },
                                        {
                                            width: 4,
                                            name: 'GB',
                                            sort: true,
                                            field: 'case_gift_box_type',
                                            default_visible: false,
                                        },
                                        {
                                            width: 4,
                                            name: 'Tax Label',
                                            sort: true,
                                            field: 'case_tax_label',
                                            default_visible: false,
                                        },
                                        {
                                            width: 8,
                                            name: 'Features',
                                            function: (record: any) => {
                                                const features = []
                                                if (record.case_gift_box_type) {
                                                    features.push(record.case_gift_box_type)
                                                }
                                                if (record.case_tax_label) {
                                                    features.push(record.case_tax_label)
                                                }
                                                if (record.case_best_before_date) {
                                                    features.push(`BBD: ${formatDate(record.case_best_before_date)}`)
                                                }
                                                if (record.case_damages) {
                                                    features.push(record.case_damages.replace(', ', ' / '))
                                                }
                                                if (record.case_general_tags) {
                                                    features.push(record.case_general_tags.replace(', ', ' / '))
                                                }
                                                if (record.case_pack_size) {
                                                    features.push(record.case_pack_size)
                                                }
                                                if (record.case_packaging) {
                                                    features.push(record.case_packaging)
                                                }
                                                return features.join(' / ')
                                            },
                                        },
                                        {
                                            width: 3,
                                            name: 'Cases',
                                            sort: true,
                                            field: 'number_of_cases',
                                            classes: ['number'],
                                        },
                                        {
                                            width: 7,
                                            name: 'SPLI Price / cs',
                                            field: 'was_bought_for',
                                            classes: ['price'],
                                            function: (record: any) => {
                                                if (record.refers_to_artkey) {
                                                    if (record.original_price_per_case) {
                                                        return <Amount
                                                            amount={record.original_price_per_case}
                                                            currency={record.original_currency}
                                                            rate={record.bought_against_rate}
                                                        />
                                                    } else if (record.original_price_per_bottle) {
                                                        return <Amount
                                                            amount={record.original_price_per_bottle * record.case_number_of_bottles}
                                                            currency={record.original_currency}
                                                            rate={record.bought_against_rate}
                                                        />
                                                    }
                                                }
                                                return '-'
                                            },
                                        },
                                        {
                                            width: 8,
                                            name: 'Net Price / cs',
                                            sort: true,
                                            field: 'was_bought_for',
                                            classes: ['price'],
                                            function: (record: any) => {
                                                const price_per_bottle = record.was_bought_for / record.case_number_of_bottles

                                                return <div>
                                                    {record.refers_to_artkey && !(
                                                        (record.original_price_per_case && +record.original_price_per_case === +record.was_bought_for) ||
                                                        (record.original_price_per_bottle && +record.original_price_per_bottle === +price_per_bottle)
                                                    ) && (
                                                        <span className="glyphicon glyphicon-download" style={{marginRight: '5px'}} />
                                                    )}
                                                    <Amount
                                                        amount={record.was_bought_for}
                                                        currency={record.was_bought_in}
                                                        display_currency={$s.currencies.default}
                                                        rate={record.bought_against_rate}
                                                    />
                                                </div>
                                            },
                                        },
                                        {
                                            width: 7,
                                            name: 'Cost / cs',
                                            sort: true,
                                            field: 'was_bought_for_plus_costs',
                                            classes: ['price'],
                                            function: (record: any) => (
                                                <Amount
                                                    amount={record.was_bought_for_plus_costs}
                                                    currency={record.was_bought_in}
                                                    rate={record.bought_against_rate}
                                                />
                                            ),
                                        },
                                        {
                                            width: 3,
                                            header: 'Cus.',
                                            name: 'Customs status',
                                            sort: true,
                                            field: 'case_customs_status',
                                        },
                                        {
                                            width: 3,
                                            header: '',
                                            name: 'Actions',
                                            function: (record: any) => {
                                                return <button
                                                    className="btn btn-default icon-button"
                                                    onclick={() => this.add_purchase_order_item(record)}
                                                    disabled={record.offer_item_artkey === undefined}
                                                    title="Add to offer"
                                                >
                                                    <span className="glyphicon glyphicon-duplicate" />
                                                </button>
                                            },
                                        },
                                    ]}
                                />
                            </div>
                        )}

                        {this.search_mode === ProductSearchMode.OFFER && (
                            <div>
                                {this.offer_items.show_counter()}

                                <div className="c-filter-group">
                                    <input
                                        type="checkbox"
                                        disabled={this.offer_items.search_result().length === 0}
                                        checked={this.toggle_all_offer_items()}
                                        onchange={(e) => this.toggle_all_offer_items(e.target.checked)}
                                        id="select-all"
                                    />
                                    <label htmlFor="select-all">Select all</label>
                                </div>

                                <CollectionTable
                                    collection={this.offer_items}
                                    options={{
                                        search_table_style: true,
                                        sticky_header: true,
                                        with_buttons: false,
                                        autoscale: true,
                                        unique_name: 'offer_item_list',
                                        row_classes: (record: any) => {
                                            if (record.custom_offer_item_artkey) return ['is-hidden']
                                            return []
                                        },
                                    }}
                                    columns={[
                                        {
                                            width: 5,
                                            name: 'Select',
                                            function: (record: any) => (
                                                <div>
                                                    <input
                                                        type="checkbox"
                                                        id={record.artkey}
                                                        onclick={() => this.check_offer_item(record.artkey)}
                                                        checked={record.custom_offer_item_artkey || this.offer_item_list.includes(record.artkey)}
                                                        disabled={record.custom_offer_item_artkey}
                                                    />
                                                    <label htmlFor={record.artkey} />
                                                </div>
                                            ),
                                        },
                                        {
                                            width: 4,
                                            name: 'Rank',
                                            sort: true,
                                            field: 'market_score',
                                            descending: true,
                                            default_visible: false,
                                            function: this.extract_rank,
                                        },
                                        {
                                            width: 4,
                                            header: header_with_popover(
                                                `${$s.currencies.default} above average price`,
                                                <span>
                                                    <span className="glyphicon glyphicon-stats" />
                                                    <span> €</span>
                                                </span>,
                                            ),
                                            name: `Above average (${$s.currencies.default})`,
                                            sort: true,
                                            field: 'avg_competitor_diff',
                                            classes: ['price'],
                                            descending: true,
                                            default_visible: false,
                                            function: (record: any) => (
                                                <Amount
                                                    amount={+record.avg_competitor_diff}
                                                    currency={$s.currencies.default}
                                                    display_currency={$s.currencies.default}
                                                />
                                            ),
                                        },
                                        {
                                            width: 4,
                                            header: header_with_popover(
                                                `${$s.currencies.default} above average in %`,
                                                <span>
                                                    <span className="glyphicon glyphicon-stats" />
                                                    <span> %</span>
                                                </span>,
                                            ),
                                            name: 'Percent above average price',
                                            sort: true,
                                            field: 'avg_competitor_diff_percentage',
                                            classes: ['number'],
                                            descending: true,
                                            default_visible: false,
                                            function: (record: any) => `${(+record.avg_competitor_diff_percentage).toFixed(1)}%`,
                                        },
                                        {
                                            width: 1,
                                            name: 'Accounts',
                                            field: 'account_slug',
                                            function: (record: any) => {
                                                if (!record.account_slugs || record.account_slugs[0] === null) {
                                                    return <div>-</div>
                                                }
                                                const icons = []
                                                for (const account_slug of record.account_slugs) {
                                                    icons.push(accountIconBySlug(account_slug))
                                                }
                                                return <div className="fl">{icons}</div>
                                            },
                                        },
                                        {
                                            width: 3,
                                            name: 'Stock age',
                                            sort: true,
                                            field: 'stock_age',
                                            function: (record: any) => {
                                                if (record.stock_age !== null) {
                                                    if (record.stock_age === record.newest_stock_age) {
                                                        return record.stock_age
                                                    }
                                                    const tooltiptext = `Oldest stock age: ${record.stock_age} days\nNewest stock age: ${record.newest_stock_age} days`
                                                    return <abbr title={tooltiptext}>{record.stock_age}</abbr>
                                                }
                                                return '-'
                                            },
                                        },
                                        {
                                            width: 5,
                                            name: 'Last price change',
                                            sort: true,
                                            field: 'last_price_change_date',
                                            function: (record: any) => {
                                                if (record.last_price_change_date !== null) {
                                                    return formatDate(record.last_price_change_date)
                                                }
                                                return '-'
                                            },
                                        },
                                        {
                                            width: 4,
                                            name: 'Article code',
                                            field: 'case_article_code',
                                            sort: true,
                                            default_visible: false,
                                        },
                                        {
                                            width: 24,
                                            name: 'Product',
                                            sort: true,
                                            field: 'product_name',
                                            function: (record: any) => {
                                                return <CellProduct
                                                    product_name={record.product_name}
                                                    number_of_bottles={record.case_number_of_bottles}
                                                    customs_status={record.case_customs_status}
                                                    gift_box_type={record.case_gift_box_type}
                                                    tax_label={record.case_tax_label}
                                                    alcohol_percentage={record.bottle_alcohol_percentage}
                                                    volume={record.bottle_volume}
                                                    refill_status={record.bottle_refill_status}
                                                    product_category_name={record.product_category}
                                                    article_code={record.case_article_code}
                                                    additional_details={
                                                        <CaseLabels
                                                            show_as_icons={true}
                                                            case={{
                                                                best_before_date: record.case_best_before_date,
                                                                gift_box_type: record.case_gift_box_type,
                                                                serialized_item_tags: record.case_serialized_item_tags,
                                                                remark: record.case_remark,
                                                                tax_label: record.case_tax_label,
                                                            }}
                                                        />
                                                    }
                                                    bottle_gtin_code={record.bottle_gtin_code}
                                                />
                                            },
                                        },
                                        {
                                            width: 6,
                                            name: 'Category',
                                            sort: true,
                                            field: 'product_category',
                                            ellipsis: true,
                                            default_visible: false,
                                            function: (record: any) => (
                                                <span className="text-capitalize">{record.product_category}</span>
                                            ),
                                        },
                                        {
                                            width: 6,
                                            name: 'Brand',
                                            sort: true,
                                            field: 'brand_name',
                                            ellipsis: true,
                                        },
                                        {
                                            width: 5,
                                            name: 'TBO supplier',
                                            sort: true,
                                            field: 'supplier_name',
                                            default_visible: false,
                                            ellipsis: true,
                                        },
                                        {
                                            width: 4,
                                            name: 'Btl / cs',
                                            sort: true,
                                            field: 'case_number_of_bottles',
                                            default_visible: false,
                                            classes: ['number'],
                                        },
                                        {
                                            width: 4,
                                            name: 'Size',
                                            sort: true,
                                            field: 'bottle_volume',
                                            default_visible: false,
                                            function: (record: any) => (
                                                <span>{(+record.bottle_volume).toFixed(1)}cl</span>
                                            ),
                                            classes: ['number'],
                                        },
                                        {
                                            width: 4,
                                            name: 'Alc %',
                                            sort: true,
                                            field: 'bottle_alcohol_percentage',
                                            default_visible: false,
                                            function: (record: any) => (
                                                <span>{(+record.bottle_alcohol_percentage).toFixed(1)}%</span>
                                            ),
                                            classes: ['number'],
                                        },
                                        {
                                            width: 3,
                                            name: 'Ref',
                                            sort: true,
                                            default_visible: false,
                                            field: 'bottle_refill_status',
                                        },
                                        {
                                            width: 4,
                                            name: 'GB',
                                            sort: true,
                                            field: 'case_gift_box_type',
                                            default_visible: false,
                                        },
                                        {
                                            width: 4,
                                            name: 'Tax Label',
                                            sort: true,
                                            field: 'case_tax_label',
                                            default_visible: false,
                                        },
                                        {
                                            width: 3,
                                            header: 'Cus.',
                                            name: 'Customs status',
                                            sort: true,
                                            field: 'case_customs_status',
                                            default_visible: false,
                                        },
                                        {
                                            width: 8,
                                            name: 'Stock value',
                                            sort: true,
                                            field: 'euro_total_stock_value',
                                            classes: ['price'],
                                            default_visible: false,
                                            function: (record: any) => {
                                                if (record.offer_item_type !== 'tbo') {
                                                    return <Amount
                                                        amount={+record.euro_total_stock_value}
                                                        currency={$s.currencies.default}
                                                    />
                                                } else {
                                                    return '-'
                                                }
                                            },
                                        },
                                        {
                                            width: 2,
                                            name: 'Number of cases in stock',
                                            header: icon_with_popover({
                                                iconId: 'home',
                                                content: 'Number of cases in stock',
                                            }),
                                            sort: true,
                                            field: 'number_of_cases_in_stock',
                                            classes: ['number'],
                                            default_visible: false,
                                        },
                                        {
                                            width: 2,
                                            name: 'Number of cases in purchase',
                                            header: icon_with_popover({
                                                iconId: 'shopping-cart',
                                                content: 'Number of cases in purchase',
                                            }),
                                            sort: true,
                                            field: 'number_of_cases_in_purchase',
                                            classes: ['number'],
                                            default_visible: false,
                                        },
                                        {
                                            width: 2,
                                            name: 'Number of cases in sales',
                                            header: icon_with_popover({
                                                iconId: 'screenshot',
                                                content: 'Number of cases in sales',
                                            }),
                                            sort: true,
                                            field: 'number_of_cases_in_sales',
                                            classes: ['number'],
                                            default_visible: false,
                                        },
                                        {
                                            width: 2,
                                            name: 'Number of cases available',
                                            header: icon_with_popover({
                                                iconId: 'fa-shield-alt',
                                                content: 'Number of cases available',
                                            }),
                                            sort: true,
                                            field: 'number_of_cases_available',
                                            classes: ['number'],
                                            default_visible: false,
                                        },
                                        {
                                            width: 3,
                                            name: 'Throughput',
                                            header: icon_with_popover({
                                                iconId: 'flash',
                                                content: 'Throughput',
                                            }),
                                            field: 'item_throughput',
                                            sort: true,
                                            classes: ['number'],
                                            default_visible: false,
                                            function: (record: any) => formatPercentage(record.item_throughput),
                                        },
                                        {
                                            width: 4,
                                            header: 'MOQ',
                                            name: 'Minimum Order Quantity',
                                            field: 'minimum_quantity',
                                            sort: true,
                                            classes: ['number'],
                                            function: (record: any) => record.minimum_quantity ?? '-',
                                        },
                                        {
                                            width: 4,
                                            header: 'List Qty',
                                            name: 'List Quantity',
                                            field: 'list_quantity',
                                            sort: true,
                                            classes: ['number'],
                                        },
                                        {
                                            width: 8,
                                            name: 'Avg purchase / cs',
                                            sort: true,
                                            field: 'avg_purchase_price',
                                            classes: ['price'],
                                            function: (record: any) => {
                                                if (record.avg_purchase_price) {
                                                    return <Amount
                                                        amount={record.avg_purchase_price}
                                                        currency={$s.currencies.default}
                                                    />
                                                } else {
                                                    return '-'
                                                }
                                            },
                                        },
                                        {
                                            width: 8,
                                            name: 'Base price / cs',
                                            sort: true,
                                            field: 'price_per_case',
                                            classes: ['price'],
                                            function: (record: any) => (
                                                <Amount
                                                    amount={+record.price_per_case}
                                                    currency={$s.currencies.default}
                                                    rate={$s.currencies.exchange_rates[record.currency].portal_rate}
                                                />
                                            ),
                                        },
                                        {
                                            width: 8,
                                            name: 'List price / cs',
                                            sort: true,
                                            field: 'list_price',
                                            classes: ['price'],
                                            function: (record: any) => (
                                                <Amount
                                                    amount={+record.list_price}
                                                    currency={record.currency}
                                                    rate={$s.currencies.exchange_rates[record.currency].portal_rate}
                                                />
                                            ),
                                        },
                                        {
                                            width: 8,
                                            name: 'List price / btl',
                                            classes: ['price'],
                                            default_visible: false,
                                            function: (record: any) => (
                                                <Amount
                                                    amount={+record.list_price / +record.number_of_bottles}
                                                    currency={record.currency}
                                                    rate={$s.currencies.exchange_rates[record.currency].portal_rate}
                                                />
                                            ),
                                        },
                                        {
                                            width: 8,
                                            name: 'Margin',
                                            classes: ['number'],
                                            default_visible: true,
                                            function: (record: any) => {
                                                if (record.price_per_case && record.avg_purchase_price) {
                                                    return <CellMargin
                                                        value={
                                                            (record.price_per_case - record.avg_purchase_price)
                                                            / record.avg_purchase_price
                                                        }
                                                    />
                                                }
                                            },
                                        },
                                        {
                                            width: 4,
                                            name: 'Delivery period',
                                            sort: true,
                                            field: 'delivery_period',
                                            classes: ['number'],
                                            function: (record: any) => {
                                                if (record.delivery_period) {
                                                    return `${record.delivery_period} ${pluralize(record.delivery_period, 'week', 'weeks')}`
                                                } else {
                                                    return '-'
                                                }
                                            },
                                        },
                                        {
                                            width: 1,
                                            name: 'Product photo icon',
                                            header: icon('fa-image'),
                                            sort: true,
                                            field: 'has_product_photo',
                                            function: (record: any) => {
                                                if (record.has_product_photo) {
                                                    return icon_with_popover({
                                                        iconId: 'fa-image',
                                                        title: 'Product photo',
                                                        content: 'This product has one or more photos.',
                                                    })
                                                }
                                            },
                                        },
                                        {
                                            width: 1,
                                            name: 'In active offer',
                                            header: ' ',
                                            function: (record: any) => {
                                                if (record.exists_in_other_offers) {
                                                    let popover_content = '<ul>'
                                                    for (const other_offer of record.exists_in_other_offers) {
                                                        const [artkey, reference] = other_offer.split(':', 2)
                                                        popover_content += `<li><a target="_blank" href="#!/offer/offers/${artkey}/custom_products">${reference}</li>`
                                                    }
                                                    popover_content += '</ul>'

                                                    return <Popover
                                                        title="This product is currently offered in the following offers:"
                                                        content={popover_content}
                                                    >
                                                        <span className="glyphicon glyphicon-info-sign" />
                                                    </Popover>
                                                }
                                                return null
                                            },
                                        },
                                    ]}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    }
}
