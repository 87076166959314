import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {Spinner} from '@bitstillery/common/components'
import {format_iso_to_date} from '@bitstillery/common/lib/format'
import {config, notifier} from '@bitstillery/common/app'
import {Amount} from '@bitstillery/common/components'

import {$m} from '@/app'
import {
    CollectionTable,
    CollectionTableColumn,
    PagedCollectionFetcherWithGET,
} from '@/components/collection/collection_table'
import {SalesApi} from '@/factserver_api/sales_api'
import {GetExactSalesOrderCollectionViewResponse} from '@/factserver_api/fact2server_api'
import {Link} from '@/components/discover'
import {SelectionController} from '@/components/selection_controller'
import {DefaultButton} from '@/components/buttons'
import {Api} from '@/factserver_api/api'

interface GetExactSalesOrderView extends GetExactSalesOrderCollectionViewResponse {
    exact_export_result?: string
}

export class ListExact extends MithrilTsxComponent<unknown> {
    exact_sales_order_fetcher: PagedCollectionFetcherWithGET<GetExactSalesOrderView> = new PagedCollectionFetcherWithGET(
        'discover/sales-orders/exact/collection-view',
        'is_invoiced_on',
        null,
        50,
        false,
    )

    selection_controller = new SelectionController(['so'])
    sales_api = new SalesApi()
    api = new Api()
    is_exporting = false
    is_bulk_exporting = false
    stop_bulk_export_requested = false

    start_export_sales_orders() {
        this.is_exporting = true

        const data = $m.data.exact_keys.get_tokens()
        data['sales_order_artkeys'] = this.selection_controller.selected_items('so')
        this.sales_api.to_exact(data).subscribe({
            next: () => {
                notifier.notify('SalesOrder exported', 'info')
                this.is_exporting = false
                this.selection_controller.remove_all('so')
                this.exact_sales_order_fetcher.reset_and_query()
            },
            error: () => {
                this.is_exporting = false
                this.exact_sales_order_fetcher.reset_and_query()
            },
        })
    }

    async start_bulk_export_sales_orders() {
        this.is_bulk_exporting = true
        for (const row of this.exact_sales_order_fetcher.fetched_rows) {
            if (row.exact_export_result || this.stop_bulk_export_requested) {
                continue
            }
            const data = $m.data.exact_keys.get_tokens()
            data['sales_order_artkeys'] = [row.artkey]
            const body = Api.create_request_body(data)
            const request = this.api.create_request(config.api_host, 'sales.exact.export_sales_orders_to_exact', 'POST', body)
            const response = await fetch(request)
            const content_type = response.headers.get('content-type')
            if (response.ok && content_type === 'application/json') {
                const result = await response.json()
                if (result['success']) {
                    row.exact_export_result = `OK ${result['failed_exports']} ${result['successful_exports']} ${result['skipped_exports']}`
                }
                else {
                    const failed_export_messages: string[] = result['failed_exports'].map((failure) => failure['message'])
                    row.exact_export_result = `FAILED ${failed_export_messages.join(', ')}`
                }
            }
            else if (response.ok) {
                row.exact_export_result = 'OK'
            }
            else {
                row.exact_export_result = `FAILED ${response.status} - ${response.statusText}`
            }
            await new Promise(r => setTimeout(r, 300)) // In order not to overload exact api.
            m.redraw()
        }
        this.is_bulk_exporting = false
        this.stop_bulk_export_requested = false
    }

    view(): m.Children {
        return <div className="c-exact-sales-order-list view">
            <div className="btn-toolbar">
                {!this.is_bulk_exporting && <DefaultButton
                    title={'Export all'}
                    disabled={this.is_exporting || this.selection_controller.length_for('so') !== 0}
                    onclick={() => this.start_bulk_export_sales_orders()}
                />}
                {this.is_bulk_exporting && <DefaultButton
                    title={'Stop export'}
                    disabled={this.is_exporting || this.selection_controller.length_for('so') !== 0}
                    onclick={() => {
                        this.stop_bulk_export_requested = true
                    }}
                />}
                {this.exact_sales_order_fetcher.fetched_rows.filter((row) => row.exact_export_result).length} / {this.exact_sales_order_fetcher.fetched_rows.length}
                <DefaultButton
                    title={'Start Export'}
                    disabled={this.is_exporting || this.selection_controller.length_for('so') === 0}

                    onclick={() => this.start_export_sales_orders()}
                />
                {this.is_exporting && <Spinner />}
            </div>

            <CollectionTable<GetExactSalesOrderCollectionViewResponse, void>
                collection_fetcher={this.exact_sales_order_fetcher}
            >
                <CollectionTableColumn<GetExactSalesOrderCollectionViewResponse>
                    header_title={() => ''}
                    data_field={(row) => <input
                        type={'checkbox'}
                        id={`select-${row.artkey}`}
                        oninput={() =>
                            this.selection_controller?.is_in_selection('so', row.artkey)
                                ? this.selection_controller?.remove_from_selection('so', row.artkey)
                                : this.selection_controller?.add_to_selection('so', row.artkey)
                        }
                        checked={this.selection_controller?.is_in_selection('so', row.artkey)}
                    />
                    }
                />
                <CollectionTableColumn<GetExactSalesOrderCollectionViewResponse>
                    header_title={() => '#'}
                    sort_name={'reference'}
                    data_field={(row) => <Link href={`/sales/orders/${row.artkey}/view/manage`}>{row.reference}</Link>}
                />
                <CollectionTableColumn<GetExactSalesOrderView>
                    header_title={() => 'Export result'}
                    sort_name={'created_on'}
                    data_field={(row) => row.exact_export_result}
                />
                <CollectionTableColumn<GetExactSalesOrderCollectionViewResponse>
                    header_title={() => 'Order Date'}
                    sort_name={'created_on'}
                    data_field={(row) => format_iso_to_date(row.created_on)}
                />
                <CollectionTableColumn<GetExactSalesOrderCollectionViewResponse>
                    header_title={() => 'Invoiced on'}
                    sort_name={'is_invoiced_on'}
                    data_field={(row) => format_iso_to_date(row.is_invoiced_on)}
                />
                <CollectionTableColumn<GetExactSalesOrderCollectionViewResponse>
                    header_title={() => 'Total value'}
                    sort_name={'created_on'}
                    data_field={(row) => <Amount currency={row.was_sold_in} amount={row.was_sold_for}/>}
                />
                <CollectionTableColumn<GetExactSalesOrderCollectionViewResponse>
                    header_title={() => 'Invoiced on'}
                    sort_name={'created_on'}
                    data_field={(row) => format_iso_to_date(row.is_invoiced_on)}
                />
                <CollectionTableColumn<GetExactSalesOrderCollectionViewResponse>
                    header_title={() => 'Relation'}
                    sort_name={'created_on'}
                    data_field={(row) => (
                        <Link href={`/crm/relations/${row.relation_artkey}/edit`}>{row.relation_name}</Link>
                    )}
                />
            </CollectionTable>
        </div>
    }
}
