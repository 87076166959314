import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {classes} from '@bitstillery/common/lib/utils'
import {Icon} from '@bitstillery/common/components'
import {tip} from '@bitstillery/common/components'

interface ProcessStep {
    completed: boolean | (() => boolean)
    disabled: boolean | (() => boolean)
    id: string
    path: string | (() => string)
    onclick?: () => void
    text: string
    tip?: Function | string
    type?: string
}

export interface ProcessStepperAttrs {
    className?: string
    data: {
        active: string
        current: number
    }
    process: ProcessStep[]
    variant?: 'vertical' | 'horizontal'
}

export class ProcessStepper extends MithrilTsxComponent<ProcessStepperAttrs> {

    tippy: any

    oncreate(vnode: m.Vnode<ProcessStepperAttrs>) {
        for (const step of vnode.attrs.process) {
            if (!step.tip) {
                continue
            }

            let selector

            selector = document.querySelector(`#tip-${step.id}`)
            const tip_config:any = {
                allowHTML: true,
                animation: 'scale',
                appendTo: document.body,
                content: this.update_tip(vnode),
                delay: 500,
                inertia: true,
                interactive: true,
                onTrigger: (instance) => {
                    instance.setProps({content: this.update_tip(step)})
                },
                touch: 'hold',
                zIndex: 10000000000000000,
            }

            this.tippy = tip(selector, tip_config)
        }
    }

    onremove() {
        if (this.tippy) {
            this.tippy.destroy()
        }
    }

    update_tip(step) {
        if (typeof step.tip === 'function') {
            return step.tip()
        } else {
            return step.tip
        }
    }

    view(vnode:m.Vnode<ProcessStepperAttrs>): m.Children {

        return <div className={classes(
            'c-process-stepper',
            `variant-${vnode.attrs.variant ? vnode.attrs.variant : 'vertical'}`,
            vnode.attrs.className,
        )}>
            {vnode.attrs.process.map((step, index) => {
                const completed = typeof step.completed === 'function' ? step.completed() : step.completed
                const disabled = typeof step.disabled === 'function' ? step.disabled() : step.disabled
                if (step.id === vnode.attrs.data.active && vnode.attrs.data.current !== index) {
                    vnode.attrs.data.current = index
                }
                return <a
                    key={step.id}
                    id={`tip-${step.id}`}
                    href={(step.disabled || !step.path) ? null : `#!${typeof step.path === 'function' ? step.path() : step.path}`}
                    onclick={(e) => {
                        if (vnode.attrs.data.active === step.id) {
                            return
                        }

                        if (disabled) {
                            e.preventDefault()
                            return
                        }
                        if (typeof step.onclick === 'function') {
                            step.onclick()
                        }
                    }}
                    className={classes('step', `type-${step.type ? step.type : 'default'}`, {
                        active: step.id === vnode.attrs.data.active,
                        completed,
                        disabled,
                    })}
                >
                    <div className="title">
                        {step.text}
                    </div>

                    {(() => {
                        if (completed) return <Icon name="checked" type="unset" />
                        if (step.id === vnode.attrs.data.active) return <Icon name="chevronRight" type="unset" />
                        if (step.type === 'danger') return <Icon name="danger" type="unset" />
                        return <Icon name="chevronDown" type="unset" />
                    })()}
                </a>
            })}
        </div>
    }
}
