/** llm:production */
// eslint-disable-next-line no-unused-vars
import m from 'mithril'
import {deref} from '@bitstillery/common/utils'
import {Country} from '@bitstillery/common/components'

import {RelationMultiSelectDropDown} from '@/components/relation'
import {RelationDropDownData} from '@/factserver_api/relation_api'
import {CountriesMultiSelect, CountriesSelect} from '@/components/html_components'
import * as inputs from '@/components/inputs'
import {TagModel} from '@/factserver_api/item_tags'
import {
    DamagesDropDown,
    GeneralDropDown,
    PackagingLabelDropDown,
    PackSizeDropDown,
} from '@/components/case_inputs'

const items = {}

/**
 * TODO: Merge ItemEditor and this one together; they are very similar.
 * @param item
 * @param options
 * @returns
 */
export const item_tags_editor = (item: any, options: any = {}) => {
    item = deref(item)
    let item_ref

    if (!items[item.artkey()]) {
        items[item.artkey()] = {};
        (async() => {
            const tag_model = new TagModel()
            await tag_model.load_data()
            items[item.artkey()].tag_model = tag_model
            item_ref = items[item.artkey()]
            const item_tags = item.item_tags()
            if (!item_ref.selection_set) {
                item_ref.tag_model.set_selection(item_tags)
                item_ref.selection_set = true
            }
        })()
    } else {
        item_ref = items[item.artkey()]
        if (items[item.artkey()].tag_model && !items[item.artkey()].selection_set) {
            const item_tags = item.item_tags()
            item_ref.tag_model.set_selection(item_tags)
            item_ref.selection_set = true
        }
    }

    if (!item_ref || !item_ref.tag_model) {
        return null
    }

    return <div className="c-item-tags-editor">
        <PackagingLabelDropDown
            label="Packaging"
            model={[item_ref.tag_model.data['Packaging'], 'selection']}
            onchange={() => {
                item.item_tags(item_ref.tag_model.serialize_selection())
            }}
            options={item_ref.tag_model.data['Packaging'].options}
        />

        <PackSizeDropDown
            label="Pack Size"
            model={[item_ref.tag_model.data['Pack Size'], 'selection']}
            onchange={() => {
                item.item_tags(item_ref.tag_model.serialize_selection())
            }}
            options={item_ref.tag_model.data['Pack Size'].options}
        />

        <GeneralDropDown
            label="General"
            model={item_ref.tag_model.data['General'].selection}
            onchange={() => {
                item.item_tags(item_ref.tag_model.serialize_selection())
            }}
            options={item_ref.tag_model.data['General'].options}
        />

        <DamagesDropDown
            label="Damages"
            model={item_ref.tag_model.data['Damages'].selection}
            onchange={() => {
                item.item_tags(item_ref.tag_model.serialize_selection())
            }}
            options={item_ref.tag_model.data['Damages'].options}
        />

        {options.not_editable_hide_from_pricelist_for_countries && options.hide_from_pricelist_for_countries_selected
            ? <div></div>
            : options.hide_from_pricelist_for_countries_selected
                ? <CountriesMultiSelect
                    label="Hide on pricelist in"
                    model={options.hide_from_pricelist_for_countries_selected!}
                />
                : null}

        {options.not_editable_hide_from_pricelist_for_suppliers && options.hide_from_pricelist_for_suppliers_selected
            ? <div></div>
            : options.hide_from_pricelist_for_suppliers_selected
                ? <RelationMultiSelectDropDown
                    get_all_for_drop_down_response$={RelationDropDownData.relations()}
                    label="Hide on pricelist from"
                    model={[options, 'hide_from_pricelist_for_suppliers_selected']}
                />
                : null}

        {item.country_of_origin && options.not_editable_country_of_origin
            ? <div className="field">
                <label>Country of origin</label>
                <Country
                    country_code={deref(item.country_of_origin)}
                    type="flag_with_country"
                />
            </div>
            : item.country_of_origin
                ? <CountriesSelect
                    label="Country of origin"
                    model={[item, 'country_of_origin']}
                />
                : null}

        {!options.only_tags
            ? [
                inputs.date(item.best_before_date, {
                    disabled: options.disabled,
                    label: 'Best Before Date',
                }),

                inputs.text(item.remark, {
                    disabled: options.disabled,
                    label: 'Remark',
                }),
            ]
            : null}

        {!options.only_tags && options.edit_gtin ?
            <div className={'field-group'}>
                {inputs.text(item.bottle_gtin_code, {
                    disabled: options.disabled,
                    label: 'Bottle GTIN',
                })}
                {inputs.text(item.case_gtin_code, {
                    disabled: options.disabled,
                    label: 'Case GTIN',
                })}
            </div> : null
        }

        {!options.only_tags && options.edit_pallet_layout ?
            [
                inputs.number(item.case().cases_per_pallet, {
                    disabled: options.disabled,
                    label: 'Cases per pallet',
                }),
                inputs.number(item.case().cases_per_pallet_layer, {
                    disabled: options.disabled,
                    label: 'Cases per pallet layer',
                }),
            ]
            : null
        }
    </div>

}
