import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {PanelContext} from '@bitstillery/common/components'
import {proxy} from '@bitstillery/common/lib/proxy'

import {SetupColumns} from '@/market/pricelists/list/components/setup_columns'
import {context} from '@/market/pricelists/list/lib/context'
import {ContextPricelist} from '@/market/pricelists/list/context/pricelist'
import {CollectionSpl} from '@/market/pricelists/list/lib/collection_spl'
import {collection} from '@/market/pricelists/list/lib/collection_spl'

export const model = (() => {
    return {
        data: proxy({
            key_figures: {},
            tabs: {
                active: 'manage_order',
            },
        }),
    }
})()

export class ListManage extends MithrilTsxComponent<unknown> {

    view(): m.Children {
        return <div className="c-market-list-pricelists view-container">
            {context.data.stepper.loading && <div className="view"></div>}
            {!context.data.stepper.loading && context.data.stepper.selection < 2 ? <CollectionSpl /> : null}
            {(!context.data.stepper.loading && context.data.stepper.selection === 2) && <SetupColumns />}
            <PanelContext
                collection={context.data.stepper.selection === 2 ? undefined : collection}
                minimizable={context.data.stepper.selection === 2}
                context={context}
            >
                <div className="content">
                    <ContextPricelist collection={collection} />
                </div>
            </PanelContext>
        </div>
    }
}
