import {classes} from '@bitstillery/common/lib/utils'
import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'

interface CollectionHeroAttrs {
    model: any
}

export class CollectionHero extends MithrilTsxComponent<CollectionHeroAttrs> {

    view(vnode: m.Vnode<CollectionHeroAttrs>) {
        return (
            <div className={classes('c-collection-hero', vnode.attrs.className)}>
                {vnode.children}
            </div>
        )
    }
}
