import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'
import {proxy} from '@bitstillery/common/lib/proxy'
import {format_month_day_year} from '@bitstillery/common/lib/format'
import {required, reset_validation, validation} from '@bitstillery/common/lib/validation'
import {
    Button,
    FieldTextArea,
    Icon,
    RowActionDelete,
} from '@bitstillery/common/components'

export class LogType {
    static CONTACT_UPDATE = 'CONTACT_UPDATE'
    static MESSAGE = 'MESSAGE'
}

interface SupplierLogbookAttrs {
    mode: 'lead' | 'supplier'
    artkey: string
}

interface LogEntry {
    log_type: string
    created_on: string
    created_by: {
        profile: {
            name: string
        }
    }
    message: string
}

export class Timeline extends MithrilTsxComponent<SupplierLogbookAttrs> {

    data = proxy({
        loading: false,
        log_entries: [] as LogEntry[],
        log_type: LogType.MESSAGE,
        message: '',
    })

    $v = {
        message: validation([this.data, 'message'], required()),
    }

    view(vnode: m.Vnode<any>): m.Children {
        return <div className="c-timeline">
            <div className="timeline">
                <div className="now">Now</div>
                {vnode.attrs.messages.map((message) => (
                    <div className="timeline-item">
                        <div class="timeline-message">
                            <div className="message-header">
                                <div className="message-header-left">
                                    <Icon
                                        name={message.log_type === LogType.CONTACT_UPDATE ? 'systemMessage' : 'profile'}
                                        className="entry-type-icon"
                                        type="unset"
                                    />
                                    {message.profile_name}&nbsp;-
                                    <div className="entry-date">{format_month_day_year(message.created_on)}</div>
                                </div>
                                <div className="message-header-right">
                                    {vnode.attrs.ondelete && <RowActionDelete
                                        needs_confirmation={true}
                                        row={message}
                                        row_delete={() => vnode.attrs.ondelete(message)}
                                        type="info"
                                    />}
                                </div>
                            </div>
                            <div className="message-content">{message.message}</div>
                        </div>
                        <Icon name="circle_outline" className="timeline-icon" size="s" type="unset" />
                    </div>
                ))}
                <div className="timeline-item">
                    <div className="timeline-message">
                        <div className="message-header">
                            <div className="message-header-left">
                                <Icon
                                    name="profile"
                                    className="entry-type-icon"
                                    type="unset"
                                />
                                New Memo message
                            </div>
                        </div>
                        <div className="message-content">
                            <FieldTextArea
                                help="Enter customer context to share with colleagues"
                                model={[this.data, 'message']}
                                validation={this.$v.message}
                            />
                            <Button
                                disabled={this.data.loading || !!Object.values(this.$v).filter((i) => i && i._invalid).length}
                                icon="save"
                                class="btn-submit"
                                text="Add Memo"
                                type="info"
                                onclick={async() => {
                                    this.data.loading = true
                                    await vnode.attrs.onsave(this.data.message)
                                    reset_validation(this.$v)
                                    this.data.message = ''
                                    this.data.loading = false
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}
