import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'
import {Tippy, Icon} from '@bitstillery/common/components'
import {classes} from '@bitstillery/common/lib/utils'

interface PanelAttrs {
    title?: string
    description?: string
    content_fetcher?: any
    download_callback?: () => void
    footer?: JSX.Element
    toolbar?: JSX.Element
    model: {
        collapsible: boolean
        collapsed: boolean
    }
}

export class Panel extends MithrilTsxComponent<PanelAttrs> {
    description_component: m.Vnode | undefined

    oncreate(vnode: m.Vnode<PanelAttrs>) {
        if (vnode.attrs.description) {
            this.description_component =
                <Tippy content={vnode.attrs.description}>
                    <Icon name={'info'}/>
                </Tippy>
        }
    }

    view(vnode: m.Vnode<PanelAttrs>) {
        return (
            <div className={classes('c-panel', {collapsible: vnode.attrs.model.collapsible}, {collapsed: vnode.attrs.model.collapsed})}>
                <div className="panel-heading">
                    {vnode.attrs.title && (
                        <div className={'panel-title'} onclick={() => {
                            vnode.attrs.model.collapsed = !vnode.attrs.model.collapsed
                        }}>
                            {vnode.attrs.title}
                        </div>
                    )}
                    {vnode.attrs.toolbar}
                    {vnode.attrs.download_callback && (
                        <Tippy content={'Download full result'}>
                            {/* @ts-ignore: TS does not recognize that download_callback cannot be undefined here */}
                            <Icon name={'excel'} onclick={() => vnode.attrs.download_callback()}/>
                        </Tippy>
                    )}
                    {this.description_component}
                </div>
                <div className="panel-body">
                    {vnode.children}
                    {vnode.attrs.footer && (<div className="panel-footer">{vnode.attrs.footer}</div>)}
                </div>
            </div>
        )
    }
}
