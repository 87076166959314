import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {
    Button,
    Dialog,
    FieldCheckbox,
    PanelContext,
    Tab,
    TabPanel,
} from '@bitstillery/common/components'
import {classes} from '@bitstillery/common/lib/utils'
import {proxy} from '@bitstillery/common/lib/proxy'
import {api, notifier} from '@bitstillery/common/app'
import {Tabs} from '@bitstillery/common/components'

import {ManageVoucher} from '@/sales/orders/view/manage/voucher'
import {ManageAdditional} from '@/sales/orders/view/manage/additional'
import {ManageCreditItem} from '@/sales/orders/view/manage/credit_item'
import {ManageDataCard} from '@/sales/orders/view/manage/data_card'
import {ManageStockItem} from '@/sales/orders/view/manage/stock_item'
import {ManageTboItem} from '@/sales/orders/view/manage/tbo_item'
import {context, EntityType, methods} from '@/sales/orders/view/lib/context'
import {collection as collection_soi, ManageCollection} from '@/sales/orders/view/manage/components/collection'
import {collection as collection_sellable, ManageCollectionSellable} from '@/sales/orders/view/manage/components/collection_sellable'
import {CombinedOrderStatus, SellableItemFilterType} from '@/factserver_api/fact2server_api'
import {AttachmentHelper} from '@/components/attachment_list'
import {DialogSettings} from '@/sales/orders/view/components/dialog_settings'
import {SalesApi} from '@/factserver_api/sales_api'
import {auto_attach_buy_from_account_supplier_invoices} from '@/purchase_orders/auto_attach_bfa_attachments'

interface ViewManageAttrs {
    attachment_helper: AttachmentHelper
}

export class ViewManage extends MithrilTsxComponent<ViewManageAttrs> {
    data = proxy({
        current: 0,
        photos: {
            include_attachments: true,
            include_item_photos: true,
        },
    })

    include_attachments = true
    include_item_photos = true
    sales_api = new SalesApi()

    async oninit() {
        await collection_sellable.is_ready()
    }

    entity_route(entity: string) {
        if (context.data.entity_artkey) {
            return `/sales/orders/${context.data.root_artkey}/view/manage/${entity}/${context.data.entity_artkey}`
        }
        return `/sales/orders/${context.data.root_artkey}/view/manage/${entity}`
    }

    set_entity(entity: string) {
        if (entity === 'SOCI') {
            // Collection must only show credit-items when the credit-items tab is active.
            collection_sellable.filters.sellable_item_type.selection = SellableItemFilterType.Credit
        } else if (entity === 'SOTI') {
            collection_sellable.filters.sellable_item_type.selection = SellableItemFilterType.TBOMarket
        } else if (entity === 'SOI') {
            collection_sellable.filters.sellable_item_type.selection = SellableItemFilterType.Stock
        }
        context.data.entity_type = entity as EntityType
    }

    view(vnode: m.Vnode<ViewManageAttrs>): m.Children {
        return <div className={classes(
            'c-view-manage sales orders view-container',
            `mode-${context.data.entity_type}`,
            ['SOBI', 'SOI', 'SOCI', 'SOTI'].includes(context.data.entity_type) && !context.data.entity_artkey ? 'context-create' : 'context-default',
        )}>
            {context.data.dialog.confirm_settings && <DialogSettings
                cancel={async() => {
                    context.data.dialog.confirm_settings = null
                    await methods.fetch_sales_order_with_items(context.data.root_artkey)
                }}
                ok={async() => {
                    if (!context.data.dialog.confirm_settings) { return }
                    this.sales_api.set_sales_order_status(
                        context.data.root_artkey, context.data.dialog.confirm_settings,
                    ).subscribe({
                        next: async() => {
                            if (context.data.dialog.confirm_settings === CombinedOrderStatus.Invoiced) {
                                if (context.data.sales_order.needs_buy_from_account) {
                                    auto_attach_buy_from_account_supplier_invoices(context.data.root_artkey)
                                }
                                m.route.set(`/sales/orders/${context.data.root_artkey}/view/invoice`)
                            } else {
                                await methods.fetch_sales_order_with_items(context.data.root_artkey)
                            }

                            context.data.dialog.confirm_settings = null
                        },
                    })
                }}
            />}

            {context.data.dialog.download_photos && <Dialog
                title="Download sales order photos"
                onclose={() => context.data.dialog.download_photos = false}
            >
                <p>
                    Download photo's associated with Sales order {context.data.sales_order.reference}.
                    You will be notified when the zip is ready to be downloaded from the <a href="#!/my-discover/downloads" target="_blank">download center</a>.
                </p>
                <b>Include: </b>
                <FieldCheckbox
                    help="These are the Sales order attachments"
                    label="Attachments"
                    model={[this.data.photos, 'include_attachments']}
                    disabled={!this.data.photos.include_item_photos}
                />
                <FieldCheckbox
                    help="Product photos of the items in the sales order"
                    label="Product photos"
                    model={[this.data.photos, 'include_item_photos']}
                    disabled={!this.data.photos.include_attachments}
                />

                {!context.data.photos_is_zipping && <Button
                    disabled={context.data.photos_is_zipping}
                    icon="download"
                    loading={context.data.photos_is_zipping}
                    text="Queue download"
                    type="info"
                    onclick={async() => {
                        context.data.photos_is_zipping = true

                        await api.post<any>(`discover/sales-orders/${context.data.root_artkey}/queue-photo-download`, {
                            include_attachments: this.data.photos.include_attachments,
                            include_item_photos: this.data.photos.include_item_photos,
                        }, true)
                        notifier.notify('Successfully queued the sales order photos for download', 'success')

                        context.data.photos_is_zipping = false
                        context.data.dialog.download_photos = false
                    }}
                />}
            </Dialog>}

            <ManageCollectionSellable />
            <ManageCollection attachment_helper={vnode.attrs.attachment_helper}/>

            {/* No need to pass the collection, because collection navigation
                doesn't work the way we swap out collections when adding an
                item
            */}
            <PanelContext collection={collection_soi} context={context}>
                <div className="content manage-panel-context">
                    {context.data.entity_type === 'data_card' && <ManageDataCard
                        sales_order={context.data.sales_order}
                        buy_from_account_purchase_orders_response={context.data.buy_from_account.purchase_orders}
                        buy_from_account_sales_orders_response={context.data.buy_from_account.sales_orders}
                    />}

                    {context.meta_entity_active() &&
                    <Tabs
                        className="c-view-context-tabs"
                        data={context.data}
                    >
                        <Tab
                            disabled={context.data.entity_artkey && !['SOBI', 'SOI'].includes(context.data.entity_type)}
                            icon={context.data.entity_artkey && ['SOBI', 'SOI'].includes(context.data.entity_type) ? 'edit' : 'sales_order_item'}
                            link={this.entity_route('SOI')}
                            onactivate={() => this.set_entity('SOI')}
                            route={`/sales/orders/${context.data.root_artkey}/view/manage/SOI{/:entity_artkey}`}
                            tabindex={11}
                            text="Stock"
                        >
                            <TabPanel>
                                <ManageStockItem />
                            </TabPanel>
                        </Tab>

                        <Tab
                            disabled={context.data.entity_artkey && context.data.entity_type !== 'SOTI'}
                            icon={context.data.entity_artkey && context.data.entity_type === 'SOTI' ? 'edit' : 'tbo'}
                            link={this.entity_route('SOTI')}
                            onactivate={() => this.set_entity('SOTI')}
                            route={`/sales/orders/${context.data.root_artkey}/view/manage/SOTI{/:entity_artkey}`}
                            tabindex={18}
                            text="TBO"
                        >
                            <TabPanel>
                                <ManageTboItem />
                            </TabPanel>
                        </Tab>

                        <Tab
                            disabled={context.data.entity_artkey && context.data.entity_type !== 'SOCI'}
                            link={this.entity_route('SOCI')}
                            icon={context.data.entity_artkey && context.data.entity_type === 'SOCI' ? 'edit' : 'credit_item'}
                            onactivate={() => this.set_entity('SOCI')}
                            route={`/sales/orders/${context.data.root_artkey}/view/manage/SOCI{/:entity_artkey}`}
                            tabindex={23}
                            text="Credit"
                        >
                            <TabPanel>
                                <ManageCreditItem />
                            </TabPanel>
                        </Tab>
                        <Tab
                            disabled={context.data.entity_artkey && context.data.entity_type !== 'VOUCHER'}
                            link={this.entity_route('VOUCHER')}
                            icon={context.data.entity_artkey && context.data.entity_type === 'VOUCHER' ? 'edit' : 'voucher'}
                            onactivate={() => this.set_entity('VOUCHER')}
                            route={`/sales/orders/${context.data.root_artkey}/view/manage/VOUCHER{/:entity_artkey}`}
                            tabindex={27}
                            text="Voucher"
                        >
                            <TabPanel>
                                <ManageVoucher />
                            </TabPanel>
                        </Tab>
                        <Tab
                            disabled={context.data.entity_artkey && context.data.entity_type !== 'SOA'}
                            link={this.entity_route('SOA')}
                            icon={context.data.entity_artkey && context.data.entity_type === 'SOA' ? 'edit' : 'additional'}
                            onactivate={() => this.set_entity('SOA')}
                            route={`/sales/orders/${context.data.root_artkey}/view/manage/SOA{/:entity_artkey}`}
                            tabindex={30}
                            text="Misc"
                        >
                            <TabPanel>
                                <ManageAdditional />
                            </TabPanel>
                        </Tab>
                    </Tabs>}
                </div>
            </PanelContext>
        </div>
    }
}
