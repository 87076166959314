import m from 'mithril'
import {classes} from '@bitstillery/common/lib/utils'
import {unique_id} from '@bitstillery/common/lib/utils'
import {Button, Icon} from '@bitstillery/common/components'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {proxy} from '@bitstillery/common/lib/proxy'
import {modelref_adapter, modelref_assign} from '@bitstillery/common/lib/store'
import {FieldTextAttrs} from '@bitstillery/common/types/field'
import {logger} from '@bitstillery/common/app'
// Navigation keys are keys that change the selection of the suggestion dropdown.
const NAVIGATION_KEYS = ['ArrowDown', 'ArrowUp', 'PageDown', 'PageUp']

export class FieldText extends MithrilTsxComponent<FieldTextAttrs> {

    $suggestion_container: HTMLElement | null
    data = proxy({
        focussed: false,
        password: {
            hide: true,
        },
        search: {
            options: [],
            selected: null,
        },
    })

    name = unique_id()

    async oncreate(vnode) {
        if (!vnode.dom) return

        if (vnode.attrs.autofocus) {
            setTimeout(() => vnode.dom.focus(), 100)
        }
        if (vnode.attrs.search && vnode.dom) {
            this.$suggestion_container = vnode.dom.querySelector('.search-suggestions') as HTMLElement
        }
    }

    format_value(vnode, value) {
        if (vnode.attrs.type === 'date') {
            // The parsed value is formatted as "yyyy-mm-dd"
            // The displayed date format will differ from the actual value.
            if (!value) return null
        }

        if (vnode.attrs.type === 'number') {
            // If the value is empty, return null.
            if (value === '' || value === null) {
                return null
            }

            value = Number(value)
            if (isNaN((value))) {
                return null
            }

            // Format decimals if specified
            if (typeof vnode.attrs.decimals === 'number') {
                value = Number(value.toFixed(vnode.attrs.decimals))
            }

            // Make sure no values outside the expected boundaries are applied to the model.
            // Do not apply min, otherwise the min is applied while a user is typing.
            if (typeof vnode.attrs.max === 'number' && value > vnode.attrs.max) {
                return vnode.attrs.max
            }
        }

        return value
    }

    navigate_keyboard(vnode, e) {
        if (!vnode.attrs.search) {
            return
        }
        if (e.key === 'Backspace') {
            // Backspace deselects any selected suggestion
            this.data.search.selected = null
            return
        } else if (e.key === 'Enter' || e.key === 'Tab') {
            if (vnode.attrs.search && vnode.attrs.search.options.length) {
                const {model_ref} = modelref_adapter(vnode.attrs.model)

                // Enter pressed while a suggestion is selected.
                if (this.data.search.selected !== null) {
                    let submit_value = vnode.attrs.search.options[this.data.search.selected] as any

                    // Update selection from the targeted suggestion.
                    modelref_assign(model_ref, submit_value.value)
                    this.onsuggestion(vnode, submit_value)
                }

                vnode.attrs.search.options.splice(0, vnode.attrs.search.options.length)
            }
            return
        }

        if (!vnode.attrs.search.options.length) {
            this.data.search.selected = null
            return
        }

        if (!NAVIGATION_KEYS.includes(e.key) || ['ArrowLeft', 'ArrowRight'].includes(e.key)) {
            return
        }

        if (this.data.search.selected === null) {
            // Navigating the cursor in the input has no influence on the selected suggestion.
            this.data.search.selected = 0
        } else {
            if (e.key === 'ArrowDown') {
                if (this.data.search.selected < vnode.attrs.search.options.length - 1) {
                    this.data.search.selected += 1
                }
            } else if (e.key === 'ArrowUp') {
                if (this.data.search.selected > 0) {
                    this.data.search.selected -= 1
                } else {
                    this.data.search.selected = 0
                }
            } else if (e.key === 'PageDown') {
                if (this.data.search.selected < vnode.attrs.search.options.length - 5) {
                    this.data.search.selected += 5
                } else {
                    this.data.search.selected = vnode.attrs.search.options.length - 1
                }
            } else if (e.key === 'PageUp') {
                if (this.data.search.selected > 4) {
                    this.data.search.selected -= 5
                } else {
                    this.data.search.selected = 0
                }
            }
        }

        // Apply the updated selection to the DOM; scroll to the selected option.
        let selected_option = this.$suggestion_container?.children[this.data.search.selected]
        if (!selected_option && this.$suggestion_container?.children.length) {
            selected_option = this.$suggestion_container.children[0]
        }

        if (selected_option) {
            this.$suggestion_container.scrollTo({
                top: selected_option.offsetTop - this.$suggestion_container.clientHeight / 2 + selected_option.clientHeight / 2,
                behavior: 'smooth',
            })
        }
    }

    onsuggestion(vnode, suggestion) {
        if (!vnode.attrs.search) return

        logger.debug(`[FieldText] exact suggestion match: ${suggestion.value}`)
        if (vnode.attrs.search.onsuggestion) {
            vnode.attrs.search.onsuggestion(suggestion)
        }
    }

    view(vnode: m.Vnode<FieldTextAttrs>) {
        if (!vnode.attrs.model[0]) return
        const {model_value, model_ref} = modelref_adapter(vnode.attrs.model)
        const validation = vnode.attrs.validation

        if (vnode.attrs.validation && model_value) {
            vnode.attrs.validation.dirty = true
        }

        // Add human readable description to the validation object.
        if (validation && vnode.attrs.label) {
            validation.description = vnode.attrs.label
        }

        const invalid = validation ? validation._invalid : false
        const disabled = vnode.attrs.disabled

        return <div id={`field-text-${this.name}`} className={classes('c-field-text', vnode.attrs.composed ? null : 'field', vnode.attrs.className, {
            disabled: disabled,
            invalid: validation && invalid && validation.dirty,
            valid: validation && !invalid && validation.dirty,
        })}>
            {vnode.attrs.label && (
                <label>{vnode.attrs.label}
                    {vnode.attrs.icon && <Icon name={vnode.attrs.icon}/>}
                    {vnode.attrs.validation && <span className="validation">{validation.label}</span>}
                </label>
            )}
            {(() => {
                if (vnode.attrs.search && vnode.attrs.search.linked) {
                    return [
                        <div className="search-linked">
                            <div className="linked-label">{model_value}</div>
                            <Button
                                class="c-button"
                                icon="close"
                                onclick={() => {
                                    vnode.attrs.search.options.length = 0
                                    modelref_assign(model_ref, '')
                                    if (vnode.attrs.search.onreset) {
                                        vnode.attrs.search.onreset()
                                    }
                                }}
                                tip="Clear selection"
                                type="info"
                            />
                        </div>,
                        (() => {
                            if (invalid && validation.dirty) {
                                return <div className="help validation">{typeof invalid.message === 'function' ? invalid.message() : invalid.message}</div>
                            } else if (vnode.attrs.help) {
                                return <div class="help">{vnode.attrs.help}</div>
                            }
                        })(),
                    ]
                }

                return <div className="input-wrapper">
                    <input
                        autocomplete={vnode.attrs.autocomplete}
                        autofocus={vnode.attrs.autofocus}
                        disabled={vnode.attrs.disabled}
                        id={vnode.attrs.id}
                        onkeydown={(e) => {
                            this.navigate_keyboard(vnode, e)
                            if (vnode.attrs.onkeydown) {
                                vnode.attrs.onkeydown(e)
                            }
                        }}
                        onkeypress={(e) => {
                            // Only handles submit on Enter key.
                            if (e.key !== 'Enter') return
                            const {model_value} = modelref_adapter(vnode.attrs.model)
                            if (vnode.attrs.onenter) {
                                vnode.attrs.onenter(e, model_value)
                            }
                        }}
                        onblur={(e) => {
                            let value = e.target.value

                            if (vnode.attrs.onfocusout) {
                                vnode.attrs.onfocusout(value)
                            }

                            // Allow the onclick event handler to be called, before its cleaned up.
                            setTimeout(() => this.data.focussed = false, 100)
                            if (vnode.attrs.search) return

                            modelref_assign(model_ref, value)

                        }}
                        onfocus={() => {
                            this.data.focussed = true
                        }}
                        oninput={async(e) => {
                            if (vnode.attrs.validation) {
                                vnode.attrs.validation.dirty = true
                            }

                            // type number/date are native formats for the input element.
                            // Other types may use a custom formatter.
                            let value = e.target.value
                            if (['date', 'number'].includes(vnode.attrs.type)) {
                                value = this.format_value(vnode, e.target.value)
                            } else if (vnode.attrs.formatter) {
                                value = vnode.attrs.formatter(value)
                            }

                            // A null formatted value just means that the value is invalid
                            // and must not be accepted as input.

                            if (value === null && !['number', 'date'].includes(vnode.attrs.type)) {
                                e.preventDefault()
                                return
                            }

                            modelref_assign(model_ref, value)

                            if (vnode.attrs.onafterupdate) {
                                vnode.attrs.onafterupdate(value)
                            }

                            if (vnode.attrs.oninput) {
                                vnode.attrs.oninput(value)
                            }
                        }}
                        placeholder={vnode.attrs.placeholder ? vnode.attrs.placeholder : ''}
                        min={'min' in vnode.attrs ? vnode.attrs.min : undefined}
                        max={'max' in vnode.attrs ? vnode.attrs.max : undefined}
                        step={(() => {
                            if (vnode.attrs.type === 'number' && typeof vnode.attrs.decimals === 'number') {
                                return `0.${'0'.repeat(vnode.attrs.decimals - 1)}1`
                            }
                            return vnode.attrs.step
                        })()}
                        tabindex={vnode.attrs.tabindex}
                        type={(() => {
                            if ('type' in vnode.attrs) {
                                if (vnode.attrs.type === 'password') {
                                    return this.data.password.hide ? 'password' : 'text'
                                }
                                if (vnode.attrs.type === 'currency') {
                                    return 'text'
                                }
                                return vnode.attrs.type
                            }
                            return 'text'
                        })()}
                        value={model_value}
                    />
                    {vnode.attrs.type === 'password' && <Icon name={this.data.password.hide ? 'eye' : 'eyeRemove'} onclick={() => {
                        this.data.password.hide = !this.data.password.hide
                    }}/>}
                    {vnode.children}

                    {!!(vnode.attrs.search) && <div
                        className={classes('search-suggestions', {
                            hidden: !this.data.focussed,
                        })}
                        onclick={(e) => {
                            const clicked_value = e.target.dataset.id
                            const suggestion_index = vnode.attrs.search.options.findIndex((i:any) => {
                                if (typeof i.value === 'string') {
                                    return i.value === clicked_value
                                }
                                return i.value === Number(clicked_value)
                            })
                            const suggestion = vnode.attrs.search.options[suggestion_index] as any
                            const {model_ref} = modelref_adapter(vnode.attrs.model)
                            modelref_assign(model_ref, suggestion.value)
                            this.onsuggestion(vnode, suggestion)
                            vnode.attrs.search.options.length = 0
                        }}
                        tabindex="-1"
                    >
                        {vnode.attrs.search.options.map((suggestion:any, index:number) => (
                            <div className={classes('suggestion', {
                                selected: index === this.data.search.selected,
                            })} data-id={suggestion.value}>{suggestion.label}</div>
                        ))}
                    </div>}

                    {(() => {
                        if (invalid && validation.dirty) {
                            return <div className="help validation">{typeof invalid.message === 'function' ? invalid.message() : invalid.message}</div>
                        } else if (vnode.attrs.help) {
                            return <div class="help">{vnode.attrs.help}</div>
                        }
                    })()}
                </div>
            })()}

        </div>
    }
}
