import {MithrilTsxComponent} from 'mithril-tsx-component'
import {classes} from '@bitstillery/common/lib/utils'
import m from 'mithril'
import {Icon} from '@bitstillery/common/components'

export interface MemoAttrs {
    alert: boolean
    className?: string
    model: any
}

/**
 * Shows the memo field of the supplier.
 */
export class Memo extends MithrilTsxComponent<MemoAttrs> {
    memo_collapsed = true

    view(vnode: m.Vnode<MemoAttrs>): m.Children {
        const multiline = vnode.attrs.model?.includes('\n')
        return <div className={classes('c-memo', vnode.attrs.className, {
            collapsed: this.memo_collapsed,
            multiline,
        })}>
            {vnode.attrs.model && <div className="memo info">
                <Icon
                    className="memo-icon"
                    onclick={() => {
                        if (multiline) {
                            this.memo_collapsed = !this.memo_collapsed
                        }
                    }}
                    name="info"
                    type="unset"
                />
                <div className="memo-content">
                    {(multiline && this.memo_collapsed) ? vnode.attrs.model : vnode.attrs.model.split('\n').map((line) => <div>{line}</div>)}
                </div>
            </div>}
            {vnode.attrs.alert && <div className="memo warning">
                <Icon
                    className="memo-icon"
                    onclick={() => {
                        if (multiline) {
                            this.memo_collapsed = !this.memo_collapsed
                        }
                    }}
                    name="info"
                    type="unset"
                />
                <div className="memo-content">{vnode.attrs.alert}</div>
            </div>}
        </div>
    }
}
