import m from 'mithril'
import {api} from '@bitstillery/common/app'
import {notifier} from '@bitstillery/common/app'
import {mount_dialog} from '@bitstillery/common/lib/dialog'
import {proxy} from '@bitstillery/common/lib/proxy'

import {CombinedOrderStatus} from '@/factserver_api/fact2server_api'
import {context, methods} from '@/sales/orders/view/lib/context'

const IMMUTABLE_STATUS = [CombinedOrderStatus.Invoiced, CombinedOrderStatus.Cancelled]

const dialog_model = proxy({
    loading: false,
    title: 'Change sales order',
    type: 'info',
})

function confirm_status_update(new_status: CombinedOrderStatus) {
    if (new_status === CombinedOrderStatus.Invoiced || new_status === CombinedOrderStatus.Confirmed) {
        context.data.dialog.confirm_settings = new_status
    } else {
        if (new_status === CombinedOrderStatus.Cancelled) {
            dialog_model.title = `Cancel sales order ${context.data.sales_order.reference}`
            dialog_model.type = 'danger'
        } else {
            dialog_model.title = `Change sales order ${context.data.sales_order.reference}`
            dialog_model.type = 'info'
        }
        const mounted = mount_dialog({
            confirm: {
                action: async() => {
                    dialog_model.loading = true
                    mounted.close()
                    const {result, success} = await api.post('sales.core.set_sales_order_status', {
                        sales_order_artkey: context.data.root_artkey,
                        status: new_status,
                    }, false) as any
                    if (!success) {
                        notifier.notify(`Failed to update status: ${result.message}`, 'warning')
                    } else {
                        context.data.stepper.active = new_status
                        notifier.notify(`Updated sales order to status: ${new_status}`, 'success')
                    }

                    await methods.fetch_sales_order_with_items(context.data.root_artkey)
                    await methods.fetch_buy_from_account_orders(context.data.root_artkey)
                    // TODO: Check this invoiced route.
                    if (new_status as CombinedOrderStatus === CombinedOrderStatus.Invoiced) {
                        m.route.set(`/sales/orders/${context.data.sales_order.artkey}/view/invoice`)
                    }
                    dialog_model.loading = false
                },
                icon: 'sales',
                text: 'Change status',
            },
            model: dialog_model,
            body: () => {
                return <p>
                    Are you sure you want to change Sales order {context.data.sales_order.reference} to status {new_status}?<br/>
                    {context.data.sales_order.is_part_of_buy_from_account && <span>
                        Note: This sales order is part of a buy from account.
                        This will not cancel the main sales order.
                    </span>}
                </p>
            },

        })
    }
}

function step_tip(step_status: CombinedOrderStatus) {
    const current_status = context.data.sales_order.combined_status

    const immutable_message = `An order with status "${status}" cannot be changed to status "${step_status}".`
    const current_status_message = `The order is already ${step_status}.`

    if (current_status === step_status) {
        return current_status_message
    }

    if (step_status === CombinedOrderStatus.Pending) {
        if (steps[CombinedOrderStatus.Pending].disabled()) {
            return immutable_message
        }

        return 'Setting the order to "pending" means that the customer can still update its order in the portal.'
    }

    if (step_status === CombinedOrderStatus.Saved) {
        if (steps[CombinedOrderStatus.Saved].disabled()) {
            return immutable_message
        }

        return 'Setting the order to "saved" means it was created by a trader for a customer; e.g. not through the portal.'
    }
    if (step_status === CombinedOrderStatus.Finalized) {
        if (steps[CombinedOrderStatus.Finalized].disabled()) {
            if (context.data.sales_order.pre_advice_reference && !(context.data.sales_order.pre_advice_cancelled_on || context.data.sales_order.pre_advice_confirmed_on)) {
                return 'The order already has a Pre-advice and cannot be changed; cancel the Pre-advice before proceeding.'
            }

            return immutable_message
        }

        return 'Setting the order to "finalized" means that the customer finished their cart in the portal; verify the order details before confirming the order.'
    }
    if (step_status === CombinedOrderStatus.Confirmed) {
        if (steps[CombinedOrderStatus.Confirmed].disabled()) {
            if (context.data.sales_order.number_of_cases_in_basket_items > 0) {
                return 'There are still unconfirmed portal basket item(s). Please convert to sales-order-item or remove these items before confirming the order.'
            }

            return immutable_message
        }

        return 'Setting the order to "confirmed" means all details have been communicated with the customer; the order is ready to be processed.'
    }
    if (step_status === CombinedOrderStatus.Invoiced) {
        if (steps[CombinedOrderStatus.Invoiced].disabled()) {
            if (current_status !== CombinedOrderStatus.Confirmed) {
                return 'Only orders with status "confirmed" can be invoiced.'
            }
            if (!context.data.sales_order.all_items_in_stock) {
                return 'Cannot invoice this order; not all items are in stock.'
            }
            if (!context.data.sales_order.all_tbo_items_are_confirmed) {
                return 'Cannot invoice this order; not all TBO items are confirmed by purchasing.'
            }

            return immutable_message
        }

        return 'Settings the order to "invoiced" means that the order has been invoiced and the customer has paid. This action cannot be undone!'
    }
    if (step_status === CombinedOrderStatus.Cancelled) {
        if (steps[CombinedOrderStatus.Cancelled].disabled()) {
            return immutable_message
        }

        return 'Setting the order to "cancelled" will cancel the order and all items in the order. This action cannot be undone!'
    }

}

export const steps = {
    [CombinedOrderStatus.Pending]: {
        completed: () => {
            return context.data.sales_order && [
                CombinedOrderStatus.Finalized,
                CombinedOrderStatus.Confirmed,
                CombinedOrderStatus.Invoiced,
            ].includes(context.data.sales_order.combined_status)
        },
        disabled: () => IMMUTABLE_STATUS.includes(context.data.sales_order.combined_status),
        id: CombinedOrderStatus.Pending,
        onclick: () => {
            confirm_status_update(CombinedOrderStatus.Pending)
        },
        text: 'Pending',
        tip: () => step_tip(CombinedOrderStatus.Pending),
    },
    [CombinedOrderStatus.Finalized]: {
        completed: () => {
            return context.data.sales_order && [
                CombinedOrderStatus.Confirmed,
                CombinedOrderStatus.Invoiced,
            ].includes(context.data.sales_order.combined_status)
        },
        disabled: () => (
            IMMUTABLE_STATUS.includes(context.data.sales_order.combined_status) ||
            context.data.sales_order.pre_advice_reference && !(context.data.sales_order.pre_advice_cancelled_on || context.data.sales_order.pre_advice_confirmed_on)
        ),
        id: CombinedOrderStatus.Finalized,
        onclick: () => {
            confirm_status_update(CombinedOrderStatus.Finalized)
        },
        text: 'Finalized',
        tip: () => step_tip(CombinedOrderStatus.Finalized),
    },
    [CombinedOrderStatus.Saved]: {
        completed: () => {
            return context.data.sales_order && [
                CombinedOrderStatus.Pending,
                CombinedOrderStatus.Finalized,
                CombinedOrderStatus.Confirmed,
                CombinedOrderStatus.Invoiced,
            ].includes(context.data.sales_order.combined_status)
        },
        disabled: () => IMMUTABLE_STATUS.includes(context.data.sales_order.combined_status),
        id: CombinedOrderStatus.Saved,
        onclick: () => {
            confirm_status_update(CombinedOrderStatus.Saved)
        },
        text: 'Saved',
        tip: () => step_tip(CombinedOrderStatus.Saved),
    },
    [CombinedOrderStatus.Confirmed]: {
        completed: () => {
            return context.data.sales_order && [
                CombinedOrderStatus.Invoiced,
            ].includes(context.data.sales_order.combined_status)
        },
        disabled: () => (
            IMMUTABLE_STATUS.includes(context.data.sales_order.combined_status) ||
            context.data.sales_order.number_of_cases_in_basket_items > 0 ||
            ![CombinedOrderStatus.Saved, CombinedOrderStatus.Finalized].includes(context.data.sales_order.combined_status)
        ),
        id: CombinedOrderStatus.Confirmed,
        onclick: () => {
            confirm_status_update(CombinedOrderStatus.Confirmed)
        },
        text: 'Confirmed',
        tip: () => step_tip(CombinedOrderStatus.Confirmed),
    },
    [CombinedOrderStatus.Invoiced]: {
        completed: () => {
            return context.data.sales_order && [
                CombinedOrderStatus.Invoiced,
            ].includes(context.data.sales_order.combined_status)
        },
        disabled: () => (
            IMMUTABLE_STATUS.includes(context.data.sales_order.combined_status) ||
            !context.data.sales_order.all_items_in_stock ||
            !context.data.sales_order.all_tbo_items_are_confirmed ||
            context.data.sales_order.combined_status !== CombinedOrderStatus.Confirmed
        ),
        id: CombinedOrderStatus.Invoiced,
        onclick: () => {
            confirm_status_update(CombinedOrderStatus.Invoiced)
        },
        text: 'Invoiced',
        tip: () => step_tip(CombinedOrderStatus.Invoiced),
    },
    [CombinedOrderStatus.Cancelled]: {
        completed: () => {
            return context.data.sales_order && context.data.sales_order.combined_status === CombinedOrderStatus.Cancelled
        },
        disabled: () => IMMUTABLE_STATUS.includes(context.data.sales_order.combined_status),
        id: CombinedOrderStatus.Cancelled,
        onclick: () => {
            confirm_status_update(CombinedOrderStatus.Cancelled)
        },
        text: 'Cancel',
        type: 'danger',
        tip: () => step_tip(CombinedOrderStatus.Cancelled),
    },
}
