import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {format_iso_to_date} from '@bitstillery/common/lib/format'
import {FieldText, Icon} from '@bitstillery/common/components'
import {Observable} from 'rxjs'

import {
    SearchApi,
    SearchAvailableItemsResponse,
    SearchContactPersonsResponse,
    SearchProductsResponse,
    SearchPurchaseOrdersResponse,
    SearchRelationsResponse,
    SearchResult,
    SearchSalesOrdersResponse,
} from '@/factserver_api/search_api'
import {Link} from '@/components/discover'

interface SearchResultAttrs<T> {
    results: T[]
    title: string

    has_searched: boolean
    is_loading: boolean
    count: number

    div_for_T: (the_t: T) => JSX.Element
}

class SearchResultView<T> extends MithrilTsxComponent<SearchResultAttrs<T>> {
    is_collapsed = false

    construct_title(vnode: m.Vnode<SearchResultAttrs<T>>): string {
        if (vnode.attrs.has_searched && vnode.attrs.results.length === 0) {
            return `${vnode.attrs.title} (no results)`
        } else if (vnode.attrs.has_searched) {
            return `${vnode.attrs.title} (${vnode.attrs.results.length}/${vnode.attrs.count})`
        }
        return vnode.attrs.title
    }

    view(vnode: m.Vnode<SearchResultAttrs<T>>): m.Children {
        if (vnode.attrs.results.length > 0) {
            return (
                <div className="search-category">
                    <div className="title">
                        {this.construct_title(vnode)}
                    </div>
                    <div className="items">
                        {!vnode.attrs.has_searched && vnode.attrs.results.length === 0 && <div>Not searched yet</div>}
                        {vnode.attrs.results.map((result: T) => vnode.attrs.div_for_T(result))}
                    </div>
                </div>
            )
        }
        return null
    }
}

interface SearchResultsAttrs {
    search_terms: string
    on_clicked_on_result: () => unknown
    on_search_done: () => unknown
}

class SearchResultsHelper<T> {
    is_loading = false
    has_searched = false
    count = 0

    search_results: T[] = []
    search_terms = ''
    on_search_done: () => unknown

    fetch_callback: (search_terms: string) => Observable<SearchResult<T>>

    constructor(
        vnode: m.Vnode<SearchResultsAttrs>,
        fetch_callback: (search_terms: string) => Observable<SearchResult<T>>,
    ) {
        this.fetch_callback = fetch_callback
        this.on_search_done = vnode.attrs.on_search_done
    }

    fetch(): void {
        if (!this.search_terms) {
            return
        }
        this.fetch_callback(this.search_terms).subscribe({
            next: (response: SearchResult<T>) => {
                this.search_results = response.search_results
                this.count = response.count
                this.is_loading = false
                this.has_searched = true
                this.on_search_done()
                m.redraw()
            },
            error: () => {
                this.has_searched = true
                this.is_loading = false
                this.on_search_done()
                m.redraw()
            },
        })
    }

    oncreate(vnode: m.Vnode<SearchResultsAttrs>): void {
        this.search_terms = vnode.attrs.search_terms
        this.fetch()
    }

    onupdate(vnode: m.Vnode<SearchResultsAttrs>): void {
        if (this.search_terms !== vnode.attrs.search_terms) {
            this.search_terms = vnode.attrs.search_terms
            if (this.search_terms) {
                this.search_results = []
            }
            this.fetch()
        }
    }

}

class SalesOrderSearchResults extends MithrilTsxComponent<SearchResultsAttrs> {
    search_api = new SearchApi()
    helper: SearchResultsHelper<SearchSalesOrdersResponse>

    constructor(vnode: m.Vnode<SearchResultsAttrs>) {
        super()
        this.helper = new SearchResultsHelper<SearchSalesOrdersResponse>(vnode, (search_terms: string) =>
            this.search_api.search_sales_orders(search_terms),
        )
    }

    oncreate(vnode: m.Vnode<SearchResultsAttrs>) {
        this.helper.oncreate(vnode)
    }

    onupdate(vnode: m.VnodeDOM<SearchResultsAttrs>): void {
        this.helper.onupdate(vnode)
    }

    view(vnode: m.Vnode<SearchResultsAttrs>): m.Children {
        return (
            <SearchResultView<SearchSalesOrdersResponse>
                title={'Sales orders'}
                is_loading={this.helper.is_loading}
                has_searched={this.helper.has_searched}
                results={this.helper.search_results}
                count={this.helper.count}
                div_for_T={(sales_order: SearchSalesOrdersResponse) => (
                    <Link
                        onclick={vnode.attrs.on_clicked_on_result}
                        target={'_self'}
                        href={`/sales/orders/${sales_order.artkey}/view/manage`}
                        className="search-item"
                    >
                        <span className="flex-1">{sales_order.reference}</span>
                        <span className="flex-5 overflow">{sales_order.relation_name}</span>
                        <span className="flex-1">{sales_order.combined_status}</span>
                        <span className="flex-1">{format_iso_to_date(sales_order.was_last_updated_on)}</span>
                    </Link>
                )}
            />
        )
    }
}

class PurchaseOrderSearchResults extends MithrilTsxComponent<SearchResultsAttrs> {
    search_api = new SearchApi()
    helper: SearchResultsHelper<SearchPurchaseOrdersResponse>

    constructor(vnode: m.Vnode<SearchResultsAttrs>) {
        super()
        this.helper = new SearchResultsHelper<SearchPurchaseOrdersResponse>(vnode, (search_terms: string) =>
            this.search_api.search_purchase_orders(search_terms),
        )
    }

    oncreate(vnode: m.Vnode<SearchResultsAttrs>) {
        this.helper.oncreate(vnode)
    }

    onupdate(vnode: m.VnodeDOM<SearchResultsAttrs>): void {
        this.helper.onupdate(vnode)
    }

    view(vnode: m.Vnode<SearchResultsAttrs>): m.Children {
        return (
            <SearchResultView<SearchPurchaseOrdersResponse>
                title={'Purchase orders'}
                is_loading={this.helper.is_loading}
                has_searched={this.helper.has_searched}
                results={this.helper.search_results}
                count={this.helper.count}
                div_for_T={(purchase_order: SearchPurchaseOrdersResponse) => (
                    <Link
                        onclick={vnode.attrs.on_clicked_on_result}
                        target={'_self'}
                        href={`/purchase-orders/manage/${purchase_order.artkey}`}
                        className="search-item"
                    >
                        <span className="flex-1">{purchase_order.reference}</span>
                        <span className="flex-5">{purchase_order.relation_name}</span>
                        <span className="flex-2">{purchase_order.status}</span>
                        <span className="flex-1">{format_iso_to_date(purchase_order.was_last_updated_on)}</span>
                    </Link>
                )}
            />
        )
    }
}

class RelationSearchResults extends MithrilTsxComponent<SearchResultsAttrs> {
    search_api = new SearchApi()
    helper: SearchResultsHelper<SearchRelationsResponse>

    constructor(vnode: m.Vnode<SearchResultsAttrs>) {
        super()
        this.helper = new SearchResultsHelper<SearchRelationsResponse>(vnode, (search_terms: string) =>
            this.search_api.search_relations(search_terms),
        )
    }

    oncreate(vnode: m.Vnode<SearchResultsAttrs>) {
        this.helper.oncreate(vnode)
    }

    onupdate(vnode: m.VnodeDOM<SearchResultsAttrs>): void {
        this.helper.onupdate(vnode)
    }

    view(vnode: m.Vnode<SearchResultsAttrs>): m.Children {
        return (
            <SearchResultView<SearchPurchaseOrdersResponse>
                title={'Relations'}
                is_loading={this.helper.is_loading}
                has_searched={this.helper.has_searched}
                results={this.helper.search_results}
                count={this.helper.count}
                div_for_T={(relation: SearchRelationsResponse) => (
                    <Link
                        onclick={vnode.attrs.on_clicked_on_result}
                        target={'_self'}
                        href={`/crm/relations/${relation.artkey}/edit`}
                        className="search-item"
                    >
                        <span className="flex-5">{relation.name}</span>
                        <span className="flex-1">{relation.country_code}</span>
                        <span className="flex-3">{relation.sales_manager_name}</span>
                    </Link>
                )}
            />
        )
    }
}

class ContactPersonSearchResults extends MithrilTsxComponent<SearchResultsAttrs> {
    search_api = new SearchApi()
    helper: SearchResultsHelper<SearchContactPersonsResponse>

    constructor(vnode: m.Vnode<SearchResultsAttrs>) {
        super()
        this.helper = new SearchResultsHelper<SearchContactPersonsResponse>(vnode, (search_terms: string) =>
            this.search_api.search_contact_persons(search_terms),
        )
    }

    oncreate(vnode: m.Vnode<SearchResultsAttrs>) {
        this.helper.oncreate(vnode)
    }

    onupdate(vnode: m.VnodeDOM<SearchResultsAttrs>): void {
        this.helper.onupdate(vnode)
    }

    view(vnode: m.Vnode<SearchResultsAttrs>): m.Children {
        return (
            <SearchResultView<SearchContactPersonsResponse>
                title={'Contact persons'}
                is_loading={this.helper.is_loading}
                has_searched={this.helper.has_searched}
                results={this.helper.search_results}
                count={this.helper.count}
                div_for_T={(contact_person: SearchContactPersonsResponse) => (
                    <div className="search-item" onclick={vnode.attrs.on_clicked_on_result}>
                        <Link
                            className="flex-2"
                            target={'_self'}
                            href={`/crm/relations/${contact_person.relation_artkey}/contact-persons/${contact_person.artkey}/edit`}
                        >
                            {contact_person.name}
                        </Link>
                        <Link className="flex-4" target={'_self'} href={`/crm/relations/${contact_person.relation_artkey}/edit`}>
                            {contact_person.relation_name}
                        </Link>
                    </div>
                )}
            />
        )
    }
}

class AvailableItemsSearchResults extends MithrilTsxComponent<SearchResultsAttrs> {
    search_api = new SearchApi()
    helper: SearchResultsHelper<SearchAvailableItemsResponse>

    constructor(vnode: m.Vnode<SearchResultsAttrs>) {
        super()
        this.helper = new SearchResultsHelper<SearchAvailableItemsResponse>(vnode, (search_terms: string) =>
            this.search_api.search_available_items(search_terms),
        )
    }

    oncreate(vnode: m.Vnode<SearchResultsAttrs>) {
        this.helper.oncreate(vnode)
    }

    onupdate(vnode: m.VnodeDOM<SearchResultsAttrs>): void {
        this.helper.onupdate(vnode)
    }

    view(vnode: m.Vnode<SearchResultsAttrs>): m.Children {
        return (
            <SearchResultView<SearchAvailableItemsResponse>
                title={'Available items'}
                is_loading={this.helper.is_loading}
                has_searched={this.helper.has_searched}
                results={this.helper.search_results}
                count={this.helper.count}
                div_for_T={(item: SearchAvailableItemsResponse) => (
                    <Link
                        className="search-item"
                        onclick={vnode.attrs.on_clicked_on_result}
                        target={'_self'}
                        href={`/stock/manage/${item.reference}`}
                    >
                        <span className="flex-1">{item.reference}</span>
                        <span className="flex-2">{item.lot}</span>
                        <span className="flex-5">{item.product_name}</span>
                        <span className="flex-2">{`${item.number_of_cases_available} cases`}</span>
                    </Link>
                )}
            />
        )
    }
}

class ProductsSearchResults extends MithrilTsxComponent<SearchResultsAttrs> {
    search_api = new SearchApi()
    helper: SearchResultsHelper<SearchProductsResponse>

    constructor(vnode: m.Vnode<SearchResultsAttrs>) {
        super()
        this.helper = new SearchResultsHelper<SearchProductsResponse>(vnode, (search_terms: string) =>
            this.search_api.search_products(search_terms),
        )
    }

    oncreate(vnode: m.Vnode<SearchResultsAttrs>) {
        this.helper.oncreate(vnode)
    }

    onupdate(vnode: m.VnodeDOM<SearchResultsAttrs>): void {
        this.helper.onupdate(vnode)
    }

    view(vnode: m.Vnode<SearchResultsAttrs>): m.Children {
        return (
            <SearchResultView<SearchProductsResponse>
                title={'Products'}
                is_loading={this.helper.is_loading}
                has_searched={this.helper.has_searched}
                results={this.helper.search_results}
                count={this.helper.count}
                div_for_T={(product: SearchProductsResponse) => (
                    <Link
                        className="search-item"
                        onclick={vnode.attrs.on_clicked_on_result}
                        target={'_self'}
                        href={`/data/products/${product.artkey}`}
                    >
                        {`${product.name}`}
                    </Link>
                )}
            />
        )
    }
}

export class Search extends MithrilTsxComponent<unknown> {
    is_collapsed = true
    is_showing_results = false
    search_terms = ''
    number_of_searches_done = 0

    clear_search_text_and_collapse(): void {
        this.is_collapsed = true
        this.is_showing_results = false
        this.search_terms = ''
    }

    register_search_done(): void {
        this.number_of_searches_done += 1
        if (this.number_of_searches_done >= 2) {
            this.number_of_searches_done = 0
        }
    }

    start_search(val: string): void {
        if (!val) {
            this.clear_search_text_and_collapse()
            return
        }
        this.search_terms = val
        this.is_showing_results = this.search_terms.length > 2
    }

    view(): m.Children {
        return (
            <div className="c-search-discover">
                <div className={'search-bar btn-group'}>
                    <FieldText
                        composed={true}
                        placeholder="Search in Discover..."
                        model={[this, 'search_terms']}
                        onkeydown={(e) => {
                            if (e.key === 'Escape') {
                                this.clear_search_text_and_collapse()
                            }
                        }}
                        oninput={(val: string) => this.start_search(val)}
                    />
                    <Icon
                        className="btn-search"
                        disabled={!this.search_terms}
                        name="search"
                    />
                    <Icon
                        className="btn-clear"
                        disabled={!this.search_terms}
                        name="backspace"
                        onclick={() => this.clear_search_text_and_collapse()}
                        tip={'Remove Search Term'}
                        variant="icon"
                    />

                </div>
                {this.is_showing_results && (
                    <div className={'autocomplete-suggestions'}>
                        <SalesOrderSearchResults
                            search_terms={this.search_terms}
                            on_clicked_on_result={() => this.clear_search_text_and_collapse()}
                            on_search_done={() => this.register_search_done()}
                        />
                        <PurchaseOrderSearchResults
                            search_terms={this.search_terms}
                            on_clicked_on_result={() => this.clear_search_text_and_collapse()}
                            on_search_done={() => this.register_search_done()}
                        />
                        <RelationSearchResults
                            search_terms={this.search_terms}
                            on_clicked_on_result={() => this.clear_search_text_and_collapse()}
                            on_search_done={() => this.register_search_done()}
                        />
                        <ContactPersonSearchResults
                            search_terms={this.search_terms}
                            on_clicked_on_result={() => this.clear_search_text_and_collapse()}
                            on_search_done={() => this.register_search_done()}
                        />
                        <AvailableItemsSearchResults
                            search_terms={this.search_terms}
                            on_clicked_on_result={() => this.clear_search_text_and_collapse()}
                            on_search_done={() => this.register_search_done()}
                        />
                        <ProductsSearchResults
                            search_terms={this.search_terms}
                            on_clicked_on_result={() => this.clear_search_text_and_collapse()}
                            on_search_done={() => this.register_search_done()}
                        />
                    </div>
                )}
            </div>
        )
    }
}
