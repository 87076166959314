import m from 'mithril'
import {format_iso_to_date} from '@bitstillery/common/lib/format'
import {AmountUnit, Button, Icon, Spinner} from '@bitstillery/common/components'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {to_specs} from '@bitstillery/common/lib/specs'
import {classes} from '@bitstillery/common/lib/utils'
import {current_account_slug} from '@bitstillery/common/account/account'
import {ContextProvider} from '@bitstillery/common/lib/context'

import {CaseInfoPanelAttrs} from './case_info_panel'

import {accountIconBySlug} from '@/accounts'
import {pricelist_link} from '@/data/components/pricelist_link'
import {$m, $s} from '@/app'
import {MarketAnalysisApi, SupplierPriceListItem} from '@/factserver_api/marketanalysis_api'
import {GetCustomTboOfferItemsByCaseOfferItem, OfferApi} from '@/factserver_api/offer_api'
import {PurchaseApi} from '@/factserver_api/purchase_api'
import {Pager} from '@/components/pager/pager'

export type BottleMarketAttrs = Pick<
    CaseInfoPanelAttrs,
    | 'bottle_artkey'
    | 'supplier_name'
    | 'specs'
    | 'ignore_ref'
    | 'current_supplier_artkey'
    | 'case_artkey'
    | 'case_customs_status'
    | 'preferred_competitors'
    | 'supplier_price_list_artkey'
    | 'exclude_competitors'
    | 'add_to_order'
    | 'add_custom_offer_item_to_purchase_order'
    /*
    Optionally provide a custom offer item for analysis purposes. This
    custom offer item is rendered in the Market panel table between
    other the price list items.
     */
    | 'custom_offer_item'
    > & {
    with_pagination?: boolean
    page_size?: number
    /** The context in which is the bottle market is used */
    context?: typeof ContextProvider
    /** The function to reset the context when the add to order button is clicked (again)*/
    context_reset?: () => void
    current_supplier_price_list_artkey?: number
    /** The entity to pick from the context */
    entity_type?: any
    fetch_info?: (number_of_items: number) => unknown
}

export class BottleMarket extends MithrilTsxComponent<BottleMarketAttrs> {
    market_analysis_api = new MarketAnalysisApi()
    offer_api = new OfferApi()
    loading_market = false
    loading_offers = false
    supplier_price_list_items: SupplierPriceListItem[] = []
    custom_offer_items: GetCustomTboOfferItemsByCaseOfferItem[] = []
    attrs: BottleMarketAttrs
    page?: number
    page_size?: number
    fetch_info?: (number_of_items: number) => unknown

    constructor(vnode: m.Vnode<BottleMarketAttrs>) {
        super()
        if (vnode.attrs.with_pagination) {
            this.page = 1
            this.page_size = vnode.attrs.page_size || 10
        }
        this.attrs = vnode.attrs
        if (this.attrs.bottle_artkey || this.attrs.specs?.product_name) {
            this.query_market()
        } else {
            // eslint-disable-next-line no-console
            console.error('Error: Bottle artkey or product name are mandatory for BottleMarket panel.')
        }
        if (this.attrs.case_artkey) {
            this.query_offers(this.attrs.case_artkey)
        }
        this.fetch_info = vnode.attrs.fetch_info
    }

    query_market(): void {
        const data = {
            bottle_artkey: this.attrs.bottle_artkey,
            specs: this.attrs.specs,
            ignore_ref: this.attrs.ignore_ref,
            supplier_artkey: this.attrs.current_supplier_artkey,
            supplier_name: this.attrs.supplier_name,
            preferred_competitors: this.attrs.preferred_competitors,
            supplier_price_list_artkey: this.attrs.supplier_price_list_artkey,
            exclude_company_type: this.attrs.exclude_competitors ? ['Competitor'] : [],
        }
        this.loading_market = true
        this.market_analysis_api.get_market_by_bottle(data).subscribe({
            next: (response: SupplierPriceListItem[]) => {
                this.supplier_price_list_items = response
                this.loading_market = false
                if (this.fetch_info) {
                    this.fetch_info(response.length)
                }
                m.redraw()
            },
        })
    }

    query_offers(case_artkey: number): void {
        const data = {
            case_artkey: case_artkey,
        }
        this.loading_offers = true
        this.offer_api.get_custom_tbo_offer_items_by_case(data).subscribe({
            next: (response: GetCustomTboOfferItemsByCaseOfferItem[]) => {
                this.custom_offer_items = response
                this.loading_offers = false
                m.redraw()
            },
        })
    }

    get is_loading(): boolean {
        return this.loading_market || this.loading_offers
    }

    get all_price_list_items(): Array<GetCustomTboOfferItemsByCaseOfferItem | SupplierPriceListItem> {
        return [...this.custom_offer_items, ...this.supplier_price_list_items]
    }

    get paged_supplier_price_list_items(): Array<GetCustomTboOfferItemsByCaseOfferItem | SupplierPriceListItem> {
        if (!this.page || !this.page_size) {
            return this.all_price_list_items
        }
        return this.all_price_list_items.slice((this.page - 1) * this.page_size, this.page * this.page_size)
    }

    view(vnode:m.Vnode<BottleMarketAttrs>): m.Children {
        let msi_offer_item: any

        // Compute the index where to show the custom offer item.
        let msi_offer_item_idx = this.supplier_price_list_items.length
        if (this.attrs.custom_offer_item) {
            msi_offer_item = this.attrs.custom_offer_item()
            for (let i = 0; i < this.supplier_price_list_items.length; i++) {
                const spli = this.supplier_price_list_items[i]
                if (msi_offer_item.euro_price_per_bottle() <= spli.price_per_bottle) {
                    msi_offer_item_idx = i
                    break
                }
            }
        }

        return <div className="c-bottle-market collection-widget">
            <div className="header">
                <div className="title">
                    <Icon name="bottle" type="info" />
                    <span>Bottle Market{this.attrs.specs?.product_name && <span> for {this.attrs.specs.product_name}</span>}</span>
                </div>

                {this.page && this.page_size && this.supplier_price_list_items.length !== 0 && <Pager
                    fetch_page={(page_index: number) => {this.page = page_index}}
                    count={this.supplier_price_list_items.length}
                    page_size={this.page_size}
                    display_page_count={false}
                />}

                <div className="legenda">
                    <Icon className="is-current-supplier-price-list" name="square" tip="Current supplier pricelist"/>
                    <Icon className="is-msp" name="square" tip="Moving Spirits"/>
                    <Icon className="is-competitor" name="square" tip="Competitor"/>
                    <Icon className="is-agent-importer-nl" name="square" tip="Agent importer NL"/>
                </div>
            </div>
            <div className="content">
                {this.supplier_price_list_items.length === 0 && <div class="placeholder">
                    {this.is_loading ? <Spinner className="table-spinner" /> : 'Not on active pricelists or offers.'}
                </div>}
                {this.supplier_price_list_items.length !== 0 && [
                    <div className="c-collection-header">
                        <div className="c-collection-columns c-collection-items has-row-status">
                            <div className="item">
                                <div className="cells" style="grid-template-columns: 1fr 1fr 1fr 1fr var(--coll-status-width);">
                                    <div className="cell cell-type-default">Supplier</div>
                                    <div className="cell cell-type-default">Price</div>
                                    <div className="cell cell-type-default">Incoterm</div>
                                    <div className="cell cell-type-default">List start</div>
                                    <div className="cell cell-status"></div>
                                </div>
                            </div>
                        </div>
                    </div>,
                    <div className="c-collection-items has-row-status">
                        {this.paged_supplier_price_list_items.map((spli_or_custom_offer_item, index) => {
                            if ('offer' in spli_or_custom_offer_item && spli_or_custom_offer_item.offer) {
                                return <CustomOfferMarketItem
                                    className={index % 2 === 0 ? 'even' : 'odd'}
                                    add_to_order={this.attrs.add_custom_offer_item_to_purchase_order}
                                    current_supplier_artkey={vnode.attrs.current_supplier_artkey}
                                    current_supplier_price_list_artkey={vnode.attrs.current_supplier_price_list_artkey}
                                    custom_offer_item={spli_or_custom_offer_item}
                                    disable_add_to_po={vnode.attrs.disable_add_to_po}
                                />
                            } {
                                return <SpliRow
                                    context={vnode.attrs.context}
                                    entity_type={vnode.attrs.entity_type}
                                    context_reset={vnode.attrs.context_reset}
                                    add_to_order={vnode.attrs.add_to_order}
                                    can_show_order_button={Boolean(vnode.attrs.add_to_order || vnode.attrs.add_custom_offer_item_to_purchase_order)}
                                    className={index % 2 === 0 ? 'even' : 'odd'}
                                    current_supplier_artkey={vnode.attrs.current_supplier_artkey}
                                    current_supplier_price_list_artkey={vnode.attrs.current_supplier_price_list_artkey}
                                    disable_add_to_po={vnode.attrs.disable_add_to_po}
                                    msi_offer_item={index === msi_offer_item_idx ? msi_offer_item : null}
                                    spli={spli_or_custom_offer_item}
                                />
                            }
                        })}

                        {/* See /#!/offer/offers/4439/custom_products > Edit */}
                        {msi_offer_item && msi_offer_item_idx === this.supplier_price_list_items.length && <MsiOfferItem
                            msi_offer_item={msi_offer_item}
                            can_show_order_button={Boolean(vnode.attrs.add_to_order || vnode.attrs.add_custom_offer_item_to_purchase_order)}
                        />}
                    </div>,
                ]}
            </div>
        </div>

    }
}

interface CustomOfferMarketItemAttrs {
    className: any
    custom_offer_item: GetCustomTboOfferItemsByCaseOfferItem
    add_to_order?: (custom_offer_item: GetCustomTboOfferItemsByCaseOfferItem) => void
    current_supplier_artkey?: number
    disable_add_to_po?: boolean
    current_supplier_price_list_artkey?: number
}

class CustomOfferMarketItem extends MithrilTsxComponent<CustomOfferMarketItemAttrs> {
    purchase_api = new PurchaseApi()

    view(vnode: m.Vnode<CustomOfferMarketItemAttrs>): m.Children {
        const custom_offer_item = vnode.attrs.custom_offer_item

        const is_competitor = custom_offer_item.supplier.company_type === 'Competitor'
        const is_current_supplier = String(custom_offer_item.supplier.artkey) === String(vnode.attrs.current_supplier_artkey)

        const features: string[] = []
        if (custom_offer_item.tax_label) {
            features.push(custom_offer_item.tax_label)
        }
        if (custom_offer_item.best_before_date) {
            features.push(`BBD: ${format_iso_to_date(custom_offer_item.best_before_date)}`)
        }

        if (custom_offer_item.offer.incoterm) {
            custom_offer_item.incoterm = `${custom_offer_item.offer.incoterm} - ${custom_offer_item.offer.incoterm_location}`
        } else {
            custom_offer_item.incoterm = 'default for relation'
        }

        return <div className={classes('c-custom-offer-market-item item', {
            [$m.data.company_type_class(custom_offer_item.supplier.company_type)]: true,
            'is-current-supplier': is_current_supplier,
        },vnode.attrs.className)}>
            <div className="cells" style="grid-template-columns: 1fr 1fr 1fr 1fr var(--coll-status-width);">
                <div className="cell cell-type-default">
                    <div className="td-group">
                        <span className="header">
                            {custom_offer_item.supplier.name}
                        </span>
                        {(() => {
                            return <span className="details">{to_specs({
                                bottle_alcohol_percentage: custom_offer_item.alcohol_percentage,
                                bottle_refill_status: custom_offer_item.refill_status,
                                bottle_volume: custom_offer_item.volume,
                                case_number_of_bottles: custom_offer_item.number_of_bottles,
                                case_gift_box_type: custom_offer_item.gift_box_type,
                                case_customs_status: custom_offer_item.customs_status,
                            })}</span>
                        })()}
                        {<span className="details">
                            {features.join(' / ')}
                                - Not available (offer)
                        </span>}
                    </div>
                </div>
                <div className="cell cell-type-default">
                    <AmountUnit
                        case_amount={custom_offer_item.purchase_price_per_case}
                        case_number_of_bottles={custom_offer_item.number_of_bottles}
                        currency={custom_offer_item.purchase_currency}
                        display_currency={$s.currencies.default}
                    />
                </div>
                <div className="cell cell-type-default">{custom_offer_item.incoterm}</div>
                <div className="cell cell-type-default">{format_iso_to_date(custom_offer_item.offer.start_date)}</div>
                <div className="cell cell-actions">
                    {(() => {
                        const can_order = vnode.attrs.add_to_order
                        const elements = [] as any

                        if (!vnode.attrs.disable_add_to_po) {
                            elements.push(<Button
                                disabled={!can_order}
                                icon="cart"
                                onclick={() => {
                                    if (!vnode.attrs.add_to_order) return
                                    vnode.attrs.add_to_order(custom_offer_item)
                                }}
                                tip={() => {
                                    if (!can_order) return
                                    let supplier_status = ''
                                    if (is_current_supplier) supplier_status = ' (from this supplier)'
                                    else if (is_competitor) supplier_status = ' (from competitor!)'
                                    return `Add to Purchase Order${supplier_status}`
                                }}
                                type={is_competitor ? 'danger' : 'default'}
                                variant="toggle"
                            />)
                        }

                        elements.push(<Button
                            icon="search"
                            tip="To the offer that contains this custom offer item"
                            type="info"
                            onclick={() => {
                                window.open(`#!/offer/offers/${custom_offer_item.offer.artkey}/custom_products`)
                            }}
                            variant="toggle"
                        />)
                        return elements

                    })()}

                </div>
            </div>
        </div>

    }
}

interface SpliRowAttrs {
    add_to_order?: (spli: SupplierPriceListItem) => void
    className: any
    can_show_order_button: boolean
    current_supplier_artkey: number
    current_supplier_price_list_artkey?: number
    // If provided will be rendered BEFORE the supplier price list item.
    disable_add_to_po?: boolean
    msi_offer_item: any
    spli: SupplierPriceListItem
    context?: typeof ContextProvider
    entity_type?: any
    context_reset?: () => void
}

class SpliRow extends MithrilTsxComponent<SpliRowAttrs> {
    purchase_api = new PurchaseApi()

    _strike_through_if_not_available(spli: SupplierPriceListItem, text: string) {
        if (spli.is_available === false) {
            return <s class={'analysis-bad-color'}>{text}</s>
        }
        return text
    }

    view(vnode: m.Vnode<SpliRowAttrs>): m.Children {
        let entity = null

        if (vnode.attrs.context && vnode.attrs.entity_type) {
            entity = vnode.attrs.context.data.entities[vnode.attrs.entity_type]
        }

        const spli = vnode.attrs.spli
        const is_competitor = spli.supplier.company_type === 'Competitor'

        const is_current_supplier = String(spli.supplier.artkey) === String(vnode.attrs.current_supplier_artkey)
        const is_current_supplier_price_list = String(spli.supplier_price_list.artkey) === String(vnode.attrs.current_supplier_price_list_artkey)
        const msi_offer_item = vnode.attrs.msi_offer_item
        return [
            msi_offer_item ? <MsiOfferItem
                msi_offer_item={msi_offer_item}
                can_show_order_button={Boolean(vnode.attrs.add_to_order || vnode.attrs.add_custom_offer_item_to_purchase_order)}
            /> : null,
            <div className={classes('item', {
                selected: entity && entity.artkey === spli.artkey,
                [$m.data.company_type_class(spli.supplier.company_type)]: true,
                'is-current-supplier-price-list': is_current_supplier_price_list,
                'is-current-supplier': is_current_supplier,
            }, vnode.attrs.className)}>
                <div className="cells" style="grid-template-columns: 1fr 1fr 1fr 1fr var(--coll-status-width);">
                    <div className="cell">
                        <div className="td-group">
                            <span className="header">
                                {accountIconBySlug(spli.supplier.account.slug, spli.supplier.account.name)}
                                {this._strike_through_if_not_available(spli, spli.supplier.name)}
                            </span>
                            {(() => {
                                return <span className="details">{to_specs({
                                    bottle_alcohol_percentage: spli.bottle.alcohol_percentage,
                                    bottle_refill_status: spli.bottle.refill_status,
                                    bottle_volume: spli.bottle.volume,
                                    case_number_of_bottles: spli.number_of_bottles_per_case,
                                    case_gift_box_type: spli.gift_box_type,
                                    case_customs_status: spli.customs_status,
                                })}</span>
                            })()}
                            {spli.aux_info && <span className="details">
                                {spli.aux_info}
                                {spli.availability_status && ` - ${spli.availability_status}`}
                            </span>
                            }
                            {(spli.cases_per_pallet || spli.cases_per_pallet_layer) && (
                                <div className='dimension-info'>
                                    <Icon name='cpp' size='xs'/>
                                    <div className='stat'>
                                        {spli.cases_per_pallet &&
                                            <span>{`${spli.cases_per_pallet} cpp`}</span>
                                        }
                                        {spli.cases_per_pallet_layer &&
                                            <span>{`${spli.cases_per_pallet_layer} cpl`}</span>
                                        }
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="cell cell-type-default">
                        <div className="td-group">
                            <AmountUnit
                                case_amount={spli.price_per_case}
                                case_number_of_bottles={spli.number_of_bottles_per_case}
                                currency={spli.currency}
                                display_currency={$s.currencies.default}
                                excise={spli.case_excise_nl}
                            />
                        </div>
                    </div>

                    <div className="cell cell-type-default">{spli.incoterm}</div>

                    <div className="cell cell-type-default">
                        {spli.supplier_price_list.start_date &&
                        <div className="td-group">
                            <span>
                                {format_iso_to_date(spli.supplier_price_list.start_date)} - {spli.supplier_price_list.end_date ? format_iso_to_date(spli.supplier_price_list.end_date) : 'N/A'}
                            </span>
                            <span className="details">{spli.supplier_price_list.status.replace('_', ' ')}</span>
                        </div>}
                    </div>
                    <div className="cell cell-type-default cell-actions">
                        {(() => {
                            const can_order = vnode.attrs.can_show_order_button && !!spli.supplier_price_list.artkey && (spli.supplier.account.slug === current_account_slug())
                            const elements = [] as any

                            if (!vnode.attrs.disable_add_to_po) {
                                elements.push(<Button
                                    active={entity && entity.artkey === spli.artkey}
                                    disabled={!can_order}
                                    onclick={() => {
                                        if (!vnode.attrs.add_to_order) return

                                        if (entity && vnode.attrs.context_reset) {
                                            // Already active; toggllng again resets the value.
                                            if (entity.artkey === spli.artkey) {
                                                vnode.attrs.context_reset()
                                            } else {
                                                vnode.attrs.add_to_order(spli)
                                            }
                                        } else {
                                            vnode.attrs.add_to_order(spli)
                                        }
                                    }}
                                    icon="cart"
                                    tip={() => {
                                        if (!can_order) return
                                        let supplier_status = ''
                                        if (is_current_supplier_price_list) supplier_status = ' (from this supplier pricelist)'
                                        else if (is_current_supplier) supplier_status = ' (from this supplier)'
                                        else if (is_competitor) supplier_status = ' (from competitor!)'
                                        return `Add to Purchase Order${supplier_status}`}
                                    }
                                    type="info"
                                    variant="toggle"
                                />)
                            }
                            elements.push(<Button
                                icon="search"
                                tip="Search for this product in the current pricelist"
                                onclick={() => {
                                    window.open(`#!${pricelist_link.from_bottle_json(spli.supplier_price_list.artkey, spli.bottle)}`)
                                }}
                                variant="toggle"
                            />)

                            return elements
                        })()}
                    </div>

                    <div className="cell cell-type-default cell-status">
                        {spli.supplier_price_list.description && <Icon
                            name="info"
                            tip={spli.supplier_price_list.description}
                            type="info"
                        />}
                    </div>
                </div>
            </div>,
        ]
    }
}

interface MsiOfferItemAttrs {
    msi_offer_item: any
    can_show_order_button: boolean
}

class MsiOfferItem extends MithrilTsxComponent<MsiOfferItemAttrs> {
    view(vnode: m.Vnode<MsiOfferItemAttrs>): m.Children {
        const msi_offer_item = vnode.attrs.msi_offer_item

        const features: string[] = []
        if (msi_offer_item.tax_label()) {
            features.push(msi_offer_item.tax_label())
        }
        if (msi_offer_item.best_before_date()) {
            features.push(`BBD: ${format_iso_to_date(msi_offer_item.best_before_date())}`)
        }

        return (
            <div className={classes('item', 'c-msi-offeritem', 'is-current-offer', 'is-current-supplier')}>
                <div className="cells" style="grid-template-columns: 1fr 1fr 1fr 1fr var(--coll-status-width);">
                    <div className="cell cell-type-default">
                        <div className="td-group">
                            <span className="header">This offer</span>
                            {(() => {
                                return <span className="details">{to_specs({
                                    bottle_alcohol_percentage: msi_offer_item.alcohol_percentage(),
                                    bottle_refill_status: msi_offer_item.refill_status(),
                                    bottle_volume: msi_offer_item.volume(),
                                    case_number_of_bottles: msi_offer_item.number_of_bottles(),
                                    case_gift_box_type: msi_offer_item.gift_box_type(),
                                    case_customs_status: msi_offer_item.customs_status(),
                                })}</span>
                            })()}
                            {!!features.length && <span className="details">
                                {features.join(' / ')}
                            </span>}
                        </div>
                    </div>
                    <div className="cell cell-type-default">
                        <div className="td-group">
                            <AmountUnit
                                case_amount={msi_offer_item.price_per_case()}
                                case_number_of_bottles={msi_offer_item.number_of_bottles()}
                                currency={msi_offer_item.currency()}
                                display_currency={$s.currencies.default}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
